import React from 'react';
import config from '../config';
import { GoogleTagAnalytics } from './googletags';

export interface Analytics {
  identify(id: string, properties?: { [property: string]: string }): void;
  screen(name: string, properties?: { [property: string]: string }): void;
  event(name: string, properties?: { [property: string]: unknown }): void;
  reset(): void;
}

class AggregateAnalytics implements Analytics {
  analytics: Analytics[];

  constructor() {
    this.analytics = [new GoogleTagAnalytics()];
    return;
  }

  identify(id: string, properties: { [property: string]: string }): void {
    try {
      this.analytics.forEach((a) => a.identify(id, properties));
    } catch (e) {
      if (config().environment !== 'production') {
        throw e;
      }
    }
  }
  screen(name: string, properties: { [property: string]: string }): void {
    try {
      this.analytics.forEach((a) => a.screen(name, properties));
    } catch (e) {
      if (config().environment !== 'production') {
        throw e;
      }
    }
  }
  event(name: string, properties: { [property: string]: unknown }): void {
    try {
      this.analytics.forEach((a) => a.event(name, properties));
    } catch (e) {
      if (config().environment !== 'production') {
        throw e;
      }
    }
  }
  reset(): void {
    try {
      this.analytics.forEach((a) => a.reset());
    } catch (e) {
      if (config().environment !== 'production') {
        throw e;
      }
    }
  }
}

export const useAnalytics = (): Analytics => {
  const [analytics] = React.useState<Analytics>(new AggregateAnalytics());
  return analytics;
};
