import styled from 'styled-components';

const SwitchContainer = styled.div``;

const SwitchLabel = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;

  opacity: ${(props) => (props.disabled ? 0.5 : 1.0)};
  transition: 0.15s all ease-in-out;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(200, 200, 200, 0.8);
    transition: 0.15s all ease-in-out;
    border-radius: 34px;
    background: white;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: rgba(200, 200, 200, 0.8);
    transition: 0.15s all ease-in-out;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #0ea5e9;
    border: 1px solid #0ea5e9;
  }

  input:checked + .slider:before {
    transform: translateX(20px);
    background-color: white;
  }
`;

export const Switch = (props: {
  enabled?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onChange?: (checked: boolean) => void;
}): JSX.Element => (
  <SwitchContainer>
    <SwitchLabel disabled={props.disabled || props.loading}>
      <input
        type="checkbox"
        disabled={props.disabled || props.loading}
        checked={props.enabled}
        onInput={(e) => {
          props.onChange?.(e.currentTarget.checked);
        }}
      />
      <span className="slider" />
    </SwitchLabel>
  </SwitchContainer>
);
