import {
  CertificateLeftSection,
  CertificateRightSection,
  CertificateSectionContainer
} from './certificate';

const CertificatePolicySkeleton = () => (
  <div>
    <div className="h-6 py-1 mt-3 w-2/3 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
    <div className="h-4 py-1 mt-3 w-1/2 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
    <div className="h-4 py-1 mt-1 w-1/3 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />

    <div className="mt-2">
      <div className="h-[22px] py-1 mt-1.5 w-9/12 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
      <div className="h-[22px] py-1 mt-1.5 w-11/12 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
      <div className="h-[22px] py-1 mt-1.5 w-7/12 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
      <div className="h-[22px] py-1 mt-1.5 w-8/12 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
    </div>
  </div>
);

const CertificateSectionSkeleton = () => (
  <CertificateSectionContainer>
    <CertificateLeftSection>
      <CertificatePolicySkeleton />
      <CertificatePolicySkeleton />
    </CertificateLeftSection>
    <CertificateRightSection>
      <div>
        <div className="h-4 py-1 mt-1 w-1/2 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
        <div className="h-5 py-1 mt-3 w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
        <div className="h-5 py-1 mt-1 w-7/12 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
        <div className="h-5 py-1 mt-1 w-10/12 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
      </div>
      <div>
        <div className="h-4 py-1 mt-1 w-full bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
        <div className="h-5 py-1 mt-3 w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
        <div className="h-5 py-1 mt-1 w-7/12 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
        <div className="h-5 py-1 mt-1 w-10/12 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
      </div>
      <div>
        <div className="h-4 py-1 mt-1 w-full bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
        <div className="h-5 py-1 mt-3 w-full bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
        <div className="h-5 py-1 mt-1 w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
        <div className="h-5 py-1 mt-1 w-10/12 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
      </div>
    </CertificateRightSection>
  </CertificateSectionContainer>
);

export const CertificateSkeleton = () => (
  <>
    <div className="h-4 py-1 mt-2.5 w-11/12 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
    <div className="h-4 py-1 mt-1.5 w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />

    <div className="flex gap-2 mt-4">
      <div className="h-8 py-1 w-24 bg-neutral-300 rounded-full dark:bg-neutral-700 animate-pulse" />
      <div className="h-8 py-1 w-36 bg-neutral-300 rounded-full dark:bg-neutral-700 animate-pulse" />
    </div>

    <div className="mt-4">
      <CertificateSectionSkeleton />
    </div>
  </>
);
