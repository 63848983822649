import apm from '@oysterjs/core/apm';
import { FeatureFlag, MerchantUser } from '@oysterjs/types';
import { Get, getEncodedQueryString } from './base';
import { getUser } from '@oysterjs/core/auth';
import config from '@oysterjs/core/config';

const getFlagOptions = () =>
  getEncodedQueryString({
    MerchantAPIKey: config().merchant?.apiKey,
    MerchantID: getUser<MerchantUser>()?.MerchantID,
    UserID: apm().getSession().userId
  });

const evaluateFlag = <T>(flagId: string) => Get<T>(`/flag/${flagId}?${getFlagOptions()}`);

const listFlags: () => Promise<{ FeatureFlags: FeatureFlag[] }> = () =>
  Get(`/flags?${getFlagOptions()}`);

const evaluateBooleanFlag = (flag: string) =>
  evaluateFlag<{ Enabled: boolean }>(flag).then((d) => d.Enabled);

export const shouldRender = () => evaluateBooleanFlag('render_widget');

export const shouldRenderPostCheckoutOffer = () =>
  evaluateBooleanFlag('render_post_checkout_offer');

export const enableDefaultInsurance = () => evaluateBooleanFlag('enable_default_insurance');

export const enableCartWidget = () => evaluateBooleanFlag('enable_cart_widget');

export const shouldRenderOnelinerWidget = () => evaluateBooleanFlag('force_render_oneliner_widget');

export const shouldRenderLandingPage = () => evaluateBooleanFlag('render_marketing_page');

export const enableCommercialOauth = () => evaluateBooleanFlag('enable_commercial_oauth');

export const enableCommercialDocumentExtraction = () =>
  evaluateBooleanFlag('enable_commercial_document_extraction');

export const allowCartWidgetObservables = () =>
  evaluateBooleanFlag('allow_cart_widget_observables');

export const enableRiskManagement = () => evaluateBooleanFlag('enable-risk-management');

export const retrieveAllFeatureFlags = (): Promise<FeatureFlag[]> =>
  listFlags().then((res) => res.FeatureFlags);
