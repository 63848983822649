import config from '@oysterjs/core/config';
import { PolicyType } from '@oysterjs/types';
import styled from 'styled-components';

const FooterStandardContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
`;

const FooterContainer = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  justify-content: space-between;
  gap: 10px;
`;

const FooterLinksContainer = styled.div`
  display: flex;

  a {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const OysterCopyright = (props: { color?: string }) => (
  <div style={{ color: props.color || '#484848', opacity: 0.5 }}>
    &copy; 2024 Oyster Technologies, Inc.
  </div>
);

const CustomFooter = (props: { policyType?: PolicyType }) => {
  switch (props.policyType) {
    case PolicyType.chubbJewelry:
      return (
        <div style={{ color: '#999999' }}>
          Insurance described is offered by Oyster Insurance Agency, LLC (California license no.
          6006158). Insurance is underwritten and provided by Federal Insurance Company (Whitehouse
          Station, NJ), a Chubb ® company. Chubb is the marketing name used to refer to
          subsidiaries of Chubb Limited providing insurance and related services. Coverage is
          subject to the language of the policies as actually issued.
        </div>
      );
    default:
      return <></>;
  }
};

export const FooterStandard = (props: { policyType?: PolicyType; color?: string }): JSX.Element => (
  <FooterContainer>
    <FooterStandardContainer>
      <OysterCopyright color={props.color} />
      <FooterLinksContainer>
        <a
          target="_blank"
          href={
            config().serviceName === 'partners'
              ? 'mailto:partners@withoyster.com'
              : 'mailto:support@withoyster.com'
          }
        >
          Contact Us
        </a>
        <a
          target="_blank"
          href={
            config().serviceName == 'partners'
              ? 'https://www.withoyster.com/merchant-partner-agreement'
              : 'https://www.withoyster.com/terms-conditions'
          }
        >
          Terms
        </a>
        <a target="_blank" href="https://www.withoyster.com/privacy-policy">
          Privacy
        </a>
      </FooterLinksContainer>
    </FooterStandardContainer>
    <CustomFooter policyType={props.policyType} />
  </FooterContainer>
);

const FooterMinimalContainer = styled.div`
  padding: 20px 40px;
  display: flex;
  font-size: 0.8em;
  justify-content: space-around;
`;

export const FooterMinimal = (props: { color?: string }): JSX.Element => (
  <FooterMinimalContainer>
    <OysterCopyright color={props.color} />
  </FooterMinimalContainer>
);
