import { IoArrowForward } from 'react-icons/io5';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { RentalPageContainer } from './components';

export const RentalWaiverSentPage = (props: { onClose: () => void }) => (
  <RentalPageContainer
    title="Waiver Sent!"
    description="We've sent the customer a link to complete the waiver. Let your customer know that they
          need to read the terms of the waiver, sign, and pay - all of which can be done from their
          mobile device."
  >
    <img style={{ width: '100%' }} src="/images/celebrate.svg" alt="" />
    {props.onClose && (
      <ButtonContainer center style={{ padding: '20px 0px' }}>
        <Button icon={<IoArrowForward />} primary onClick={props.onClose}>
          Close
        </Button>
      </ButtonContainer>
    )}
  </RentalPageContainer>
);
