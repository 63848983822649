import apm from '@oysterjs/core/apm';

import * as React from 'react';
import { MerchantUser, User } from '@oysterjs/types';

interface Auth<T = User | MerchantUser> {
  Token?: string;
  // TODO: the backend currently returns a separate field, `MerchantUser`
  // for merchant users. We should unify these based on the typing here.
  User?: T;
  MerchantUser?: MerchantUser;
}

export type SetAuthFn = (auth: Auth) => void;

export const useAuth = (): [Auth, SetAuthFn] => {
  const getAuth = () => {
    const data = window.localStorage.getItem('oyster_token');
    if (data) {
      const auth: Auth = JSON.parse(data);
      apm().setUserContext({
        id: auth.User?.ID || auth.MerchantUser?.ID,
        email: auth.User?.Email || auth.MerchantUser?.Email
      });
      return auth;
    }

    return {};
  };

  const setAuth = (auth: Auth) => {
    apm().setUserContext({
      id: auth.User?.ID || auth.MerchantUser?.ID,
      email: auth.User?.Email || auth.MerchantUser?.Email
    });

    window.localStorage.setItem('oyster_token', JSON.stringify(auth));
    _setAuth(auth);
  };

  const [auth, _setAuth] = React.useState<Auth>(getAuth());

  React.useEffect(() => {
    const updateAuth = () => setAuth(getAuth());
    window.addEventListener('storage', updateAuth);
    return () => window.removeEventListener('storage', updateAuth);
  }, []);

  return [auth, setAuth];
};

export const getToken = (): string | null => {
  const data = window.localStorage.getItem('oyster_token');
  return !data ? null : JSON.parse(data)?.Token;
};

export const getUser = <T = User | MerchantUser,>(): T | null => {
  const data = window.localStorage.getItem('oyster_token');
  return !data ? null : JSON.parse(data)?.User || JSON.parse(data)?.MerchantUser;
};

export const resetToken = (redirect?: string): void => {
  window.localStorage.removeItem('oyster_token');
  window.location.href = `/signin${redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''}`;
};
