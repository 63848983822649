import { FeatureFlag, FeatureFlagExperiments } from '@oysterjs/types';
import { retrieveAllFeatureFlags } from './flag';

let appConfiguration: AppConfiguration;

export interface AppConfiguration {
  ensureFeatureFlags(): Promise<void>;
  getAll(): Promise<FeatureFlag[]>;
  getAllSync(): FeatureFlag[];
  get(flagId: string): Promise<FeatureFlag | undefined>;
  update(flagId: string, updateFunction: (featureFlag: FeatureFlag) => void): Promise<void>;
}

class MainAppConfiguration implements AppConfiguration {
  featureFlags: FeatureFlag[] = [];
  retrieveFlagsPromise: Promise<FeatureFlag[] | undefined> | undefined;

  async ensureFeatureFlags(): Promise<void> {
    if (this.featureFlags.length === 0) {
      if (!this.retrieveFlagsPromise) {
        // Set a 2 second timeout so we don't have missing events
        // if FF API is not available
        const timeoutPromise = new Promise<FeatureFlag[] | undefined>((resolve) => {
          const timeoutId = setTimeout(() => {
            clearTimeout(timeoutId);
            resolve(undefined);
          }, 2000);
        });

        this.retrieveFlagsPromise = Promise.race([timeoutPromise, retrieveAllFeatureFlags()]);
      }

      // Update flags variable on resolve
      await this.retrieveFlagsPromise?.then((res) => {
        if (res) {
          this.featureFlags = res;
        }
      });

      // Clean up the promise
      this.retrieveFlagsPromise = undefined;
    }

    return;
  }

  async getAll(): Promise<FeatureFlag[]> {
    await this.ensureFeatureFlags();
    return this.featureFlags;
  }

  getAllSync(): FeatureFlag[] {
    return this.featureFlags;
  }

  async get(flagId: string): Promise<FeatureFlag | undefined> {
    await this.ensureFeatureFlags();
    return this.featureFlags.find((ff) => ff.ID === flagId);
  }

  async update(flagId: string, updateFunction: (featureFlag: FeatureFlag) => void): Promise<void> {
    await this.ensureFeatureFlags();
    const updateIndex = this.featureFlags.findIndex((f) => f.ID === flagId);
    if (updateIndex >= 0) {
      // Update existing feature flags
      updateFunction(this.featureFlags[updateIndex]);
    } else {
      // Create a new feature flag and insert it
      const ff: FeatureFlag = {
        ID: flagId,
        Value: false
      };
      updateFunction(ff);

      this.featureFlags = [...this.featureFlags, ff];
    }

    return;
  }
}

export class MockAppConfiguration implements AppConfiguration {
  featureFlags = [
    {
      ID: FeatureFlagExperiments.oneliner_widget,
      Value: true
    }
  ];

  async ensureFeatureFlags(): Promise<void> {
    return;
  }
  async getAll(): Promise<FeatureFlag[]> {
    return this.featureFlags;
  }
  getAllSync(): FeatureFlag[] {
    return this.featureFlags;
  }
  async get(flagId: string): Promise<FeatureFlag | undefined> {
    return this.featureFlags.find((ff) => ff.ID === flagId);
  }
  async update(): Promise<void> {
    // Do nothing for now
    return;
  }
}

// This needs to be called after apm has been initialized.
export const init = async () => {
  appConfiguration = new MainAppConfiguration();
  await appConfiguration.ensureFeatureFlags();
};

export const setAppConfiguration = (config: AppConfiguration) => {
  appConfiguration = config;
};

// eslint-disable-next-line
// @ts-ignore
export default (): AppConfiguration => appConfiguration;
