import clsx from 'clsx';

import {
  MerchantIntegration,
  MerchantRentalConfiguration,
  PolicyTableValues,
  WaiverEntry
} from '@oysterjs/types';
import { getDisplayInsuranceType } from '../../types/map';
import { BusinessPolicyFieldsFragment, FileRole, PolicyState } from '../../types/graphql';
import { Button } from '@oysterjs/ui/v2/button';
import { ArrowRightIcon, DocumentIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import { MerchantFieldsFragment } from '../../types/graphql';
import { PageTitle } from '@oysterjs/ui/Page/section';
import { useHistory } from 'react-router';

interface HomePageProps {
  merchant: MerchantFieldsFragment;
  businessPolicies: BusinessPolicyFieldsFragment[];
  policyReferrals?: PolicyTableValues[];
  integrations: MerchantIntegration[];
  rentalConfiguration?: MerchantRentalConfiguration;
  rentalWaivers: WaiverEntry[];
}

const getBadgeColor = (status: PolicyState): string => {
  switch (status) {
    case PolicyState.Inforce:
      return 'bg-green-50 text-green-700 ring-green-600/20';
    case PolicyState.Binding:
    case PolicyState.Pending:
      return 'bg-amber-50 text-amber-700 ring-amber-600/20';
    case PolicyState.Canceled:
      return 'bg-red-50 text-red-700 ring-red-600/20';
    default:
      return 'bg-neutral-50 text-neutral-700 ring-neutral-600/20';
  }
};

const ChannelBadge = (props: { state: PolicyState }) => (
  <span
    className={clsx(
      'inline-flex flex-shrink-0 rounded-full px-2.5 py-0.5 text-xs font-medium ring-1 ring-inset m-0 ml-1',
      getBadgeColor(props.state)
    )}
  >
    {props.state || 'UNKNOWN'}
  </span>
);

const BusinessInsuranceSection = (props: HomePageProps) => {
  const history = useHistory();
  const policies = props.businessPolicies.filter((p) => p.state !== PolicyState.Expired);

  if (policies.length) {
    return (
      <ul role="list" className="grid grid-cols-1 gap-8 mt-8 @lg:grid-cols-2 @5xl:grid-cols-3">
        {policies.map((p) => (
          <li
            role="button"
            key={p.id}
            className="col-span-1 rounded-lg bg-white shadow transition-all duration-200 hover:shadow-lg cursor-pointer"
            onClick={() => history.push(`/insurance/overview/policy/${p.id}`)}
          >
            <div className="flex w-full justify-between space-x-6 p-6">
              <div className="flex-1">
                <div className="flex items-start gap-x-3 flex-row">
                  <h3 className="text-sm my-0 font-medium text-neutral-900">
                    {getDisplayInsuranceType(p.type)}
                  </h3>
                  <ChannelBadge state={p.state} />
                </div>
                <div className="flex items-center gap-x-2 mt-1 text-neutral-500">
                  <p className="text-sm m-0">{p.carrier?.name || 'N/A'}</p>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle r={1} cx={1} cy={1} />
                  </svg>{' '}
                  <p className="text-sm m-0">{p.policyNumber || 'N/A'}</p>
                </div>

                {p.expiresAt && (
                  <p className="text-sm mt-1 text-neutral-500">
                    Expires{' '}
                    {new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(
                      new Date(p.expiresAt)
                    )}
                  </p>
                )}
              </div>
              <div className="inline-flex rounded-md p-3 bg-neutral-100 self-start">
                <ShieldCheckIcon className="w-5 h-5 text-neutral-700" />
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="py-8">
      <p className="text-sm text-center text-neutral-500">
        You have no business insurance policies.
      </p>
      <div className="flex justify-center mt-2">
        <Button type="button" color="sky" href="/insurance">
          Apply for Coverage
        </Button>
      </div>
    </div>
  );
};

const CertificatesSection = (props: HomePageProps) => {
  const certificates =
    props.merchant?.files
      ?.filter((f) => f.role === FileRole.Certificate)
      .filter((f) => !!f.details?.certificateNumber)
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) || [];

  const history = useHistory();

  if (certificates.length) {
    return (
      <ul role="list" className="grid grid-cols-1 gap-8 mt-8 @2xl:grid-cols-2">
        {certificates.slice(0, 3).map((certificate) => (
          <li
            role="button"
            key={certificate.id}
            className="col-span-1 rounded-lg bg-white shadow transition-all duration-200 hover:shadow-lg cursor-pointer"
            onClick={() =>
              history.push(
                `/insurance/certificates/certificate/${certificate.details?.certificateNumber}`
              )
            }
          >
            <div className="flex w-full justify-between space-x-6 px-6 py-4">
              <div className="flex-1">
                <div className="flex items-start gap-x-3 flex-row">
                  <h3 className="text-sm my-0 font-medium text-neutral-900">
                    {certificate.details?.certificateHolder.name}
                  </h3>
                </div>
                <p className="text-sm mt-1 text-neutral-500">
                  {new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(
                    new Date(certificate.createdAt)
                  )}
                </p>
              </div>
              <div className="inline-flex rounded-md p-3 bg-neutral-100 self-start">
                <DocumentIcon className="w-5 h-5" />
              </div>
            </div>
          </li>
        ))}
        <li
          key="create-certificate"
          role="button"
          className="col-span-1 px-2 py-4 rounded-lg border border-dashed border-neutral-300 transition-all duration-200 hover:border-neutral-400 cursor-pointer"
          onClick={() => history.push(`/insurance`)}
        >
          <div className="flex w-full h-full justify-center">
            <a className="self-center text-center text-sm text-primary-600 font-medium">
              View and create more <ArrowRightIcon className="inline w-4 h-4" />
            </a>
          </div>
        </li>
      </ul>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-8 mt-8 @2xl:grid-cols-2">
      <div
        className="col-span-1 py-4 px-2 rounded-lg border border-dashed border-neutral-300 transition-all duration-200 hover:border-neutral-400 cursor-pointer"
        onClick={() => history.push(`/insurance/certificates/create`)}
      >
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-sm text-neutral-500 w-full text-center">No certificates created.</p>
          <a
            className="self-center text-center text-sm text-primary-600 font-medium"
            href="/insurance/certificates/create"
          >
            Create Certificate
          </a>
        </div>
      </div>
    </div>
  );
};

export const HomePage = (props: HomePageProps) => {
  const history = useHistory();

  return (
    <div className="@container">
      <PageTitle
        title="Welcome to Oyster"
        description="View and manage your business insurance policies, certificates, risk, and more"
      />
      <div className="mt-8 border-b border-neutral-200 pb-4 sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-base font-medium text-neutral-900 font-body m-0">
            Business Insurance
          </h2>
          <p className="mt-1 text-sm text-neutral-500">
            View and manage your business insurance policies
          </p>
        </div>
        <div className="mt-3 flex sm:ml-4 sm:mt-0 gap-2">
          {props.businessPolicies.length > 0 && (
            <Button type="button" color="white" href="/insurance">
              View All
            </Button>
          )}
          <Button type="button" color="sky" href="/insurance">
            Apply for Coverage
          </Button>
        </div>
      </div>
      <BusinessInsuranceSection {...props} />

      {/* {props.businessPolicies.length > 0 && (
        <>
          <div className="mt-8 border-b border-neutral-200 pb-4 sm:flex sm:items-center sm:justify-between">
            <div>
              <h2 className="text-base font-medium text-neutral-900 font-body m-0">
                Certificates of Insurance
              </h2>
              <p className="mt-1 text-sm text-neutral-500">
                Recently created certificates of insurance
              </p>
            </div>
            <div className="mt-3 flex sm:ml-4 sm:mt-0 gap-2">
              <Button type="button" color="sky" href="/insurance/certificates/create">
                Create Certificate
              </Button>
            </div>
          </div>
          <CertificatesSection {...props} />
        </>
      )} */}

      <div className="grid grid-cols-1 gap-8 mt-8 @md:grid-cols-2 @xl:grid-cols-3">
        {props.businessPolicies.length > 0 && (
          <div className="col-span-1 @md:col-span-2 @xl:col-span-2">
            <div className="border-b border-neutral-200 pb-4 sm:flex sm:items-center sm:justify-between">
              <div>
                <h2 className="text-base font-medium text-neutral-900 font-body m-0">
                  Certificates of Insurance
                </h2>
                <p className="mt-1 text-sm text-neutral-500">
                  Recently created certificates of insurance
                </p>
              </div>
              {/* <div className="mt-3 flex sm:ml-4 sm:mt-0 gap-2">
                <Button type="button" color="sky" href="/insurance/certificates/create">
                  Create
                </Button>
              </div> */}
            </div>
            <CertificatesSection {...props} />
          </div>
        )}
        {/* <div className="col-span-1 @md:col-span-2 @xl:col-span-2">
          <div className="mt-8 border-b border-neutral-200 pb-4 sm:flex sm:items-center sm:justify-between">
            <div>
              <h2 className="text-base font-medium text-neutral-900 font-body m-0">Integrations</h2>
              <p className="mt-1 text-sm text-neutral-500">Your live integrations and channels</p>
            </div>
            <div className="mt-3 flex sm:ml-4 sm:mt-0 gap-2">
              <Button type="button" color="white" href="/integrations">
                View All
              </Button>
            </div>
          </div>
          {integrations.length > 0 && (
            <ul
              role="list"
              className="grid grid-cols-1 gap-8 mt-8 @4xl:grid-cols-2 @8xl:grid-cols-3"
            >
              {integrations.map((integration) => (
                <ChannelCard
                  key={integration.ID}
                  type={integration.Type}
                  status={integration.Status}
                />
              ))}
            </ul>
          )}
          {integrations.length === 0 && (
            <div className="mt-8">
              <p className="text-sm text-center text-neutral-500">You have no live integrations.</p>
              <div className="flex justify-center mt-2">
                <Button type="button" color="sky" href="/integrations">
                  Set up an integration
                </Button>
              </div>
            </div>
          )}
        </div> */}
        <div className="col-span-1 @md:col-span-2 @xl:col-span-1">
          <div className="border-b border-neutral-200 pb-4 sm:flex sm:items-center sm:justify-between">
            <div>
              <h2 className="text-base font-medium text-neutral-900 font-body m-0">
                Risk Management
              </h2>
              <p className="mt-1 text-sm text-neutral-500">Manage business risk</p>
            </div>
          </div>
          <div className="mt-8">
            <div
              className="col-span-1 py-4 px-2 rounded-lg border border-dashed border-neutral-300 transition-all duration-200 hover:border-neutral-400 cursor-pointer"
              onClick={() => history.push(`/risk`)}
            >
              <div className="flex flex-col items-center justify-center h-full">
                <p className="text-sm text-neutral-500 w-full text-center">No data available.</p>
                <a
                  className="self-center text-center text-sm text-primary-600 font-medium"
                  href="/risk"
                >
                  Set up risk management
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
