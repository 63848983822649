import * as React from 'react';
import styled from 'styled-components';

import apm from '@oysterjs/core/apm';
import { WrappedError } from '@oysterjs/core/errors';
import { IoCheckmark, IoClipboard } from 'react-icons/io5';

const ErrorIllustration = styled.img<{ forceMobile?: boolean }>`
  border-radius: 8px;
  width: 100%;
  max-width: ${(props) => (props.forceMobile ? '400px' : '300px')};
  transform: scaleX(-1);

  @media (max-width: 700px) {
    max-width: 250px;
  }

  @media (max-width: 600px) {
    max-width: 400px;
  }
`;

const ErrorContainer = styled.div<{ forceMobile?: boolean }>`
  padding: 40px;
  display: flex;
  max-width: 1000px;
  box-sizing: border-box;
  margin: 0 auto;
  flex-direction: ${(props) => (props.forceMobile ? 'column' : 'row')};
  gap: 20px;
  align-items: flex-start;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ErrorDetailsContainer = styled.div`
  position: relative;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  max-width: 500px;
  width: 100%;
  background: #eaeaea;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ErrorDetailContainer = styled.div`
  gap: 10px;
`;

const ErrorDetailTitle = styled.div`
  text-transform: uppercase;
  color: #666666;
  font-size: 0.7em;
  font-weight: 600;
  padding-bottom: 4px;
`;
const ErrorDetailDescription = styled.pre`
  font-size: 1em;
  font-family: monospace;
  white-space: pre-wrap;
`;

export const ErrorDetail = (props: { title: string; description: string }) => (
  <ErrorDetailContainer>
    <ErrorDetailTitle>{props.title}</ErrorDetailTitle>
    <ErrorDetailDescription>{props.description}</ErrorDetailDescription>
  </ErrorDetailContainer>
);

const ErrorCopyData = styled.button`
  border: 1px solid #e8e8e8;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: rgba(255, 255, 255, 0.65);
  color: #666666;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease-in-out all;
  cursor: pointer;

  &:hover:not(.active) {
    background: rgba(255, 255, 255, 0.85);
    color: #333333;
  }

  &:active:not(.active) {
    background: rgba(255, 255, 255, 1);
  }
`;

export const ErrorDetails = (
  props: React.PropsWithChildren<React.AllHTMLAttributes<HTMLDivElement> & { errorData: string }>
) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (!copied) {
      return;
    }

    navigator.clipboard.writeText(props.errorData);
    setTimeout(() => setCopied(false), 3000);
  }, [copied]);

  return (
    <ErrorDetailsContainer {...props}>
      <ErrorCopyData className={copied ? 'active' : undefined} onClick={() => setCopied(true)}>
        {copied ? <IoCheckmark /> : <IoClipboard />}
      </ErrorCopyData>
      {props.children}
    </ErrorDetailsContainer>
  );
};

const ErrorComponent = (props: { error: Error | WrappedError; forceMobile?: boolean }) => {
  const message = props.error.toString();
  const transaction = apm().getSession().pageLoadId;
  const errorData = [
    { title: 'Error', description: message },
    { title: 'Transaction ID', description: transaction }
  ].filter(({ description }) => !!description);

  return (
    <ErrorContainer forceMobile={props.forceMobile}>
      <ErrorIllustration src="/images/error.svg" forceMobile={props.forceMobile} />
      <div>
        <h1 style={{ margin: 0 }}>Something went wrong.</h1>
        <p>
          There was an error while trying to perform your requested action. If the issue persists,
          please contact our team at{' '}
          <a href="mailto:support@withoyster.com">support@withoyster.com</a> and include this error
          for reference.
        </p>
        <ErrorDetails errorData={errorData.map((d) => `${d.title}\n${d.description}`).join('\n\n')}>
          {errorData.map((data) => (
            <ErrorDetail key={data.title} {...data} />
          ))}
        </ErrorDetails>
      </div>
    </ErrorContainer>
  );
};

/*
  position: relative;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  max-width: 500px;
  width: 100%;
  background: #eaeaea;
  display: flex;
  flex-direction: column;
  gap: 20px;
  */

const ErrorDetailsV2 = (
  props: React.PropsWithChildren<React.AllHTMLAttributes<HTMLDivElement> & { errorData: string }>
) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (!copied) {
      return;
    }

    navigator.clipboard.writeText(props.errorData);
    setTimeout(() => setCopied(false), 3000);
  }, [copied]);

  return (
    <div className="relative rounded-xl p-5 bg-neutral-200 flex flex-col gap-8 break-words">
      <ErrorCopyData className={copied ? 'active' : undefined} onClick={() => setCopied(true)}>
        {copied ? <IoCheckmark /> : <IoClipboard />}
      </ErrorCopyData>
      {props.children}
    </div>
  );
};

export const ErrorComponentV2 = (props: { error: Error | WrappedError; forceMobile?: boolean }) => {
  const message = props.error.toString();
  const transaction = apm().getSessionId();
  const errorData = [
    { title: 'Error', description: message },
    { title: 'Session ID', description: transaction || '' }
  ].filter(({ description }) => !!description);

  return (
    <div>
      <h1 className="text-base/4 font-semibold text-primary-500">Application error</h1>
      <h2 className="text-3xl/8">Something went wrong</h2>
      <p className="mt-2 text-sm">
        There was an error while trying to perform your requested action. If the issue persists,
        please contact our team via the chat widget or via email at{' '}
        <a href="mailto:support@withoyster.com">support@withoyster.com</a> and include this error
        for reference.
      </p>

      <div className="my-8 grid grid-cols-3 gap-4">
        <div className="col-span-3">
          <ErrorDetailsV2
            errorData={errorData.map((d) => `${d.title}\n${d.description}`).join('\n\n')}
          >
            {errorData
              .filter((d) => !!d.description)
              .map((data) => (
                <ErrorDetail key={data.title} {...data} />
              ))}
          </ErrorDetailsV2>
        </div>
      </div>
    </div>
  );
};

export default class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{ forceMobile?: boolean }>,
  { error?: Error | WrappedError }
> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(err: Error) {
    return { error: err };
  }

  componentDidCatch(error) {
    apm().captureError(error);
  }

  render(): React.ReactNode {
    if (this.state.error) {
      return <ErrorComponent forceMobile={this.props.forceMobile} error={this.state.error} />;
    }

    return this.props.children;
  }
}

export class ErrorBoundaryV2 extends React.Component<
  React.PropsWithChildren<unknown>,
  { error?: Error | WrappedError }
> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(err: Error) {
    return { error: err };
  }

  componentDidCatch(error) {
    apm().captureError(error);
  }

  render(): React.ReactNode {
    if (this.state.error) {
      return <ErrorComponentV2 error={this.state.error} />;
    }

    return this.props.children;
  }
}
