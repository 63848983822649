import * as React from 'react';
import clsx from 'clsx';
import { WrappedError } from '@oysterjs/core/errors';
import { MerchantIntegration, MerchantIntegrationSettings } from '@oysterjs/types';
import { VerticalLayoutItem } from '@oysterjs/ui/VerticalLayout';
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/16/solid';

const BetaLabel = () => (
  <div className="inline bg-primary-300 py-1 px-2 text-xs text-white mx-3 rounded-full">Beta</div>
);

export const AdditionalSetup = (props: { setupUrl: string }) => (
  <div
    style={{
      borderLeft: '4px solid #ffbca9',
      background: '#fff3f0',
      color: '#605c59',
      padding: '10px',
      width: '100%',
      fontSize: '0.8em'
    }}
  >
    This option requires additional setup.{' '}
    <a href={props.setupUrl} target="_blank">
      Click here
    </a>{' '}
    for instructions.
  </div>
);

export const ChannelSetting = (
  props: React.PropsWithChildren<{
    title: string;
    description: string | JSX.Element;
    indented?: boolean;
    beta?: boolean;
    rightContent?: JSX.Element;
    footer?: JSX.Element;
  }>
) => (
  <div className={clsx(props.indented && 'pl-4', 'flex flex-col gap-2')}>
    <div className="flex justify-between gap-4">
      <div className="max-w-[80%]">
        <h4 className="flex items-center m-0 text-base font-medium font-body text-neutral-900">
          {props.title}
          {props.beta && <BetaLabel />}
        </h4>
        <p className="text-sm text-neutral-500">{props.description}</p>
        {props.footer && <div className="mt-1">{props.footer}</div>}
      </div>
      {props.rightContent && <div>{props.rightContent}</div>}
    </div>
    <div className="max-w-[80%]">{props.children}</div>
  </div>
);

export const ChannelSettingsWrapper = <T extends MerchantIntegrationSettings>(props: {
  integration: MerchantIntegration;
  children: (
    settings: T,
    loading: boolean,
    onUpdateSettings: (settings: T) => void
  ) => React.ReactNode;
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const onUpdate = async (settings: MerchantIntegrationSettings) => {
    const integration = { ...props.integration, Settings: settings };

    setLoading(true);
    setUpdated(false);
    setError(undefined);

    try {
      await props.onUpdateIntegration(integration);
      setUpdated(true);
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h3 className="text-lg font-semibold text-neutral-900">Settings</h3>
      <div className="flex flex-col gap-4">
        {props.children(props.integration.Settings as T, loading, onUpdate)}
      </div>
      {(updated || error) && (
        <div>
          {updated && (
            <div className="flex gap-1 items-center text-green-600 text-xs">
              <CheckIcon className="w-4 h-4" />
              <div>Saved!</div>
            </div>
          )}
          {error && (
            <div className="flex gap-1 items-center text-red-600 text-xs">
              <ExclamationCircleIcon className="w-4 h-4" />
              <div>{error}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const Step = (props: React.PropsWithChildren<{ title: string; last?: boolean }>) => (
  <VerticalLayoutItem icon={<></>} last={props.last}>
    <h3 className="text-lg font-semibold text-neutral-900 my-1">{props.title}</h3>
    <p className="text-sm text-neutral-500 mt-1">{props.children}</p>
  </VerticalLayoutItem>
);
