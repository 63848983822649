import styled from 'styled-components';
import { useId } from 'react-id-generator';

const RadioContainer = styled.div<{ disabled?: boolean; checked?: boolean }>`
  .radio-symbol {
    pointer-events: none;
    user-select: none;
  }

  .radio-container {
    box-sizing: border-box;
    background: #ffffff;
  }

  .radio-container * {
    box-sizing: border-box;
  }

  .radio-input {
    position: absolute;
    visibility: hidden;
  }

  .radio {
    user-select: none;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    overflow: hidden;
    transition: all 0.15s ease;
    display: flex;
  }

  .radio span {
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    position: relative;
    margin-top: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: scale(1);
    border: 1px solid #cccfdb;
    transition: all 0.15s ease;
    background: ${(props) => (props.disabled ? '#666666' : props.checked ? '#0EA5E9' : 'white')};
  }

  .radio span svg {
    position: absolute;
    top: 2px;
    left: 2px;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.15s ease;
    transform: translate3d(0, 0, 0);
  }

  .radio-input:checked + .radio span {
    background: ${(props) => (props.disabled ? '' : '#0EA5E9')};
    border-color: ${(props) => (props.disabled ? '#666666' : '#0EA5E9')};
    animation: zoom-in-out 0.15s ease;
  }

  .radio-input:checked + .radio span svg {
    stroke-dashoffset: 0;
  }

  @keyframes zoom-in-out {
    50% {
      transform: scale(0.9);
    }
  }
`;

export interface RadioProps {
  label: string;
  id?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

export const Radio = (props: RadioProps): JSX.Element => {
  const [id] = useId();
  return (
    <RadioContainer disabled={props.disabled} checked={props.checked}>
      <div className="radio-container">
        <input
          id={props.id || id}
          className="radio-input"
          type="radio"
          name={props.name}
          checked={props.checked}
          disabled={props.disabled}
          onInput={props.onChange}
          readOnly
        />
        <label className="radio" htmlFor={id}>
          <span style={{ display: 'none' }}>{props.label}</span>
          <span aria-hidden>
            <svg className="radio-symbol" width="10px" height="10px" viewBox="0 0 12 12">
              <circle cx="6" cy="6" r="3" fill="white" />
            </svg>
          </span>
        </label>
      </div>
    </RadioContainer>
  );
};
