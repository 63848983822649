import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Int64: { input: number; output: number };
  Time: { input: Date; output: Date };
};

export enum AchPaymentStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Sent = 'SENT'
}

/** Describes an address that can be accepted as input */
export type Address = {
  __typename?: 'Address';
  /**
   *  Level 3 administrative division depending on country. For example, this is
   * the city in the U.S., a muncipality in Austria, a ward in Singapore, etc.
   */
  city: Scalars['String']['output'];
  /** First line of the address. */
  line1: Scalars['String']['output'];
  /** Optional second line of the address. */
  line2?: Maybe<Scalars['String']['output']>;
  /**
   *  Country-specific mailing identifier, e.g. ZIP Code in the U.S., Post Code
   * in the U.K., etc.
   */
  postalCode: Scalars['String']['output'];
  /**
   *  Level 2 administrative division depending on country. For example, this is
   * the county in the U.S., prefectural city in China, division in India, etc.
   */
  subZone?: Maybe<Scalars['String']['output']>;
  /**
   *  Level 1 administrative division depending on country. For example, this is
   * the state in the U.S., the province in Canada, etc.
   */
  zone: Scalars['String']['output'];
};

/** Describes an address that can be accepted as input */
export type AddressInput = {
  /**
   *  Level 3 administrative division depending on country. For example, this is
   * the city in the U.S., a muncipality in Austria, a ward in Singapore, etc.
   */
  city: Scalars['String']['input'];
  /** First line of the address. */
  line1: Scalars['String']['input'];
  /** Optional second line of the address. */
  line2?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Country-specific mailing identifier, e.g. ZIP Code in the U.S., Post Code
   * in the U.K., etc.
   */
  postalCode: Scalars['String']['input'];
  /**
   *  Level 2 administrative division depending on country. For example, this is
   * the county in the U.S., prefectural city in China, division in India, etc.
   */
  subZone?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Level 1 administrative division depending on country. For example, this is
   * the state in the U.S., the province in Canada, etc.
   */
  zone: Scalars['String']['input'];
};

/**
 * Describes the Ascend billable that this policy is associated with. This
 * type represents the response from the Ascend /v1/billables API
 */
export type AscendBillable = {
  __typename?: 'AscendBillable';
  /** Fee collected by your agency (dollars). Fully earned, not financeable, and funded directly to your agency. */
  agencyFees: Scalars['Float']['output'];
  /** The identifier assigned to the quote/endorsement by the issuing carrier or wholesaler. */
  billableIdentifier: Scalars['String']['output'];
  /** Fee collected by the wholesaler (dollars). Fully earned, not financeable, and funded directly to the wholesaler. */
  brokerFees: Scalars['Float']['output'];
  /** An insurance company. */
  carrier: AscendCarrier;
  /** A type of coverage. */
  coverageType: AscendCoverage;
  /** A short description to explain to the insured what they are purchasing. */
  description?: Maybe<Scalars['String']['output']>;
  /** The unique identifier for the billable */
  id: Scalars['String']['output'];
  /** Determines if the carrier can audit customer raising premium significantly. */
  isAuditable: Scalars['Boolean']['output'];
  /** Determines if specific lines of business are filed with other bureaus which they have to notify on cancelation. */
  isFiled: Scalars['Boolean']['output'];
  /** Determines if the policy is fined by 10% on top of earned premium when insured cancels */
  isShortRate: Scalars['Boolean']['output'];
  /** Set state determined extended cancelation period for the policy. */
  minDaysToCancel: Scalars['Int']['output'];
  /** The minimum rate to charge insureds when they decide to finance. */
  minEarnedRate: Scalars['Float']['output'];
  /** Any additional fees that come with the policy (dollars). This fee is treated as non-refundable & fully earned. */
  otherFees: Scalars['Float']['output'];
  /** Payouts created for this program, if any. This corresponds to the data returned from the /v1/payouts endpoint */
  payouts: Array<AscendPayout>;
  /** Any policy fees that come with the billable (dollars). This fee is treated as non-refundable & fully earned. */
  policyFees: Scalars['Float']['output'];
  /** The amount to pay for the insurance policy (dollars). */
  premium: Scalars['Float']['output'];
  /** Your commission rate for this policy. */
  sellerCommissionRate?: Maybe<Scalars['Float']['output']>;
  /** The amount of surplus lines tax to be collected (dollars). */
  surplusLinesTax: Scalars['Float']['output'];
  /** Any taxes & unearned fees (dollars). Taxes and fees are financed. */
  taxesAndFees: Scalars['Float']['output'];
  /** An insurance wholesaler or MGA. */
  wholesaler?: Maybe<AscendWholesaler>;
};

/** An insurance company. */
export type AscendCarrier = {
  __typename?: 'AscendCarrier';
  /** A human readable unique identifier. */
  id: Scalars['String']['output'];
  /** The carrier display name. */
  title: Scalars['String']['output'];
};

/** A type of coverage. */
export type AscendCoverage = {
  __typename?: 'AscendCoverage';
  /** A human readable unique identifier. */
  id: Scalars['String']['output'];
  /** The coverage type display name. */
  title: Scalars['String']['output'];
};

/** A document representing a transaction between the insured and Ascend. */
export type AscendInvoice = {
  __typename?: 'AscendInvoice';
  /** The checkout url of the invoice. */
  checkoutUrl?: Maybe<Scalars['String']['output']>;
  /** The date by which the invoice should be paid. */
  dueDate?: Maybe<Scalars['Time']['output']>;
  /** The invoice unique identifier. */
  id: Scalars['String']['output'];
  /** The unique identifier of the insured invoice is attached to. */
  insuredID: Scalars['String']['output'];
  /** A number assigned to uniquely identify the invoice. */
  invoiceNumber: Scalars['String']['output'];
  /** The url of the invoice document. */
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  /** The date when the invoice was shared with the insured. */
  issuedAt?: Maybe<Scalars['Time']['output']>;
  /** The date when the invoice was paid by the insured. */
  paidAt?: Maybe<Scalars['Time']['output']>;
  /** The name of the insured/business paying for the invoice. */
  payerName: Scalars['String']['output'];
  /** It represents the payment instrument used by an insured. */
  paymentMethod?: Maybe<AscendPaymentMethod>;
  /** The unique identifier of the program invoice is attached to. */
  programID?: Maybe<Scalars['String']['output']>;
  /** The current state of the invoice. */
  status: Scalars['String']['output'];
  /** The total amount charged, in dollars. */
  totalAmount: Scalars['Float']['output'];
};

/** A loan associated with an Ascend program */
export type AscendLoan = {
  __typename?: 'AscendLoan';
  /** The amount financed through the loan represented in dollars. */
  amountFinanced: Scalars['Float']['output'];
  /** The total annual rate charged. */
  apr: Scalars['Float']['output'];
  /** The date when the loan was created. The date uses UTC and is represented in the ISO8601 format. */
  createdAt: Scalars['Time']['output'];
  /** The initial up-front payment represented in dollars. */
  downPayment: Scalars['Float']['output'];
  /** The loan unique identifier. */
  id: Scalars['String']['output'];
  /** The number of payments that will be made to repay the loan. */
  numberOfPayments: Scalars['Int']['output'];
  /** The current state of the loan. */
  status: Scalars['String']['output'];
  /** The amount that will be payed in each payment in dollars. */
  termPayment: Scalars['Float']['output'];
  /** The date when the loan was last updated. The date uses UTC and is represented in the ISO8601 format. */
  updatedAt: Scalars['Time']['output'];
};

/** The account the Ascend payout was made to */
export type AscendPayableAccount = {
  __typename?: 'AscendPayableAccount';
  /** The ID of the payable account */
  id: Scalars['String']['output'];
  /** The last four digits of the bank account */
  lastFour: Scalars['String']['output'];
  /** The owner name of the bank account */
  ownerName: Scalars['String']['output'];
  /** The routing number of the bank account */
  routingNumber: Scalars['String']['output'];
  /** The usage type of the bank account */
  usageType: AscendPayableAccountUsageType;
};

export enum AscendPayableAccountUsageType {
  /** Organization's bank account we transfer commissions to. */
  CommissionAccount = 'COMMISSION_ACCOUNT',
  /** Paid out to carrier/wholesaler */
  SupplierAccount = 'SUPPLIER_ACCOUNT',
  /** Organization's bank account we transfer full premium to. */
  TrustAccount = 'TRUST_ACCOUNT'
}

export type AscendPaymentMethod = {
  __typename?: 'AscendPaymentMethod';
  type: AscendPaymentMethodType;
};

export enum AscendPaymentMethodType {
  AchCreditTransfer = 'ACH_CREDIT_TRANSFER',
  AchDebit = 'ACH_DEBIT',
  Card = 'CARD'
}

/** A payout from Ascend to an entity */
export type AscendPayout = {
  __typename?: 'AscendPayout';
  /** The date the payout was created */
  createdAt: Scalars['Time']['output'];
  /** The date that the payout failed */
  failedAt?: Maybe<Scalars['Time']['output']>;
  /** The gross amount paid out, in dollars. */
  grossPayoutAmount: Scalars['Float']['output'];
  /** The payout unique identifier */
  id: Scalars['String']['output'];
  /** If found, the details of the bank transaction on Oyster's side */
  linkedBankTransaction?: Maybe<LinkedBankTransaction>;
  /** The net amount paid out, in dollars. */
  netPayoutAmount: Scalars['Float']['output'];
  /** The date that the payout was paid */
  paidAt?: Maybe<Scalars['Time']['output']>;
  /** The account that the payout was made to */
  payableAccount: AscendPayableAccount;
  /** The date that the payout will be paid */
  payingAt?: Maybe<Scalars['Time']['output']>;
  /** The type of the recipient */
  recipientType: AscendRecipientType;
  /** The reference number included in the bank memo associated with this payout */
  reference?: Maybe<Scalars['String']['output']>;
  /** The current state of the payout */
  status: AscendPayoutStatus;
  /** The type of the payout */
  type: AscendPayoutType;
};

/** The status of the payout */
export enum AscendPayoutStatus {
  /** The payout has been canceled */
  Canceled = 'CANCELED',
  /** The payout has failed to reach the payable account. */
  Failed = 'FAILED',
  /** The payout is blocked until the invoice release_date when funds can be paid to the payable account. */
  OnHold = 'ON_HOLD',
  /** The payout has been paid to designated PayableAccount */
  Paid = 'PAID',
  /** The payout has been initiated but we have not received confirmation that it has cleared. */
  Paying = 'PAYING',
  /** The payout is waiting for confirmation from servicer indicating it has been funded. */
  Transferred = 'TRANSFERRED',
  /** Initial state, no action has been taken on the payout. */
  Unpaid = 'UNPAID'
}

/** The type of the payout */
export enum AscendPayoutType {
  /** Transfer cumulative supplier funding to org so that they can pay out to supplier - paid to the organization's trust account */
  AgentSupplierFunding = 'AGENT_SUPPLIER_FUNDING',
  /** Policy's commission paid to organization's commission account */
  Commission = 'COMMISSION',
  /** Policy's full premium (premium w/ commission) paid to organization's trust account */
  FullPremium = 'FULL_PREMIUM',
  /** Policy's net premium (full premium - commission) paid out to org's trust account (direct bill) */
  NetPremium = 'NET_PREMIUM',
  /** Payout to agency's commission account for one-off invoice */
  OneOff = 'ONE_OFF',
  /** Payout to org for additional funds they have transferred to us - paid to the organization's commission account */
  Overpayment = 'OVERPAYMENT',
  /** Policy's premium paid out to supplier's honor_supplier_account or supplier_account (agency bill) */
  Supplier = 'SUPPLIER',
  /** Payout to agency's trust account for surplus line taxes */
  SurplusLinesTax = 'SURPLUS_LINES_TAX',
  /** Financed policy's downpayment paid to honor's downpayment account */
  TakeRate = 'TAKE_RATE'
}

/** A collection of insurance quotes pooled to produce 1 checkout link. */
export type AscendProgram = {
  __typename?: 'AscendProgram';
  /** The date the program was archived. The date uses UTC and is represented in the ISO8601 format. */
  archivedAt?: Maybe<Scalars['Time']['output']>;
  /** The date the program was checked out. The date uses UTC and is represented in the ISO8601 format. */
  checkedOutAt?: Maybe<Scalars['Time']['output']>;
  /** The date the program was created. The date uses UTC and is represented in the ISO8601 format. */
  createdAt: Scalars['Time']['output'];
  /** The unique identifier for the program */
  id: Scalars['String']['output'];
  /** Invoices created for this program, if any. This corresponds to the data returned from the /v1/invoices endpoint */
  invoices: Array<AscendInvoice>;
  /** Financing details for this program, if any. This corresponds to the data returned from the /v1/loans endpoint */
  loans: Array<AscendLoan>;
  /** A URL to complete the checkout process. */
  programUrl: Scalars['String']['output'];
  /** The payment option chosen by the insured during the checkout process. */
  selectedPaymentOptionType?: Maybe<PaymentOptionType>;
  /** The state of the program */
  status: AscendProgramStatus;
  /** The date the program was last updated. The date uses UTC and is represented in the ISO8601 format. */
  updatedAt: Scalars['Time']['output'];
};

export enum AscendProgramCreationMode {
  BundleWithExistingQuote = 'BUNDLE_WITH_EXISTING_QUOTE',
  CreateProgram = 'CREATE_PROGRAM',
  UseSpecificProgramId = 'USE_SPECIFIC_PROGRAM_ID'
}

/** The status of a program */
export enum AscendProgramStatus {
  Archived = 'ARCHIVED',
  CheckedOut = 'CHECKED_OUT',
  Created = 'CREATED',
  Elected = 'ELECTED',
  Purchased = 'PURCHASED',
  ReadyForCheckout = 'READY_FOR_CHECKOUT'
}

/** The type of the Ascend recipient */
export enum AscendRecipientType {
  Account = 'ACCOUNT',
  Carrier = 'CARRIER',
  Organization = 'ORGANIZATION',
  Wholesaler = 'WHOLESALER'
}

/** An insurance wholesaler or MGA. */
export type AscendWholesaler = {
  __typename?: 'AscendWholesaler';
  /** A human readable unique identifier. */
  id: Scalars['String']['output'];
  /** The wholesaler display name. */
  title: Scalars['String']['output'];
};

export type AvailableCoverageLimit = {
  __typename?: 'AvailableCoverageLimit';
  insuranceType: InsuranceType;
  limits: InsuranceLimits;
};

/** Represents the construction type of a building. */
export enum BuildingConstructionType {
  /** A fire resistive construction type. */
  FireResistive = 'FIRE_RESISTIVE',
  /** A frame construction type. */
  Frame = 'FRAME',
  /** A joisted masonry construction type. */
  JoistedMasonry = 'JOISTED_MASONRY',
  /** A masonry non-combustible construction type. */
  MasonryNonCombustible = 'MASONRY_NON_COMBUSTIBLE',
  /** A modified fire resistive construction type. */
  ModifiedFireResistive = 'MODIFIED_FIRE_RESISTIVE',
  /** A non-combustible construction type. */
  NonCombustible = 'NON_COMBUSTIBLE'
}

/** Represents the building location ownership type */
export enum BuildingOwnershipType {
  /** The building is a home that is owned by the business. */
  Home = 'HOME',
  /** The building is leased by the business. */
  Leased = 'LEASED',
  /** The building is owned by the business. */
  Owned = 'OWNED'
}

/** Represents the type of roof on a building. */
export enum BuildingRoofType {
  /** Asphalt shingles roof type. */
  AsphaltShingles = 'ASPHALT_SHINGLES',
  /** Built-up gravel roof type. */
  BuiltUpGravel = 'BUILT_UP_GRAVEL',
  /** Built-up without gravel roof type. */
  BuiltUpWithoutGravel = 'BUILT_UP_WITHOUT_GRAVEL',
  /** Clay tiles roof type. */
  ClayTiles = 'CLAY_TILES',
  /** Foam roof type. */
  Foam = 'FOAM',
  /** Metal roof type. */
  Metal = 'METAL',
  /** Modified bitumen roof type. */
  ModifiedBitumen = 'MODIFIED_BITUMEN',
  /** Single-ply ballasted roof type. */
  SinglePlyBallasted = 'SINGLE_PLY_BALLASTED',
  /** Single-ply PVC TPO roof type. */
  SinglePlyPvcTpo = 'SINGLE_PLY_PVC_TPO',
  /** Single-ply SPDM rubber roof type. */
  SinglePlySpdmRubber = 'SINGLE_PLY_SPDM_RUBBER',
  /** Wood shingles roof type. */
  WoodShingles = 'WOOD_SHINGLES'
}

/** Represents the type of burglar alarm installed at a business location. */
export enum BurglarAlarmType {
  /** A central burglar alarm type. */
  BurglarCentral = 'BURGLAR_CENTRAL',
  /** A local burglar alarm type. */
  BurglarLocal = 'BURGLAR_LOCAL',
  /** No burglar alarm. */
  BurglarNone = 'BURGLAR_NONE',
  /** A police and fire burglar alarm type. */
  BurglarPoliceFire = 'BURGLAR_POLICE_FIRE'
}

/** BusinessApplication represents an application */
export type BusinessApplication = {
  __typename?: 'BusinessApplication';
  /** The annual revenue of the business. */
  annualRevenue: Scalars['Float']['output'];
  /** The 'doing business as' name of the business. */
  businessDba: Scalars['String']['output'];
  /** The name of the business. */
  businessName: Scalars['String']['output'];
  /** The primary contact information for the business. */
  contact: BusinessContact;
  /** CoverForce applications associated with this application */
  coverforceApplications: Array<CoverforceApplication>;
  /** The date the application was originally created */
  createdAt: Scalars['Time']['output'];
  /** Information about the employees at the business */
  employeeInfo: BusinessEmployeeInfo;
  /** The Federal Employer Identification Number of the business. */
  fein: Scalars['String']['output'];
  /** SHA-256 hash of the application data */
  fingerprint: Scalars['String']['output'];
  /** The ID of the business application */
  id: Scalars['ID']['output'];
  /** The desired effective date of the insurance. */
  insuranceEffectiveAt?: Maybe<Scalars['Time']['output']>;
  /** The types of insurance the business is applying for. */
  insuranceTypes: Array<InsuranceType>;
  /** Indicates whether the business is a non-profit organization. */
  isNonProfit: Scalars['Boolean']['output'];
  /** The legal entity type of the business. */
  legalEntityType?: Maybe<BusinessLegalEntityType>;
  /** List of locations associated with the business. */
  locations: Array<BusinessLocation>;
  /** The mailing address of the business. */
  mailingAddress: Address;
  /** The North American Industry Classification System code of the business. */
  naicsCode: Scalars['String']['output'];
  /** A description of the nature of the business. */
  natureOfBusiness: Scalars['String']['output'];
  /** Information about the business owners. */
  owners: Array<BusinessOwner>;
  /** Any past policy losses */
  pastPolicyLosses: Array<PastPolicyLoss>;
  /**
   * Carriers that will quote one or more coverages for this application.
   * Can only be queried after the application has been completed.
   */
  quotableCarriers: Array<InsuranceCarrier>;
  /** Current quotes for this application, if any are available. */
  quotes: Array<BusinessApplicationQuote>;
  /** Carriers selected by the customer to generate quotes for. */
  selectedCarriers: Array<BusinessApplicationCarrier>;
  /** The selected insurance limits for the application. */
  selectedLimits: InsuranceLimits;
  /** State of the application */
  state: BusinessApplicationState;
  /** Answers to underwriting questions. */
  underwritingAnswers: Array<UnderwritingAnswer>;
  /** Underwriting questions needed on this application. */
  underwritingQuestions: Array<UnderwritingQuestion>;
  /** Underwriting statements and disclosures that the insured needs to acknowledge. */
  underwritingStatements: Array<UnderwritingStatement>;
  /** The date this application was last updated */
  updatedAt: Scalars['Time']['output'];
  /** The version of this application */
  version: Scalars['Int64']['output'];
  /**
   * The previous versions of this application, subject to the specified limit and offset.
   * The first version in this list will be this version (i.e. identical to the current application), followed by the version before that, and so-forth.
   */
  versionHistory: Array<BusinessApplicationVersion>;
  /** The website of the business. */
  website: Scalars['String']['output'];
  /** The year the business was founded. */
  yearOfFounding: Scalars['String']['output'];
  /** The number of years of management experience the business has. */
  yearsOfManagementExperience: Scalars['Int']['output'];
};

/** BusinessApplication represents an application */
export type BusinessApplicationVersionHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum BusinessApplicationCarrier {
  Amtrust = 'AMTRUST',
  Biberk = 'BIBERK',
  Chubb = 'CHUBB',
  Cna = 'CNA',
  Coalition = 'COALITION',
  Coterie = 'COTERIE',
  Employers = 'EMPLOYERS',
  Gaig = 'GAIG',
  Guard = 'GUARD',
  Hiscox = 'HISCOX',
  Libertymutual = 'LIBERTYMUTUAL',
  Markel = 'MARKEL',
  Nationwide = 'NATIONWIDE',
  Next = 'NEXT',
  Progressive = 'PROGRESSIVE',
  Travelers = 'TRAVELERS'
}

export type BusinessApplicationQuote = {
  __typename?: 'BusinessApplicationQuote';
  /** The carrier that has offered this quote */
  carrier: BusinessApplicationCarrier;
  /** The link to the carrier portal to view and modify the quote */
  carrierBridgingLink?: Maybe<Scalars['String']['output']>;
  /** The link to the carrier portal to bind and claim the policy */
  carrierPurchaseLink?: Maybe<Scalars['String']['output']>;
  /** The ID of the quote in the carrier system */
  carrierQuoteId: Scalars['String']['output'];
  /** The ID of the quote */
  id: Scalars['String']['output'];
  /** The effective date of the insurance */
  insuranceEffectiveDate: Scalars['Time']['output'];
  /** The expiration date of the insurance */
  insuranceExpiryDate: Scalars['Time']['output'];
  /** List of payment plans available for this quote if the quote is bindable */
  paymentPlans: Array<BusinessApplicationQuotePaymentPlan>;
  /** If this policy is binding, the policy ID from the carrier system. */
  policyId?: Maybe<Scalars['String']['output']>;
  /** The type of insurance policy */
  policyType: InsuranceType;
  /** The premium amount for the policy */
  premiumAmount: Scalars['Float']['output'];
  /** The URL of the quote proposal PDF */
  quoteProposalUrl?: Maybe<Scalars['String']['output']>;
  /** The state of the quote */
  state: BusinessApplicationQuoteState;
  /** The tax amount for the policy */
  taxAmount: Scalars['Float']['output'];
  /** The total amount for the policy */
  totalAmount: Scalars['Float']['output'];
  /** The type of quote */
  type: BusinessApplicationQuoteType;
};

/** Type representing a payment plan for a business application quote */
export type BusinessApplicationQuotePaymentPlan = {
  __typename?: 'BusinessApplicationQuotePaymentPlan';
  /** Description of the payment plan */
  description: Scalars['String']['output'];
  /** Initial down payment amount */
  downAmount: Scalars['Float']['output'];
  /** Due date for the down payment */
  downDueAt?: Maybe<Scalars['Time']['output']>;
  /** Amount for each installment */
  installmentAmount: Scalars['Float']['output'];
  /** Number of installments */
  installmentCount: Scalars['Int']['output'];
  /** Due date for the installments */
  installmentDueAt?: Maybe<Scalars['Time']['output']>;
  /** Fee charged for each installment */
  installmentFee: Scalars['Float']['output'];
  /** Frequency of the installments */
  installmentFrequency: PaymentPlanInstallmentFrequency;
  /** Method of payment for the plan */
  paymentMethod: PaymentPlanPaymentMethodType;
  /** Unique identifier for the payment plan */
  paymentPlanId: Scalars['String']['output'];
  /** Title of the payment plan */
  title: Scalars['String']['output'];
  /** Total amount to be paid */
  totalAmount: Scalars['Float']['output'];
  /** Total fees associated with the payment plan */
  totalFees: Scalars['Float']['output'];
};

/** Enum representing the possible states of a business application quote */
export enum BusinessApplicationQuoteState {
  /** The quote is in the process of being bound */
  Binding = 'BINDING',
  /** The quote has been created */
  Created = 'CREATED'
}

/** Enum representing the different types of quotes for a business application */
export enum BusinessApplicationQuoteType {
  /** Quote that can be bound online */
  BindOnline = 'BIND_ONLINE',
  /** Quote that serves as a bridge between different stages */
  Bridge = 'BRIDGE',
  /** Quote that has been declined */
  Decline = 'DECLINE',
  /** Quote that has failed for some reason */
  Failed = 'FAILED',
  /** Quote that needs to be referred for further review */
  Refer = 'REFER'
}

/** The state of business application */
export enum BusinessApplicationState {
  /** The application has been canceled and is no longer active */
  Canceled = 'CANCELED',
  /** The application has been created but no further action has been taken */
  Created = 'CREATED',
  /** A quote has been generated for the application */
  Quoted = 'QUOTED',
  /** The application is in the process of being quoted */
  Quoting = 'QUOTING',
  /** The application has been submitted for review or processing */
  Submitted = 'SUBMITTED'
}

/** Describes the reason why an application was updated */
export enum BusinessApplicationUpdateReasonCode {
  /** Placeholder reason codes until the use case becomes more clear */
  Unknown = 'UNKNOWN'
}

/** Describes the type of update that occured to a application */
export enum BusinessApplicationUpdateType {
  /** The application was canceled and is no longer active */
  CancelApplication = 'CANCEL_APPLICATION',
  /** The application was created, either directely or by completing an offer */
  CreateApplication = 'CREATE_APPLICATION',
  /** The application was completed and submitted, and is now ready to be reviewed by the carrier */
  SubmitApplication = 'SUBMIT_APPLICATION',
  /** The application was updated. Requires manual inspection of the application to determine the actual update */
  UpdateApplication = 'UPDATE_APPLICATION'
}

/** Describes a particular version of an application */
export type BusinessApplicationVersion = {
  __typename?: 'BusinessApplicationVersion';
  /** The full application object that includes all of the updates made in this version and all previous versions */
  businessApplication: BusinessApplication;
  /** The date this version was created */
  createdAt: Scalars['Time']['output'];
  /** Produces a human-readable string that can be used to diff against another application version. */
  diffableJson: Scalars['String']['output'];
  /** The version number of the previous application version, if there is any. If this field is null, then this version is the only version */
  previousVersion?: Maybe<BusinessApplicationVersion>;
  /** The ID of the entity that initiated the update, depending on the type */
  updateInitiatorId: Scalars['String']['output'];
  /** The type of the entity that initiated the update */
  updateInitiatorType: UpdateInitiatorType;
  /** Indicates whether this update is 'private' in the sense that it should not be displayed to the policyholder */
  updatePrivate: Scalars['Boolean']['output'];
  /** A human-readable description of the reason why this update was made */
  updateReason?: Maybe<Scalars['String']['output']>;
  /** A programmatic representation of the reason why this update was made */
  updateReasonCode?: Maybe<BusinessApplicationUpdateReasonCode>;
  /** The types of updates made between this version and the previous version */
  updateTypes: Array<BusinessApplicationUpdateType>;
  /** The version number of this application version */
  version: Scalars['Int64']['output'];
};

/** A business category */
export type BusinessCategory = {
  __typename?: 'BusinessCategory';
  /** The category */
  category: Scalars['String']['output'];
  /** The full path of the category, starting with the root category and including the category itself */
  categoryPath: Array<Scalars['String']['output']>;
  /** The ID of the category */
  id: Scalars['ID']['output'];
  /** The legacy product vertical, if applicable */
  legacyProductVertical?: Maybe<ProductType>;
  /** The parent category */
  parent?: Maybe<BusinessCategory>;
  /** The risk score of the category */
  riskScore: Scalars['Float']['output'];
};

/** Represents a contact person for the business. */
export type BusinessContact = {
  __typename?: 'BusinessContact';
  /** The email address of the contact person. */
  email: Scalars['String']['output'];
  /** The name of the contact person. */
  fullName: Scalars['String']['output'];
  /** The phone number of the contact person. */
  phone: Scalars['String']['output'];
};

/** Represents a contact person for the business. */
export type BusinessContactInput = {
  /** The email address of the contact person. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The name of the contact person. */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** The phone number of the contact person. */
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Represents information about the employees at a business location. */
export type BusinessEmployeeInfo = {
  __typename?: 'BusinessEmployeeInfo';
  /** The job code ID associated with the employees. */
  jobCode: Scalars['String']['output'];
  /** The number of full-time employees at the business location. */
  numFullTimeEmployees: Scalars['Int']['output'];
  /** The number of part-time employees at the business location. */
  numPartTimeEmployees: Scalars['Int']['output'];
  /** The total payroll amount for all employees at the business location. */
  totalEmployeePayroll: Scalars['Float']['output'];
};

/** Represents information about the employees at a business location. */
export type BusinessEmployeeInfoInput = {
  /** The job code ID associated with the employees. */
  jobCode?: InputMaybe<Scalars['String']['input']>;
  /** The number of full-time employees at the business location. */
  numFullTimeEmployees?: InputMaybe<Scalars['Int']['input']>;
  /** The number of part-time employees at the business location. */
  numPartTimeEmployees?: InputMaybe<Scalars['Int']['input']>;
  /** The total payroll amount for all employees at the business location. */
  totalEmployeePayroll?: InputMaybe<Scalars['Float']['input']>;
};

/** Input type for business insurance personalization details */
export type BusinessInsurancePersonalizationInput = {
  /** Description for other insurance types not listed */
  insuranceOtherDesc?: InputMaybe<Scalars['String']['input']>;
  /** List of insurance types required by the business */
  insuranceTypes: Array<InsuranceType>;
  /** Description for other operation types not listed */
  operationOtherDesc?: InputMaybe<Scalars['String']['input']>;
  /** List of operation types for the business */
  operationTypes: Array<OperationType>;
  /** Revenue from manufacturing operations */
  revenueManufacturing?: InputMaybe<Scalars['Float']['input']>;
  /** Revenue from other operations */
  revenueOther?: InputMaybe<Scalars['Float']['input']>;
  /** Revenue from rental operations */
  revenueRental?: InputMaybe<Scalars['Float']['input']>;
  /** Percentage of rental revenue that is guided */
  revenueRentalPercentGuided?: InputMaybe<Scalars['Float']['input']>;
  /** Revenue from retail operations */
  revenueRetail?: InputMaybe<Scalars['Float']['input']>;
  /** Revenue from service or repair operations */
  revenueServiceOrRepair?: InputMaybe<Scalars['Float']['input']>;
  /** Revenue from wholesale operations */
  revenueWholesale?: InputMaybe<Scalars['Float']['input']>;
  /** Total payroll for the business */
  totalPayroll?: InputMaybe<Scalars['Float']['input']>;
};

/** Represents the legal entity type of a business. */
export enum BusinessLegalEntityType {
  /** An association. */
  Association = 'ASSOCIATION',
  /** A corporation. */
  Corporation = 'CORPORATION',
  /** An estate. */
  Estate = 'ESTATE',
  /** An executor. */
  Executor = 'EXECUTOR',
  /** A general partnership. */
  GeneralPartnership = 'GENERAL_PARTNERSHIP',
  /** A government entity. */
  GovernmentEntity = 'GOVERNMENT_ENTITY',
  /** An individual. */
  Individual = 'INDIVIDUAL',
  /** Joint employers. */
  JointEmployers = 'JOINT_EMPLOYERS',
  /** A joint venture. */
  JointVenture = 'JOINT_VENTURE',
  /** A labor union. */
  LaborUnion = 'LABOR_UNION',
  /** A limited liability company. */
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  /** A limited liability partnership. */
  LimitedLiabilityPartnership = 'LIMITED_LIABILITY_PARTNERSHIP',
  /** A limited partnership. */
  LimitedPartnership = 'LIMITED_PARTNERSHIP',
  /** Multiple statuses. */
  MultipleStatus = 'MULTIPLE_STATUS',
  /** A non-profit association. */
  NonProfitAssociation = 'NON_PROFIT_ASSOCIATION',
  /** A non-profit corporation. */
  NonProfitCorporation = 'NON_PROFIT_CORPORATION',
  /** An S corporation. */
  SCorporation = 'S_CORPORATION',
  /** Tenants in common. */
  TenantsInCommon = 'TENANTS_IN_COMMON',
  /** A trust. */
  Trust = 'TRUST'
}

/** Represents a business location. */
export type BusinessLocation = {
  __typename?: 'BusinessLocation';
  /** The address of the business location. */
  address: Address;
  /** Information about the building of the business location. */
  buildingInfo: BusinessLocationBuildingInfo;
  /** Information about the employees at the business location. */
  employeeInfo: BusinessEmployeeInfo;
  /** The unique identifier of the business location. */
  id: Scalars['ID']['output'];
  /** Indicates whether this is the primary business location. */
  isPrimary: Scalars['Boolean']['output'];
};

/** Represents information about the building of a business location. */
export type BusinessLocationBuildingInfo = {
  __typename?: 'BusinessLocationBuildingInfo';
  /** The annual sales of the business location. */
  annualSales: Scalars['Float']['output'];
  /** The area occupied by the business. */
  areaOccupiedByBusiness: Scalars['Float']['output'];
  /** The building coverage amount. */
  buildingCoverage: Scalars['Float']['output'];
  /** The type of burglar alarm installed at the business location. */
  burglarAlarmType?: Maybe<BurglarAlarmType>;
  /** The construction type of the building. */
  constructionType?: Maybe<BuildingConstructionType>;
  /** The year the electrical wiring was last updated. */
  electricalWiringUpdateYear: Scalars['String']['output'];
  /** The year the heating system was last updated. */
  heatingUpdateYear: Scalars['String']['output'];
  /** The type of ownership of this business location. */
  ownershipType?: Maybe<BuildingOwnershipType>;
  /** The personal property coverage amount. */
  personalPropertyCoverage: Scalars['Float']['output'];
  /** The year the plumbing system was last updated. */
  plumbingUpdateYear: Scalars['String']['output'];
  /** The type of roof on the building. */
  roofType?: Maybe<BuildingRoofType>;
  /** The year the roof was last updated. */
  roofUpdateYear: Scalars['String']['output'];
  /** The percentage of the building that is sprinklered. */
  sprinkleredPercentage: Scalars['Float']['output'];
  /** The total area of the building. */
  totalArea: Scalars['Float']['output'];
  /** The total number of stories in the building. */
  totalStories: Scalars['Int']['output'];
  /** The year the building was built. */
  yearBuilt: Scalars['String']['output'];
};

/** Represents information about the building of a business location. */
export type BusinessLocationBuildingInfoInput = {
  /** The annual sales of the business location. */
  annualSales?: InputMaybe<Scalars['Float']['input']>;
  /** The area occupied by the business. */
  areaOccupiedByBusiness?: InputMaybe<Scalars['Float']['input']>;
  /** The building coverage amount. */
  buildingCoverage?: InputMaybe<Scalars['Float']['input']>;
  /** The type of burglar alarm installed at the business location. */
  burglarAlarmType?: InputMaybe<BurglarAlarmType>;
  /** The construction type of the building. */
  constructionType?: InputMaybe<BuildingConstructionType>;
  /** The year the electrical wiring was last updated. */
  electricalWiringUpdateYear?: InputMaybe<Scalars['String']['input']>;
  /** The year the heating system was last updated. */
  heatingUpdateYear?: InputMaybe<Scalars['String']['input']>;
  /** Indicates whether the business owns the building. */
  ownershipType?: InputMaybe<BuildingOwnershipType>;
  /** The personal property coverage amount. */
  personalPropertyCoverage?: InputMaybe<Scalars['Float']['input']>;
  /** The year the plumbing system was last updated. */
  plumbingUpdateYear?: InputMaybe<Scalars['String']['input']>;
  /** The type of roof on the building. */
  roofType?: InputMaybe<BuildingRoofType>;
  /** The year the roof was last updated. */
  roofUpdateYear?: InputMaybe<Scalars['String']['input']>;
  /** The percentage of the building that is sprinklered. */
  sprinkleredPercentage?: InputMaybe<Scalars['Float']['input']>;
  /** The total area of the building. */
  totalArea?: InputMaybe<Scalars['Float']['input']>;
  /** The total number of stories in the building. */
  totalStories?: InputMaybe<Scalars['Int']['input']>;
  /** The year the building was built. */
  yearBuilt?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessLocationInput = {
  address: AddressInput;
  buildingInfo: BusinessLocationBuildingInfoInput;
  employeeInfo: BusinessEmployeeInfoInput;
  id: Scalars['String']['input'];
  isPrimary: Scalars['Boolean']['input'];
};

/** Represents an owner of the business. */
export type BusinessOwner = {
  __typename?: 'BusinessOwner';
  /** The annual payroll of the business owner. */
  annualPayroll: Scalars['Float']['output'];
  /** The date of birth of the business owner. */
  dateOfBirth?: Maybe<Scalars['Time']['output']>;
  /** The full name of the business owner. */
  fullName: Scalars['String']['output'];
  /** The job code associated with the business owner. */
  jobCode?: Maybe<Scalars['String']['output']>;
  /** The location ID associated with the business owner. */
  locationId: Scalars['String']['output'];
};

/** Represents an owner of the business. */
export type BusinessOwnerInput = {
  /** The annual payroll of the business owner. */
  annualPayroll: Scalars['Float']['input'];
  /** The date of birth of the business owner. */
  dateOfBirth: Scalars['String']['input'];
  /** The full name of the business owner. */
  fullName: Scalars['String']['input'];
  /** The job code of the business owner. */
  jobCode: Scalars['String']['input'];
};

/** Business Policy represents the business or commercial insurance policy */
export type BusinessPolicy = {
  __typename?: 'BusinessPolicy';
  /** The broker providing this policy, if any */
  broker?: Maybe<InsuranceCarrier>;
  /** If the policy was canceled or nonrenewed, information about the latest instance of cancellation or nonrenewal */
  cancellation?: Maybe<BusinessPolicyCancellation>;
  /** The carrier providing this policy */
  carrier: InsuranceCarrier;
  /** The date the policy object was originally created */
  createdAt: Scalars['Time']['output'];
  /** Detailed information extracted from the policy document, if available */
  details?: Maybe<BusinessPolicyExtractedDetails>;
  /** The timestamp when this policy expires */
  expiresAt?: Maybe<Scalars['Time']['output']>;
  /** The external/carrier ID of the policy */
  externalId: Scalars['ID']['output'];
  /** All attached files for this application */
  files: Array<File>;
  /** The internal ID of the policy */
  id: Scalars['ID']['output'];
  /** The timestamp when this policy is inforce */
  inforceAt?: Maybe<Scalars['Time']['output']>;
  /** The timestamp when this policy was issued */
  issuedAt?: Maybe<Scalars['Time']['output']>;
  /** The market this policy was written in */
  market: InsuranceMarket;
  /** Merchant that is associated with the policy */
  merchant?: Maybe<Merchant>;
  /** The number or external identifier associated with this policy within the carrier system */
  policyNumber: Scalars['String']['output'];
  /** The pricing information for this policy */
  pricing: BusinessPolicyPricing;
  /** The number or external identifier associated with this quote within the carrier system */
  quoteNumber: Scalars['String']['output'];
  /** If the policy was reinstated, information about the latest instance of when it was reinstated */
  reinstatement?: Maybe<BusinessPolicyReinstatement>;
  /** If this policy was renewed from another policy, information about the renewal and its previous versions */
  renewal?: Maybe<BusinessPolicyRenewal>;
  /** The source of the policy, and associated details */
  source: BusinessPolicySource;
  /** The current state of the policy */
  state: PolicyState;
  /** The type of insurance this policy represents */
  type: InsuranceType;
  /** The date this policy object was last updated */
  updatedAt: Scalars['Time']['output'];
  /** The version of this policy */
  version: Scalars['Int64']['output'];
  /** The previous versions of this policy. The first version in this list will be this version (i.e. identical to the current policy), followed by the version before that, and so-forth. */
  versionHistory?: Maybe<Array<BusinessPolicyVersion>>;
};

/** Details about agency billing for a business policy */
export type BusinessPolicyAgencyBilling = {
  __typename?: 'BusinessPolicyAgencyBilling';
  /** Details about Ascend billable, if applicable */
  ascendBillable?: Maybe<AscendBillable>;
  /** Details about Ascend program, if applicable */
  ascendProgram?: Maybe<AscendProgram>;
  /** Details about Stripe invoice, if applicable */
  stripeInvoice?: Maybe<StripeInvoice>;
  /** The type of agency billing used */
  type: BusinessPolicyAgencyBillingType;
};

/** The type of agency billing used for the policy */
export enum BusinessPolicyAgencyBillingType {
  /** Billing is handled through Ascend */
  Ascend = 'ASCEND',
  /** Billing is handled manually */
  Manual = 'MANUAL',
  /** Billing is handled through Stripe invoices */
  StripeInvoice = 'STRIPE_INVOICE'
}

/** The billing type of policy, indicating how the policy is billed */
export enum BusinessPolicyBillingType {
  /** Policy is billed by Oyster and then paid to the carrier */
  Agency = 'AGENCY',
  /** Policy is billed directly by the carrier */
  Direct = 'DIRECT',
  /** Billing type is unknown or not specified */
  Unknown = 'UNKNOWN'
}

/** A blanket additional insured for the policy */
export type BusinessPolicyBlanketAdditionalInsured = {
  __typename?: 'BusinessPolicyBlanketAdditionalInsured';
  /** The description of the blanket additional insured */
  description: Scalars['String']['output'];
  /** The form numbers that reference the blanket additional insured */
  formNumbers: Array<Scalars['String']['output']>;
  /** Supporting quotes from the policy document */
  quotes: Array<Scalars['String']['output']>;
  /** The reasoning for why the blanket additional insured is included */
  reasoning: Scalars['String']['output'];
  /** The type of the blanket additional insured */
  type: Scalars['String']['output'];
};

/** Detailed information about a policy cancellation */
export type BusinessPolicyCancellation = {
  __typename?: 'BusinessPolicyCancellation';
  /** The date and time when the cancellation was initiated */
  canceledAt: Scalars['Time']['output'];
  /** The entity that initiated the cancellation */
  canceledBy: BusinessPolicyCancellationSource;
  /** The date and time when the cancellation becomes effective */
  effectiveAt: Scalars['Time']['output'];
  /** The reason for the cancellation */
  reason: BusinessPolicyCancellationReason;
  /** A detailed description of the cancellation reason */
  reasonDescription: Scalars['String']['output'];
  /** The commission that Oyster will return to the customer */
  returnCommission: Scalars['Float']['output'];
  /** The premium that Oyster will return to the carrier or broker */
  returnPremium: Scalars['Float']['output'];
  /** The type of cancellation */
  type: BusinessPolicyCancellationType;
};

/** The reason for policy cancellation */
export enum BusinessPolicyCancellationReason {
  /** Carrier closed the insurance class */
  CarrierClosedClass = 'CARRIER_CLOSED_CLASS',
  /** Cancellation due to loss history */
  CarrierLossHistory = 'CARRIER_LOSS_HISTORY',
  /** Carrier cancelled due to non-payment */
  CarrierNonpayment = 'CARRIER_NONPAYMENT',
  /** Carrier's underwriting decision */
  CarrierUnderwritingReason = 'CARRIER_UNDERWRITING_REASON',
  /** Insured closed their business */
  InsuredClosedBusiness = 'INSURED_CLOSED_BUSINESS',
  /** Insured found better coverage elsewhere */
  InsuredFoundBetterCoverage = 'INSURED_FOUND_BETTER_COVERAGE',
  /** Insured found a better price elsewhere */
  InsuredFoundBetterPrice = 'INSURED_FOUND_BETTER_PRICE',
  /** Insured failed to make payments */
  InsuredNonpayment = 'INSURED_NONPAYMENT',
  /** Insured no longer has exposure requiring coverage */
  InsuredNoExposure = 'INSURED_NO_EXPOSURE',
  /** Other unspecified reason */
  Other = 'OTHER',
  /** Oyster found better coverage for the insured */
  OysterFoundBetterCoverage = 'OYSTER_FOUND_BETTER_COVERAGE',
  /** Oyster found a better price for the insured */
  OysterFoundBetterPrice = 'OYSTER_FOUND_BETTER_PRICE'
}

/** The source of the policy cancellation */
export enum BusinessPolicyCancellationSource {
  /** Cancellation initiated by the carrier */
  Carrier = 'CARRIER',
  /** Cancellation initiated by the insured */
  Insured = 'INSURED',
  /** Cancellation initiated by Oyster */
  Oyster = 'OYSTER'
}

/** The type of policy cancellation */
export enum BusinessPolicyCancellationType {
  /** Standard cancellation of the policy */
  Cancellation = 'CANCELLATION',
  /** Non-renewal of the policy */
  Nonrenewal = 'NONRENEWAL',
  /** Expiration of the quote without conversion to a policy */
  QuoteExpired = 'QUOTE_EXPIRED'
}

/** Description of a policy coverage or exclusion */
export type BusinessPolicyCoverageOrExclusionDescription = {
  __typename?: 'BusinessPolicyCoverageOrExclusionDescription';
  /** The description of the coverage or exclusion */
  description: Scalars['String']['output'];
  /** Supporting quotes from the policy document */
  policyQuotes: Array<Scalars['String']['output']>;
  /** The title of the coverage or exclusion */
  title: Scalars['String']['output'];
};

/** An endorsement for the policy */
export type BusinessPolicyEndorsement = {
  __typename?: 'BusinessPolicyEndorsement';
  /** The form numbers that reference the endorsement */
  formNumbers: Array<Scalars['String']['output']>;
  /** Whether the endorsement is included */
  included: Scalars['Boolean']['output'];
  /** Supporting quotes from the policy document */
  quotes: Array<Scalars['String']['output']>;
  /** The reasoning for why the endorsement is included */
  reasoning: Scalars['String']['output'];
};

/** The endorsements for the policy */
export type BusinessPolicyEndorsements = {
  __typename?: 'BusinessPolicyEndorsements';
  /** Whether the primary and non-contributory coverage endorsement is included */
  primaryAndNonContributoryCoverage: BusinessPolicyEndorsement;
  /** Whether the waiver of subrogation endorsement is included */
  waiverOfSubrogation: BusinessPolicyEndorsement;
};

/** Entity involved in the policy */
export type BusinessPolicyEntity = {
  __typename?: 'BusinessPolicyEntity';
  /** The address of the entity */
  address: Address;
  /** The name of the entity */
  name: Scalars['String']['output'];
};

/** Extra limits for the policy, not covered by the standard limits */
export type BusinessPolicyExtraInsuranceLimit = {
  __typename?: 'BusinessPolicyExtraInsuranceLimit';
  /** The limit for the extra coverage */
  limit: Scalars['Float']['output'];
  /** The name of the limit */
  name: Scalars['String']['output'];
};

/** Policy details extracted from the policy document */
export type BusinessPolicyExtractedDetails = {
  __typename?: 'BusinessPolicyExtractedDetails';
  /** The blanket additional insureds for the policy */
  blanketAdditionalInsureds: Array<BusinessPolicyBlanketAdditionalInsured>;
  /** The carrier on the policy */
  carrier?: Maybe<BusinessPolicyEntity>;
  /** The carrier's NAIC code */
  carrierNaic?: Maybe<Scalars['String']['output']>;
  /** The coverage descriptions for the policy */
  coverageDescriptions: Array<BusinessPolicyCoverageOrExclusionDescription>;
  /** The deductible for the policy */
  deductible?: Maybe<Scalars['Float']['output']>;
  /** The endorsements for the policy */
  endorsements: BusinessPolicyEndorsements;
  /** The exclusion descriptions for the policy */
  exclusionDescriptions: Array<BusinessPolicyCoverageOrExclusionDescription>;
  /** Extra limits for the policy, not covered by the standard limits */
  extraLimits: Array<BusinessPolicyExtraInsuranceLimit>;
  /** The insured on the policy */
  insured?: Maybe<BusinessPolicyEntity>;
  /** The limits for the policy */
  limits: BusinessPolicyInsuranceLimits;
  /** The insured locations for the policy */
  locations: Array<BusinessPolicyLocation>;
  /** The named additional insureds for the policy */
  namedAdditionalInsureds: Array<BusinessPolicyNamedAdditionalInsured>;
  /** The policy number */
  policyNumber?: Maybe<Scalars['String']['output']>;
  /** A summary of the policy details */
  summary?: Maybe<Scalars['String']['output']>;
};

export type BusinessPolicyInput = {
  agencyBillingType?: InputMaybe<BusinessPolicyAgencyBillingType>;
  /** Additional fees collected by Oyster as revenue. */
  agencyFees: Scalars['Float']['input'];
  ascendExistingQuoteRefId?: InputMaybe<Scalars['String']['input']>;
  ascendProgramCreationMode?: InputMaybe<AscendProgramCreationMode>;
  ascendProgramId?: InputMaybe<Scalars['String']['input']>;
  billingType: BusinessPolicyBillingType;
  /** Fees collected by the wholesale broker */
  brokerFees: Scalars['Float']['input'];
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion: Scalars['Float']['input'];
  brokerId?: InputMaybe<Scalars['String']['input']>;
  carrierId: Scalars['String']['input'];
  estimatedCommission: Scalars['Float']['input'];
  expiresAt?: InputMaybe<Scalars['Time']['input']>;
  id: Scalars['String']['input'];
  inforceAt?: InputMaybe<Scalars['Time']['input']>;
  issuedAt?: InputMaybe<Scalars['Time']['input']>;
  market: InsuranceMarket;
  minimumEarnedPremium: Scalars['Float']['input'];
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees: Scalars['Float']['input'];
  /** Payment interval of the policy. If billed via ASCEND, leave this blank */
  paymentIntervalType?: InputMaybe<PaymentIntervalType>;
  paymentOccurrencesPerInterval: Scalars['Int']['input'];
  /** Fees collected by the carrier to administer the policy or program */
  policyFees: Scalars['Float']['input'];
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  /** The actual premium of the policy */
  premium: Scalars['Float']['input'];
  quoteNumber: Scalars['String']['input'];
  sourceAgentId: Scalars['String']['input'];
  sourceLeadId?: InputMaybe<Scalars['String']['input']>;
  sourceNotes: Scalars['String']['input'];
  sourceProcessType: BusinessPolicySourceProcessType;
  sourceSubType?: InputMaybe<BusinessPolicySourceSubType>;
  sourceType: BusinessPolicySourceType;
  state: PolicyState;
  /** Taxes levied on the policy premium and any taxable fees */
  taxes: Scalars['Float']['input'];
  /** Total amount to charge for the policy, including premium and all taxes and fees */
  total: Scalars['Float']['input'];
  type: InsuranceType;
};

/** Limits for the policy. The policy has the coverage and associated limit if the value is non null or zero. */
export type BusinessPolicyInsuranceLimits = {
  __typename?: 'BusinessPolicyInsuranceLimits';
  cyberAggregateLimit?: Maybe<Scalars['Float']['output']>;
  cyberRetentionLimit?: Maybe<Scalars['Float']['output']>;
  glAggregateLimit?: Maybe<Scalars['Float']['output']>;
  glMedicalPerOccurrenceLimit?: Maybe<Scalars['Float']['output']>;
  glMedicalPerPersonLimit?: Maybe<Scalars['Float']['output']>;
  glPerOccurrenceLimit?: Maybe<Scalars['Float']['output']>;
  glPersonalAndAdvertisingInjuryLimit?: Maybe<Scalars['Float']['output']>;
  glProductsAndCompletedOperationsLimit?: Maybe<Scalars['Float']['output']>;
  glRentedPremisesDamageLimit?: Maybe<Scalars['Float']['output']>;
  propertyBuildingLimit?: Maybe<Scalars['Float']['output']>;
  propertyContentsLimit?: Maybe<Scalars['Float']['output']>;
  umbrellaOrExcessAggregateLimit?: Maybe<Scalars['Float']['output']>;
  umbrellaOrExcessPerOccurrenceLimit?: Maybe<Scalars['Float']['output']>;
  wcPerAccidentLimit?: Maybe<Scalars['Float']['output']>;
  wcPerDiseaseEmployeeLimit?: Maybe<Scalars['Float']['output']>;
  wcPerDiseasePolicyLimit?: Maybe<Scalars['Float']['output']>;
};

export type BusinessPolicyLocation = {
  __typename?: 'BusinessPolicyLocation';
  /** The address of the location */
  address: Address;
  /** The limits for the location */
  limits: BusinessPolicyInsuranceLimits;
};

/** A named additional insured for the policy */
export type BusinessPolicyNamedAdditionalInsured = {
  __typename?: 'BusinessPolicyNamedAdditionalInsured';
  /** The address of the named additional insured */
  address: Address;
  /** The form numbers that reference the named additional insured */
  formNumbers: Array<Scalars['String']['output']>;
  /** The type of the named additional insured */
  name: Scalars['String']['output'];
  /** Supporting quotes from the policy document */
  quotes: Array<Scalars['String']['output']>;
  /** The reasoning for why the named additional insured is included */
  reasoning: Scalars['String']['output'];
};

/** Represents all pricing information for a policy */
export type BusinessPolicyPricing = {
  __typename?: 'BusinessPolicyPricing';
  /** The adjusted commission that Oyster will receive for this policy, which is the estimated commission minus any returns to the carrier or broker. If the policy is not canceled, this will be nil. */
  adjustedCommission?: Maybe<Scalars['Float']['output']>;
  /** The adjusted premium that Oyster will receive for this policy, which is the premium minus any returns to the customer. If the policy is not canceled, this will be nil. */
  adjustedPremium?: Maybe<Scalars['Float']['output']>;
  /** Add a resolver to compute adjusted commission + our fees. If the policy is not canceled, this will be the same as estimatedRevenue */
  adjustedRevenue: Scalars['Float']['output'];
  /** If billing type is AGENCY, this specifies information about the agency bill */
  agencyBilling?: Maybe<BusinessPolicyAgencyBilling>;
  /** Additional fees collected by Oyster as revenue. */
  agencyFees: Scalars['Float']['output'];
  /** The billing type */
  billingType: BusinessPolicyBillingType;
  /** Fees collected by the wholesale broker */
  brokerFees: Scalars['Float']['output'];
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion: Scalars['Float']['output'];
  /** Estimate of the commission Oyster receive for this policy */
  estimatedCommission: Scalars['Float']['output'];
  /** Add a resolver to compute estimated commission + our fees */
  estimatedRevenue: Scalars['Float']['output'];
  /** The interval type of payment plan. Leave null if currently unknown */
  intervalType?: Maybe<PaymentIntervalType>;
  /** The minimum premium that must be paid for policy, regardless of cancellation, if any */
  minimumEarnedPremium: Scalars['Float']['output'];
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees: Scalars['Float']['output'];
  /** Fees collected by the carrier to administer the policy or program */
  policyFees: Scalars['Float']['output'];
  /** The premium for this policy */
  premium: Scalars['Float']['output'];
  /** Taxes levied on the policy premium and any taxable fees */
  taxes: Scalars['Float']['output'];
  /** The total price paid for this policy */
  total: Scalars['Float']['output'];
};

export type BusinessPolicyQueryInput = {
  id: Scalars['ID']['input'];
  type: BusinessPolicyQueryInputType;
};

export enum BusinessPolicyQueryInputType {
  MerchantId = 'MERCHANT_ID',
  PolicyId = 'POLICY_ID'
}

/** Information about a policy reinstatement */
export type BusinessPolicyReinstatement = {
  __typename?: 'BusinessPolicyReinstatement';
  /** The date and time when the reinstatement becomes effective */
  effectiveAt: Scalars['Time']['output'];
  /** A description of the reason for reinstatement */
  reasonDescription: Scalars['String']['output'];
  /** The date and time when the reinstatement was processed */
  reinstatedAt: Scalars['Time']['output'];
};

/** Information about policy renewals */
export type BusinessPolicyRenewal = {
  __typename?: 'BusinessPolicyRenewal';
  /** List of policies that renew this policy */
  nextPolicies: Array<BusinessPolicy>;
  /** List of previous policies that this policy renews */
  previousPolicies: Array<BusinessPolicy>;
};

/** Detailed information about the source of a business policy */
export type BusinessPolicySource = {
  __typename?: 'BusinessPolicySource';
  /** The Oyster agent associated with this policy, if any */
  agent?: Maybe<OysterAgent>;
  /** The ID of the lead, if applicable */
  leadId?: Maybe<Scalars['String']['output']>;
  /** Additional notes about the policy source */
  notes: Scalars['String']['output'];
  /** The type of process used to handle the policy */
  processType: BusinessPolicySourceProcessType;
  /** The subtype of the policy source, if applicable */
  subType?: Maybe<BusinessPolicySourceSubType>;
  /** The main type of the policy source */
  type: BusinessPolicySourceType;
};

/** Describes the process type for handling the business policy */
export enum BusinessPolicySourceProcessType {
  /** Process led by an agent */
  AgentLed = 'AGENT_LED',
  /** Self-service process */
  SelfServe = 'SELF_SERVE'
}

/** Specifies the subtype of the business policy source */
export enum BusinessPolicySourceSubType {
  /** Referral from an accounting partner */
  AccountingPartner = 'ACCOUNTING_PARTNER',
  /** Referral from an agency */
  Agency = 'AGENCY',
  /** Lead from Bold Penguin */
  BoldPenguin = 'BOLD_PENGUIN',
  /** Referral from a customer */
  Customer = 'CUSTOMER',
  /** Other type of referral */
  OtherReferral = 'OTHER_REFERRAL',
  /** Lead from Tivly */
  Tivly = 'TIVLY'
}

/** Describes the source where the business deal comes from */
export enum BusinessPolicySourceType {
  /** Cross-sell through Go-To-Market strategy */
  GtmCrossSell = 'GTM_CROSS_SELL',
  /** Inbound lead or inquiry */
  Inbound = 'INBOUND',
  /** Other unspecified source */
  Other = 'OTHER',
  /** Paid lead acquisition */
  PaidLead = 'PAID_LEAD',
  /** Referral from an existing connection */
  Referral = 'REFERRAL'
}

/** Describes the reason why an application was updated */
export enum BusinessPolicyUpdateReasonCode {
  /** Placeholder reason codes until the use case becomes more clear */
  Unknown = 'UNKNOWN'
}

/** Describes the type of update that occured to a policy */
export enum BusinessPolicyUpdateType {
  /** The policy was canceled and is no longer active */
  CancelPolicy = 'CANCEL_POLICY',
  /** The policy was created */
  CreatePolicy = 'CREATE_POLICY',
  /** The policy expired and its no longer active */
  ExpirePolicy = 'EXPIRE_POLICY',
  /** The policy was reinstated after previously being canceled */
  ReinstatePolicy = 'REINSTATE_POLICY',
  /** The policy was created as a renewal */
  RenewPolicy = 'RENEW_POLICY',
  /** The policy was updated with new details extracted from the policy document */
  UpdateExtractedDetails = 'UPDATE_EXTRACTED_DETAILS',
  /** The policy was updated. Requires manual inspection of the policy to determine the actual update */
  UpdatePolicy = 'UPDATE_POLICY'
}

/** Describes a particular version of a policy */
export type BusinessPolicyVersion = {
  __typename?: 'BusinessPolicyVersion';
  /** The full policy object that includes all of the updates made in this version and all previous versions */
  businessPolicy: BusinessPolicy;
  /** The date this version was created */
  createdAt: Scalars['Time']['output'];
  /** Produces a human-readable string that can be used to diff against another policy version. */
  diffableJson: Scalars['String']['output'];
  /** The version number of the previous policy version, if there is any. If this field is null, then this version is the only version */
  previousVersion?: Maybe<BusinessPolicyVersion>;
  /** The ID of the entity that initiated the update, depending on the type */
  updateInitiatorId: Scalars['String']['output'];
  /** The type of the entity that initiated the update */
  updateInitiatorType: UpdateInitiatorType;
  /** Indicates whether this update is 'private' in the sense that it should not be displayed to the policyholder */
  updatePrivate: Scalars['Boolean']['output'];
  /** A human-readable description of the reason why this update was made */
  updateReason?: Maybe<Scalars['String']['output']>;
  /** A programmatic representation of the reason why this update was made */
  updateReasonCode?: Maybe<BusinessPolicyUpdateReasonCode>;
  /** The types of updates made between this version and the previous version */
  updateTypes: Array<BusinessPolicyUpdateType>;
  /** The version number of this policy version */
  version: Scalars['Int64']['output'];
};

export type CancelBusinessPolicyInput = {
  canceledBy: BusinessPolicyCancellationSource;
  effectiveAt: Scalars['Time']['input'];
  reason: BusinessPolicyCancellationReason;
  reasonDescription: Scalars['String']['input'];
  returnCommission: Scalars['Float']['input'];
  returnPremium: Scalars['Float']['input'];
  type: BusinessPolicyCancellationType;
};

/** CertificateCoverage represents a specific coverage in the insurance certificate. */
export type CertificateCoverage = {
  __typename?: 'CertificateCoverage';
  /** The date when the coverage becomes effective */
  effectiveDate: Scalars['Time']['output'];
  /** The date when the coverage expires */
  expirationDate: Scalars['Time']['output'];
  /** Indicates if there is a waiver of subrogation */
  hasWaiverOfSubrogation: Scalars['Boolean']['output'];
  /** Indicates if the certificate holder is an additional insured */
  holderIsAdditionalInsured: Scalars['Boolean']['output'];
  /** The insurer providing this coverage */
  insurer: CoverageInsurer;
  /** A list of limits for this coverage */
  limits: Array<CertificateLimit>;
  /** The policy number for this coverage */
  policyNumber: Scalars['String']['output'];
  /** The type of insurance coverage */
  type: InsuranceType;
};

/** CertificateDetails contains the detailed information of an insurance certificate. */
export type CertificateDetails = {
  __typename?: 'CertificateDetails';
  /** The entity that holds the certificate */
  certificateHolder: CertificateEntity;
  /** The unique identifier for the certificate */
  certificateNumber: Scalars['String']['output'];
  /** A list of coverages included in the certificate */
  coverages: Array<CertificateCoverage>;
  /** A description of the operations covered by the certificate */
  descriptionOfOperations: Scalars['String']['output'];
  /** The entity that is insured */
  insured: CertificateEntity;
  /** The entity that produced the certificate */
  producer: CertificateEntity;
};

/** CertificateEntity represents an entity involved in the insurance certificate. */
export type CertificateEntity = {
  __typename?: 'CertificateEntity';
  /** The address of the entity */
  address: Scalars['String']['output'];
  /** The name of the entity */
  name: Scalars['String']['output'];
};

/** CertificateLimit represents a specific limit in the insurance coverage. */
export type CertificateLimit = {
  __typename?: 'CertificateLimit';
  /** The monetary value of the limit */
  limit: Scalars['Float']['output'];
  /** The name of the limit */
  name: Scalars['String']['output'];
};

/** Represents an action required for compliance */
export type ComplianceAction = {
  __typename?: 'ComplianceAction';
  /** The person assigned to complete this action */
  assignee?: Maybe<ComplianceActionAssignee>;
  /** The person who completed this action, if applicable */
  completedBy?: Maybe<ComplianceActionAssignee>;
  /** The date when this action was completed, if applicable */
  completionDate?: Maybe<Scalars['Time']['output']>;
  /** List of requirements for completing this action */
  completionRequirements: Array<ComplianceActionCompletionRequirement>;
  /** List of compliance items associated with this action */
  complianceItems: Array<ComplianceItem>;
  /** Detailed description of the compliance action */
  description: Scalars['String']['output'];
  /** The date by which this action should be completed */
  dueDate: Scalars['Time']['output'];
  /** Unique identifier for the compliance action */
  id: Scalars['ID']['output'];
  /** Current status of the compliance action */
  status: ComplianceActionStatus;
  /** Title of the compliance action */
  title: Scalars['String']['output'];
};

/** Represents a person assigned to or who completed a compliance action */
export type ComplianceActionAssignee = {
  __typename?: 'ComplianceActionAssignee';
  /** Email address of the assignee */
  email: Scalars['String']['output'];
  /** Unique identifier for the assignee */
  id: Scalars['ID']['output'];
  /** Full name of the assignee */
  name: Scalars['String']['output'];
};

/** Represents a requirement for completing a compliance action */
export type ComplianceActionCompletionRequirement = {
  __typename?: 'ComplianceActionCompletionRequirement';
  /** Detailed description of the completion requirement */
  description: Scalars['String']['output'];
  /** Unique identifier for the completion requirement */
  id: Scalars['ID']['output'];
  /** Title of the completion requirement */
  title: Scalars['String']['output'];
};

/** Represents the status of a compliance action */
export enum ComplianceActionStatus {
  /** The action has been completed */
  Closed = 'CLOSED',
  /** The action is still pending completion */
  Open = 'OPEN'
}

/** Represents a group of compliance items */
export type ComplianceGroup = {
  __typename?: 'ComplianceGroup';
  /** Detailed description of the compliance group */
  description: Scalars['String']['output'];
  /** List of compliance items within this group */
  items: Array<ComplianceItem>;
  /** Unique identifier for the compliance group */
  slug: Scalars['ID']['output'];
  /** Title of the compliance group */
  title: Scalars['String']['output'];
};

/** Represents a specific compliance item */
export type ComplianceItem = {
  __typename?: 'ComplianceItem';
  /** List of actions associated with this compliance item */
  actions: Array<ComplianceAction>;
  /** Detailed description of the compliance item */
  description: Scalars['String']['output'];
  /** Unique identifier for the compliance item */
  id: Scalars['ID']['output'];
  /** List of reference materials related to this compliance item */
  referenceMaterials: Array<ComplianceReferenceMaterial>;
  /** List of requirements for this compliance item */
  requirements: Array<ComplianceRequirement>;
  /** Slug identifier for the compliance item */
  slug: Scalars['String']['output'];
  /** List of standards associated with this compliance item */
  standards: Array<ComplianceStandard>;
  /** Title of the compliance item */
  title: Scalars['String']['output'];
};

/** Represents reference material for compliance */
export type ComplianceReferenceMaterial = {
  __typename?: 'ComplianceReferenceMaterial';
  /** Detailed description of the reference material */
  description: Scalars['String']['output'];
  /** Title of the reference material */
  title: Scalars['String']['output'];
  /** URL to access the reference material */
  url: Scalars['String']['output'];
};

/** Represents a specific requirement for compliance */
export type ComplianceRequirement = {
  __typename?: 'ComplianceRequirement';
  /** Detailed description of the compliance requirement */
  description: Scalars['String']['output'];
  /** Title of the compliance requirement */
  title: Scalars['String']['output'];
};

/** Represents a compliance standard */
export type ComplianceStandard = {
  __typename?: 'ComplianceStandard';
  /** Detailed description of the compliance standard */
  description: Scalars['String']['output'];
  /** Title of the compliance standard */
  title: Scalars['String']['output'];
  /** URL for more information about the standard */
  url: Scalars['String']['output'];
};

/** CoverageInsurer represents the insurance company providing the coverage. */
export type CoverageInsurer = {
  __typename?: 'CoverageInsurer';
  /** The National Association of Insurance Commissioners (NAIC) number of the insurer */
  naic: Scalars['String']['output'];
  /** The name of the insurance company */
  name: Scalars['String']['output'];
};

/** Represents information about the associated CoverForce application. */
export type CoverforceApplication = {
  __typename?: 'CoverforceApplication';
  /** The ID of the policy that is binding, if any was selected */
  bindingPolicyId?: Maybe<Scalars['String']['output']>;
  /** The ID of the quote that is binding, if any was selected */
  bindingQuoteId?: Maybe<Scalars['String']['output']>;
  /** The ID of the associated CoverForce application. */
  id: Scalars['ID']['output'];
  /** The fingerprint of the application last submitted for quotes. */
  lastApplicationFingerprint: Scalars['String']['output'];
  /** The timestamp that the Coverforce application was last submitted for quotes */
  lastQuotesRefreshedAt?: Maybe<Scalars['Time']['output']>;
  /** The type of insurance the CoverForce application is for. */
  type: InsuranceType;
};

export type CreateApplicationInput = {
  /** The legal name of the business applying for insurance. */
  businessName: Scalars['String']['input'];
  /** The email address of the contact person for the business application. */
  email: Scalars['String']['input'];
  /** The name of the contact person for the business application. */
  fullName: Scalars['String']['input'];
  /** The phone number of the contact person for the business application. */
  phone: Scalars['String']['input'];
};

export type CreateBusinessPoliciesInput = {
  insured: InsuredInput;
  merchantId: Scalars['String']['input'];
  policies: Array<BusinessPolicyInput>;
};

export type CreateFileInput = {
  bytesBase64: Scalars['String']['input'];
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  role: FileRole;
  visibility: FileVisibility;
};

/** Input type for creating a new merchant */
export type CreateMerchantInput = {
  /** Physical address of the business */
  address: AddressInput;
  /** ID of the business category */
  businessCategoryId: Scalars['String']['input'];
  /** 'Doing Business As' name of the business */
  businessDba: Scalars['String']['input'];
  /** Legal name of the business */
  businessName: Scalars['String']['input'];
  /** Email address for the business */
  email: Scalars['String']['input'];
  /** Personalization details for the merchant */
  personalization?: InputMaybe<PersonalizationInput>;
  /** Phone number for the business */
  phone: Scalars['String']['input'];
  /** First name of the primary user */
  userFirstName: Scalars['String']['input'];
  /** Last name of the primary user */
  userLastName: Scalars['String']['input'];
  /** Website of the business */
  website: Scalars['String']['input'];
};

/**
 * A file describes a document that is attached to an object, with
 * metadata and URLs to fetch it.
 */
export type File = {
  __typename?: 'File';
  /** The internal URL to fetch the file */
  adminUrl: Scalars['String']['output'];
  /** The timestamp this file was created */
  createdAt: Scalars['Time']['output'];
  /** The details of this specific file, depending on the file role */
  details?: Maybe<FileDetails>;
  /** The unique identifier of the file */
  id: Scalars['ID']['output'];
  /** The file name, for display and download purposes */
  name: Scalars['String']['output'];
  /** The notes associated with the file */
  notes: Scalars['String']['output'];
  /** The role of the file, i.e. how it relates to the object it is attached to */
  role: FileRole;
  /** The size of the file in bytes, if available */
  size: Scalars['Int']['output'];
  /** The file MIME type */
  type: Scalars['String']['output'];
  /** The timestamp this file was updated */
  updatedAt: Scalars['Time']['output'];
  /** The public URL to fetch the file, if the file is visible to the user */
  url?: Maybe<Scalars['String']['output']>;
  /** The visibility of the file, i.e. who can view this file */
  visibility: FileVisibility;
};

/**
 * FileDetails is a union type that represents different types of file details.
 * Currently, it only includes CertificateDetails.
 */
export type FileDetails = CertificateDetails;

/** File role describes a type of file related to an object */
export enum FileRole {
  /** The file is a form generated as part of an application */
  ApplicationForm = 'APPLICATION_FORM',
  /** The file is a binder, a pre-coverage confirmation document */
  Binder = 'BINDER',
  /** The file is a certificate of insurance */
  Certificate = 'CERTIFICATE',
  /** The file is a template to generate a certificate of liability insurance */
  CertificateOfLiabilityInsuranceTemplate = 'CERTIFICATE_OF_LIABILITY_INSURANCE_TEMPLATE',
  /** The file is an endorsement, affixed to the policy document */
  Endorsement = 'ENDORSEMENT',
  /** The file is an invoice, generally for internal tracking purposes */
  Invoice = 'INVOICE',
  /** The file is a loss policy release, issued when a policy is canceled by the customer */
  LossPolicyRelease = 'LOSS_POLICY_RELEASE',
  /** The file is a notice of cancellation, issued when a policy is being canceled or nonrenewed by the carrier */
  NoticeOfCancellation = 'NOTICE_OF_CANCELLATION',
  /** The file is of another type */
  Other = 'OTHER',
  /** The file is a policy document */
  PolicyDocument = 'POLICY_DOCUMENT',
  /** The file is a quote document */
  QuoteDocument = 'QUOTE_DOCUMENT',
  /** The file role is unknown */
  Unknown = 'UNKNOWN'
}

/** File visibility describes who is able to view a file */
export enum FileVisibility {
  /** Only internal users can view this file */
  Internal = 'INTERNAL',
  /** End users and internal users can view this file */
  User = 'USER'
}

export type GenerateCertificateInput = {
  additionalInsured: Scalars['Boolean']['input'];
  additionalInsuredRelationship?: InputMaybe<Scalars['String']['input']>;
  additionalInsuredRelationshipDescription?: InputMaybe<Scalars['String']['input']>;
  address: AddressInput;
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

export type GetBusinessApplication = {
  id?: InputMaybe<Scalars['ID']['input']>;
  merchantId?: InputMaybe<Scalars['ID']['input']>;
  policyId?: InputMaybe<Scalars['ID']['input']>;
};

export type InsuranceCarrier = {
  __typename?: 'InsuranceCarrier';
  /** If available, the ID of this carrier in Ascend */
  ascendId?: Maybe<Scalars['String']['output']>;
  /** Human readable short identifier of this carrier */
  id: Scalars['String']['output'];
  /** True if this insurance entity is a broker which we use to access a carrier */
  isBroker: Scalars['Boolean']['output'];
  /** True if this insurance entity is a carrier with binding authority */
  isCarrier: Scalars['Boolean']['output'];
  /** Legal name of this carrier */
  name: Scalars['String']['output'];
  /** The types of insurance policies supported by this carrier */
  supportedPolicies?: Maybe<Array<InsuranceType>>;
};

export type InsuranceLimits = {
  __typename?: 'InsuranceLimits';
  bopAggregateLimit?: Maybe<Scalars['Float']['output']>;
  bopPerOccurrenceLimit?: Maybe<Scalars['Float']['output']>;
  coverageState: Scalars['String']['output'];
  cyberAggregateLimit?: Maybe<Scalars['Float']['output']>;
  cyberRetentionLimit?: Maybe<Scalars['Float']['output']>;
  glAggregateLimit?: Maybe<Scalars['Float']['output']>;
  glPerOccurrenceLimit?: Maybe<Scalars['Float']['output']>;
  wcPerAccidentLimit?: Maybe<Scalars['Float']['output']>;
  wcPerDiseaseEmployeeLimit?: Maybe<Scalars['Float']['output']>;
  wcPerDiseasePolicyLimit?: Maybe<Scalars['Float']['output']>;
};

export type InsuranceLimitsInput = {
  bopAggregateLimit?: InputMaybe<Scalars['Float']['input']>;
  bopPerOccurrenceLimit?: InputMaybe<Scalars['Float']['input']>;
  coverageState: Scalars['String']['input'];
  cyberAggregateLimit?: InputMaybe<Scalars['Float']['input']>;
  cyberRetentionLimit?: InputMaybe<Scalars['Float']['input']>;
  glAggregateLimit?: InputMaybe<Scalars['Float']['input']>;
  glPerOccurrenceLimit?: InputMaybe<Scalars['Float']['input']>;
  wcPerAccidentLimit?: InputMaybe<Scalars['Float']['input']>;
  wcPerDiseaseEmployeeLimit?: InputMaybe<Scalars['Float']['input']>;
  wcPerDiseasePolicyLimit?: InputMaybe<Scalars['Float']['input']>;
};

/** Describes the market the insurance policy was written in. */
export enum InsuranceMarket {
  /** The admitted market */
  Admitted = 'ADMITTED',
  /** The Excess and Surplus (E&S) market */
  ExcessAndSurplus = 'EXCESS_AND_SURPLUS'
}

/** Insurance type for business insurance */
export enum InsuranceType {
  AccidentAndHealth = 'ACCIDENT_AND_HEALTH',
  BusinessOwners = 'BUSINESS_OWNERS',
  CommercialAuto = 'COMMERCIAL_AUTO',
  Cyber = 'CYBER',
  DirectorsAndOfficers = 'DIRECTORS_AND_OFFICERS',
  EmploymentPracticesLiability = 'EMPLOYMENT_PRACTICES_LIABILITY',
  ErrorsAndOmission = 'ERRORS_AND_OMISSION',
  GeneralLiability = 'GENERAL_LIABILITY',
  JewelersBlock = 'JEWELERS_BLOCK',
  Other = 'OTHER',
  Package = 'PACKAGE',
  ProductLiability = 'PRODUCT_LIABILITY',
  ProfessionalLiability = 'PROFESSIONAL_LIABILITY',
  Property = 'PROPERTY',
  Rental = 'RENTAL',
  ShippingAndTransportation = 'SHIPPING_AND_TRANSPORTATION',
  UmbrellaOrExcess = 'UMBRELLA_OR_EXCESS',
  WorkersCompensation = 'WORKERS_COMPENSATION'
}

export type InsuredInput = {
  addressCity: Scalars['String']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  addressState: Scalars['String']['input'];
  addressZipCode: Scalars['String']['input'];
  businessName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

/** Details of a bank transaction linked to a payout */
export type LinkedBankTransaction = {
  __typename?: 'LinkedBankTransaction';
  /** The amount of the transaction in dollars */
  amount: Scalars['Float']['output'];
  /** The ID of the counterparty in the bank account's system */
  counterPartyId: Scalars['String']['output'];
  /** The name of the counterparty */
  counterPartyName: Scalars['String']['output'];
  /** The date the transaction was created */
  createdAt: Scalars['Time']['output'];
  /** The link to the transaction in the partner bank's system */
  dashboardLink: Scalars['String']['output'];
  /** The bank description of the transaction */
  description: Scalars['String']['output'];
  /** The ID of the transaction in the bank account */
  id: Scalars['String']['output'];
  /** The memo line of the transaction */
  memo: Scalars['String']['output'];
  /** The status of the transaction in the bank account */
  status: AchPaymentStatus;
};

/** Describes a merchant partner's account */
export type Merchant = {
  __typename?: 'Merchant';
  /** The business' physical address */
  address: Address;
  /** Any applications that this merchant has submitted */
  applications: Array<BusinessApplication>;
  /** The business category that this business belongs to */
  businessCategory?: Maybe<BusinessCategory>;
  /** The date this merchant was created */
  createdAt: Scalars['Time']['output'];
  /** The business' dba */
  dba: Scalars['String']['output'];
  /** The business' website domain */
  domain: Scalars['String']['output'];
  /** The business' email address */
  email: Scalars['String']['output'];
  /** Any files that are associated with this merchant */
  files: Array<File>;
  /** The ID of the merchant */
  id: Scalars['ID']['output'];
  /** The merchant's API keys */
  keys?: Maybe<Array<MerchantKey>>;
  /** The Mercury recipient ID for this merchant, used to determine the payout information for this merchant */
  mercuryRecipientId?: Maybe<Scalars['String']['output']>;
  /** The business' name */
  name: Scalars['String']['output'];
  /** The merchant's preferred payment method */
  paymentMethod: MerchantPaymentMethod;
  /** The business' contact phone number */
  phone: Scalars['String']['output'];
  /** List of product verticals this business sells */
  productVerticals: Array<ProductType>;
  /** The date this merchant was last updated */
  updatedAt: Scalars['Time']['output'];
  /** The users that can access this merchant's account */
  users: Array<MerchantUser>;
};

/** A merchant's API key */
export type MerchantKey = {
  __typename?: 'MerchantKey';
  /** The API key the merchant uses to authenticate themselves via the API/SDK */
  apiKey: Scalars['ID']['output'];
  /** The date this API key was created */
  createdAt: Scalars['Time']['output'];
  /** The Merchant that this API key belongs to */
  merchant?: Maybe<Merchant>;
  /** The date this API key was updated */
  updatedAt: Scalars['Time']['output'];
};

/** The payment method */
export enum MerchantPaymentMethod {
  /** The merchant has opted to be paid out via physical check */
  Check = 'CHECK',
  /** The merchant has opted to be paid out via ACH and has connected a bank account */
  Electronic = 'ELECTRONIC',
  /** The merchant has not selected a payment method */
  Unknown = 'UNKNOWN'
}

/** A user that can log into the merchant's account */
export type MerchantUser = {
  __typename?: 'MerchantUser';
  /** The email address of the user */
  email: Scalars['String']['output'];
  /** The first name of the user */
  firstName: Scalars['String']['output'];
  /** The ID of the user */
  id: Scalars['ID']['output'];
  /** The last name of the user */
  lastName: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Adds a new business location to an existing business application.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *   location: The input data required to add a new business location.
   *
   * Returns:
   *   The updated BusinessApplication object with the new location added.
   */
  addBusinessLocation: BusinessApplication;
  /**
   * Binds a quote to a business application.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *   quoteId: The unique identifier of the quote to bind.
   *   paymentPlanId: The ID of the payment plan to use.
   *   paymentMethod: The payment method to use.
   *
   * Returns:
   *   The updated BusinessApplication object with the quote bound.
   */
  bindQuote: BusinessApplication;
  /** Delete the document and remove the reference from the given business policy */
  businessPolicyDeleteDocument?: Maybe<BusinessPolicy>;
  /** Upload the document for this business policy to S3 */
  businessPolicyUploadDocument: File;
  /** Cancel the specified business insurance policy */
  cancelBusinessPolicy: BusinessPolicy;
  /**
   * Creates a new business application.
   *
   * Arguments:
   *   application: The input data required to create a new business application.
   *
   * Returns:
   *   The created BusinessApplication object.
   */
  createBusinessApplication: BusinessApplication;
  /** Create business insurance policies according to specification */
  createBusinessPolicies: Array<BusinessPolicy>;
  /** Create a new merchant */
  createMerchant?: Maybe<Merchant>;
  /** Deletes the specified business insurance policy */
  deleteBusinessPolicy?: Maybe<BusinessPolicy>;
  /** Generate a certificate of insurance with the given inputs for the current merchant */
  generateCertificate?: Maybe<File>;
  /**
   * Starts the quote process for a business application if it's not already quoting
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *
   * Returns:
   *   The updated BusinessApplication object.
   */
  quoteBusinessApplication: BusinessApplication;
  /** Reinstate the specified business insurance policy */
  reinstateBusinessPolicy: BusinessPolicy;
  /**
   * Removes a business location from an existing business application.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *   locationId: The unique identifier of the business location to remove.
   *
   * Returns:
   *   The updated BusinessApplication object with the location removed.
   */
  removeBusinessLocation: BusinessApplication;
  /** Renew the specified business insurance policy */
  renewBusinessPolicy: BusinessPolicy;
  /** Add a message to the policy AI conversation */
  riskManagementAddPolicyConversationMessage: Array<PolicyConversationMessage>;
  /** Mark an action item as complete */
  riskManagementMarkActionItemComplete: ComplianceAction;
  /** Update the assignee of an action item */
  riskManagementSetActionItemAssignee: ComplianceAction;
  /** Update the due date of an action item */
  riskManagementSetActionItemDueDate: ComplianceAction;
  /** Set up the risk management data for a merchant */
  riskManagementSetup: RiskManagementData;
  /**
   * Submits and finalizes the business application if not already submitted. This
   * indicates that a customer has finished submitting their information and is
   * ready to be reviewed by an agent.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *
   * Returns:
   *   The updated BusinessApplication object.
   */
  submitBusinessApplication: BusinessApplication;
  /**
   * Updates an existing business application.
   *
   * Arguments:
   *   id: The unique identifier of the business application to update.
   *   application: The input data required to update the business application.
   *
   * Returns:
   *   The updated BusinessApplication object.
   */
  updateBusinessApplication: BusinessApplication;
  /**
   * Updates an existing business location in a business application.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *   locationId: The unique identifier of the business location to update.
   *   location: The input data required to update the business location.
   *
   * Returns:
   *   The updated BusinessApplication object with the updated location.
   */
  updateBusinessLocation: BusinessApplication;
  /** Update the specified business insurance policy */
  updateBusinessPolicy: BusinessPolicy;
  /** Update an existing merchant */
  updateMerchant?: Maybe<Merchant>;
};

export type MutationAddBusinessLocationArgs = {
  applicationId: Scalars['ID']['input'];
  location: BusinessLocationInput;
};

export type MutationBindQuoteArgs = {
  applicationId: Scalars['ID']['input'];
  paymentMethod?: InputMaybe<PaymentPlanPaymentMethodType>;
  paymentPlanId?: InputMaybe<Scalars['String']['input']>;
  quoteId: Scalars['ID']['input'];
};

export type MutationBusinessPolicyDeleteDocumentArgs = {
  fileId: Scalars['ID']['input'];
  policyId: Scalars['ID']['input'];
};

export type MutationBusinessPolicyUploadDocumentArgs = {
  policyId: Scalars['ID']['input'];
  req: CreateFileInput;
};

export type MutationCancelBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: CancelBusinessPolicyInput;
};

export type MutationCreateBusinessApplicationArgs = {
  application: CreateApplicationInput;
};

export type MutationCreateBusinessPoliciesArgs = {
  req: CreateBusinessPoliciesInput;
};

export type MutationCreateMerchantArgs = {
  req: CreateMerchantInput;
};

export type MutationDeleteBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationGenerateCertificateArgs = {
  req: GenerateCertificateInput;
};

export type MutationQuoteBusinessApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationReinstateBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: ReinstateBusinessPolicyInput;
};

export type MutationRemoveBusinessLocationArgs = {
  applicationId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};

export type MutationRenewBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: RenewBusinessPolicyInput;
};

export type MutationRiskManagementAddPolicyConversationMessageArgs = {
  message: Scalars['String']['input'];
  policyId: Scalars['ID']['input'];
};

export type MutationRiskManagementMarkActionItemCompleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRiskManagementSetActionItemAssigneeArgs = {
  id: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRiskManagementSetActionItemDueDateArgs = {
  dueDate: Scalars['Time']['input'];
  id: Scalars['ID']['input'];
};

export type MutationRiskManagementSetupArgs = {
  input: RiskManagementSetupInput;
};

export type MutationSubmitBusinessApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateBusinessApplicationArgs = {
  application: UpdateApplicationInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateBusinessLocationArgs = {
  applicationId: Scalars['ID']['input'];
  location: BusinessLocationInput;
  locationId: Scalars['ID']['input'];
};

export type MutationUpdateBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: UpdateBusinessPolicyInput;
};

export type MutationUpdateMerchantArgs = {
  id: Scalars['ID']['input'];
  req: UpdateMerchantInput;
};

export enum OperationType {
  ManufacturingDesign = 'MANUFACTURING_DESIGN',
  Other = 'OTHER',
  Rental = 'RENTAL',
  Retail = 'RETAIL',
  Service = 'SERVICE',
  Wholesale = 'WHOLESALE'
}

export type OysterAgent = {
  __typename?: 'OysterAgent';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PastPolicyLoss = {
  __typename?: 'PastPolicyLoss';
  /** The date when the claim was made. */
  claimDate?: Maybe<Scalars['Time']['output']>;
  /** The status of the claim. */
  claimStatus?: Maybe<PastPolicyLossClaimStatus>;
  /** The date when the loss occurred. */
  lossDate?: Maybe<Scalars['Time']['output']>;
  /** A description of the loss. */
  lossDescription: Scalars['String']['output'];
  /** The 2-letter state code where the loss occurred. */
  lossState: Scalars['String']['output'];
  /** The type of loss that occurred. */
  lossType?: Maybe<PastPolicyLossType>;
  /** The date when the policy became effective. */
  policyEffectiveDate?: Maybe<Scalars['Time']['output']>;
  /** The date when the policy expired. */
  policyExpirationDate?: Maybe<Scalars['Time']['output']>;
  /** The type of insurance policy. */
  policyType?: Maybe<InsuranceType>;
  /** The total amount paid for the claim. */
  totalPaidAmount: Scalars['Float']['output'];
  /** The total amount reserved for the claim. */
  totalReservedAmount: Scalars['Float']['output'];
};

export enum PastPolicyLossClaimStatus {
  /** The claim has been closed. */
  Closed = 'CLOSED',
  /** The claim is currently open. */
  Open = 'OPEN',
  Unknown = 'UNKNOWN'
}

export type PastPolicyLossInput = {
  /** The date when the claim was made. */
  claimDate: Scalars['String']['input'];
  /** The status of the claim. */
  claimStatus?: InputMaybe<PastPolicyLossClaimStatus>;
  /** The date when the loss occurred. */
  lossDate: Scalars['String']['input'];
  /** A description of the loss. */
  lossDescription: Scalars['String']['input'];
  /** The 2-letter state code where the loss occurred. */
  lossState: Scalars['String']['input'];
  /** The type of loss that occurred. */
  lossType?: InputMaybe<PastPolicyLossType>;
  /** The date when the policy became effective. */
  policyEffectiveDate: Scalars['String']['input'];
  /** The date when the policy expired. */
  policyExpirationDate: Scalars['String']['input'];
  /** The type of insurance policy. */
  policyType?: InputMaybe<InsuranceType>;
  /** The total amount paid for the claim. */
  totalPaidAmount: Scalars['Float']['input'];
  /** The total amount reserved for the claim. */
  totalReservedAmount: Scalars['Float']['input'];
};

export enum PastPolicyLossType {
  BodilyInjuryOther = 'BODILY_INJURY_OTHER',
  BusinessInterruption = 'BUSINESS_INTERRUPTION',
  ComputerFraud = 'COMPUTER_FRAUD',
  ContingentBusinessInterruption = 'CONTINGENT_BUSINESS_INTERRUPTION',
  CyberIncident = 'CYBER_INCIDENT',
  DigitalData = 'DIGITAL_DATA',
  EmployeePractices = 'EMPLOYEE_PRACTICES',
  ErrorsAndOmissions = 'ERRORS_AND_OMISSIONS',
  Fire = 'FIRE',
  FoodSpoilage = 'FOOD_SPOILAGE',
  FundTransferFraud = 'FUND_TRANSFER_FRAUD',
  GeneralLiabilityProducts = 'GENERAL_LIABILITY_PRODUCTS',
  GlPropertyDamage = 'GL_PROPERTY_DAMAGE',
  Hail = 'HAIL',
  Indemnity = 'INDEMNITY',
  InlandMarine = 'INLAND_MARINE',
  LiabilityMedicalPayments = 'LIABILITY_MEDICAL_PAYMENTS',
  MediaLiability = 'MEDIA_LIABILITY',
  Medical = 'MEDICAL',
  MedicalAndIndemnity = 'MEDICAL_AND_INDEMNITY',
  NetworkExtortion = 'NETWORK_EXTORTION',
  Other = 'OTHER',
  PaymentCard = 'PAYMENT_CARD',
  PersonalInjury = 'PERSONAL_INJURY',
  PrivacyAndNetworkSecurity = 'PRIVACY_AND_NETWORK_SECURITY',
  ProfessionalLiability = 'PROFESSIONAL_LIABILITY',
  PropertyDamageCollapse = 'PROPERTY_DAMAGE_COLLAPSE',
  PropertyDamageOther = 'PROPERTY_DAMAGE_OTHER',
  RegulatoryProceeding = 'REGULATORY_PROCEEDING',
  SlipFallInside = 'SLIP_FALL_INSIDE',
  SlipFallOutside = 'SLIP_FALL_OUTSIDE',
  SocialEngineeringFraud = 'SOCIAL_ENGINEERING_FRAUD',
  TechnologyErrorsAndOmissions = 'TECHNOLOGY_ERRORS_AND_OMISSIONS',
  Theft = 'THEFT',
  Unknown = 'UNKNOWN',
  Vandalism = 'VANDALISM',
  WaterNonWeatherRelated = 'WATER_NON_WEATHER_RELATED',
  Windstorm = 'WINDSTORM'
}

/** PaymentIntervalType enumerates the interval of a fee occurrence */
export enum PaymentIntervalType {
  /** Describes an annual payment */
  Annual = 'ANNUAL',
  /** Describes a monthly payment */
  Monthly = 'MONTHLY',
  /** Describes a non-recurring payment */
  None = 'NONE',
  /** Describes a quarterly payment */
  Quarterly = 'QUARTERLY'
}

/** How the customer chose to pay */
export enum PaymentOptionType {
  AnnualPayInFull = 'ANNUAL_PAY_IN_FULL',
  MonthlyFinanced = 'MONTHLY_FINANCED'
}

/** Enum representing the frequency of installments for a payment plan */
export enum PaymentPlanInstallmentFrequency {
  /** Monthly installments */
  Monthly = 'MONTHLY',
  /** No recurring installments */
  None = 'NONE',
  /** Quarterly installments */
  Quarterly = 'QUARTERLY'
}

/** Enum representing the different payment methods available for a payment plan */
export enum PaymentPlanPaymentMethodType {
  /** Payment via credit card */
  CreditCard = 'CREDIT_CARD',
  /** Payment via direct debit */
  DirectDebit = 'DIRECT_DEBIT',
  /** Payment via electronic fund transfer */
  ElectronicFundTransfer = 'ELECTRONIC_FUND_TRANSFER'
}

/** Input type for overall personalization details */
export type PersonalizationInput = {
  /** Business insurance personalization details */
  businessInsurance?: InputMaybe<BusinessInsurancePersonalizationInput>;
};

/** Represents the policy AI conversation for a policy */
export type PolicyConversationMessage = {
  __typename?: 'PolicyConversationMessage';
  /** List of attachments for the message */
  attachments: Array<PolicyConversationMessageAttachment>;
  /** The content of the message */
  content: Scalars['String']['output'];
  /** The ID of the message */
  id: Scalars['ID']['output'];
  /** The role of the message */
  role: PolicyConversationMessageRole;
  /** The timestamp of the message */
  timestamp: Scalars['Time']['output'];
};

/** Represents an attachment for a policy AI conversation message */
export type PolicyConversationMessageAttachment = {
  __typename?: 'PolicyConversationMessageAttachment';
  /** The content of the attachment */
  content: Scalars['String']['output'];
  /** The type of the attachment */
  type: PolicyConversationMessageAttachmentType;
};

/** Represents the type of an attachment for a policy AI conversation message */
export enum PolicyConversationMessageAttachmentType {
  /** A quote from a document */
  Quote = 'QUOTE'
}

/** Represents the role of a policy AI conversation message */
export enum PolicyConversationMessageRole {
  /** The message is from the AI */
  Ai = 'AI',
  /** The message is from the user */
  User = 'USER'
}

/** The enumeration of states for business policy */
export enum PolicyState {
  /** The policy is in the process of being bound or finalized */
  Binding = 'BINDING',
  /** The policy has been terminated or canceled before its expiration date */
  Canceled = 'CANCELED',
  /** The policy has reached its expiration date and is no longer active */
  Expired = 'EXPIRED',
  /** The policy is currently active and in force */
  Inforce = 'INFORCE',
  /** The policy is in a pending state, awaiting further action or information */
  Pending = 'PENDING',
  /** The policy has been submitted for review or approval */
  Submitted = 'SUBMITTED',
  /** The state of the policy is unknown or not determined */
  Unknown = 'UNKNOWN'
}

/** The type of a single insured item on a policy */
export enum ProductType {
  BeautyAndSupplements = 'BEAUTY_AND_SUPPLEMENTS',
  /** A bike or eBike */
  Bike = 'BIKE',
  /** A collectible */
  Collectibles = 'COLLECTIBLES',
  /** An electronics item, such as a phone, laptop, or tablet */
  Electronics = 'ELECTRONICS',
  /** Fashion pieces */
  Fashion = 'FASHION',
  FoodAndBeverage = 'FOOD_AND_BEVERAGE',
  /** Furniture items */
  Furniture = 'FURNITURE',
  /** A jewelry item */
  Jewelry = 'JEWELRY',
  /** A motorcycle, moped, scooter, or other higher-powered motor vehicle */
  Motorcycle = 'MOTORCYCLE',
  /** An ATV, UTV, or other offroad vehicle */
  OffroadVehicle = 'OFFROAD_VEHICLE',
  /** Other producs */
  Other = 'OTHER',
  OutdoorSports = 'OUTDOOR_SPORTS',
  TobaccoCbdSmokeShop = 'TOBACCO_CBD_SMOKE_SHOP',
  /** An unknown product type. This value should be impossible */
  Unknown = 'UNKNOWN'
}

export type Query = {
  __typename?: 'Query';
  /** Retrieve a business application by ID */
  businessApplication: BusinessApplication;
  /** Retrieve all business applications */
  businessApplications: Array<BusinessApplication>;
  /** Fetch all business categories */
  businessCategories: Array<BusinessCategory>;
  businessPolicies: Array<BusinessPolicy>;
  businessPolicy?: Maybe<BusinessPolicy>;
  carriers: Array<InsuranceCarrier>;
  certificate?: Maybe<File>;
  /**
   * Returns the available limits for a particular application and state.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *   state: The state for which to retrieve the available limits.
   *
   * Returns:
   *   A list of AvailableCoverageLimit objects representing the available coverage limits.
   */
  coverageLimits: Array<AvailableCoverageLimit>;
  /** Fetch a single merchant by ID */
  merchant?: Maybe<Merchant>;
  /** Fetch all merchants */
  merchants: Array<Merchant>;
  /** Get the policy AI conversation for a policy ID */
  policyConversation: Array<PolicyConversationMessage>;
  /** Get the risk management data for a merchant */
  riskManagement?: Maybe<RiskManagementData>;
};

export type QueryBusinessApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCertificateArgs = {
  certificateNumber: Scalars['String']['input'];
};

export type QueryCoverageLimitsArgs = {
  applicationId: Scalars['ID']['input'];
  state: Scalars['String']['input'];
};

export type QueryMerchantArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryPolicyConversationArgs = {
  policyId: Scalars['ID']['input'];
};

export type ReinstateBusinessPolicyInput = {
  effectiveAt: Scalars['Time']['input'];
  reasonDescription: Scalars['String']['input'];
};

export type RenewBusinessPolicyInput = {
  agencyBillingType?: InputMaybe<BusinessPolicyAgencyBillingType>;
  /** Additional fees collected by Oyster as revenue. */
  agencyFees: Scalars['Float']['input'];
  ascendProgramCreationMode?: InputMaybe<AscendProgramCreationMode>;
  ascendProgramId?: InputMaybe<Scalars['String']['input']>;
  billingType: BusinessPolicyBillingType;
  /** Fees collected by the wholesale broker */
  brokerFees: Scalars['Float']['input'];
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion: Scalars['Float']['input'];
  /** The estimated commission Oyster earns on this policy */
  estimatedCommission: Scalars['Float']['input'];
  expiresAt?: InputMaybe<Scalars['Time']['input']>;
  inforceAt?: InputMaybe<Scalars['Time']['input']>;
  issuedAt?: InputMaybe<Scalars['Time']['input']>;
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees: Scalars['Float']['input'];
  /** Fees collected by the carrier to administer the policy or program */
  policyFees: Scalars['Float']['input'];
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  /** The actual premium of the policy */
  premium: Scalars['Float']['input'];
  quoteNumber: Scalars['String']['input'];
  state: PolicyState;
  /** Taxes levied on the policy premium and any taxable fees */
  taxes: Scalars['Float']['input'];
  /** Total amount to charge for the policy, including premium and all taxes and fees */
  total: Scalars['Float']['input'];
};

/** Represents the risk management data for a merchant */
export type RiskManagementData = {
  __typename?: 'RiskManagementData';
  /** List of action items related to the merchant's risk management */
  actionItems: Array<ComplianceAction>;
  /** List of compliance groups associated with the merchant */
  compliance: Array<ComplianceGroup>;
};

export type RiskManagementSetupInput = {
  /** Description of any upcoming changes to the business */
  changeDescription: Scalars['String']['input'];
  /** List of compliance frameworks that the merchant is subject to */
  complianceFrameworks: Scalars['String']['input'];
  /** Description of the business */
  description: Scalars['String']['input'];
};

export enum Role {
  Admin = 'ADMIN',
  Anonymous = 'ANONYMOUS',
  Bpo = 'BPO',
  User = 'USER'
}

/** The Stripe invoice information used to bill a policy */
export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  id: Scalars['String']['output'];
};

/** Represents an answer to an underwriting question. */
export type UnderwritingAnswer = {
  __typename?: 'UnderwritingAnswer';
  /** The answer to the question. */
  answer: Scalars['String']['output'];
  /** The ID of the location associated with the answer. */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** The ID of the question associated with the answer. */
  questionId: Scalars['ID']['output'];
};

export type UnderwritingAnswerInput = {
  answer: Scalars['String']['input'];
  locationId?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['String']['input'];
};

/** Represents an answer option for an underwriting question. */
export type UnderwritingAnswerOption = {
  __typename?: 'UnderwritingAnswerOption';
  /** The display text of the answer option. */
  displayText: Scalars['String']['output'];
  /** The value of the answer option. */
  value: Scalars['String']['output'];
};

/** Represents an underwriting question. */
export type UnderwritingQuestion = {
  __typename?: 'UnderwritingQuestion';
  /** The possible answer options for the question. */
  answerOptions?: Maybe<Array<UnderwritingAnswerOption>>;
  /** Carriers that require an answer to this question" */
  carriers: Array<BusinessApplicationCarrier>;
  /** The default answer to the question, if any. */
  defaultAnswer?: Maybe<Scalars['String']['output']>;
  /** Question IDs that are dependent on this question. */
  dependentQuestions: Array<UnderwritingQuestionDependency>;
  /** Indicates whether the question has a parent question. */
  hasParentQuestion: Scalars['Boolean']['output'];
  /** The unique identifier of the underwriting question. */
  id: Scalars['ID']['output'];
  /** The ID of the location associated with the question. */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** The text of the underwriting question. */
  question: Scalars['String']['output'];
  /** The tooltip providing additional information about the question. */
  tooltip: Scalars['String']['output'];
  /** The type of the underwriting question. */
  type: UnderwritingQuestionType;
};

/** Represents a dependency between underwriting questions. */
export type UnderwritingQuestionDependency = {
  __typename?: 'UnderwritingQuestionDependency';
  /** The condition that must be met for the dependent questions to be shown. */
  condition?: Maybe<Scalars['String']['output']>;
  /** The IDs of the questions that are dependent on the parent question. */
  dependentQuestionIds: Array<Scalars['ID']['output']>;
  /** The type of the dependency. */
  type: UnderwritingQuestionDependencyType;
};

export enum UnderwritingQuestionDependencyType {
  /** Indicates that a question should be shown if the condition matches */
  Conditional = 'CONDITIONAL',
  /** Indicates that a question should always be shown */
  Direct = 'DIRECT'
}

/** Represents the type of an underwriting question. */
export enum UnderwritingQuestionType {
  /** A date question. */
  Date = 'DATE',
  /** An email question. */
  Email = 'EMAIL',
  /** A multi-select dropdown question. */
  Multiselectdropdown = 'MULTISELECTDROPDOWN',
  /** A number question. */
  Number = 'NUMBER',
  /** A phone number question. */
  Phonenumber = 'PHONENUMBER',
  /** A radio button question. */
  Radio = 'RADIO',
  /** A single-select dropdown question. */
  Singleselectdropdown = 'SINGLESELECTDROPDOWN',
  /** A text question. */
  Text = 'TEXT',
  /** A year question. */
  Year = 'YEAR'
}

/** Represents an underwriting statement. */
export type UnderwritingStatement = {
  __typename?: 'UnderwritingStatement';
  /** The carrier that the underwriting statement is associated with. */
  carrier: BusinessApplicationCarrier;
  /** Formatted markdown string with the underwriting statement. */
  markdown: Scalars['String']['output'];
};

/** Represents the type of an underwriting statement. */
export enum UnderwritingStatementType {
  /** A payment statement. */
  Payment = 'PAYMENT',
  /** An underwriting statement. */
  Underwriting = 'UNDERWRITING'
}

export type UpdateApplicationInput = {
  /** The annual revenue of the business. */
  annualRevenue?: InputMaybe<Scalars['Float']['input']>;
  /** The "Doing Business As" name of the business. */
  businessDba?: InputMaybe<Scalars['String']['input']>;
  /** The legal name of the business. */
  businessName?: InputMaybe<Scalars['String']['input']>;
  /** The information for the the contact person on the business application. */
  contact?: InputMaybe<BusinessContactInput>;
  /** Information about the employees at the business location. */
  employeeInfo?: InputMaybe<BusinessEmployeeInfoInput>;
  /** The Federal Employer Identification Number (FEIN) of the business. */
  fein?: InputMaybe<Scalars['String']['input']>;
  /** The desired effective date for the insurance policy. */
  insuranceEffectiveAt?: InputMaybe<Scalars['Time']['input']>;
  /** The insurance types the business is interested in. */
  insuranceTypes?: InputMaybe<Array<InsuranceType>>;
  /** The legal entity type of the business. */
  legalEntityType?: InputMaybe<BusinessLegalEntityType>;
  /** The mailing address of the business. */
  mailingAddress?: InputMaybe<AddressInput>;
  /** The NAICS classification code for the business. */
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  /** The owners of the business. */
  owners?: InputMaybe<Array<BusinessOwnerInput>>;
  /** The past policy losses of the business. */
  pastPolicyLosses?: InputMaybe<Array<PastPolicyLossInput>>;
  /** The carriers that this business wants quotes from */
  selectedCarriers?: InputMaybe<Array<BusinessApplicationCarrier>>;
  /** The selected insurance limits for the business. */
  selectedLimits?: InputMaybe<InsuranceLimitsInput>;
  /** The underwriting answers for the business. */
  underwritingAnswers?: InputMaybe<Array<UnderwritingAnswerInput>>;
  /** The year the business was founded. */
  yearOfFounding?: InputMaybe<Scalars['String']['input']>;
  /** The years of management experience of the business. */
  yearsOfManagementExperience?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateBusinessPolicyInput = {
  /** Additional fees collected by Oyster as revenue. */
  agencyFees?: InputMaybe<Scalars['Float']['input']>;
  ascendBillableId?: InputMaybe<Scalars['String']['input']>;
  ascendProgramId?: InputMaybe<Scalars['String']['input']>;
  /** Fees collected by the wholesale broker */
  brokerFees?: InputMaybe<Scalars['Float']['input']>;
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion?: InputMaybe<Scalars['Float']['input']>;
  brokerId?: InputMaybe<Scalars['String']['input']>;
  carrierId?: InputMaybe<Scalars['String']['input']>;
  estimatedCommission?: InputMaybe<Scalars['Float']['input']>;
  expiresAt?: InputMaybe<Scalars['Time']['input']>;
  inforceAt?: InputMaybe<Scalars['Time']['input']>;
  issuedAt?: InputMaybe<Scalars['Time']['input']>;
  market?: InputMaybe<InsuranceMarket>;
  minimumEarnedPremium?: InputMaybe<Scalars['Float']['input']>;
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees?: InputMaybe<Scalars['Float']['input']>;
  paymentIntervalType?: InputMaybe<PaymentIntervalType>;
  paymentOccurrencesPerInterval?: InputMaybe<Scalars['Int']['input']>;
  /** Fees collected by the carrier to administer the policy or program */
  policyFees?: InputMaybe<Scalars['Float']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  /** The actual premium of the policy */
  premium?: InputMaybe<Scalars['Float']['input']>;
  quoteNumber?: InputMaybe<Scalars['String']['input']>;
  sourceAgentId?: InputMaybe<Scalars['String']['input']>;
  sourceLeadId?: InputMaybe<Scalars['String']['input']>;
  sourceNotes?: InputMaybe<Scalars['String']['input']>;
  sourceProcessType?: InputMaybe<BusinessPolicySourceProcessType>;
  sourceSubType?: InputMaybe<BusinessPolicySourceSubType>;
  sourceType?: InputMaybe<BusinessPolicySourceType>;
  state?: InputMaybe<PolicyState>;
  /** Taxes levied on the policy premium and any taxable fees */
  taxes?: InputMaybe<Scalars['Float']['input']>;
  /** Total amount to charge for the policy, including premium and all taxes and fees */
  total?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<InsuranceType>;
  updateReason: Scalars['String']['input'];
};

/** Describes the type of entity that initiated an update */
export enum UpdateInitiatorType {
  /** Anonymous indicates an unauthenticated external user */
  Anonymous = 'ANONYMOUS',
  /** BPO indicates a Business Process Outsourcing entity */
  Bpo = 'BPO',
  /** Cronjob indicates a scheduled automated job */
  Cronjob = 'CRONJOB',
  /** Oyster indicates an internal employee at Oyster */
  Oyster = 'OYSTER',
  /** User indicates an authenticated external user */
  User = 'USER',
  /** Workflow indicates a Temporal workflow */
  Workflow = 'WORKFLOW'
}

/** Input type for updating an existing merchant */
export type UpdateMerchantInput = {
  /** New business category ID for the merchant */
  businessCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** New 'Doing Business As' name of the business */
  businessDba?: InputMaybe<Scalars['String']['input']>;
  /** New legal name of the business */
  businessName?: InputMaybe<Scalars['String']['input']>;
};

/** Enumeration of validation codes used to categorize validation errors. */
export enum ValidationCode {
  /** Indicates that a required field is missing */
  Required = 'REQUIRED',
  /** Indicates that a field failed validation checks */
  ValidationFailed = 'VALIDATION_FAILED'
}

/** Describes a validation error for a specific field or set of fields. */
export type ValidationError = {
  __typename?: 'ValidationError';
  /** The type of validation error that occurred */
  code: ValidationCode;
  /** An array of field names associated with the validation error. May contain multiple fields for related errors. */
  field: Array<Scalars['String']['output']>;
  /** A human-readable message describing the validation error */
  message: Scalars['String']['output'];
};

export type CertificateDetailsFieldsFragment = {
  __typename?: 'CertificateDetails';
  certificateNumber: string;
  descriptionOfOperations: string;
  insured: { __typename?: 'CertificateEntity'; name: string; address: string };
  producer: { __typename?: 'CertificateEntity'; name: string; address: string };
  certificateHolder: { __typename?: 'CertificateEntity'; name: string; address: string };
  coverages: Array<{
    __typename?: 'CertificateCoverage';
    type: InsuranceType;
    effectiveDate: Date;
    expirationDate: Date;
    policyNumber: string;
    holderIsAdditionalInsured: boolean;
    hasWaiverOfSubrogation: boolean;
    limits: Array<{ __typename?: 'CertificateLimit'; name: string; limit: number }>;
    insurer: { __typename?: 'CoverageInsurer'; name: string; naic: string };
  }>;
};

export type GetCertificateQueryVariables = Exact<{
  certificateNumber: Scalars['String']['input'];
}>;

export type GetCertificateQuery = {
  __typename?: 'Query';
  certificate?:
    | {
        __typename?: 'File';
        url?: string | null | undefined;
        details?:
          | {
              __typename?: 'CertificateDetails';
              certificateNumber: string;
              descriptionOfOperations: string;
              insured: { __typename?: 'CertificateEntity'; name: string; address: string };
              producer: { __typename?: 'CertificateEntity'; name: string; address: string };
              certificateHolder: {
                __typename?: 'CertificateEntity';
                name: string;
                address: string;
              };
              coverages: Array<{
                __typename?: 'CertificateCoverage';
                type: InsuranceType;
                effectiveDate: Date;
                expirationDate: Date;
                policyNumber: string;
                holderIsAdditionalInsured: boolean;
                hasWaiverOfSubrogation: boolean;
                limits: Array<{ __typename?: 'CertificateLimit'; name: string; limit: number }>;
                insurer: { __typename?: 'CoverageInsurer'; name: string; naic: string };
              }>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GenerateCertificateMutationVariables = Exact<{
  req: GenerateCertificateInput;
}>;

export type GenerateCertificateMutation = {
  __typename?: 'Mutation';
  generateCertificate?:
    | {
        __typename?: 'File';
        details?:
          | { __typename?: 'CertificateDetails'; certificateNumber: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCoverageLimitsQueryVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  state: Scalars['String']['input'];
}>;

export type GetCoverageLimitsQuery = {
  __typename?: 'Query';
  coverageLimits: Array<{
    __typename?: 'AvailableCoverageLimit';
    insuranceType: InsuranceType;
    limits: {
      __typename?: 'InsuranceLimits';
      bopAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      coverageState: string;
      cyberAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
    };
  }>;
};

export type ApplicationFieldsFragment = {
  __typename?: 'BusinessApplication';
  annualRevenue: number;
  businessDba: string;
  businessName: string;
  fein: string;
  id: string;
  insuranceEffectiveAt?: Date | null | undefined;
  insuranceTypes: Array<InsuranceType>;
  legalEntityType?: BusinessLegalEntityType | null | undefined;
  naicsCode: string;
  selectedCarriers: Array<BusinessApplicationCarrier>;
  state: BusinessApplicationState;
  yearOfFounding: string;
  yearsOfManagementExperience: number;
  contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
  employeeInfo: {
    __typename?: 'BusinessEmployeeInfo';
    numFullTimeEmployees: number;
    numPartTimeEmployees: number;
    totalEmployeePayroll: number;
  };
  locations: Array<{
    __typename?: 'BusinessLocation';
    id: string;
    isPrimary: boolean;
    address: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    buildingInfo: {
      __typename?: 'BusinessLocationBuildingInfo';
      annualSales: number;
      areaOccupiedByBusiness: number;
      buildingCoverage: number;
      burglarAlarmType?: BurglarAlarmType | null | undefined;
      constructionType?: BuildingConstructionType | null | undefined;
      ownershipType?: BuildingOwnershipType | null | undefined;
      personalPropertyCoverage: number;
      sprinkleredPercentage: number;
      totalArea: number;
      totalStories: number;
      yearBuilt: string;
    };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numPartTimeEmployees: number;
      numFullTimeEmployees: number;
      totalEmployeePayroll: number;
      jobCode: string;
    };
  }>;
  mailingAddress: {
    __typename?: 'Address';
    line1: string;
    line2?: string | null | undefined;
    city: string;
    zone: string;
    postalCode: string;
  };
  owners: Array<{
    __typename?: 'BusinessOwner';
    fullName: string;
    annualPayroll: number;
    dateOfBirth?: Date | null | undefined;
    jobCode?: string | null | undefined;
  }>;
  pastPolicyLosses: Array<{
    __typename?: 'PastPolicyLoss';
    claimDate?: Date | null | undefined;
    claimStatus?: PastPolicyLossClaimStatus | null | undefined;
    lossDate?: Date | null | undefined;
    lossDescription: string;
    lossState: string;
    lossType?: PastPolicyLossType | null | undefined;
    policyEffectiveDate?: Date | null | undefined;
    policyExpirationDate?: Date | null | undefined;
    policyType?: InsuranceType | null | undefined;
    totalPaidAmount: number;
    totalReservedAmount: number;
  }>;
  selectedLimits: {
    __typename?: 'InsuranceLimits';
    coverageState: string;
    wcPerAccidentLimit?: number | null | undefined;
    wcPerDiseaseEmployeeLimit?: number | null | undefined;
    wcPerDiseasePolicyLimit?: number | null | undefined;
    glPerOccurrenceLimit?: number | null | undefined;
    glAggregateLimit?: number | null | undefined;
    bopPerOccurrenceLimit?: number | null | undefined;
    bopAggregateLimit?: number | null | undefined;
    cyberRetentionLimit?: number | null | undefined;
    cyberAggregateLimit?: number | null | undefined;
  };
  underwritingAnswers: Array<{
    __typename?: 'UnderwritingAnswer';
    answer: string;
    locationId?: string | null | undefined;
    questionId: string;
  }>;
  underwritingQuestions: Array<{
    __typename?: 'UnderwritingQuestion';
    id: string;
    carriers: Array<BusinessApplicationCarrier>;
    defaultAnswer?: string | null | undefined;
    hasParentQuestion: boolean;
    locationId?: string | null | undefined;
    question: string;
    tooltip: string;
    type: UnderwritingQuestionType;
    answerOptions?:
      | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
      | null
      | undefined;
    dependentQuestions: Array<{
      __typename?: 'UnderwritingQuestionDependency';
      condition?: string | null | undefined;
      dependentQuestionIds: Array<string>;
      type: UnderwritingQuestionDependencyType;
    }>;
  }>;
  underwritingStatements: Array<{
    __typename?: 'UnderwritingStatement';
    carrier: BusinessApplicationCarrier;
    markdown: string;
  }>;
};

export type ApplicationQuoteFieldsFragment = {
  __typename?: 'BusinessApplication';
  quotes: Array<{
    __typename?: 'BusinessApplicationQuote';
    carrier: BusinessApplicationCarrier;
    id: string;
    policyType: InsuranceType;
    premiumAmount: number;
    quoteProposalUrl?: string | null | undefined;
    state: BusinessApplicationQuoteState;
    totalAmount: number;
    type: BusinessApplicationQuoteType;
    paymentPlans: Array<{
      __typename?: 'BusinessApplicationQuotePaymentPlan';
      description: string;
      downAmount: number;
      downDueAt?: Date | null | undefined;
      installmentAmount: number;
      installmentCount: number;
      installmentDueAt?: Date | null | undefined;
      installmentFee: number;
      installmentFrequency: PaymentPlanInstallmentFrequency;
      paymentMethod: PaymentPlanPaymentMethodType;
      paymentPlanId: string;
      title: string;
      totalAmount: number;
      totalFees: number;
    }>;
  }>;
};

export type ApplicationQuotableCarrierFieldsFragment = {
  __typename?: 'BusinessApplication';
  quotableCarriers: Array<{
    __typename?: 'InsuranceCarrier';
    ascendId?: string | null | undefined;
    id: string;
    isBroker: boolean;
    isCarrier: boolean;
    name: string;
    supportedPolicies?: Array<InsuranceType> | null | undefined;
  }>;
};

export type GetBusinessApplicationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  includeQuotes?: InputMaybe<Scalars['Boolean']['input']>;
  includeQuotableCarriers?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetBusinessApplicationQuery = {
  __typename?: 'Query';
  businessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
    quotes: Array<{
      __typename?: 'BusinessApplicationQuote';
      carrier: BusinessApplicationCarrier;
      id: string;
      policyType: InsuranceType;
      premiumAmount: number;
      quoteProposalUrl?: string | null | undefined;
      state: BusinessApplicationQuoteState;
      totalAmount: number;
      type: BusinessApplicationQuoteType;
      paymentPlans: Array<{
        __typename?: 'BusinessApplicationQuotePaymentPlan';
        description: string;
        downAmount: number;
        downDueAt?: Date | null | undefined;
        installmentAmount: number;
        installmentCount: number;
        installmentDueAt?: Date | null | undefined;
        installmentFee: number;
        installmentFrequency: PaymentPlanInstallmentFrequency;
        paymentMethod: PaymentPlanPaymentMethodType;
        paymentPlanId: string;
        title: string;
        totalAmount: number;
        totalFees: number;
      }>;
    }>;
    quotableCarriers: Array<{
      __typename?: 'InsuranceCarrier';
      ascendId?: string | null | undefined;
      id: string;
      isBroker: boolean;
      isCarrier: boolean;
      name: string;
      supportedPolicies?: Array<InsuranceType> | null | undefined;
    }>;
  };
};

export type CreateBusinessApplicationMutationVariables = Exact<{
  application: CreateApplicationInput;
}>;

export type CreateBusinessApplicationMutation = {
  __typename?: 'Mutation';
  createBusinessApplication: { __typename?: 'BusinessApplication'; id: string };
};

export type UpdateBusinessApplicationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  application: UpdateApplicationInput;
  includeQuotes?: InputMaybe<Scalars['Boolean']['input']>;
  includeQuotableCarriers?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateBusinessApplicationMutation = {
  __typename?: 'Mutation';
  updateBusinessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
    quotes: Array<{
      __typename?: 'BusinessApplicationQuote';
      carrier: BusinessApplicationCarrier;
      id: string;
      policyType: InsuranceType;
      premiumAmount: number;
      quoteProposalUrl?: string | null | undefined;
      state: BusinessApplicationQuoteState;
      totalAmount: number;
      type: BusinessApplicationQuoteType;
      paymentPlans: Array<{
        __typename?: 'BusinessApplicationQuotePaymentPlan';
        description: string;
        downAmount: number;
        downDueAt?: Date | null | undefined;
        installmentAmount: number;
        installmentCount: number;
        installmentDueAt?: Date | null | undefined;
        installmentFee: number;
        installmentFrequency: PaymentPlanInstallmentFrequency;
        paymentMethod: PaymentPlanPaymentMethodType;
        paymentPlanId: string;
        title: string;
        totalAmount: number;
        totalFees: number;
      }>;
    }>;
    quotableCarriers: Array<{
      __typename?: 'InsuranceCarrier';
      ascendId?: string | null | undefined;
      id: string;
      isBroker: boolean;
      isCarrier: boolean;
      name: string;
      supportedPolicies?: Array<InsuranceType> | null | undefined;
    }>;
  };
};

export type QuoteBusinessApplicationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type QuoteBusinessApplicationMutation = {
  __typename?: 'Mutation';
  quoteBusinessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
    quotes: Array<{
      __typename?: 'BusinessApplicationQuote';
      carrier: BusinessApplicationCarrier;
      id: string;
      policyType: InsuranceType;
      premiumAmount: number;
      quoteProposalUrl?: string | null | undefined;
      state: BusinessApplicationQuoteState;
      totalAmount: number;
      type: BusinessApplicationQuoteType;
      paymentPlans: Array<{
        __typename?: 'BusinessApplicationQuotePaymentPlan';
        description: string;
        downAmount: number;
        downDueAt?: Date | null | undefined;
        installmentAmount: number;
        installmentCount: number;
        installmentDueAt?: Date | null | undefined;
        installmentFee: number;
        installmentFrequency: PaymentPlanInstallmentFrequency;
        paymentMethod: PaymentPlanPaymentMethodType;
        paymentPlanId: string;
        title: string;
        totalAmount: number;
        totalFees: number;
      }>;
    }>;
    quotableCarriers: Array<{
      __typename?: 'InsuranceCarrier';
      ascendId?: string | null | undefined;
      id: string;
      isBroker: boolean;
      isCarrier: boolean;
      name: string;
      supportedPolicies?: Array<InsuranceType> | null | undefined;
    }>;
  };
};

export type SubmitBusinessApplicationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubmitBusinessApplicationMutation = {
  __typename?: 'Mutation';
  submitBusinessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
    quotes: Array<{
      __typename?: 'BusinessApplicationQuote';
      carrier: BusinessApplicationCarrier;
      id: string;
      policyType: InsuranceType;
      premiumAmount: number;
      quoteProposalUrl?: string | null | undefined;
      state: BusinessApplicationQuoteState;
      totalAmount: number;
      type: BusinessApplicationQuoteType;
      paymentPlans: Array<{
        __typename?: 'BusinessApplicationQuotePaymentPlan';
        description: string;
        downAmount: number;
        downDueAt?: Date | null | undefined;
        installmentAmount: number;
        installmentCount: number;
        installmentDueAt?: Date | null | undefined;
        installmentFee: number;
        installmentFrequency: PaymentPlanInstallmentFrequency;
        paymentMethod: PaymentPlanPaymentMethodType;
        paymentPlanId: string;
        title: string;
        totalAmount: number;
        totalFees: number;
      }>;
    }>;
    quotableCarriers: Array<{
      __typename?: 'InsuranceCarrier';
      ascendId?: string | null | undefined;
      id: string;
      isBroker: boolean;
      isCarrier: boolean;
      name: string;
      supportedPolicies?: Array<InsuranceType> | null | undefined;
    }>;
  };
};

export type AddBusinessApplicationLocationMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  location: BusinessLocationInput;
}>;

export type AddBusinessApplicationLocationMutation = {
  __typename?: 'Mutation';
  businessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
  };
};

export type UpdateBusinessApplicationLocationMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  location: BusinessLocationInput;
}>;

export type UpdateBusinessApplicationLocationMutation = {
  __typename?: 'Mutation';
  businessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
  };
};

export type RemoveBusinessApplicationLocationMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
}>;

export type RemoveBusinessApplicationLocationMutation = {
  __typename?: 'Mutation';
  businessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
  };
};

export type BindQuoteMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  quoteId: Scalars['ID']['input'];
  paymentPlanId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentPlanPaymentMethodType>;
}>;

export type BindQuoteMutation = {
  __typename?: 'Mutation';
  bindQuote: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
    quotes: Array<{
      __typename?: 'BusinessApplicationQuote';
      carrier: BusinessApplicationCarrier;
      id: string;
      policyType: InsuranceType;
      premiumAmount: number;
      quoteProposalUrl?: string | null | undefined;
      state: BusinessApplicationQuoteState;
      totalAmount: number;
      type: BusinessApplicationQuoteType;
      paymentPlans: Array<{
        __typename?: 'BusinessApplicationQuotePaymentPlan';
        description: string;
        downAmount: number;
        downDueAt?: Date | null | undefined;
        installmentAmount: number;
        installmentCount: number;
        installmentDueAt?: Date | null | undefined;
        installmentFee: number;
        installmentFrequency: PaymentPlanInstallmentFrequency;
        paymentMethod: PaymentPlanPaymentMethodType;
        paymentPlanId: string;
        title: string;
        totalAmount: number;
        totalFees: number;
      }>;
    }>;
    quotableCarriers: Array<{
      __typename?: 'InsuranceCarrier';
      ascendId?: string | null | undefined;
      id: string;
      isBroker: boolean;
      isCarrier: boolean;
      name: string;
      supportedPolicies?: Array<InsuranceType> | null | undefined;
    }>;
  };
};

export type BusinessPolicyDetailFieldsFragment = {
  __typename?: 'BusinessPolicy';
  details?:
    | {
        __typename?: 'BusinessPolicyExtractedDetails';
        deductible?: number | null | undefined;
        policyNumber?: string | null | undefined;
        summary?: string | null | undefined;
        blanketAdditionalInsureds: Array<{
          __typename?: 'BusinessPolicyBlanketAdditionalInsured';
          type: string;
          description: string;
        }>;
        carrier?:
          | {
              __typename?: 'BusinessPolicyEntity';
              name: string;
              address: {
                __typename?: 'Address';
                line1: string;
                line2?: string | null | undefined;
                city: string;
                zone: string;
                postalCode: string;
              };
            }
          | null
          | undefined;
        coverageDescriptions: Array<{
          __typename?: 'BusinessPolicyCoverageOrExclusionDescription';
          description: string;
          policyQuotes: Array<string>;
          title: string;
        }>;
        exclusionDescriptions: Array<{
          __typename?: 'BusinessPolicyCoverageOrExclusionDescription';
          description: string;
          policyQuotes: Array<string>;
          title: string;
        }>;
        extraLimits: Array<{
          __typename?: 'BusinessPolicyExtraInsuranceLimit';
          limit: number;
          name: string;
        }>;
        insured?:
          | {
              __typename?: 'BusinessPolicyEntity';
              name: string;
              address: {
                __typename?: 'Address';
                line1: string;
                line2?: string | null | undefined;
                city: string;
                zone: string;
                postalCode: string;
              };
            }
          | null
          | undefined;
        limits: {
          __typename?: 'BusinessPolicyInsuranceLimits';
          cyberAggregateLimit?: number | null | undefined;
          cyberRetentionLimit?: number | null | undefined;
          glAggregateLimit?: number | null | undefined;
          glMedicalPerOccurrenceLimit?: number | null | undefined;
          glMedicalPerPersonLimit?: number | null | undefined;
          glPerOccurrenceLimit?: number | null | undefined;
          glPersonalAndAdvertisingInjuryLimit?: number | null | undefined;
          glProductsAndCompletedOperationsLimit?: number | null | undefined;
          glRentedPremisesDamageLimit?: number | null | undefined;
          propertyBuildingLimit?: number | null | undefined;
          propertyContentsLimit?: number | null | undefined;
          umbrellaOrExcessAggregateLimit?: number | null | undefined;
          umbrellaOrExcessPerOccurrenceLimit?: number | null | undefined;
          wcPerAccidentLimit?: number | null | undefined;
          wcPerDiseaseEmployeeLimit?: number | null | undefined;
          wcPerDiseasePolicyLimit?: number | null | undefined;
        };
        locations: Array<{
          __typename?: 'BusinessPolicyLocation';
          address: {
            __typename?: 'Address';
            line1: string;
            line2?: string | null | undefined;
            city: string;
            zone: string;
            postalCode: string;
          };
          limits: {
            __typename?: 'BusinessPolicyInsuranceLimits';
            cyberAggregateLimit?: number | null | undefined;
            cyberRetentionLimit?: number | null | undefined;
            glAggregateLimit?: number | null | undefined;
            glMedicalPerOccurrenceLimit?: number | null | undefined;
            glMedicalPerPersonLimit?: number | null | undefined;
            glPerOccurrenceLimit?: number | null | undefined;
            glPersonalAndAdvertisingInjuryLimit?: number | null | undefined;
            glProductsAndCompletedOperationsLimit?: number | null | undefined;
            glRentedPremisesDamageLimit?: number | null | undefined;
            propertyBuildingLimit?: number | null | undefined;
            propertyContentsLimit?: number | null | undefined;
            umbrellaOrExcessAggregateLimit?: number | null | undefined;
            umbrellaOrExcessPerOccurrenceLimit?: number | null | undefined;
            wcPerAccidentLimit?: number | null | undefined;
            wcPerDiseaseEmployeeLimit?: number | null | undefined;
            wcPerDiseasePolicyLimit?: number | null | undefined;
          };
        }>;
        namedAdditionalInsureds: Array<{
          __typename?: 'BusinessPolicyNamedAdditionalInsured';
          name: string;
          address: {
            __typename?: 'Address';
            line1: string;
            line2?: string | null | undefined;
            city: string;
            zone: string;
            postalCode: string;
          };
        }>;
      }
    | null
    | undefined;
};

export type BusinessPolicyFieldsFragment = {
  __typename?: 'BusinessPolicy';
  id: string;
  type: InsuranceType;
  policyNumber: string;
  state: PolicyState;
  inforceAt?: Date | null | undefined;
  expiresAt?: Date | null | undefined;
  files: Array<{
    __typename?: 'File';
    id: string;
    name: string;
    role: FileRole;
    url?: string | null | undefined;
  }>;
  carrier: { __typename?: 'InsuranceCarrier'; name: string };
  pricing: {
    __typename?: 'BusinessPolicyPricing';
    billingType: BusinessPolicyBillingType;
    intervalType?: PaymentIntervalType | null | undefined;
    premium: number;
    total: number;
  };
  details?:
    | {
        __typename?: 'BusinessPolicyExtractedDetails';
        deductible?: number | null | undefined;
        policyNumber?: string | null | undefined;
        summary?: string | null | undefined;
        blanketAdditionalInsureds: Array<{
          __typename?: 'BusinessPolicyBlanketAdditionalInsured';
          type: string;
          description: string;
        }>;
        carrier?:
          | {
              __typename?: 'BusinessPolicyEntity';
              name: string;
              address: {
                __typename?: 'Address';
                line1: string;
                line2?: string | null | undefined;
                city: string;
                zone: string;
                postalCode: string;
              };
            }
          | null
          | undefined;
        coverageDescriptions: Array<{
          __typename?: 'BusinessPolicyCoverageOrExclusionDescription';
          description: string;
          policyQuotes: Array<string>;
          title: string;
        }>;
        exclusionDescriptions: Array<{
          __typename?: 'BusinessPolicyCoverageOrExclusionDescription';
          description: string;
          policyQuotes: Array<string>;
          title: string;
        }>;
        extraLimits: Array<{
          __typename?: 'BusinessPolicyExtraInsuranceLimit';
          limit: number;
          name: string;
        }>;
        insured?:
          | {
              __typename?: 'BusinessPolicyEntity';
              name: string;
              address: {
                __typename?: 'Address';
                line1: string;
                line2?: string | null | undefined;
                city: string;
                zone: string;
                postalCode: string;
              };
            }
          | null
          | undefined;
        limits: {
          __typename?: 'BusinessPolicyInsuranceLimits';
          cyberAggregateLimit?: number | null | undefined;
          cyberRetentionLimit?: number | null | undefined;
          glAggregateLimit?: number | null | undefined;
          glMedicalPerOccurrenceLimit?: number | null | undefined;
          glMedicalPerPersonLimit?: number | null | undefined;
          glPerOccurrenceLimit?: number | null | undefined;
          glPersonalAndAdvertisingInjuryLimit?: number | null | undefined;
          glProductsAndCompletedOperationsLimit?: number | null | undefined;
          glRentedPremisesDamageLimit?: number | null | undefined;
          propertyBuildingLimit?: number | null | undefined;
          propertyContentsLimit?: number | null | undefined;
          umbrellaOrExcessAggregateLimit?: number | null | undefined;
          umbrellaOrExcessPerOccurrenceLimit?: number | null | undefined;
          wcPerAccidentLimit?: number | null | undefined;
          wcPerDiseaseEmployeeLimit?: number | null | undefined;
          wcPerDiseasePolicyLimit?: number | null | undefined;
        };
        locations: Array<{
          __typename?: 'BusinessPolicyLocation';
          address: {
            __typename?: 'Address';
            line1: string;
            line2?: string | null | undefined;
            city: string;
            zone: string;
            postalCode: string;
          };
          limits: {
            __typename?: 'BusinessPolicyInsuranceLimits';
            cyberAggregateLimit?: number | null | undefined;
            cyberRetentionLimit?: number | null | undefined;
            glAggregateLimit?: number | null | undefined;
            glMedicalPerOccurrenceLimit?: number | null | undefined;
            glMedicalPerPersonLimit?: number | null | undefined;
            glPerOccurrenceLimit?: number | null | undefined;
            glPersonalAndAdvertisingInjuryLimit?: number | null | undefined;
            glProductsAndCompletedOperationsLimit?: number | null | undefined;
            glRentedPremisesDamageLimit?: number | null | undefined;
            propertyBuildingLimit?: number | null | undefined;
            propertyContentsLimit?: number | null | undefined;
            umbrellaOrExcessAggregateLimit?: number | null | undefined;
            umbrellaOrExcessPerOccurrenceLimit?: number | null | undefined;
            wcPerAccidentLimit?: number | null | undefined;
            wcPerDiseaseEmployeeLimit?: number | null | undefined;
            wcPerDiseasePolicyLimit?: number | null | undefined;
          };
        }>;
        namedAdditionalInsureds: Array<{
          __typename?: 'BusinessPolicyNamedAdditionalInsured';
          name: string;
          address: {
            __typename?: 'Address';
            line1: string;
            line2?: string | null | undefined;
            city: string;
            zone: string;
            postalCode: string;
          };
        }>;
      }
    | null
    | undefined;
};

export type GetMerchantBusinessPoliciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMerchantBusinessPoliciesQuery = {
  __typename?: 'Query';
  businessPolicies: Array<{
    __typename?: 'BusinessPolicy';
    id: string;
    type: InsuranceType;
    policyNumber: string;
    state: PolicyState;
    inforceAt?: Date | null | undefined;
    expiresAt?: Date | null | undefined;
    files: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      role: FileRole;
      url?: string | null | undefined;
    }>;
    carrier: { __typename?: 'InsuranceCarrier'; name: string };
    pricing: {
      __typename?: 'BusinessPolicyPricing';
      billingType: BusinessPolicyBillingType;
      intervalType?: PaymentIntervalType | null | undefined;
      premium: number;
      total: number;
    };
    details?:
      | {
          __typename?: 'BusinessPolicyExtractedDetails';
          deductible?: number | null | undefined;
          policyNumber?: string | null | undefined;
          summary?: string | null | undefined;
          blanketAdditionalInsureds: Array<{
            __typename?: 'BusinessPolicyBlanketAdditionalInsured';
            type: string;
            description: string;
          }>;
          carrier?:
            | {
                __typename?: 'BusinessPolicyEntity';
                name: string;
                address: {
                  __typename?: 'Address';
                  line1: string;
                  line2?: string | null | undefined;
                  city: string;
                  zone: string;
                  postalCode: string;
                };
              }
            | null
            | undefined;
          coverageDescriptions: Array<{
            __typename?: 'BusinessPolicyCoverageOrExclusionDescription';
            description: string;
            policyQuotes: Array<string>;
            title: string;
          }>;
          exclusionDescriptions: Array<{
            __typename?: 'BusinessPolicyCoverageOrExclusionDescription';
            description: string;
            policyQuotes: Array<string>;
            title: string;
          }>;
          extraLimits: Array<{
            __typename?: 'BusinessPolicyExtraInsuranceLimit';
            limit: number;
            name: string;
          }>;
          insured?:
            | {
                __typename?: 'BusinessPolicyEntity';
                name: string;
                address: {
                  __typename?: 'Address';
                  line1: string;
                  line2?: string | null | undefined;
                  city: string;
                  zone: string;
                  postalCode: string;
                };
              }
            | null
            | undefined;
          limits: {
            __typename?: 'BusinessPolicyInsuranceLimits';
            cyberAggregateLimit?: number | null | undefined;
            cyberRetentionLimit?: number | null | undefined;
            glAggregateLimit?: number | null | undefined;
            glMedicalPerOccurrenceLimit?: number | null | undefined;
            glMedicalPerPersonLimit?: number | null | undefined;
            glPerOccurrenceLimit?: number | null | undefined;
            glPersonalAndAdvertisingInjuryLimit?: number | null | undefined;
            glProductsAndCompletedOperationsLimit?: number | null | undefined;
            glRentedPremisesDamageLimit?: number | null | undefined;
            propertyBuildingLimit?: number | null | undefined;
            propertyContentsLimit?: number | null | undefined;
            umbrellaOrExcessAggregateLimit?: number | null | undefined;
            umbrellaOrExcessPerOccurrenceLimit?: number | null | undefined;
            wcPerAccidentLimit?: number | null | undefined;
            wcPerDiseaseEmployeeLimit?: number | null | undefined;
            wcPerDiseasePolicyLimit?: number | null | undefined;
          };
          locations: Array<{
            __typename?: 'BusinessPolicyLocation';
            address: {
              __typename?: 'Address';
              line1: string;
              line2?: string | null | undefined;
              city: string;
              zone: string;
              postalCode: string;
            };
            limits: {
              __typename?: 'BusinessPolicyInsuranceLimits';
              cyberAggregateLimit?: number | null | undefined;
              cyberRetentionLimit?: number | null | undefined;
              glAggregateLimit?: number | null | undefined;
              glMedicalPerOccurrenceLimit?: number | null | undefined;
              glMedicalPerPersonLimit?: number | null | undefined;
              glPerOccurrenceLimit?: number | null | undefined;
              glPersonalAndAdvertisingInjuryLimit?: number | null | undefined;
              glProductsAndCompletedOperationsLimit?: number | null | undefined;
              glRentedPremisesDamageLimit?: number | null | undefined;
              propertyBuildingLimit?: number | null | undefined;
              propertyContentsLimit?: number | null | undefined;
              umbrellaOrExcessAggregateLimit?: number | null | undefined;
              umbrellaOrExcessPerOccurrenceLimit?: number | null | undefined;
              wcPerAccidentLimit?: number | null | undefined;
              wcPerDiseaseEmployeeLimit?: number | null | undefined;
              wcPerDiseasePolicyLimit?: number | null | undefined;
            };
          }>;
          namedAdditionalInsureds: Array<{
            __typename?: 'BusinessPolicyNamedAdditionalInsured';
            name: string;
            address: {
              __typename?: 'Address';
              line1: string;
              line2?: string | null | undefined;
              city: string;
              zone: string;
              postalCode: string;
            };
          }>;
        }
      | null
      | undefined;
  }>;
};

export type GetMerchantBusinessPolicyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetMerchantBusinessPolicyQuery = {
  __typename?: 'Query';
  businessPolicy?:
    | {
        __typename?: 'BusinessPolicy';
        id: string;
        type: InsuranceType;
        policyNumber: string;
        state: PolicyState;
        inforceAt?: Date | null | undefined;
        expiresAt?: Date | null | undefined;
        files: Array<{
          __typename?: 'File';
          id: string;
          name: string;
          role: FileRole;
          url?: string | null | undefined;
        }>;
        carrier: { __typename?: 'InsuranceCarrier'; name: string };
        pricing: {
          __typename?: 'BusinessPolicyPricing';
          billingType: BusinessPolicyBillingType;
          intervalType?: PaymentIntervalType | null | undefined;
          premium: number;
          total: number;
        };
        details?:
          | {
              __typename?: 'BusinessPolicyExtractedDetails';
              deductible?: number | null | undefined;
              policyNumber?: string | null | undefined;
              summary?: string | null | undefined;
              blanketAdditionalInsureds: Array<{
                __typename?: 'BusinessPolicyBlanketAdditionalInsured';
                type: string;
                description: string;
              }>;
              carrier?:
                | {
                    __typename?: 'BusinessPolicyEntity';
                    name: string;
                    address: {
                      __typename?: 'Address';
                      line1: string;
                      line2?: string | null | undefined;
                      city: string;
                      zone: string;
                      postalCode: string;
                    };
                  }
                | null
                | undefined;
              coverageDescriptions: Array<{
                __typename?: 'BusinessPolicyCoverageOrExclusionDescription';
                description: string;
                policyQuotes: Array<string>;
                title: string;
              }>;
              exclusionDescriptions: Array<{
                __typename?: 'BusinessPolicyCoverageOrExclusionDescription';
                description: string;
                policyQuotes: Array<string>;
                title: string;
              }>;
              extraLimits: Array<{
                __typename?: 'BusinessPolicyExtraInsuranceLimit';
                limit: number;
                name: string;
              }>;
              insured?:
                | {
                    __typename?: 'BusinessPolicyEntity';
                    name: string;
                    address: {
                      __typename?: 'Address';
                      line1: string;
                      line2?: string | null | undefined;
                      city: string;
                      zone: string;
                      postalCode: string;
                    };
                  }
                | null
                | undefined;
              limits: {
                __typename?: 'BusinessPolicyInsuranceLimits';
                cyberAggregateLimit?: number | null | undefined;
                cyberRetentionLimit?: number | null | undefined;
                glAggregateLimit?: number | null | undefined;
                glMedicalPerOccurrenceLimit?: number | null | undefined;
                glMedicalPerPersonLimit?: number | null | undefined;
                glPerOccurrenceLimit?: number | null | undefined;
                glPersonalAndAdvertisingInjuryLimit?: number | null | undefined;
                glProductsAndCompletedOperationsLimit?: number | null | undefined;
                glRentedPremisesDamageLimit?: number | null | undefined;
                propertyBuildingLimit?: number | null | undefined;
                propertyContentsLimit?: number | null | undefined;
                umbrellaOrExcessAggregateLimit?: number | null | undefined;
                umbrellaOrExcessPerOccurrenceLimit?: number | null | undefined;
                wcPerAccidentLimit?: number | null | undefined;
                wcPerDiseaseEmployeeLimit?: number | null | undefined;
                wcPerDiseasePolicyLimit?: number | null | undefined;
              };
              locations: Array<{
                __typename?: 'BusinessPolicyLocation';
                address: {
                  __typename?: 'Address';
                  line1: string;
                  line2?: string | null | undefined;
                  city: string;
                  zone: string;
                  postalCode: string;
                };
                limits: {
                  __typename?: 'BusinessPolicyInsuranceLimits';
                  cyberAggregateLimit?: number | null | undefined;
                  cyberRetentionLimit?: number | null | undefined;
                  glAggregateLimit?: number | null | undefined;
                  glMedicalPerOccurrenceLimit?: number | null | undefined;
                  glMedicalPerPersonLimit?: number | null | undefined;
                  glPerOccurrenceLimit?: number | null | undefined;
                  glPersonalAndAdvertisingInjuryLimit?: number | null | undefined;
                  glProductsAndCompletedOperationsLimit?: number | null | undefined;
                  glRentedPremisesDamageLimit?: number | null | undefined;
                  propertyBuildingLimit?: number | null | undefined;
                  propertyContentsLimit?: number | null | undefined;
                  umbrellaOrExcessAggregateLimit?: number | null | undefined;
                  umbrellaOrExcessPerOccurrenceLimit?: number | null | undefined;
                  wcPerAccidentLimit?: number | null | undefined;
                  wcPerDiseaseEmployeeLimit?: number | null | undefined;
                  wcPerDiseasePolicyLimit?: number | null | undefined;
                };
              }>;
              namedAdditionalInsureds: Array<{
                __typename?: 'BusinessPolicyNamedAdditionalInsured';
                name: string;
                address: {
                  __typename?: 'Address';
                  line1: string;
                  line2?: string | null | undefined;
                  city: string;
                  zone: string;
                  postalCode: string;
                };
              }>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type FileFieldsFragment = {
  __typename?: 'File';
  id: string;
  name: string;
  role: FileRole;
  url?: string | null | undefined;
  createdAt: Date;
  details?:
    | {
        __typename?: 'CertificateDetails';
        certificateNumber: string;
        descriptionOfOperations: string;
        certificateHolder: { __typename?: 'CertificateEntity'; name: string; address: string };
        insured: { __typename?: 'CertificateEntity'; name: string; address: string };
      }
    | null
    | undefined;
};

export type MerchantFieldsFragment = {
  __typename?: 'Merchant';
  id: string;
  name: string;
  files: Array<{
    __typename?: 'File';
    id: string;
    name: string;
    role: FileRole;
    url?: string | null | undefined;
    createdAt: Date;
    details?:
      | {
          __typename?: 'CertificateDetails';
          certificateNumber: string;
          descriptionOfOperations: string;
          certificateHolder: { __typename?: 'CertificateEntity'; name: string; address: string };
          insured: { __typename?: 'CertificateEntity'; name: string; address: string };
        }
      | null
      | undefined;
  }>;
};

export type GetMerchantQueryVariables = Exact<{ [key: string]: never }>;

export type GetMerchantQuery = {
  __typename?: 'Query';
  merchant?:
    | {
        __typename?: 'Merchant';
        id: string;
        name: string;
        files: Array<{
          __typename?: 'File';
          id: string;
          name: string;
          role: FileRole;
          url?: string | null | undefined;
          createdAt: Date;
          details?:
            | {
                __typename?: 'CertificateDetails';
                certificateNumber: string;
                descriptionOfOperations: string;
                certificateHolder: {
                  __typename?: 'CertificateEntity';
                  name: string;
                  address: string;
                };
                insured: { __typename?: 'CertificateEntity'; name: string; address: string };
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type ActionItemFieldsFragment = {
  __typename?: 'ComplianceAction';
  completionDate?: Date | null | undefined;
  description: string;
  dueDate: Date;
  id: string;
  status: ComplianceActionStatus;
  title: string;
  assignee?:
    | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
    | null
    | undefined;
  completedBy?:
    | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
    | null
    | undefined;
  completionRequirements: Array<{
    __typename?: 'ComplianceActionCompletionRequirement';
    description: string;
    title: string;
  }>;
  complianceItems: Array<{ __typename?: 'ComplianceItem'; id: string; title: string }>;
};

export type RiskManagementDataFieldsFragment = {
  __typename?: 'RiskManagementData';
  compliance: Array<{
    __typename?: 'ComplianceGroup';
    slug: string;
    title: string;
    description: string;
    items: Array<{
      __typename?: 'ComplianceItem';
      description: string;
      id: string;
      slug: string;
      title: string;
      actions: Array<{
        __typename?: 'ComplianceAction';
        completionDate?: Date | null | undefined;
        description: string;
        dueDate: Date;
        id: string;
        status: ComplianceActionStatus;
        title: string;
        assignee?:
          | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
          | null
          | undefined;
        completedBy?:
          | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
          | null
          | undefined;
        completionRequirements: Array<{
          __typename?: 'ComplianceActionCompletionRequirement';
          description: string;
          title: string;
        }>;
        complianceItems: Array<{ __typename?: 'ComplianceItem'; id: string; title: string }>;
      }>;
      referenceMaterials: Array<{
        __typename?: 'ComplianceReferenceMaterial';
        description: string;
        title: string;
        url: string;
      }>;
      requirements: Array<{
        __typename?: 'ComplianceRequirement';
        description: string;
        title: string;
      }>;
      standards: Array<{
        __typename?: 'ComplianceStandard';
        description: string;
        title: string;
        url: string;
      }>;
    }>;
  }>;
  actionItems: Array<{
    __typename?: 'ComplianceAction';
    completionDate?: Date | null | undefined;
    description: string;
    dueDate: Date;
    id: string;
    status: ComplianceActionStatus;
    title: string;
    assignee?:
      | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
      | null
      | undefined;
    completedBy?:
      | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
      | null
      | undefined;
    completionRequirements: Array<{
      __typename?: 'ComplianceActionCompletionRequirement';
      description: string;
      title: string;
    }>;
    complianceItems: Array<{ __typename?: 'ComplianceItem'; id: string; title: string }>;
  }>;
};

export type RiskManagementSetupMutationVariables = Exact<{
  description: Scalars['String']['input'];
  changeDescription: Scalars['String']['input'];
  complianceFrameworks: Scalars['String']['input'];
}>;

export type RiskManagementSetupMutation = {
  __typename?: 'Mutation';
  riskManagementSetup: {
    __typename?: 'RiskManagementData';
    compliance: Array<{
      __typename?: 'ComplianceGroup';
      slug: string;
      title: string;
      description: string;
      items: Array<{
        __typename?: 'ComplianceItem';
        description: string;
        id: string;
        slug: string;
        title: string;
        actions: Array<{
          __typename?: 'ComplianceAction';
          completionDate?: Date | null | undefined;
          description: string;
          dueDate: Date;
          id: string;
          status: ComplianceActionStatus;
          title: string;
          assignee?:
            | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
            | null
            | undefined;
          completedBy?:
            | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
            | null
            | undefined;
          completionRequirements: Array<{
            __typename?: 'ComplianceActionCompletionRequirement';
            description: string;
            title: string;
          }>;
          complianceItems: Array<{ __typename?: 'ComplianceItem'; id: string; title: string }>;
        }>;
        referenceMaterials: Array<{
          __typename?: 'ComplianceReferenceMaterial';
          description: string;
          title: string;
          url: string;
        }>;
        requirements: Array<{
          __typename?: 'ComplianceRequirement';
          description: string;
          title: string;
        }>;
        standards: Array<{
          __typename?: 'ComplianceStandard';
          description: string;
          title: string;
          url: string;
        }>;
      }>;
    }>;
    actionItems: Array<{
      __typename?: 'ComplianceAction';
      completionDate?: Date | null | undefined;
      description: string;
      dueDate: Date;
      id: string;
      status: ComplianceActionStatus;
      title: string;
      assignee?:
        | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
        | null
        | undefined;
      completedBy?:
        | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
        | null
        | undefined;
      completionRequirements: Array<{
        __typename?: 'ComplianceActionCompletionRequirement';
        description: string;
        title: string;
      }>;
      complianceItems: Array<{ __typename?: 'ComplianceItem'; id: string; title: string }>;
    }>;
  };
};

export type GetRiskManagementQueryVariables = Exact<{ [key: string]: never }>;

export type GetRiskManagementQuery = {
  __typename?: 'Query';
  riskManagement?:
    | {
        __typename?: 'RiskManagementData';
        compliance: Array<{
          __typename?: 'ComplianceGroup';
          slug: string;
          title: string;
          description: string;
          items: Array<{
            __typename?: 'ComplianceItem';
            description: string;
            id: string;
            slug: string;
            title: string;
            actions: Array<{
              __typename?: 'ComplianceAction';
              completionDate?: Date | null | undefined;
              description: string;
              dueDate: Date;
              id: string;
              status: ComplianceActionStatus;
              title: string;
              assignee?:
                | {
                    __typename?: 'ComplianceActionAssignee';
                    email: string;
                    id: string;
                    name: string;
                  }
                | null
                | undefined;
              completedBy?:
                | {
                    __typename?: 'ComplianceActionAssignee';
                    email: string;
                    id: string;
                    name: string;
                  }
                | null
                | undefined;
              completionRequirements: Array<{
                __typename?: 'ComplianceActionCompletionRequirement';
                description: string;
                title: string;
              }>;
              complianceItems: Array<{ __typename?: 'ComplianceItem'; id: string; title: string }>;
            }>;
            referenceMaterials: Array<{
              __typename?: 'ComplianceReferenceMaterial';
              description: string;
              title: string;
              url: string;
            }>;
            requirements: Array<{
              __typename?: 'ComplianceRequirement';
              description: string;
              title: string;
            }>;
            standards: Array<{
              __typename?: 'ComplianceStandard';
              description: string;
              title: string;
              url: string;
            }>;
          }>;
        }>;
        actionItems: Array<{
          __typename?: 'ComplianceAction';
          completionDate?: Date | null | undefined;
          description: string;
          dueDate: Date;
          id: string;
          status: ComplianceActionStatus;
          title: string;
          assignee?:
            | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
            | null
            | undefined;
          completedBy?:
            | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
            | null
            | undefined;
          completionRequirements: Array<{
            __typename?: 'ComplianceActionCompletionRequirement';
            description: string;
            title: string;
          }>;
          complianceItems: Array<{ __typename?: 'ComplianceItem'; id: string; title: string }>;
        }>;
      }
    | null
    | undefined;
};

export type RiskManagementSetActionItemDueDateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  dueDate: Scalars['Time']['input'];
}>;

export type RiskManagementSetActionItemDueDateMutation = {
  __typename?: 'Mutation';
  riskManagementSetActionItemDueDate: {
    __typename?: 'ComplianceAction';
    completionDate?: Date | null | undefined;
    description: string;
    dueDate: Date;
    id: string;
    status: ComplianceActionStatus;
    title: string;
    assignee?:
      | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
      | null
      | undefined;
    completedBy?:
      | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
      | null
      | undefined;
    completionRequirements: Array<{
      __typename?: 'ComplianceActionCompletionRequirement';
      description: string;
      title: string;
    }>;
    complianceItems: Array<{ __typename?: 'ComplianceItem'; id: string; title: string }>;
  };
};

export type RiskManagementSetActionItemAssigneeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type RiskManagementSetActionItemAssigneeMutation = {
  __typename?: 'Mutation';
  riskManagementSetActionItemAssignee: {
    __typename?: 'ComplianceAction';
    completionDate?: Date | null | undefined;
    description: string;
    dueDate: Date;
    id: string;
    status: ComplianceActionStatus;
    title: string;
    assignee?:
      | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
      | null
      | undefined;
    completedBy?:
      | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
      | null
      | undefined;
    completionRequirements: Array<{
      __typename?: 'ComplianceActionCompletionRequirement';
      description: string;
      title: string;
    }>;
    complianceItems: Array<{ __typename?: 'ComplianceItem'; id: string; title: string }>;
  };
};

export type RiskManagementMarkActionItemCompleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RiskManagementMarkActionItemCompleteMutation = {
  __typename?: 'Mutation';
  riskManagementMarkActionItemComplete: {
    __typename?: 'ComplianceAction';
    completionDate?: Date | null | undefined;
    description: string;
    dueDate: Date;
    id: string;
    status: ComplianceActionStatus;
    title: string;
    assignee?:
      | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
      | null
      | undefined;
    completedBy?:
      | { __typename?: 'ComplianceActionAssignee'; email: string; id: string; name: string }
      | null
      | undefined;
    completionRequirements: Array<{
      __typename?: 'ComplianceActionCompletionRequirement';
      description: string;
      title: string;
    }>;
    complianceItems: Array<{ __typename?: 'ComplianceItem'; id: string; title: string }>;
  };
};

export type PolicyConversationMessageFieldsFragment = {
  __typename?: 'PolicyConversationMessage';
  content: string;
  id: string;
  role: PolicyConversationMessageRole;
  timestamp: Date;
  attachments: Array<{
    __typename?: 'PolicyConversationMessageAttachment';
    content: string;
    type: PolicyConversationMessageAttachmentType;
  }>;
};

export type GetPolicyConversationQueryVariables = Exact<{
  policyId: Scalars['ID']['input'];
}>;

export type GetPolicyConversationQuery = {
  __typename?: 'Query';
  policyConversation: Array<{
    __typename?: 'PolicyConversationMessage';
    content: string;
    id: string;
    role: PolicyConversationMessageRole;
    timestamp: Date;
    attachments: Array<{
      __typename?: 'PolicyConversationMessageAttachment';
      content: string;
      type: PolicyConversationMessageAttachmentType;
    }>;
  }>;
};

export type AddPolicyConversationMessageMutationVariables = Exact<{
  policyId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
}>;

export type AddPolicyConversationMessageMutation = {
  __typename?: 'Mutation';
  riskManagementAddPolicyConversationMessage: Array<{
    __typename?: 'PolicyConversationMessage';
    content: string;
    id: string;
    role: PolicyConversationMessageRole;
    timestamp: Date;
    attachments: Array<{
      __typename?: 'PolicyConversationMessageAttachment';
      content: string;
      type: PolicyConversationMessageAttachmentType;
    }>;
  }>;
};

export const CertificateDetailsFieldsFragmentDoc = gql`
  fragment CertificateDetailsFields on CertificateDetails {
    certificateNumber
    insured {
      name
      address
    }
    producer {
      name
      address
    }
    certificateHolder {
      name
      address
    }
    descriptionOfOperations
    coverages {
      type
      effectiveDate
      expirationDate
      policyNumber
      holderIsAdditionalInsured
      hasWaiverOfSubrogation
      limits {
        name
        limit
      }
      insurer {
        name
        naic
      }
    }
  }
`;
export const ApplicationFieldsFragmentDoc = gql`
  fragment ApplicationFields on BusinessApplication {
    annualRevenue
    businessDba
    businessName
    contact {
      fullName
      email
      phone
    }
    employeeInfo {
      numFullTimeEmployees
      numPartTimeEmployees
      totalEmployeePayroll
    }
    fein
    id
    insuranceEffectiveAt
    insuranceTypes
    legalEntityType
    locations {
      id
      address {
        line1
        line2
        city
        zone
        postalCode
      }
      buildingInfo {
        annualSales
        areaOccupiedByBusiness
        buildingCoverage
        burglarAlarmType
        constructionType
        ownershipType
        personalPropertyCoverage
        sprinkleredPercentage
        totalArea
        totalStories
        yearBuilt
      }
      employeeInfo {
        numPartTimeEmployees
        numFullTimeEmployees
        totalEmployeePayroll
        jobCode
      }
      isPrimary
    }
    mailingAddress {
      line1
      line2
      city
      zone
      postalCode
    }
    naicsCode
    owners {
      fullName
      annualPayroll
      dateOfBirth
      jobCode
    }
    pastPolicyLosses {
      claimDate
      claimStatus
      lossDate
      lossDescription
      lossState
      lossType
      policyEffectiveDate
      policyExpirationDate
      policyType
      totalPaidAmount
      totalReservedAmount
    }
    selectedCarriers
    selectedLimits {
      coverageState
      wcPerAccidentLimit
      wcPerDiseaseEmployeeLimit
      wcPerDiseasePolicyLimit
      glPerOccurrenceLimit
      glAggregateLimit
      bopPerOccurrenceLimit
      bopAggregateLimit
      cyberRetentionLimit
      cyberAggregateLimit
    }
    state
    underwritingAnswers {
      answer
      locationId
      questionId
    }
    underwritingQuestions {
      id
      answerOptions {
        displayText
        value
      }
      carriers
      defaultAnswer
      dependentQuestions {
        condition
        dependentQuestionIds
        type
      }
      hasParentQuestion
      locationId
      question
      tooltip
      type
    }
    underwritingStatements {
      carrier
      markdown
    }
    yearOfFounding
    yearsOfManagementExperience
  }
`;
export const ApplicationQuoteFieldsFragmentDoc = gql`
  fragment ApplicationQuoteFields on BusinessApplication {
    quotes {
      carrier
      id
      paymentPlans {
        description
        downAmount
        downDueAt
        installmentAmount
        installmentCount
        installmentDueAt
        installmentFee
        installmentFrequency
        paymentMethod
        paymentPlanId
        title
        totalAmount
        totalFees
      }
      policyType
      premiumAmount
      quoteProposalUrl
      state
      totalAmount
      type
    }
  }
`;
export const ApplicationQuotableCarrierFieldsFragmentDoc = gql`
  fragment ApplicationQuotableCarrierFields on BusinessApplication {
    quotableCarriers {
      ascendId
      id
      isBroker
      isCarrier
      name
      supportedPolicies
    }
  }
`;
export const BusinessPolicyDetailFieldsFragmentDoc = gql`
  fragment BusinessPolicyDetailFields on BusinessPolicy {
    details {
      blanketAdditionalInsureds {
        type
        description
      }
      carrier {
        name
        address {
          line1
          line2
          city
          zone
          postalCode
        }
      }
      coverageDescriptions {
        description
        policyQuotes
        title
      }
      deductible
      exclusionDescriptions {
        description
        policyQuotes
        title
      }
      extraLimits {
        limit
        name
      }
      insured {
        name
        address {
          line1
          line2
          city
          zone
          postalCode
        }
      }
      limits {
        cyberAggregateLimit
        cyberRetentionLimit
        glAggregateLimit
        glMedicalPerOccurrenceLimit
        glMedicalPerPersonLimit
        glPerOccurrenceLimit
        glPersonalAndAdvertisingInjuryLimit
        glProductsAndCompletedOperationsLimit
        glRentedPremisesDamageLimit
        propertyBuildingLimit
        propertyContentsLimit
        umbrellaOrExcessAggregateLimit
        umbrellaOrExcessPerOccurrenceLimit
        wcPerAccidentLimit
        wcPerDiseaseEmployeeLimit
        wcPerDiseasePolicyLimit
      }
      locations {
        address {
          line1
          line2
          city
          zone
          postalCode
        }
        limits {
          cyberAggregateLimit
          cyberRetentionLimit
          glAggregateLimit
          glMedicalPerOccurrenceLimit
          glMedicalPerPersonLimit
          glPerOccurrenceLimit
          glPersonalAndAdvertisingInjuryLimit
          glProductsAndCompletedOperationsLimit
          glRentedPremisesDamageLimit
          propertyBuildingLimit
          propertyContentsLimit
          umbrellaOrExcessAggregateLimit
          umbrellaOrExcessPerOccurrenceLimit
          wcPerAccidentLimit
          wcPerDiseaseEmployeeLimit
          wcPerDiseasePolicyLimit
        }
      }
      namedAdditionalInsureds {
        name
        address {
          line1
          line2
          city
          zone
          postalCode
        }
      }
      policyNumber
      summary
    }
  }
`;
export const BusinessPolicyFieldsFragmentDoc = gql`
  fragment BusinessPolicyFields on BusinessPolicy {
    id
    type
    files {
      id
      name
      role
      url
    }
    policyNumber
    state
    inforceAt
    expiresAt
    carrier {
      name
    }
    pricing {
      billingType
      intervalType
      premium
      total
    }
    ...BusinessPolicyDetailFields
  }
  ${BusinessPolicyDetailFieldsFragmentDoc}
`;
export const FileFieldsFragmentDoc = gql`
  fragment FileFields on File {
    id
    name
    role
    url
    createdAt
    details {
      ... on CertificateDetails {
        certificateNumber
        certificateHolder {
          name
          address
        }
        insured {
          name
          address
        }
        descriptionOfOperations
      }
    }
  }
`;
export const MerchantFieldsFragmentDoc = gql`
  fragment MerchantFields on Merchant {
    id
    name
    files {
      ...FileFields
    }
  }
  ${FileFieldsFragmentDoc}
`;
export const ActionItemFieldsFragmentDoc = gql`
  fragment ActionItemFields on ComplianceAction {
    assignee {
      email
      id
      name
    }
    completedBy {
      email
      id
      name
    }
    completionDate
    completionRequirements {
      description
      title
    }
    complianceItems {
      id
      title
    }
    description
    dueDate
    id
    status
    title
  }
`;
export const RiskManagementDataFieldsFragmentDoc = gql`
  fragment RiskManagementDataFields on RiskManagementData {
    compliance {
      slug
      title
      description
      items {
        actions {
          ...ActionItemFields
        }
        description
        id
        referenceMaterials {
          description
          title
          url
        }
        requirements {
          description
          title
        }
        slug
        standards {
          description
          title
          url
        }
        title
      }
    }
    actionItems {
      ...ActionItemFields
    }
  }
  ${ActionItemFieldsFragmentDoc}
`;
export const PolicyConversationMessageFieldsFragmentDoc = gql`
  fragment PolicyConversationMessageFields on PolicyConversationMessage {
    attachments {
      content
      type
    }
    content
    id
    role
    timestamp
  }
`;
export const GetCertificateDocument = gql`
  query GetCertificate($certificateNumber: String!) {
    certificate(certificateNumber: $certificateNumber) {
      url
      details {
        ...CertificateDetailsFields
      }
    }
  }
  ${CertificateDetailsFieldsFragmentDoc}
`;

/**
 * __useGetCertificateQuery__
 *
 * To run a query within a React component, call `useGetCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateQuery({
 *   variables: {
 *      certificateNumber: // value for 'certificateNumber'
 *   },
 * });
 */
export function useGetCertificateQuery(
  baseOptions: Apollo.QueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables> &
    ({ variables: GetCertificateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCertificateQuery, GetCertificateQueryVariables>(
    GetCertificateDocument,
    options
  );
}
export function useGetCertificateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCertificateQuery, GetCertificateQueryVariables>(
    GetCertificateDocument,
    options
  );
}
export function useGetCertificateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCertificateQuery, GetCertificateQueryVariables>(
    GetCertificateDocument,
    options
  );
}
export type GetCertificateQueryHookResult = ReturnType<typeof useGetCertificateQuery>;
export type GetCertificateLazyQueryHookResult = ReturnType<typeof useGetCertificateLazyQuery>;
export type GetCertificateSuspenseQueryHookResult = ReturnType<
  typeof useGetCertificateSuspenseQuery
>;
export type GetCertificateQueryResult = Apollo.QueryResult<
  GetCertificateQuery,
  GetCertificateQueryVariables
>;
export const GenerateCertificateDocument = gql`
  mutation GenerateCertificate($req: GenerateCertificateInput!) {
    generateCertificate(req: $req) {
      details {
        ... on CertificateDetails {
          certificateNumber
        }
      }
    }
  }
`;
export type GenerateCertificateMutationFn = Apollo.MutationFunction<
  GenerateCertificateMutation,
  GenerateCertificateMutationVariables
>;

/**
 * __useGenerateCertificateMutation__
 *
 * To run a mutation, you first call `useGenerateCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCertificateMutation, { data, loading, error }] = useGenerateCertificateMutation({
 *   variables: {
 *      req: // value for 'req'
 *   },
 * });
 */
export function useGenerateCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateCertificateMutation,
    GenerateCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateCertificateMutation, GenerateCertificateMutationVariables>(
    GenerateCertificateDocument,
    options
  );
}
export type GenerateCertificateMutationHookResult = ReturnType<
  typeof useGenerateCertificateMutation
>;
export type GenerateCertificateMutationResult = Apollo.MutationResult<GenerateCertificateMutation>;
export type GenerateCertificateMutationOptions = Apollo.BaseMutationOptions<
  GenerateCertificateMutation,
  GenerateCertificateMutationVariables
>;
export const GetCoverageLimitsDocument = gql`
  query GetCoverageLimits($applicationId: ID!, $state: String!) {
    coverageLimits(applicationId: $applicationId, state: $state) {
      insuranceType
      limits {
        bopAggregateLimit
        bopPerOccurrenceLimit
        coverageState
        cyberAggregateLimit
        cyberRetentionLimit
        glAggregateLimit
        glPerOccurrenceLimit
        wcPerAccidentLimit
        wcPerDiseaseEmployeeLimit
        wcPerDiseasePolicyLimit
      }
    }
  }
`;

/**
 * __useGetCoverageLimitsQuery__
 *
 * To run a query within a React component, call `useGetCoverageLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoverageLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoverageLimitsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetCoverageLimitsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoverageLimitsQuery, GetCoverageLimitsQueryVariables> &
    ({ variables: GetCoverageLimitsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoverageLimitsQuery, GetCoverageLimitsQueryVariables>(
    GetCoverageLimitsDocument,
    options
  );
}
export function useGetCoverageLimitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoverageLimitsQuery, GetCoverageLimitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoverageLimitsQuery, GetCoverageLimitsQueryVariables>(
    GetCoverageLimitsDocument,
    options
  );
}
export function useGetCoverageLimitsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCoverageLimitsQuery,
    GetCoverageLimitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCoverageLimitsQuery, GetCoverageLimitsQueryVariables>(
    GetCoverageLimitsDocument,
    options
  );
}
export type GetCoverageLimitsQueryHookResult = ReturnType<typeof useGetCoverageLimitsQuery>;
export type GetCoverageLimitsLazyQueryHookResult = ReturnType<typeof useGetCoverageLimitsLazyQuery>;
export type GetCoverageLimitsSuspenseQueryHookResult = ReturnType<
  typeof useGetCoverageLimitsSuspenseQuery
>;
export type GetCoverageLimitsQueryResult = Apollo.QueryResult<
  GetCoverageLimitsQuery,
  GetCoverageLimitsQueryVariables
>;
export const GetBusinessApplicationDocument = gql`
  query GetBusinessApplication(
    $id: ID!
    $includeQuotes: Boolean = false
    $includeQuotableCarriers: Boolean = false
  ) {
    businessApplication(id: $id) {
      ...ApplicationFields
      ...ApplicationQuoteFields @include(if: $includeQuotes)
      ...ApplicationQuotableCarrierFields @include(if: $includeQuotableCarriers)
    }
  }
  ${ApplicationFieldsFragmentDoc}
  ${ApplicationQuoteFieldsFragmentDoc}
  ${ApplicationQuotableCarrierFieldsFragmentDoc}
`;

/**
 * __useGetBusinessApplicationQuery__
 *
 * To run a query within a React component, call `useGetBusinessApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeQuotes: // value for 'includeQuotes'
 *      includeQuotableCarriers: // value for 'includeQuotableCarriers'
 *   },
 * });
 */
export function useGetBusinessApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBusinessApplicationQuery,
    GetBusinessApplicationQueryVariables
  > &
    ({ variables: GetBusinessApplicationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBusinessApplicationQuery, GetBusinessApplicationQueryVariables>(
    GetBusinessApplicationDocument,
    options
  );
}
export function useGetBusinessApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessApplicationQuery,
    GetBusinessApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBusinessApplicationQuery, GetBusinessApplicationQueryVariables>(
    GetBusinessApplicationDocument,
    options
  );
}
export function useGetBusinessApplicationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetBusinessApplicationQuery,
    GetBusinessApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBusinessApplicationQuery, GetBusinessApplicationQueryVariables>(
    GetBusinessApplicationDocument,
    options
  );
}
export type GetBusinessApplicationQueryHookResult = ReturnType<
  typeof useGetBusinessApplicationQuery
>;
export type GetBusinessApplicationLazyQueryHookResult = ReturnType<
  typeof useGetBusinessApplicationLazyQuery
>;
export type GetBusinessApplicationSuspenseQueryHookResult = ReturnType<
  typeof useGetBusinessApplicationSuspenseQuery
>;
export type GetBusinessApplicationQueryResult = Apollo.QueryResult<
  GetBusinessApplicationQuery,
  GetBusinessApplicationQueryVariables
>;
export const CreateBusinessApplicationDocument = gql`
  mutation CreateBusinessApplication($application: CreateApplicationInput!) {
    createBusinessApplication(application: $application) {
      id
    }
  }
`;
export type CreateBusinessApplicationMutationFn = Apollo.MutationFunction<
  CreateBusinessApplicationMutation,
  CreateBusinessApplicationMutationVariables
>;

/**
 * __useCreateBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useCreateBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessApplicationMutation, { data, loading, error }] = useCreateBusinessApplicationMutation({
 *   variables: {
 *      application: // value for 'application'
 *   },
 * });
 */
export function useCreateBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBusinessApplicationMutation,
    CreateBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBusinessApplicationMutation,
    CreateBusinessApplicationMutationVariables
  >(CreateBusinessApplicationDocument, options);
}
export type CreateBusinessApplicationMutationHookResult = ReturnType<
  typeof useCreateBusinessApplicationMutation
>;
export type CreateBusinessApplicationMutationResult =
  Apollo.MutationResult<CreateBusinessApplicationMutation>;
export type CreateBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessApplicationMutation,
  CreateBusinessApplicationMutationVariables
>;
export const UpdateBusinessApplicationDocument = gql`
  mutation UpdateBusinessApplication(
    $id: ID!
    $application: UpdateApplicationInput!
    $includeQuotes: Boolean = false
    $includeQuotableCarriers: Boolean = false
  ) {
    updateBusinessApplication(id: $id, application: $application) {
      ...ApplicationFields
      ...ApplicationQuoteFields @include(if: $includeQuotes)
      ...ApplicationQuotableCarrierFields @include(if: $includeQuotableCarriers)
    }
  }
  ${ApplicationFieldsFragmentDoc}
  ${ApplicationQuoteFieldsFragmentDoc}
  ${ApplicationQuotableCarrierFieldsFragmentDoc}
`;
export type UpdateBusinessApplicationMutationFn = Apollo.MutationFunction<
  UpdateBusinessApplicationMutation,
  UpdateBusinessApplicationMutationVariables
>;

/**
 * __useUpdateBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessApplicationMutation, { data, loading, error }] = useUpdateBusinessApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      application: // value for 'application'
 *      includeQuotes: // value for 'includeQuotes'
 *      includeQuotableCarriers: // value for 'includeQuotableCarriers'
 *   },
 * });
 */
export function useUpdateBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBusinessApplicationMutation,
    UpdateBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBusinessApplicationMutation,
    UpdateBusinessApplicationMutationVariables
  >(UpdateBusinessApplicationDocument, options);
}
export type UpdateBusinessApplicationMutationHookResult = ReturnType<
  typeof useUpdateBusinessApplicationMutation
>;
export type UpdateBusinessApplicationMutationResult =
  Apollo.MutationResult<UpdateBusinessApplicationMutation>;
export type UpdateBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateBusinessApplicationMutation,
  UpdateBusinessApplicationMutationVariables
>;
export const QuoteBusinessApplicationDocument = gql`
  mutation QuoteBusinessApplication($id: ID!) {
    quoteBusinessApplication(id: $id) {
      ...ApplicationFields
      ...ApplicationQuoteFields
      ...ApplicationQuotableCarrierFields @include(if: false)
    }
  }
  ${ApplicationFieldsFragmentDoc}
  ${ApplicationQuoteFieldsFragmentDoc}
  ${ApplicationQuotableCarrierFieldsFragmentDoc}
`;
export type QuoteBusinessApplicationMutationFn = Apollo.MutationFunction<
  QuoteBusinessApplicationMutation,
  QuoteBusinessApplicationMutationVariables
>;

/**
 * __useQuoteBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useQuoteBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteBusinessApplicationMutation, { data, loading, error }] = useQuoteBusinessApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuoteBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuoteBusinessApplicationMutation,
    QuoteBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuoteBusinessApplicationMutation,
    QuoteBusinessApplicationMutationVariables
  >(QuoteBusinessApplicationDocument, options);
}
export type QuoteBusinessApplicationMutationHookResult = ReturnType<
  typeof useQuoteBusinessApplicationMutation
>;
export type QuoteBusinessApplicationMutationResult =
  Apollo.MutationResult<QuoteBusinessApplicationMutation>;
export type QuoteBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  QuoteBusinessApplicationMutation,
  QuoteBusinessApplicationMutationVariables
>;
export const SubmitBusinessApplicationDocument = gql`
  mutation SubmitBusinessApplication($id: ID!) {
    submitBusinessApplication(id: $id) {
      ...ApplicationFields
      ...ApplicationQuoteFields
      ...ApplicationQuotableCarrierFields @include(if: false)
    }
  }
  ${ApplicationFieldsFragmentDoc}
  ${ApplicationQuoteFieldsFragmentDoc}
  ${ApplicationQuotableCarrierFieldsFragmentDoc}
`;
export type SubmitBusinessApplicationMutationFn = Apollo.MutationFunction<
  SubmitBusinessApplicationMutation,
  SubmitBusinessApplicationMutationVariables
>;

/**
 * __useSubmitBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBusinessApplicationMutation, { data, loading, error }] = useSubmitBusinessApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitBusinessApplicationMutation,
    SubmitBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitBusinessApplicationMutation,
    SubmitBusinessApplicationMutationVariables
  >(SubmitBusinessApplicationDocument, options);
}
export type SubmitBusinessApplicationMutationHookResult = ReturnType<
  typeof useSubmitBusinessApplicationMutation
>;
export type SubmitBusinessApplicationMutationResult =
  Apollo.MutationResult<SubmitBusinessApplicationMutation>;
export type SubmitBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  SubmitBusinessApplicationMutation,
  SubmitBusinessApplicationMutationVariables
>;
export const AddBusinessApplicationLocationDocument = gql`
  mutation AddBusinessApplicationLocation($applicationId: ID!, $location: BusinessLocationInput!) {
    businessApplication: addBusinessLocation(applicationId: $applicationId, location: $location) {
      ...ApplicationFields
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type AddBusinessApplicationLocationMutationFn = Apollo.MutationFunction<
  AddBusinessApplicationLocationMutation,
  AddBusinessApplicationLocationMutationVariables
>;

/**
 * __useAddBusinessApplicationLocationMutation__
 *
 * To run a mutation, you first call `useAddBusinessApplicationLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBusinessApplicationLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBusinessApplicationLocationMutation, { data, loading, error }] = useAddBusinessApplicationLocationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useAddBusinessApplicationLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBusinessApplicationLocationMutation,
    AddBusinessApplicationLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddBusinessApplicationLocationMutation,
    AddBusinessApplicationLocationMutationVariables
  >(AddBusinessApplicationLocationDocument, options);
}
export type AddBusinessApplicationLocationMutationHookResult = ReturnType<
  typeof useAddBusinessApplicationLocationMutation
>;
export type AddBusinessApplicationLocationMutationResult =
  Apollo.MutationResult<AddBusinessApplicationLocationMutation>;
export type AddBusinessApplicationLocationMutationOptions = Apollo.BaseMutationOptions<
  AddBusinessApplicationLocationMutation,
  AddBusinessApplicationLocationMutationVariables
>;
export const UpdateBusinessApplicationLocationDocument = gql`
  mutation UpdateBusinessApplicationLocation(
    $applicationId: ID!
    $locationId: ID!
    $location: BusinessLocationInput!
  ) {
    businessApplication: updateBusinessLocation(
      applicationId: $applicationId
      locationId: $locationId
      location: $location
    ) {
      ...ApplicationFields
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type UpdateBusinessApplicationLocationMutationFn = Apollo.MutationFunction<
  UpdateBusinessApplicationLocationMutation,
  UpdateBusinessApplicationLocationMutationVariables
>;

/**
 * __useUpdateBusinessApplicationLocationMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessApplicationLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessApplicationLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessApplicationLocationMutation, { data, loading, error }] = useUpdateBusinessApplicationLocationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      locationId: // value for 'locationId'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useUpdateBusinessApplicationLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBusinessApplicationLocationMutation,
    UpdateBusinessApplicationLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBusinessApplicationLocationMutation,
    UpdateBusinessApplicationLocationMutationVariables
  >(UpdateBusinessApplicationLocationDocument, options);
}
export type UpdateBusinessApplicationLocationMutationHookResult = ReturnType<
  typeof useUpdateBusinessApplicationLocationMutation
>;
export type UpdateBusinessApplicationLocationMutationResult =
  Apollo.MutationResult<UpdateBusinessApplicationLocationMutation>;
export type UpdateBusinessApplicationLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateBusinessApplicationLocationMutation,
  UpdateBusinessApplicationLocationMutationVariables
>;
export const RemoveBusinessApplicationLocationDocument = gql`
  mutation RemoveBusinessApplicationLocation($applicationId: ID!, $locationId: ID!) {
    businessApplication: removeBusinessLocation(
      applicationId: $applicationId
      locationId: $locationId
    ) {
      ...ApplicationFields
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type RemoveBusinessApplicationLocationMutationFn = Apollo.MutationFunction<
  RemoveBusinessApplicationLocationMutation,
  RemoveBusinessApplicationLocationMutationVariables
>;

/**
 * __useRemoveBusinessApplicationLocationMutation__
 *
 * To run a mutation, you first call `useRemoveBusinessApplicationLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBusinessApplicationLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBusinessApplicationLocationMutation, { data, loading, error }] = useRemoveBusinessApplicationLocationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRemoveBusinessApplicationLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBusinessApplicationLocationMutation,
    RemoveBusinessApplicationLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveBusinessApplicationLocationMutation,
    RemoveBusinessApplicationLocationMutationVariables
  >(RemoveBusinessApplicationLocationDocument, options);
}
export type RemoveBusinessApplicationLocationMutationHookResult = ReturnType<
  typeof useRemoveBusinessApplicationLocationMutation
>;
export type RemoveBusinessApplicationLocationMutationResult =
  Apollo.MutationResult<RemoveBusinessApplicationLocationMutation>;
export type RemoveBusinessApplicationLocationMutationOptions = Apollo.BaseMutationOptions<
  RemoveBusinessApplicationLocationMutation,
  RemoveBusinessApplicationLocationMutationVariables
>;
export const BindQuoteDocument = gql`
  mutation BindQuote(
    $applicationId: ID!
    $quoteId: ID!
    $paymentPlanId: String
    $paymentMethod: PaymentPlanPaymentMethodType
  ) {
    bindQuote(
      applicationId: $applicationId
      quoteId: $quoteId
      paymentPlanId: $paymentPlanId
      paymentMethod: $paymentMethod
    ) {
      ...ApplicationFields
      ...ApplicationQuoteFields
      ...ApplicationQuotableCarrierFields @include(if: false)
    }
  }
  ${ApplicationFieldsFragmentDoc}
  ${ApplicationQuoteFieldsFragmentDoc}
  ${ApplicationQuotableCarrierFieldsFragmentDoc}
`;
export type BindQuoteMutationFn = Apollo.MutationFunction<
  BindQuoteMutation,
  BindQuoteMutationVariables
>;

/**
 * __useBindQuoteMutation__
 *
 * To run a mutation, you first call `useBindQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBindQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bindQuoteMutation, { data, loading, error }] = useBindQuoteMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      quoteId: // value for 'quoteId'
 *      paymentPlanId: // value for 'paymentPlanId'
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useBindQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<BindQuoteMutation, BindQuoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BindQuoteMutation, BindQuoteMutationVariables>(
    BindQuoteDocument,
    options
  );
}
export type BindQuoteMutationHookResult = ReturnType<typeof useBindQuoteMutation>;
export type BindQuoteMutationResult = Apollo.MutationResult<BindQuoteMutation>;
export type BindQuoteMutationOptions = Apollo.BaseMutationOptions<
  BindQuoteMutation,
  BindQuoteMutationVariables
>;
export const GetMerchantBusinessPoliciesDocument = gql`
  query GetMerchantBusinessPolicies {
    businessPolicies {
      ...BusinessPolicyFields
    }
  }
  ${BusinessPolicyFieldsFragmentDoc}
`;

/**
 * __useGetMerchantBusinessPoliciesQuery__
 *
 * To run a query within a React component, call `useGetMerchantBusinessPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantBusinessPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantBusinessPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMerchantBusinessPoliciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >(GetMerchantBusinessPoliciesDocument, options);
}
export function useGetMerchantBusinessPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >(GetMerchantBusinessPoliciesDocument, options);
}
export function useGetMerchantBusinessPoliciesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMerchantBusinessPoliciesQuery,
    GetMerchantBusinessPoliciesQueryVariables
  >(GetMerchantBusinessPoliciesDocument, options);
}
export type GetMerchantBusinessPoliciesQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPoliciesQuery
>;
export type GetMerchantBusinessPoliciesLazyQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPoliciesLazyQuery
>;
export type GetMerchantBusinessPoliciesSuspenseQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPoliciesSuspenseQuery
>;
export type GetMerchantBusinessPoliciesQueryResult = Apollo.QueryResult<
  GetMerchantBusinessPoliciesQuery,
  GetMerchantBusinessPoliciesQueryVariables
>;
export const GetMerchantBusinessPolicyDocument = gql`
  query GetMerchantBusinessPolicy($id: ID!) {
    businessPolicy(id: $id) {
      ...BusinessPolicyFields
    }
  }
  ${BusinessPolicyFieldsFragmentDoc}
`;

/**
 * __useGetMerchantBusinessPolicyQuery__
 *
 * To run a query within a React component, call `useGetMerchantBusinessPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantBusinessPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantBusinessPolicyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMerchantBusinessPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMerchantBusinessPolicyQuery,
    GetMerchantBusinessPolicyQueryVariables
  > &
    ({ variables: GetMerchantBusinessPolicyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMerchantBusinessPolicyQuery, GetMerchantBusinessPolicyQueryVariables>(
    GetMerchantBusinessPolicyDocument,
    options
  );
}
export function useGetMerchantBusinessPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMerchantBusinessPolicyQuery,
    GetMerchantBusinessPolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMerchantBusinessPolicyQuery,
    GetMerchantBusinessPolicyQueryVariables
  >(GetMerchantBusinessPolicyDocument, options);
}
export function useGetMerchantBusinessPolicySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMerchantBusinessPolicyQuery,
    GetMerchantBusinessPolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMerchantBusinessPolicyQuery,
    GetMerchantBusinessPolicyQueryVariables
  >(GetMerchantBusinessPolicyDocument, options);
}
export type GetMerchantBusinessPolicyQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPolicyQuery
>;
export type GetMerchantBusinessPolicyLazyQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPolicyLazyQuery
>;
export type GetMerchantBusinessPolicySuspenseQueryHookResult = ReturnType<
  typeof useGetMerchantBusinessPolicySuspenseQuery
>;
export type GetMerchantBusinessPolicyQueryResult = Apollo.QueryResult<
  GetMerchantBusinessPolicyQuery,
  GetMerchantBusinessPolicyQueryVariables
>;
export const GetMerchantDocument = gql`
  query GetMerchant {
    merchant {
      ...MerchantFields
    }
  }
  ${MerchantFieldsFragmentDoc}
`;

/**
 * __useGetMerchantQuery__
 *
 * To run a query within a React component, call `useGetMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMerchantQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMerchantQuery, GetMerchantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMerchantQuery, GetMerchantQueryVariables>(GetMerchantDocument, options);
}
export function useGetMerchantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMerchantQuery, GetMerchantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMerchantQuery, GetMerchantQueryVariables>(
    GetMerchantDocument,
    options
  );
}
export function useGetMerchantSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMerchantQuery, GetMerchantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMerchantQuery, GetMerchantQueryVariables>(
    GetMerchantDocument,
    options
  );
}
export type GetMerchantQueryHookResult = ReturnType<typeof useGetMerchantQuery>;
export type GetMerchantLazyQueryHookResult = ReturnType<typeof useGetMerchantLazyQuery>;
export type GetMerchantSuspenseQueryHookResult = ReturnType<typeof useGetMerchantSuspenseQuery>;
export type GetMerchantQueryResult = Apollo.QueryResult<
  GetMerchantQuery,
  GetMerchantQueryVariables
>;
export const RiskManagementSetupDocument = gql`
  mutation RiskManagementSetup(
    $description: String!
    $changeDescription: String!
    $complianceFrameworks: String!
  ) {
    riskManagementSetup(
      input: {
        description: $description
        changeDescription: $changeDescription
        complianceFrameworks: $complianceFrameworks
      }
    ) {
      ...RiskManagementDataFields
    }
  }
  ${RiskManagementDataFieldsFragmentDoc}
`;
export type RiskManagementSetupMutationFn = Apollo.MutationFunction<
  RiskManagementSetupMutation,
  RiskManagementSetupMutationVariables
>;

/**
 * __useRiskManagementSetupMutation__
 *
 * To run a mutation, you first call `useRiskManagementSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRiskManagementSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [riskManagementSetupMutation, { data, loading, error }] = useRiskManagementSetupMutation({
 *   variables: {
 *      description: // value for 'description'
 *      changeDescription: // value for 'changeDescription'
 *      complianceFrameworks: // value for 'complianceFrameworks'
 *   },
 * });
 */
export function useRiskManagementSetupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RiskManagementSetupMutation,
    RiskManagementSetupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RiskManagementSetupMutation, RiskManagementSetupMutationVariables>(
    RiskManagementSetupDocument,
    options
  );
}
export type RiskManagementSetupMutationHookResult = ReturnType<
  typeof useRiskManagementSetupMutation
>;
export type RiskManagementSetupMutationResult = Apollo.MutationResult<RiskManagementSetupMutation>;
export type RiskManagementSetupMutationOptions = Apollo.BaseMutationOptions<
  RiskManagementSetupMutation,
  RiskManagementSetupMutationVariables
>;
export const GetRiskManagementDocument = gql`
  query GetRiskManagement {
    riskManagement {
      ...RiskManagementDataFields
    }
  }
  ${RiskManagementDataFieldsFragmentDoc}
`;

/**
 * __useGetRiskManagementQuery__
 *
 * To run a query within a React component, call `useGetRiskManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiskManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiskManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRiskManagementQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRiskManagementQuery, GetRiskManagementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRiskManagementQuery, GetRiskManagementQueryVariables>(
    GetRiskManagementDocument,
    options
  );
}
export function useGetRiskManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRiskManagementQuery, GetRiskManagementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRiskManagementQuery, GetRiskManagementQueryVariables>(
    GetRiskManagementDocument,
    options
  );
}
export function useGetRiskManagementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRiskManagementQuery,
    GetRiskManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRiskManagementQuery, GetRiskManagementQueryVariables>(
    GetRiskManagementDocument,
    options
  );
}
export type GetRiskManagementQueryHookResult = ReturnType<typeof useGetRiskManagementQuery>;
export type GetRiskManagementLazyQueryHookResult = ReturnType<typeof useGetRiskManagementLazyQuery>;
export type GetRiskManagementSuspenseQueryHookResult = ReturnType<
  typeof useGetRiskManagementSuspenseQuery
>;
export type GetRiskManagementQueryResult = Apollo.QueryResult<
  GetRiskManagementQuery,
  GetRiskManagementQueryVariables
>;
export const RiskManagementSetActionItemDueDateDocument = gql`
  mutation RiskManagementSetActionItemDueDate($id: ID!, $dueDate: Time!) {
    riskManagementSetActionItemDueDate(id: $id, dueDate: $dueDate) {
      ...ActionItemFields
    }
  }
  ${ActionItemFieldsFragmentDoc}
`;
export type RiskManagementSetActionItemDueDateMutationFn = Apollo.MutationFunction<
  RiskManagementSetActionItemDueDateMutation,
  RiskManagementSetActionItemDueDateMutationVariables
>;

/**
 * __useRiskManagementSetActionItemDueDateMutation__
 *
 * To run a mutation, you first call `useRiskManagementSetActionItemDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRiskManagementSetActionItemDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [riskManagementSetActionItemDueDateMutation, { data, loading, error }] = useRiskManagementSetActionItemDueDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dueDate: // value for 'dueDate'
 *   },
 * });
 */
export function useRiskManagementSetActionItemDueDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RiskManagementSetActionItemDueDateMutation,
    RiskManagementSetActionItemDueDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RiskManagementSetActionItemDueDateMutation,
    RiskManagementSetActionItemDueDateMutationVariables
  >(RiskManagementSetActionItemDueDateDocument, options);
}
export type RiskManagementSetActionItemDueDateMutationHookResult = ReturnType<
  typeof useRiskManagementSetActionItemDueDateMutation
>;
export type RiskManagementSetActionItemDueDateMutationResult =
  Apollo.MutationResult<RiskManagementSetActionItemDueDateMutation>;
export type RiskManagementSetActionItemDueDateMutationOptions = Apollo.BaseMutationOptions<
  RiskManagementSetActionItemDueDateMutation,
  RiskManagementSetActionItemDueDateMutationVariables
>;
export const RiskManagementSetActionItemAssigneeDocument = gql`
  mutation RiskManagementSetActionItemAssignee($id: ID!, $userId: ID!) {
    riskManagementSetActionItemAssignee(id: $id, userId: $userId) {
      ...ActionItemFields
    }
  }
  ${ActionItemFieldsFragmentDoc}
`;
export type RiskManagementSetActionItemAssigneeMutationFn = Apollo.MutationFunction<
  RiskManagementSetActionItemAssigneeMutation,
  RiskManagementSetActionItemAssigneeMutationVariables
>;

/**
 * __useRiskManagementSetActionItemAssigneeMutation__
 *
 * To run a mutation, you first call `useRiskManagementSetActionItemAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRiskManagementSetActionItemAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [riskManagementSetActionItemAssigneeMutation, { data, loading, error }] = useRiskManagementSetActionItemAssigneeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRiskManagementSetActionItemAssigneeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RiskManagementSetActionItemAssigneeMutation,
    RiskManagementSetActionItemAssigneeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RiskManagementSetActionItemAssigneeMutation,
    RiskManagementSetActionItemAssigneeMutationVariables
  >(RiskManagementSetActionItemAssigneeDocument, options);
}
export type RiskManagementSetActionItemAssigneeMutationHookResult = ReturnType<
  typeof useRiskManagementSetActionItemAssigneeMutation
>;
export type RiskManagementSetActionItemAssigneeMutationResult =
  Apollo.MutationResult<RiskManagementSetActionItemAssigneeMutation>;
export type RiskManagementSetActionItemAssigneeMutationOptions = Apollo.BaseMutationOptions<
  RiskManagementSetActionItemAssigneeMutation,
  RiskManagementSetActionItemAssigneeMutationVariables
>;
export const RiskManagementMarkActionItemCompleteDocument = gql`
  mutation RiskManagementMarkActionItemComplete($id: ID!) {
    riskManagementMarkActionItemComplete(id: $id) {
      ...ActionItemFields
    }
  }
  ${ActionItemFieldsFragmentDoc}
`;
export type RiskManagementMarkActionItemCompleteMutationFn = Apollo.MutationFunction<
  RiskManagementMarkActionItemCompleteMutation,
  RiskManagementMarkActionItemCompleteMutationVariables
>;

/**
 * __useRiskManagementMarkActionItemCompleteMutation__
 *
 * To run a mutation, you first call `useRiskManagementMarkActionItemCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRiskManagementMarkActionItemCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [riskManagementMarkActionItemCompleteMutation, { data, loading, error }] = useRiskManagementMarkActionItemCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRiskManagementMarkActionItemCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RiskManagementMarkActionItemCompleteMutation,
    RiskManagementMarkActionItemCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RiskManagementMarkActionItemCompleteMutation,
    RiskManagementMarkActionItemCompleteMutationVariables
  >(RiskManagementMarkActionItemCompleteDocument, options);
}
export type RiskManagementMarkActionItemCompleteMutationHookResult = ReturnType<
  typeof useRiskManagementMarkActionItemCompleteMutation
>;
export type RiskManagementMarkActionItemCompleteMutationResult =
  Apollo.MutationResult<RiskManagementMarkActionItemCompleteMutation>;
export type RiskManagementMarkActionItemCompleteMutationOptions = Apollo.BaseMutationOptions<
  RiskManagementMarkActionItemCompleteMutation,
  RiskManagementMarkActionItemCompleteMutationVariables
>;
export const GetPolicyConversationDocument = gql`
  query GetPolicyConversation($policyId: ID!) {
    policyConversation(policyId: $policyId) {
      ...PolicyConversationMessageFields
    }
  }
  ${PolicyConversationMessageFieldsFragmentDoc}
`;

/**
 * __useGetPolicyConversationQuery__
 *
 * To run a query within a React component, call `useGetPolicyConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyConversationQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetPolicyConversationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPolicyConversationQuery,
    GetPolicyConversationQueryVariables
  > &
    ({ variables: GetPolicyConversationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyConversationQuery, GetPolicyConversationQueryVariables>(
    GetPolicyConversationDocument,
    options
  );
}
export function useGetPolicyConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyConversationQuery,
    GetPolicyConversationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyConversationQuery, GetPolicyConversationQueryVariables>(
    GetPolicyConversationDocument,
    options
  );
}
export function useGetPolicyConversationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPolicyConversationQuery,
    GetPolicyConversationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPolicyConversationQuery, GetPolicyConversationQueryVariables>(
    GetPolicyConversationDocument,
    options
  );
}
export type GetPolicyConversationQueryHookResult = ReturnType<typeof useGetPolicyConversationQuery>;
export type GetPolicyConversationLazyQueryHookResult = ReturnType<
  typeof useGetPolicyConversationLazyQuery
>;
export type GetPolicyConversationSuspenseQueryHookResult = ReturnType<
  typeof useGetPolicyConversationSuspenseQuery
>;
export type GetPolicyConversationQueryResult = Apollo.QueryResult<
  GetPolicyConversationQuery,
  GetPolicyConversationQueryVariables
>;
export const AddPolicyConversationMessageDocument = gql`
  mutation AddPolicyConversationMessage($policyId: ID!, $message: String!) {
    riskManagementAddPolicyConversationMessage(policyId: $policyId, message: $message) {
      ...PolicyConversationMessageFields
    }
  }
  ${PolicyConversationMessageFieldsFragmentDoc}
`;
export type AddPolicyConversationMessageMutationFn = Apollo.MutationFunction<
  AddPolicyConversationMessageMutation,
  AddPolicyConversationMessageMutationVariables
>;

/**
 * __useAddPolicyConversationMessageMutation__
 *
 * To run a mutation, you first call `useAddPolicyConversationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPolicyConversationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPolicyConversationMessageMutation, { data, loading, error }] = useAddPolicyConversationMessageMutation({
 *   variables: {
 *      policyId: // value for 'policyId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddPolicyConversationMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPolicyConversationMessageMutation,
    AddPolicyConversationMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPolicyConversationMessageMutation,
    AddPolicyConversationMessageMutationVariables
  >(AddPolicyConversationMessageDocument, options);
}
export type AddPolicyConversationMessageMutationHookResult = ReturnType<
  typeof useAddPolicyConversationMessageMutation
>;
export type AddPolicyConversationMessageMutationResult =
  Apollo.MutationResult<AddPolicyConversationMessageMutation>;
export type AddPolicyConversationMessageMutationOptions = Apollo.BaseMutationOptions<
  AddPolicyConversationMessageMutation,
  AddPolicyConversationMessageMutationVariables
>;
