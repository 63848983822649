import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Spinner } from '../Spinner';

const UnstyledLink = styled.a`
  color: inherit;
  text-decoration: inherit;

  &:visited {
    color: inherit;
    text-decoration: inherit;
  }
`;

const ButtonContainerComponent = styled.div<ButtonContainerProps>`
  display: flex;
  flex-direction: row;
  gap: 0px 6px;

  width: 100%;
  justify-content: ${(props) => (props.center ? 'center' : 'left')};
`;

interface ButtonContainerProps {
  center?: boolean;
}

export const ButtonContainer = (
  props: React.PropsWithChildren<React.AllHTMLAttributes<HTMLDivElement> & ButtonContainerProps>
) => <ButtonContainerComponent {...props}>{props.children}</ButtonContainerComponent>;

const ButtonComponent = styled.button<{
  primary?: boolean;
  loading?: boolean;
}>`
  border: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  padding: 10px 15px;
  border-radius: 30px;
  user-select: none;

  font-size: 0.8em;
  font-weight: 500;

  min-width: 100px;
  justify-content: center;

  color: ${(props) => (props.primary ? 'white' : '#333333')};
  background: ${(props) => (props.primary ? '#0ea5e9' : '#fafafa')};
  border: ${(props) => (props.primary ? '1px solid transparent' : '1px solid #DADADA')};
  font-family: 'Rubik', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;

  opacity: ${(props) => (props.loading || props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.loading || props.disabled ? 'default' : 'pointer')};

  transition: all 0.15s ease-in-out;

  &:active {
    transform: ${(props) => {
      if (props.loading || props.disabled) {
        return 'none';
      }
      return 'translateY(2px)';
    }};
    background: ${(props) => {
      if (props.loading || props.disabled) {
        return props.primary ? '#0ea5e9' : '#fafafa';
      }
      return props.primary ? '#269985' : 'rgb(212, 212, 212)';
    }};
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ButtonDivComponent = styled.div<{
  primary?: boolean;
  loading?: boolean;
  disabled?: boolean;
}>`
  border: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  padding: 10px 15px;
  border-radius: 30px;
  user-select: none;

  font-size: 0.8em;
  font-weight: 500;

  color: ${(props) => (props.primary ? 'white' : '#333333')};
  background: ${(props) => (props.primary ? '#0ea5e9' : '#fafafa')};
  border: ${(props) => (props.primary ? '1px solid transparent' : '1px solid #DADADA')};
  font-family: 'Rubik', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;

  opacity: ${(props) => (props.loading || props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.loading || props.disabled ? 'default' : 'pointer')};

  transition: all 0.15s ease-in-out;

  :active {
    transform: translateY(2px);
    background: ${(props) => {
      if (props.loading || props.disabled) {
        return props.primary ? '#0ea5e9' : '#fafafa';
      }
      return props.primary ? '#269985' : 'rgb(212, 212, 212)';
    }};
  }

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
`;

interface ButtonProps {
  primary?: boolean;
  loading?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  leftIcon?: JSX.Element;
  style?: React.CSSProperties;
}

interface LinkProps {
  href: string;
  target?: string;
  onClick?: () => void;
}

export const ButtonLink = (props: React.PropsWithChildren<ButtonProps & LinkProps>) =>
  props.disabled ? (
    <Button {...props} />
  ) : props.href.match(/^https?:\/\//) ? (
    <UnstyledLink href={props.href} target={props.target}>
      <Button {...props} />
    </UnstyledLink>
  ) : (
    <Link to={props.href} style={{ textDecoration: 'none', display: 'inline-block' }}>
      <ButtonDiv {...props} />
    </Link>
  );

export const ButtonDiv = (props: React.PropsWithChildren<ButtonProps>) => (
  <ButtonDivComponent
    {...props}
    primary={props.primary}
    loading={props.loading}
    disabled={props.disabled || props.loading}
    role="button"
  >
    {props.leftIcon ? React.cloneElement(props.leftIcon, { 'aria-hidden': true }) : null}
    {props.children}
    {!props.loading && props.icon ? React.cloneElement(props.icon, { 'aria-hidden': true }) : null}
    {props.loading && <Spinner color={props.primary ? '#ffffff' : '#333333'} size={14} />}
  </ButtonDivComponent>
);

export const Button = (
  props: React.PropsWithChildren<
    ButtonProps & {
      onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown;
      onPointerUp?: (e: React.PointerEvent<HTMLButtonElement>) => unknown;
    }
  >
) => (
  <ButtonComponent
    onClick={
      !props.loading && !props.disabled ? (e) => props.onClick && props.onClick(e) : undefined
    }
    onPointerUp={(e) => !props.loading && !props.disabled && props.onPointerUp?.(e)}
    primary={props.primary ? props.primary : undefined}
    loading={props.loading ? props.loading : undefined}
    disabled={props.disabled || props.loading}
    style={props.style}
  >
    {props.leftIcon ? React.cloneElement(props.leftIcon, { 'aria-hidden': true }) : null}
    {props.children}
    {!props.loading && props.icon ? React.cloneElement(props.icon, { 'aria-hidden': true }) : null}
    {props.loading && <Spinner color={props.primary ? '#ffffff' : '#333333'} size={14} />}
  </ButtonComponent>
);

export const UnstyledButton = styled.button`
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  border: none;
  cursor: pointer;
`;

export const LinkComponent = (props: React.PropsWithChildren<LinkProps>) =>
  props.href.match(/^https?:\/\//) || props.href === '#' ? (
    <UnstyledLink href={props.href} target={props.target}>
      {props.children}
    </UnstyledLink>
  ) : (
    <Link to={props.href} target={props.target}>
      {props.children}
    </Link>
  );
