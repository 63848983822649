import clsx from 'clsx';
import React from 'react';
import { Link } from './link';

export function Text({ className, ...props }: React.ComponentPropsWithoutRef<'p'>) {
  return (
    <p
      data-slot="text"
      {...props}
      className={clsx('text-base/6 text-neutral-500 sm:text-sm/6 dark:text-neutral-400', className)}
    />
  );
}

export function TextLink({ className, ...props }: React.ComponentPropsWithoutRef<typeof Link>) {
  return (
    <Link
      {...props}
      className={clsx(
        'text-neutral-950 underline decoration-neutral-950/50 data-[hover]:decoration-neutral-950 dark:text-white dark:decoration-white/50 dark:data-[hover]:decoration-white',
        className
      )}
    />
  );
}

export function Strong({ className, ...props }: React.ComponentPropsWithoutRef<'strong'>) {
  return <strong {...props} className={clsx(className, 'font-medium  dark:text-white')} />;
}

export function Code({ className, ...props }: React.ComponentPropsWithoutRef<'code'>) {
  return (
    <code
      {...props}
      className={clsx(
        className,
        'rounded border border-neutral-950/10 bg-neutral-950/[2.5%] px-0.5 text-sm font-medium text-neutral-950 sm:text-[0.8125rem] dark:border-white/20 dark:bg-white/5 dark:text-white'
      )}
    />
  );
}
