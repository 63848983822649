import React from 'react';

import {
  addRentalAssetSerialNumber,
  createMerchantRentalAsset,
  deleteMerchantRentalAsset
} from '@oysterjs/core/api/merchant';
import { DeepPartial, RentalAsset, ValidationError } from '@oysterjs/types';
import { RentalPageContainer } from './components';
import { Table } from '@oysterjs/ui/Table';
import { Button, ButtonContainer, UnstyledButton } from '@oysterjs/ui/Button';
import { IoArrowForward, IoTrashOutline } from 'react-icons/io5';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { ErrorDisplay, TextAreaInput, TextInput } from '@oysterjs/ui/Form/text';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';
import { Banner } from '@oysterjs/ui/Banner';
import { Select } from '@oysterjs/ui/Form/select';

export const ViewRentalAssetPage = (props: {
  asset?: RentalAsset;
  assets: RentalAsset[];
  onChange: () => Promise<void>;
  onClose: () => void;
}) => {
  const [deleteSerialNumberLoading, setDeleteSerialNumberLoading] = React.useState(false);
  const [addSerialNumberLoading, setAddSerialNumberLoading] = React.useState(false);
  const [serialNumber, setSerialNumber] = React.useState<string>('');
  const [error, setError] = React.useState<string>();

  const asset = props.asset;
  if (!asset) {
    return null;
  }

  // Find all non-deleted assets whose name matches and does not have an
  // empty serial number.
  const serialNumberAssets = props.assets.filter(
    (a) => a.Name === asset.Name && !!a.Details.SerialNumber
  );

  const deleteAsset = async (assetId: string) => {
    if (deleteSerialNumberLoading) {
      return;
    }

    setDeleteSerialNumberLoading(true);

    try {
      await deleteMerchantRentalAsset(assetId);
      await props.onChange();

      setError(undefined);
    } catch (e) {
      setError(WrappedError.asWrappedError(e).message);
    } finally {
      setDeleteSerialNumberLoading(false);
    }
  };

  const addSerialNumber = async () => {
    if (addSerialNumberLoading) {
      return;
    }

    setAddSerialNumberLoading(true);

    try {
      await addRentalAssetSerialNumber(asset.ID, serialNumber);
      await props.onChange();

      setSerialNumber('');
      setError(undefined);
    } catch (e) {
      setError(WrappedError.asWrappedError(e).message);
    } finally {
      setAddSerialNumberLoading(false);
    }
  };

  return (
    <RentalPageContainer title={asset.Name} description={asset.Description}>
      <Table style={{ textAlign: 'left' }}>
        <thead>
          <tr>
            <th style={{ width: '100%' }}>Serial Number</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {serialNumberAssets.map((a) => (
            <tr key={a.ID}>
              <td>{a.Details.SerialNumber}</td>
              <td>
                <UnstyledButton
                  onClick={() => deleteAsset(a.ID)}
                  disabled={deleteSerialNumberLoading}
                >
                  <IoTrashOutline />
                </UnstyledButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <FormContainer>
        <FormRowHeader
          title="Add serial number"
          description="Add another serial number for this asset"
        />
        <div style={{ display: 'flex', gap: '10px', paddingBottom: '20px' }}>
          <div style={{ flex: '1 1 0' }}>
            <TextInput
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.currentTarget.value)}
              disabled={addSerialNumberLoading}
            />
          </div>
          <div style={{ minWidth: '120px' }}>
            <Button
              primary
              onClick={addSerialNumber}
              disabled={!serialNumber}
              loading={addSerialNumberLoading}
            >
              Add Serial Number
            </Button>
          </div>
        </div>
        {error && <ErrorDisplay>{error}</ErrorDisplay>}
      </FormContainer>
    </RentalPageContainer>
  );
};

export const CreateRentalAssetPage = (props: {
  onCreate: (asset: RentalAsset) => Promise<void>;
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [asset, _setAsset] = React.useState<DeepPartial<RentalAsset>>({});

  const setAsset = (field: string, fn: React.SetStateAction<DeepPartial<RentalAsset>>) => {
    if (validationError?.Field === field) {
      setValidationError(undefined);
    }

    _setAsset(fn);
  };

  const addAsset = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const createdAsset = await createMerchantRentalAsset(asset);
      await props.onCreate(createdAsset);
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      if (err.type() === ErrorType.validationError) {
        setValidationError(err.getValidationError());
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <RentalPageContainer title="Add Asset" description="Add a new asset to your inventory.">
      <>
        <Banner
          title="Important"
          description="This will create a new asset. If you would like to add a serial number for an existing asset, click that asset in the Asset Manager and add the serial number there."
        />
        <p>
          An asset is defined as{' '}
          <b>all items in your inventory of the same type, model, and value</b>. For example, if you
          have ten (10) "Aventon Level.2" bikes, you would create one asset here with quantity "10"
          add each bike's serial number in the next step.
        </p>
        <FormContainer style={{ padding: '20px 0px' }} onSubmit={(e) => e.preventDefault()}>
          <FormRow>
            <FormColumn title="Type" description="Choose the type of the asset">
              <Select
                options={[
                  { value: '' },
                  { value: 'Bicycle', displayValue: 'Bike/eBike' },
                  { value: 'Kayak' },
                  { value: 'Paddleboard' }
                ]}
                error={validationError?.Field === 'Type' && validationError.Message}
                onChange={(value) => setAsset('Type', (prev) => ({ ...prev, Type: value }))}
              />
            </FormColumn>
            <FormColumn title="Name" description='Name of the asset, e.g. "Aventon Level.2"'>
              <TextInput
                error={validationError?.Field === 'Name' && validationError.Message}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setAsset('Name', (prev) => ({ ...prev, Name: value }));
                }}
              />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn title="Value" description="Cost to replace a single unit of this asset">
              <TextInput
                currency
                error={validationError?.Field === 'Value' && validationError.Message}
                onChange={(e) => {
                  const value = parseFloat(e.currentTarget.value);
                  setAsset('Value', (prev) => ({
                    ...prev,
                    Value: isNaN(value) ? undefined : value
                  }));
                }}
              />
            </FormColumn>
            <FormColumn title="Quantity" description="The number of distinct units of this asset">
              <TextInput error={validationError?.Field === 'Quantity' && validationError.Message} />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn title="Asset Description" description="Optional description of the asset">
              <TextAreaInput
                error={validationError?.Field === 'Description' && validationError.Message}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setAsset('Description', (prev) => ({ ...prev, Description: value }));
                }}
              />
            </FormColumn>
          </FormRow>

          <ButtonContainer>
            <Button primary icon={<IoArrowForward />} onClick={addAsset} loading={loading}>
              Add Asset
            </Button>
          </ButtonContainer>
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </FormContainer>
      </>
    </RentalPageContainer>
  );
};
