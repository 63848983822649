import {
  AccountSummary,
  AttachmentFile,
  Policy,
  Product,
  Statement,
  UserReferral,
  UserVerification
} from '@oysterjs/types';
import { Delete, Get, getEncodedQueryString, Post } from './base';
import { CreatePolicyOptions } from './sdk';
import config from '@oysterjs/core/config';

export const getPolicies = () => Get<{ Policies: Policy[] }>('/user/policies');

export const getPolicy = (policyId: string) =>
  Get<{ Policy: Policy }>(`/user/policies/${policyId}`);

export const userCreatePolicy = (products: Product[], opts?: CreatePolicyOptions) =>
  Post<{ Policy: Policy }>(
    `/user/policy?${getEncodedQueryString({
      AllowValidationErrors: opts?.allowValidationErrors
    })}`,
    {
      Products: products,
      Insured: opts?.insured,
      ReferralChannelInfo: opts?.referralChannel
    },
    {
      headers: {
        'X-Merchant-API-Key': opts?.merchantApiKey || config().merchant?.apiKey || '',
        'X-Merchant-Integration-ID': opts?.integrationID || ''
      }
    }
  );

export const getAccountSummary = (redirectUrl?: string) =>
  Get<AccountSummary>('/user/account', { redirectUrl });

export const confirmAccount = (redirectUrl?: string) =>
  Post<AccountSummary>('/user/account/confirm', undefined, { redirectUrl });

export const getAccountStatement = (statementId: string) =>
  Get<{ Statement: Statement }>(`/user/account/statements/${statementId}`);

export const retrieveVerificationSession = () =>
  Post<{ VerificationSession: UserVerification }>('/user/account/verifications');

export const deletePaymentMethod = (id: string) => Delete(`/user/account/payment_methods/${id}`);

export const makePaymentMethodDefault = (id: string) =>
  Post(`/user/account/payment_methods/${id}/default`);

export const createPaymentMethod = () =>
  Post<{ StripeClientSecret: string }>('/user/account/payment_methods').then(
    (d) => d.StripeClientSecret
  );

export const getReferralList = () =>
  Get<{ Referrals: UserReferral[] }>('/user/referrals').then((d) => d.Referrals || []);

export const expressInterest = (product: string) => Post('/user/interest', { Product: product });

export const userCreatePolicyAttachments = (policyId: string, fileRole: string, files: File[]) => {
  const form = new FormData();
  files.forEach((file) => form.append('files[]', file));
  form.append('FileRole', fileRole);
  return Post<{ Metadata: AttachmentFile[] }>(`/user/policy/${policyId}/attach`, undefined, {
    body: form
  }).then((d) => d.Metadata || []);
};

export const userGetPaymentSession = (policyId: string): Promise<string> =>
  Post<{ StripeClientSecret: string }>(`/user/policies/${policyId}/payment`).then((d) => {
    return d.StripeClientSecret;
  });

export const userCompletePolicy = (policyId: string) =>
  Post<{ Policy: Policy; Redirect?: string }>(`/user/policies/${policyId}/complete`, undefined, {
    headers: {
      'X-Merchant-API-Key': config().merchant?.apiKey || ''
    }
  });

export const userIdentify = (email?: string, firstName?: string, lastName?: string) =>
  Post<{ Token: string; Email: string }>(`/user/identify`, {
    Email: email,
    FirstName: firstName,
    LastName: lastName
  });
