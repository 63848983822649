import { loadStripe, Stripe } from '@stripe/stripe-js';
import config from './config';

const STRIPE_PUBLIC_KEY = () => config().secrets.stripePublicKey || '';
const COTERIE_STRIPE_PUBLIC_KEY = () => config().secrets.coterieStripePublicKey || '';

const stripe = loadStripe(STRIPE_PUBLIC_KEY(), {
  apiVersion: '2020-08-27'
});

export default (): Promise<Stripe | null> => stripe;

export const loadCoterieStripe = (): Promise<Stripe | null> =>
  loadStripe(COTERIE_STRIPE_PUBLIC_KEY(), {
    apiVersion: '2020-08-27'
  });
