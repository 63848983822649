import styled from 'styled-components';

const ProgressContainer = styled.div`
  margin: 30px 0px 30px 0px;
  position: relative;
`;

const ProgressBarContainer = styled.div``;

const ProgressBarTrack = styled.div`
  background: #eaeaea;
  width: 100%;
  height: 6px;
  border-radius: 4px;
`;

const ProgressBarInner = styled.div`
  height: 100%;
  background: #0ea5e9;
  border-radius: 4px;
  transition: all 0.15s ease-in;
`;

const ProgressStepContainer = styled.div`
  position: absolute;
  top: -16px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ProgressStep = styled.div<{ filled: boolean }>`
  height: 32px;
  width: 32px;
  transform: scale(1);
  border-radius: 50%;
  background: ${(props) => (props.filled ? '#0EA5E9' : '#eaeaea')};
  transition: all 0.15s ease-in;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a,
  svg {
    display: block;
    font-size: 1em;
    color: ${(props) => (props.filled ? 'white' : '#666666')};
    transition: all 0.15s ease-in;
  }
`;

const ProgressBar = (props: { percentage: number }) => (
  <ProgressBarContainer>
    <ProgressBarTrack>
      <ProgressBarInner style={{ width: `${props.percentage.toFixed(0)}%` }} />
    </ProgressBarTrack>
  </ProgressBarContainer>
);

interface ProgressProps {
  steps: number | JSX.Element[];
  currentStep: number;
}

export const Progress = (props: ProgressProps): JSX.Element => {
  const numSteps = (() => {
    if (Number.isFinite(props.steps)) {
      return props.steps as number;
    }
    if (Array.isArray(props.steps)) {
      return (props.steps as JSX.Element[]).length;
    }
    // impossible?
    return 0;
  })();

  const steps = (() => {
    if (Number.isFinite(props.steps)) {
      return [...Array(numSteps).keys()].map(() => <></>);
    }
    if (Array.isArray(props.steps)) {
      return props.steps as JSX.Element[];
    }
    // impossible?
    return [];
  })();

  return (
    <ProgressContainer aria-hidden>
      <ProgressBar percentage={(100 * props.currentStep) / (numSteps - 1)} />
      <ProgressStepContainer>
        {steps.map((step, i) => (
          <ProgressStep
            key={i}
            filled={i <= props.currentStep}
            style={{
              left: `${(100 * i) / (numSteps - 1)}%`
            }}
          >
            {step}
          </ProgressStep>
        ))}
      </ProgressStepContainer>
    </ProgressContainer>
  );
};
