export type Environment = 'production' | 'staging' | 'dev' | 'local';

export type Service = 'getoyster' | 'dashboard' | 'partners' | 'oysterjs' | 'webhooks';
export type Backend = 'admin' | 'api' | 'merchant' | 'integrate' | 'metrics' | 'statics';

const getEnvironment = (): Environment => {
  if (typeof window === 'undefined') {
    return 'local';
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const environment = process.env.OYSTER_ENVIRONMENT || window?.oyster?.opts?.environment;
  switch (environment) {
    case 'production':
    case 'staging':
    case 'dev':
    case 'local':
      return environment;
    default:
      return 'local';
  }
};

const getApiBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://api.withoyster.com';
    case 'staging':
      return 'https://api.staging.withoyster.com';
    case 'dev':
      return devServiceUrl('api');
    case 'local':
      return 'http://localhost:8080';
  }
};

const getAdminBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://admin.oysterinc.net';
    case 'staging':
      return 'https://admin.staging.oysterinc.net';
    case 'dev':
      return devServiceUrl('admin');
    case 'local':
      return 'http://localhost:8080';
  }
};

const getMerchantBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://merchant.withoyster.com';
    case 'staging':
      return 'https://merchant.staging.withoyster.com';
    case 'dev':
      return devServiceUrl('merchant');
    case 'local':
      return 'http://localhost:8084';
  }
};

const getMetricsBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://mtx.withoyster.com';
    case 'staging':
      return 'https://mtx.staging.withoyster.com';
    case 'dev':
      return devServiceUrl('metrics');
    case 'local':
      return 'http://localhost:8083';
  }
};

const getStaticsBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://s.withoyster.com';
    case 'staging':
      return 'https://s.staging.withoyster.com';
    case 'dev':
      return devServiceUrl('statics');
    case 'local':
      return 'http://localhost:8081';
  }
};

const getIntegrateBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://integrate.withoyster.com';
    case 'staging':
      return 'https://integrate.staging.withoyster.com';
    case 'dev':
      return devServiceUrl('integrate');
    case 'local':
      return 'http://localhost:8085';
  }
};

const getOysterJsBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://js.withoyster.com';
    case 'staging':
      return 'https://js.staging.withoyster.com';
    case 'dev':
      return devServiceUrl('oysterjs');
    case 'local':
      return 'http://localhost:8001';
  }
};

const getDashboardBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://dashboard.withoyster.com';
    case 'staging':
      return 'https://dashboard.staging.withoyster.com';
    case 'dev':
      return devServiceUrl('dashboard');
    case 'local':
      return 'http://localhost:8180';
  }
};

const getGetOysterBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://get.withoyster.com';
    case 'staging':
      return 'https://get.staging.withoyster.com';
    case 'dev':
      return devServiceUrl('getoyster');
    case 'local':
      return 'http://localhost:8181';
  }
};

const getPartnersBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://partners.withoyster.com';
    case 'staging':
      return 'https://partners.staging.withoyster.com';
    case 'dev':
      return devServiceUrl('partners');
    case 'local':
      return 'http://localhost:8182';
  }
};

const getWebhooksBaseUrl = (): string => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://webhooks.withoyster.com';
    case 'staging':
      return 'https://webhooks.staging.withoyster.com';
    case 'dev':
      return devServiceUrl('webhooks');
    case 'local':
      return 'http://localhost:8080';
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getServiceName = (): Service => (process.env.SERVICE_NAME as Service) || '';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getServiceVersion = (): string => process.env.SERVICE_VERSION || '';

const devServiceUrl = (service: string) => {
  // Get the user from the URL
  const [, user] = window.location.host.split('.');

  // Host will be in the format of {service}.{user}.dev.oysterinc.net
  return `https://${service}.${user}.dev.oysterinc.net`;
};

const getSecret = (secretName: string, devSecret?: string) =>
  devSecret || window?.['oyster']?.['opts']?.[secretName];

// Configuration defines the various configuration values that can
// be set on the app
type StaticConfiguration = {
  readonly serviceName: Service;
  readonly serviceVersion: string;
  readonly environment: Environment;
  readonly serviceBaseUrl: { [K in Service]: string };
  readonly backendBaseUrl: { [K in Backend]: string };
  readonly secrets: {
    readonly mapboxPublicKey?: string;
    readonly shopifyPublicKey?: string;
    readonly stripePublicKey?: string;
    readonly coterieStripePublicKey?: string;
    readonly sentryDsn?: string;
  };
};

type DynamicConfiguration = {
  readonly merchant?: {
    readonly id?: string;
    readonly apiKey?: string;
    readonly integrationId?: string;
  };
};

let dynamicConfig: DynamicConfiguration = {};
export const configure = (opts: DynamicConfiguration) => {
  dynamicConfig = opts;
};

export type Configuration = StaticConfiguration & DynamicConfiguration;

// config builds and exports the configuration for usage.
export default (): Configuration => ({
  serviceName: getServiceName(),
  serviceVersion: getServiceVersion(),
  environment: getEnvironment(),
  serviceBaseUrl: {
    dashboard: getDashboardBaseUrl(),
    getoyster: getGetOysterBaseUrl(),
    partners: getPartnersBaseUrl(),
    oysterjs: getOysterJsBaseUrl(),
    webhooks: getWebhooksBaseUrl()
  },
  backendBaseUrl: {
    admin: getAdminBaseUrl(),
    api: getApiBaseUrl(),
    merchant: getMerchantBaseUrl(),
    integrate: getIntegrateBaseUrl(),
    metrics: getMetricsBaseUrl(),
    statics: getStaticsBaseUrl()
  },
  secrets: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mapboxPublicKey: getSecret('mapboxPublicKey', process.env.MAPBOX_PUBLIC_KEY),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    shopifyPublicKey: getSecret('shopifyPublicKey', process.env.SHOPIFY_PUBLIC_KEY),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    stripePublicKey: getSecret('stripePublicKey', process.env.STRIPE_PUBLIC_KEY),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    coterieStripePublicKey: getSecret(
      'coterieStripePublicKey',
      process.env.COTERIE_STRIPE_PUBLIC_KEY
    ),
    sentryDsn: process.env.SENTRY_DSN
  },
  ...dynamicConfig
});
