import * as React from 'react';
import styled from 'styled-components';

const BadgeContainer = styled.div<{ color: string }>`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 4px 12px;
  background: ${(props) => props.color};
  box-sizing: border-box;
  font-size: 0.75em;
  font-weight: 500;
  color: #333333;
`;

export const Badge = (
  props: React.PropsWithChildren<{
    icon?: JSX.Element;
    label: string;
    color: string;
    textColor?: string;
    display?: string;
  }>
) => (
  <div style={{ display: props.display || 'inline-block' }}>
    <BadgeContainer color={props.color}>
      {props.icon && (
        <div aria-hidden style={{ display: 'flex' }}>
          {props.icon}
        </div>
      )}
      <div style={{ color: props.textColor }}>{props.label}</div>
    </BadgeContainer>
  </div>
);
