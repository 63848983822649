import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(250, 250, 250, 0.8);
  z-index: 999;

  &.slideout-enter {
    opacity: 0;
  }
  &.slideout-enter-active {
    opacity: 1;
    transition: 0.3s opacity ease;
  }
  &.slideout-exit {
    opacity: 1;
  }
  &.slideout-exit-active {
    opacity: 0;
    transition: 0.3s opacity ease;
  }

  @media (min-width: 660px) {
    &.slideout-enter .slideout-container {
      right: -60px;
    }
    &.slideout-enter-active .slideout-container {
      right: 0px;
      transition: 0.3s right ease;
    }
    &.slideout-exit .slideout-container {
      right: 0px;
    }
    &.slideout-exit-active .slideout-container {
      right: -60px;
      transition: 0.3s right ease;
    }
  }

  @media (max-width: 660px) {
    &.slideout-enter .slideout-container {
      top: 100%;
    }
    &.slideout-enter-active .slideout-container {
      top: 0px;
      transition: 0.3s top ease;
    }
    &.slideout-exit .slideout-container {
      top: 0px;
    }
    &.slideout-exit-active .slideout-container {
      top: 100%;
      transition: 0.3s top ease;
    }
  }
`;

const SlideOutContainer = styled.div<{ wide?: boolean }>`
  background: white;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  width: ${(props) => (props.wide ? '1000px' : '660px')};
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 660px) {
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  padding: 0px 20px;
  box-sizing: border-box;
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 10px;
`;

const HeaderButtonContainer = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #666666;
  transition: all 0.1s ease-in-out;

  &.clickable:hover {
    cursor: pointer;
    background-color: #666666;
    color: white;
  }
`;

const Header = (props: { title: string; onBack?: () => void; onClose?: () => void }) => (
  <HeaderContainer>
    <div
      style={{
        display: 'flex',
        flex: '1 0 auto',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <div>{props.title}</div>
      <HeaderButtonContainer
        onClick={props.onClose}
        className={props.onClose ? 'clickable' : undefined}
      >
        <IoClose />
      </HeaderButtonContainer>
    </div>
  </HeaderContainer>
);

const ContentBox = styled.div`
  position: absolute;
  top: 60px;
  padding: 20px 20px;
  height: calc(100% - 60px);
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;

  @media (max-width: 500px) {
    padding: 20px 0px;
  }
`;

interface SlideOutProps {
  title?: string;
  showing?: boolean;
  wide?: boolean;
  onClose: () => void;
  onBack?: () => void;
}

export const SlideOut = (
  props: React.PropsWithChildren<React.AllHTMLAttributes<HTMLDivElement> & SlideOutProps>
) => {
  const [overflowState, setOverflowState] = React.useState(document.body.style.overflow);

  React.useEffect(() => {
    if (props.showing) {
      setOverflowState(document.body.style.overflow);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = overflowState;
    }

    return () => {
      document.body.style.overflow = overflowState;
    };
  }, [props.showing]);

  return (
    <CSSTransition
      component={null}
      unmountOnExit
      in={props.showing}
      timeout={500}
      classNames="slideout"
    >
      <Overlay onClick={props.onClose}>
        <SlideOutContainer
          wide={props.wide}
          onClick={(e) => e.stopPropagation()}
          className="slideout-container"
        >
          <Header title={props.title || ''} onClose={props.onClose} onBack={props.onBack} />
          <ContentBox>{props.children}</ContentBox>
        </SlideOutContainer>
      </Overlay>
    </CSSTransition>
  );
};
