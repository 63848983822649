import styled from 'styled-components';
import {
  InStoreSalesChannelType,
  Merchant,
  OnlineSalesChannelType,
  Personalization,
  RentalProductType,
  SalesChannelType,
  ValidationError
} from '@oysterjs/types';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { Checkbox, CheckboxProps } from '@oysterjs/ui/Form/checkbox';
import { Radio, RadioProps } from '@oysterjs/ui/Form/radio';
import { TextInput } from '@oysterjs/ui/Form/text';
import { OptionGroup } from '@oysterjs/ui/Form/option';
import { Select } from '@oysterjs/ui/Form/select';

export interface PageProps {
  merchant: Merchant;
  onNext: () => Promise<void>;
  onBack: ((e) => unknown) | null;
  onUpdate: (merchant: Merchant) => void;
  validationError?: ValidationError;
  loading: boolean;
}

export const NColumnListContainer = styled.div<{ columns: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  & > * {
    flex: 1 0
      calc(
        ${(props) => Math.floor(100 / props.columns)}% -
          ${(props) => Math.ceil(8 / props.columns)}px
      );
  }
`;

export const LabeledCheckbox = (props: CheckboxProps) => (
  <div style={{ display: 'flex', gap: '8px', cursor: 'pointer' }} onClick={props.onChange}>
    <Checkbox {...props} />
    <div>{props.label}</div>
  </div>
);

const LabeledRadio = (props: RadioProps) => (
  <div style={{ display: 'flex', gap: '8px', cursor: 'pointer' }} onClick={props.onChange}>
    <Radio {...props} />
    <div>{props.label}</div>
  </div>
);

export const MerchantPersonalizationProductInsuranceForm = (props: PageProps) => {
  const updatePersonalization = (fn: (prev: Personalization) => Personalization) =>
    props.onUpdate({
      ...props.merchant,
      BusinessProfile: {
        ...props.merchant.BusinessProfile,
        Personalization: fn(props.merchant.BusinessProfile.Personalization)
      }
    });

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        props.onNext();
      }}
    >
      <FormRowHeader
        title="Where do your customers buy your products?"
        description="Choose one or more media through which you sell your products."
      />
      <FormRow
        error={props.validationError?.Field === 'SalesChannels' && props.validationError?.Message}
      >
        <FormColumn>
          <OptionGroup
            name="where-sell"
            multi
            options={[
              {
                title: 'Online',
                description:
                  'You accept orders online either using a custom e-commerce platform or an off-the-shelf one like Shopify or WooCommerce.',
                enabled: props.merchant.BusinessProfile.Personalization.SalesChannels?.includes(
                  SalesChannelType.Online
                ),
                onSelect: () =>
                  updatePersonalization((prev) => ({
                    ...prev,
                    SalesChannels: !prev.SalesChannels?.includes(SalesChannelType.Online)
                      ? [...(prev.SalesChannels || []), SalesChannelType.Online]
                      : prev.SalesChannels.filter((v) => v !== SalesChannelType.Online)
                  }))
              },
              {
                title: 'Owned Retail',
                description:
                  'You accept orders in-store with a physical point-of-sale system, like Lightspeed.',
                enabled: props.merchant.BusinessProfile.Personalization.SalesChannels?.includes(
                  SalesChannelType.InStore
                ),
                onSelect: () =>
                  updatePersonalization((prev) => ({
                    ...prev,
                    SalesChannels: !prev.SalesChannels?.includes(SalesChannelType.InStore)
                      ? [...(prev.SalesChannels || []), SalesChannelType.InStore]
                      : prev.SalesChannels.filter((v) => v !== SalesChannelType.InStore)
                  }))
              },
              {
                title: 'Dealer',
                description: 'Your sales occur through authorized dealers or third-parties.',
                enabled: props.merchant.BusinessProfile.Personalization.SalesChannels?.includes(
                  SalesChannelType.Dealer
                ),
                onSelect: () =>
                  updatePersonalization((prev) => ({
                    ...prev,
                    SalesChannels: !prev.SalesChannels?.includes(SalesChannelType.Dealer)
                      ? [...(prev.SalesChannels || []), SalesChannelType.Dealer]
                      : prev.SalesChannels.filter((v) => v !== SalesChannelType.Dealer)
                  }))
              },
              {
                title: 'Rental',
                description: 'Some of your products are available to rent or lease.',
                enabled: props.merchant.BusinessProfile.Personalization.SalesChannels?.includes(
                  SalesChannelType.Rental
                ),
                onSelect: () =>
                  updatePersonalization((prev) => ({
                    ...prev,
                    SalesChannels: !prev.SalesChannels?.includes(SalesChannelType.Rental)
                      ? [...(prev.SalesChannels || []), SalesChannelType.Rental]
                      : prev.SalesChannels.filter((v) => v !== SalesChannelType.Rental)
                  }))
              }
            ]}
          />
        </FormColumn>
      </FormRow>
      {props.merchant.BusinessProfile.Personalization.SalesChannels?.includes(
        SalesChannelType.Online
      ) && (
        <>
          <FormRowHeader
            title="Which online sales platform do you use?"
            description="Choose the platform you use to sell products online."
          />
          <FormRow>
            <FormColumn>
              <Select
                options={[
                  { value: '' },
                  { value: OnlineSalesChannelType.Shopify },
                  { value: OnlineSalesChannelType.WooCommerce },
                  { value: OnlineSalesChannelType.Lightspeed },
                  { value: OnlineSalesChannelType.BigCommerce },
                  { value: OnlineSalesChannelType.Adobe },
                  { value: OnlineSalesChannelType.Custom },
                  { value: OnlineSalesChannelType.Other }
                ]}
                value={props.merchant.BusinessProfile.Personalization.OnlineSalesChannelType}
                onChange={(v) =>
                  updatePersonalization((prev) => ({
                    ...prev,
                    OnlineSalesChannelType: v
                  }))
                }
                error={
                  props.validationError?.Field === 'OnlineSalesChannelType' &&
                  props.validationError?.Message
                }
                style={{ maxWidth: '360px' }}
              />
            </FormColumn>
          </FormRow>
        </>
      )}
      {props.merchant.BusinessProfile.Personalization.SalesChannels?.includes(
        SalesChannelType.Online
      ) &&
        props.merchant.BusinessProfile.Personalization.OnlineSalesChannelType ===
          OnlineSalesChannelType.Other && (
          <FormRow>
            <FormColumn title="Enter the name of your online sales platform">
              <TextInput
                placeholder="Shopify, Lightspeed, etc."
                error={
                  props.validationError?.Field === 'OnlineSalesChannelName' &&
                  props.validationError?.Message
                }
                value={props.merchant.BusinessProfile.Personalization.OnlineSalesChannelName}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  updatePersonalization((prev) => ({
                    ...prev,
                    OnlineSalesChannelName: value
                  }));
                }}
                style={{ maxWidth: '360px' }}
              />
            </FormColumn>
          </FormRow>
        )}
      {props.merchant.BusinessProfile.Personalization.SalesChannels?.includes(
        SalesChannelType.Online
      ) &&
        props.merchant.BusinessProfile.Personalization.OnlineSalesChannelType ===
          OnlineSalesChannelType.Shopify && (
          <FormRow>
            <FormColumn title="Enter your Shopify admin URL">
              <TextInput
                placeholder="store-name.myshopify.com"
                error={
                  props.validationError?.Field === 'ShopifyAdminURL' &&
                  props.validationError?.Message
                }
                value={props.merchant.BusinessProfile.Personalization.ShopifyAdminURL}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  updatePersonalization((prev) => ({
                    ...prev,
                    ShopifyAdminURL: value
                  }));
                }}
                style={{ maxWidth: '360px' }}
              />
            </FormColumn>
          </FormRow>
        )}
      {props.merchant.BusinessProfile.Personalization.SalesChannels?.includes(
        SalesChannelType.InStore
      ) && (
        <>
          <FormRowHeader
            title="Which in-store sales platform do you use?"
            description="Choose the platform you use to sell products in-store."
          />
          <FormRow>
            <FormColumn>
              <Select
                options={[
                  { value: '' },
                  { value: InStoreSalesChannelType.ShopifyPOS },
                  { value: InStoreSalesChannelType.LightspeedRSeries },
                  { value: InStoreSalesChannelType.LightspeedXSeries },
                  { value: InStoreSalesChannelType.Ascend },
                  { value: InStoreSalesChannelType.Edge },
                  { value: InStoreSalesChannelType.Other }
                ]}
                value={props.merchant.BusinessProfile.Personalization.InStoreSalesChannelType}
                onChange={(v) =>
                  updatePersonalization((prev) => ({
                    ...prev,
                    InStoreSalesChannelType: v
                  }))
                }
                error={
                  props.validationError?.Field === 'InStoreSalesChannelType' &&
                  props.validationError?.Message
                }
                style={{ maxWidth: '360px' }}
              />
            </FormColumn>
          </FormRow>
        </>
      )}
      {props.merchant.BusinessProfile.Personalization.SalesChannels?.includes(
        SalesChannelType.InStore
      ) &&
        props.merchant.BusinessProfile.Personalization.InStoreSalesChannelType ===
          InStoreSalesChannelType.Other && (
          <FormRow>
            <FormColumn title="Enter the name of your in-store sales platform">
              <TextInput
                placeholder="Lightspeed, etc."
                error={
                  props.validationError?.Field === 'InStoreSalesChannelName' &&
                  props.validationError?.Message
                }
                value={props.merchant.BusinessProfile.Personalization.InStoreSalesChannelName}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  updatePersonalization((prev) => ({
                    ...prev,
                    InStoreSalesChannelName: value
                  }));
                }}
                style={{ maxWidth: '360px' }}
              />
            </FormColumn>
          </FormRow>
        )}
      {props.merchant.BusinessProfile.Personalization.SalesChannels?.includes(
        SalesChannelType.Rental
      ) && (
        <FormRow>
          <FormColumn title="Enter the name of your rental platform">
            <TextInput
              placeholder="Peek Pro, Booqable, etc."
              error={
                props.validationError?.Field === 'RentalChannelName' &&
                props.validationError?.Message
              }
              value={props.merchant.BusinessProfile.Personalization.RentalChannelName}
              onChange={(e) => {
                const value = e.currentTarget.value;
                updatePersonalization((prev) => ({
                  ...prev,
                  RentalChannelName: value
                }));
              }}
              style={{ maxWidth: '360px' }}
            />
          </FormColumn>
        </FormRow>
      )}
      <FormRowHeader
        title="Annual retail revenue"
        description="Approximately how much revenue does your business do through retail sales each year?"
      />
      <FormRow>
        <FormColumn style={{ maxWidth: '180px' }}>
          <TextInput
            error={
              props.validationError?.Field === 'ProductAnnualRevenue' &&
              props.validationError?.Message
            }
            value={props.merchant.BusinessProfile.Personalization.ProductAnnualRevenue}
            onChange={(e) => {
              updatePersonalization((personalization) => ({
                ...personalization,
                ProductAnnualRevenue: e.currentTarget.value
              }));
            }}
          />
        </FormColumn>
      </FormRow>
    </FormContainer>
  );
};

export const MerchantPersonalizationRentalInsuranceForm = (props: PageProps) => {
  const updatePersonalization = (fn: (prev: Personalization) => Personalization) =>
    props.onUpdate({
      ...props.merchant,
      BusinessProfile: {
        ...props.merchant.BusinessProfile,
        Personalization: fn(props.merchant.BusinessProfile.Personalization)
      }
    });

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        props.onNext();
      }}
    >
      <FormRowHeader
        title="What types of products do you rent?"
        description="Select all types of products you rent, excluding accessories (e.g. helmets, locks, etc.)"
      />
      <FormRow
        error={
          props.validationError?.Field === 'RentalProductTypes' && props.validationError.Message
        }
      >
        <NColumnListContainer columns={1}>
          {[
            RentalProductType.BikesAndEbikes,
            RentalProductType.Kayaks,
            RentalProductType.Paddleboards,
            RentalProductType.Other
          ].map((operation) => (
            <LabeledCheckbox
              key={operation}
              label={operation}
              checked={props.merchant.BusinessProfile.Personalization.RentalProductTypes?.includes(
                operation
              )}
              onChange={() => {
                const checked =
                  props.merchant.BusinessProfile.Personalization.RentalProductTypes?.includes(
                    operation
                  );
                if (checked) {
                  updatePersonalization((personalization) => ({
                    ...personalization,
                    RentalProductTypes: (personalization.RentalProductTypes || []).filter(
                      (o) => o !== operation
                    )
                  }));
                } else {
                  updatePersonalization((personalization) => ({
                    ...personalization,
                    RentalProductTypes: [...(personalization.RentalProductTypes || []), operation]
                  }));
                }
              }}
            />
          ))}
        </NColumnListContainer>
      </FormRow>
      {props.merchant.BusinessProfile.Personalization.RentalProductTypes?.includes(
        RentalProductType.Other
      ) && (
        <FormRow>
          <FormColumn title="Describe the other products you rent">
            <TextInput
              placeholder="Scooters, Mopeds, etc."
              error={
                props.validationError?.Field === 'RentalProductTypeOtherDesc' &&
                props.validationError?.Message
              }
              value={props.merchant.BusinessProfile.Personalization.RentalProductTypeOtherDesc}
              onChange={(e) => {
                const value = e.currentTarget.value;
                updatePersonalization((prev) => ({
                  ...prev,
                  RentalProductTypeOtherDesc: value
                }));
              }}
              style={{ maxWidth: '360px' }}
            />
          </FormColumn>
        </FormRow>
      )}
      {props.merchant.BusinessProfile.Personalization.RentalProductTypes?.some((productType) =>
        [RentalProductType.BikesAndEbikes, RentalProductType.Other].includes(productType)
      ) && (
        <>
          <FormRowHeader
            title="Do any rental assets exceed the specifications of class 1, 2, or 3 ebikes?"
            description="This only applies to motorized assets, such as bikes, scooters, mopeds, etc."
          />
          <FormRow
            error={
              props.validationError?.Field === 'RentalMotorizedAssetsExceedEBikeClassification' &&
              props.validationError.Message
            }
          >
            <FormColumn>
              <NColumnListContainer columns={1}>
                <LabeledRadio
                  name="RentalMotorizedAssetsExceedEBikeClassification"
                  label="Yes, some assets exceed those specifications."
                  checked={
                    props.merchant.BusinessProfile.Personalization
                      .RentalMotorizedAssetsExceedEBikeClassification === true
                  }
                  onChange={() =>
                    updatePersonalization((personalization) => ({
                      ...personalization,
                      RentalMotorizedAssetsExceedEBikeClassification: true
                    }))
                  }
                />
                <LabeledRadio
                  name="RentalMotorizedAssetsExceedEBikeClassification"
                  label="No, either we don't rent any motorized assets or they all meet the specifications."
                  checked={
                    props.merchant.BusinessProfile.Personalization
                      .RentalMotorizedAssetsExceedEBikeClassification === false
                  }
                  onChange={() =>
                    updatePersonalization((personalization) => ({
                      ...personalization,
                      RentalMotorizedAssetsExceedEBikeClassification: false
                    }))
                  }
                />
              </NColumnListContainer>
            </FormColumn>
          </FormRow>
        </>
      )}
      <FormRowHeader title="Describe your rentals" />
      <FormRow breakMobile>
        <FormColumn
          title="Maximum asset value"
          description="What is the maximum value of your rental assets, not including accesories?"
        >
          <TextInput
            error={
              props.validationError?.Field === 'RentalMaximumAssetValue' &&
              props.validationError?.Message
            }
            value={props.merchant.BusinessProfile.Personalization.RentalMaximumAssetValue}
            onChange={(e) => {
              const value = e.currentTarget.value;
              updatePersonalization((prev) => ({
                ...prev,
                RentalMaximumAssetValue: value
              }));
            }}
          />
        </FormColumn>
        <FormColumn
          title="Average asset value"
          description="What is the average value of your rental assets, not including accessories?"
        >
          <TextInput
            error={
              props.validationError?.Field === 'RentalAverageAssetValue' &&
              props.validationError?.Message
            }
            value={props.merchant.BusinessProfile.Personalization.RentalAverageAssetValue}
            onChange={(e) => {
              const value = e.currentTarget.value;
              updatePersonalization((prev) => ({
                ...prev,
                RentalAverageAssetValue: value
              }));
            }}
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn
          title="Annual rental revenue"
          description="Approximately how much revenue does your business earn from rentals each year?"
        >
          <TextInput
            error={
              props.validationError?.Field === 'RentalAnnualRevenue' &&
              props.validationError?.Message
            }
            value={props.merchant.BusinessProfile.Personalization.RentalAnnualRevenue}
            onChange={(e) => {
              const value = e.currentTarget.value;
              updatePersonalization((prev) => ({
                ...prev,
                RentalAnnualRevenue: value
              }));
            }}
          />
        </FormColumn>
        <FormColumn
          title="Annual rental volume"
          description="Approximately how many rentals does your business do each year?"
        >
          <TextInput
            error={
              props.validationError?.Field === 'RentalAnnualVolume' &&
              props.validationError?.Message
            }
            value={props.merchant.BusinessProfile.Personalization.RentalAnnualVolume}
            onChange={(e) => {
              const value = e.currentTarget.value;
              updatePersonalization((prev) => ({
                ...prev,
                RentalAnnualVolume: value
              }));
            }}
          />
        </FormColumn>
      </FormRow>

      <FormRowHeader title="How do you manage your rentals?" />
      <FormRow breakMobile>
        <FormColumn
          title="Rental platform"
          description="Which platform (e.g. Peek Pro, Booqable) do you use to manage bookings?"
        >
          <TextInput
            error={
              props.validationError?.Field === 'RentalPlatformName' &&
              props.validationError?.Message
            }
            value={props.merchant.BusinessProfile.Personalization.RentalPlatformName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              updatePersonalization((prev) => ({
                ...prev,
                RentalPlatformName: value
              }));
            }}
          />
        </FormColumn>
        <FormColumn
          title="Point of Sale"
          description="Which platform (e.g. Lightspeed) do you use to collect payment from the customer?"
        >
          <TextInput
            error={
              props.validationError?.Field === 'RentalPointOfSaleName' &&
              props.validationError?.Message
            }
            value={props.merchant.BusinessProfile.Personalization.RentalPointOfSaleName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              updatePersonalization((prev) => ({
                ...prev,
                RentalPointOfSaleName: value
              }));
            }}
          />
        </FormColumn>
      </FormRow>
    </FormContainer>
  );
};
