import * as React from 'react';
import styled from 'styled-components';
import { ErrorDisplay, TextInputComponent } from './text';
import { IoAdd } from 'react-icons/io5';
import { Spinner } from '../Spinner';

const SelectInput = styled.select<{ error?: string | false | null }>`
  border: 0;
  outline: none;
  appearance: none;
  background: white;

  border-radius: 20px;
  border: ${(props) => (props.error ? '2px solid #d1344b' : '2px solid #e8e8e8')};
  box-sizing: border-box;
  font-size: 1em;
  padding: 8px 40px 8px 20px;

  width: 100%;

  transition: 0.15s all;

  &:focus {
    border: 2px solid #0ea5e9;
  }

  ::placeholder {
    color: #aaaaaa;
  }

  font-family: 'Inter', 'Helvetica Neue', 'Arial', sans-serif;
`;

const Carat = styled.div`
  display: block;
  position: absolute;
  cursor: pointer;
  right: 1rem;
  top: 50%;
  margin-top: -1px;
  width: 0;
  height: 0;
  border-top: 5px solid #999;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  pointer-events: none;
`;

export interface SelectProps {
  initialSelected?: string; // denotes uncontrolled
  selected?: string; // denotes controlled
  disabled?: boolean;
  style?: React.CSSProperties;

  error?: string | false | null;
  options?: { value: string; displayValue?: string }[];
  onChange?: (value: string) => void;
}

export const Select = (
  props: React.AllHTMLAttributes<HTMLSelectElement> & SelectProps
): JSX.Element => {
  return (
    <div>
      <div style={{ position: 'relative', maxWidth: props.style?.maxWidth || '100%' }}>
        <SelectInput
          {...props}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            e.stopPropagation();
            if (props.onChange) {
              props.onChange(e.currentTarget.value);
            }
          }}
        >
          {(props.options || []).map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayValue || option.value}
            </option>
          ))}
        </SelectInput>
        <Carat />
      </div>
      {props.error && <ErrorDisplay>{props.error}</ErrorDisplay>}
    </div>
  );
};

export const UnstyledSelect = (props: SelectProps): JSX.Element => {
  const [selected, setSelected] = React.useState(props.selected || props.initialSelected);

  React.useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  return (
    <select
      style={{
        border: '0',
        fontSize: '1em',
        cursor: 'pointer',
        transition: 'all 0.15s ease',
        ...(props.style || {})
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(e) => {
        e.stopPropagation();
        if (!props.selected) {
          setSelected(e.currentTarget.value);
        }
        if (props.onChange) {
          props.onChange(e.currentTarget.value);
        }
      }}
      value={selected}
      disabled={props.disabled}
    >
      {(props.options || []).map((option) => (
        <option key={option.value} value={option.value}>
          {option.displayValue || option.value}
        </option>
      ))}
    </select>
  );
};

const SearchableSelectOptionsContainer = styled.div`
  position: absolute;
  display: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 4px;
  z-index: 100;
  background: white;
  width: 100%;

  &:hover {
    display: block;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    padding: 10px 20px;
    font-size: 0.9em;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background: #0ea5e9;
      color: white;
      cursor: pointer;

      span.highlighted {
        background: none;
        font-weight: normal;
      }
    }
  }

  li.add-item {
    border-top: 2px solid #f2f2f2;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.9em;
    font-weight: 600;

    &.loading {
      background: #f8f8f8;
      cursor: wait;
      color: #666666;
    }
  }

  span.highlighted {
    background: yellow;
    font-weight: 600;
  }
`;

const SearchableSelectOptions = (props: {
  options: string[];
  inputText: string;
  onSelect: (option: string) => void;
  onAdd: (option: string) => Promise<void>;
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleAddOption = async () => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      await props.onAdd(props.inputText);
    } finally {
      setLoading(false);
    }
  };

  const caseInsensitiveIndexOf = (s: string, sub: string) =>
    s.toLowerCase().indexOf(sub.toLowerCase());

  // Ranked options are the options to display in the dropdown. This is filtered
  // and ordered in an intuitive way for the user.
  const rankedOptions = props.options
    // First, only show options that contain the input string as a complete substring
    // unless the input string is empty, in which case show all opions.
    .filter((option) => !props.inputText || caseInsensitiveIndexOf(option, props.inputText) !== -1)
    // Next, build the sort key for each option. First, sort by the position of the
    // first complete substring match (prefer options that have the input text earlier
    // in the option rather than later). If the positions are the same, break the tie
    // by sorting based on the index option, keeping them in the original order (this is
    // also known as a stable sort). The final element is the actual option to render.
    .map((s, i): [number, number, string] => [caseInsensitiveIndexOf(s, props.inputText), i, s])
    // Generic sort comparator that sorts ascending by each corresponding element in the
    // sort key, checking subsequent elements in the key when prior elements are equal.
    .sort((a, b) => {
      for (let i = 0; i < a.length && i < b.length; i++) {
        if (a[i] !== b[i]) {
          return a[i] < b[i] ? -1 : 1;
        }
      }
      return 0;
    });

  return (
    <SearchableSelectOptionsContainer className="suggestions-container">
      <ul>
        <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
          {rankedOptions.map(([, , option]) => (
            <li key={option} onClick={() => props.onSelect?.(option)}>
              {[...option].map((char, i) => (
                <span
                  key={`${i}_${char}`}
                  className={
                    caseInsensitiveIndexOf(option, props.inputText) !== -1 &&
                    i >= caseInsensitiveIndexOf(option, props.inputText) &&
                    i < caseInsensitiveIndexOf(option, props.inputText) + props.inputText.length
                      ? 'highlighted'
                      : undefined
                  }
                >
                  {char}
                </span>
              ))}
            </li>
          ))}
        </div>
        {!props.options.some((opt) => opt === props.inputText) && props.inputText != '' && (
          <li className={`add-item${loading ? ' loading' : ''}`} onClick={handleAddOption}>
            {loading && (
              <>
                <Spinner size={12} color="#333333" /> Adding "{props.inputText}"...
              </>
            )}
            {!loading && (
              <>
                <IoAdd /> Add "{props.inputText}"
              </>
            )}
          </li>
        )}
      </ul>
    </SearchableSelectOptionsContainer>
  );
};

interface SearchableSelectProps {
  options: string[];
  initialValue?: string;
  disabled?: boolean;
  error?: string;
  onChange?: (value: string) => void;
  onAddOption?: (value: string) => Promise<void>;
  onSelectOption?: (value: string) => void;
}

export const SearchableSelect = (props: SearchableSelectProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = React.useState(false);
  const [value, setValue] = React.useState(props.initialValue);

  React.useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue]);

  return (
    <div style={{ position: 'relative' }}>
      <TextInputComponent
        value={value}
        error={props.error}
        onChange={(e) => {
          setSelectedOption(false);
          setValue(e.currentTarget.value.trim());
          props.onChange?.(e.currentTarget.value.trim());
        }}
        onFocus={() => setSelectedOption(false)}
        disabled={props.disabled}
      />
      {!selectedOption && (
        <SearchableSelectOptions
          options={props.options}
          inputText={value || ''}
          onSelect={(value: string) => {
            setSelectedOption(true);
            setValue(value);
            props.onSelectOption?.(value);
          }}
          onAdd={async (value: string) => {
            if (!props.onAddOption) {
              return;
            }

            await props.onAddOption(value);
            setSelectedOption(true);
            setValue(value);
          }}
        />
      )}
      {props.error && <ErrorDisplay>{props.error}</ErrorDisplay>}
    </div>
  );
};
