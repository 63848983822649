import * as React from 'react';
import styled from 'styled-components';

import { Spinner } from '../Spinner';

interface Props<T> {
  request: Promise<T>;
  exactlyOnce?: boolean;
  hideSpinner?: boolean;
  inline?: boolean;
  children: (data: T) => JSX.Element | null;
}

export const LoadableContainer = styled.div<{ inline?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  ${(props) =>
    props.inline
      ? ''
      : `
    padding: 40px 0px;display: flex;
    flex-direction: row;
    justify-content: center;
  `}
`;

export function Loadable<T>(props: Props<T>): JSX.Element | null {
  const [data, setData] = React.useState<T | null>(null);

  React.useEffect(() => {
    if (!props.exactlyOnce || !data) {
      props.request.then(setData);
    }
  }, [props.request]);

  if (!data) {
    return props.hideSpinner ? null : (
      <LoadableContainer inline={props.inline}>
        <Spinner color="#333333" />
      </LoadableContainer>
    );
  }

  return props.children(data);
}
