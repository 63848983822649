import React from 'react';
import styled from 'styled-components';
import { NumericFormat, OnValueChange } from 'react-number-format';

export const ErrorDisplay = styled.div`
  font-size: 0.8em;
  margin-top: 5px;
  color: #d1344b;
`;

export const TextInputComponent = styled.input<{
  error?: string | null | false | true;
  currency?: boolean | false;
}>`
  border: 0;
  outline: none;

  border-radius: 20px;
  border: ${(props) => (props.error ? '2px solid #d1344b' : '2px solid #e8e8e8')};
  box-sizing: border-box;
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1em;
  padding: 8px 16px;

  padding-left: ${(props) => props.currency && '30px'};

  width: 100%;

  transition: 0.15s all;

  &:focus {
    border: 2px solid #0ea5e9;
  }

  &:focus + .suggestions-container {
    display: block;
  }
`;

export const TextInput = React.forwardRef(
  (
    props: React.AllHTMLAttributes<HTMLInputElement> & {
      error?: string | null | false | true;
      currency?: boolean | false;
    },
    ref: React.Ref<HTMLInputElement>
  ) => (
    <>
      <div style={{ position: 'relative' }}>
        {props.currency && (
          <span style={{ position: 'absolute', left: '12px', top: '10px', color: '#aaaaaa' }}>
            $
          </span>
        )}
        <TextInputComponent {...props} ref={ref} />
        {props.error && props.error !== true && <ErrorDisplay>{props.error}</ErrorDisplay>}
      </div>
    </>
  )
);

export const CurrencyComponent = styled(NumericFormat)<{
  error?: string | null | false | true;
  currency?: boolean | false;
}>`
  border: 0;
  outline: none;

  border-radius: 20px;
  border: ${(props) => (props.error ? '2px solid #d1344b' : '2px solid #e8e8e8')};
  box-sizing: border-box;
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1em;
  padding: 8px 16px;

  width: 100%;

  transition: 0.15s all;

  &:focus {
    border: 2px solid #0ea5e9;
  }

  &:focus + .suggestions-container {
    display: block;
  }
`;

export const CurrencyInput = (
  props: React.AllHTMLAttributes<HTMLInputElement> & {
    error?: string | null | false | true;
    currency?: boolean | false;
    value?: string | number;
    onValueChange?: OnValueChange;
    style?: React.CSSProperties;
  }
) => {
  return (
    <div style={{ position: 'relative' }}>
      <CurrencyComponent
        prefix="$"
        thousandSeparator=","
        currency={props.currency}
        value={props.value}
        onValueChange={props.onValueChange}
        style={props.style}
        error={props.error}
      />
      {props.error && props.error !== true && <ErrorDisplay>{props.error}</ErrorDisplay>}
    </div>
  );
};

const SuggestionsContainer = styled.div`
  position: absolute;
  display: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 100;
  background: white;
  font-size: 0.9em;

  &:hover {
    display: block;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    padding: 10px 20px;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background: #0ea5e9;
      color: white;
      cursor: pointer;
    }
  }
`;

const Suggestions = (props: {
  suggestions: string[];
  onSelect?: (suggestion: string, index: number) => void;
}) => (
  <SuggestionsContainer className="suggestions-container">
    <ul>
      {props.suggestions.map((s, i) => (
        <li key={s} onClick={() => (props.onSelect ? props.onSelect(s, i) : null)}>
          {s}
        </li>
      ))}
    </ul>
  </SuggestionsContainer>
);

interface AutocompleteTextInputProps {
  suggestions: string[];
  id?: string;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onSelectSuggestion?: (suggestion: string, index: number) => void;
}

export const AutocompleteTextInput = (props: AutocompleteTextInputProps): JSX.Element => {
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(false);
  return (
    <div style={{ position: 'relative' }}>
      <TextInputComponent
        id={props.id}
        value={props.value}
        error={props.error ? 'error' : ''}
        onChange={(e) => {
          setSelectedSuggestion(false);
          props.onChange?.(e.currentTarget.value);
        }}
        disabled={props.disabled}
      />
      {props.suggestions.length > 0 && !selectedSuggestion && (
        <Suggestions
          suggestions={props.suggestions}
          onSelect={(suggestion: string, index: number) => {
            setSelectedSuggestion(true);
            props.onSelectSuggestion?.(suggestion, index);
          }}
        />
      )}
    </div>
  );
};
export const TextAreaInputComponent = styled.textarea<{ error?: string | null | false }>`
  border: 0;
  outline: none;

  border-radius: 20px;
  border: ${(props) => (props.error ? '2px solid #d1344b' : '2px solid #e8e8e8')};
  box-sizing: border-box;
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1em;
  padding: 12px 16px;

  width: 100%;

  transition: 0.15s all;

  &:focus {
    border: 2px solid #0ea5e9;
  }
`;

export const TextAreaInput: React.FunctionComponent<
  React.AllHTMLAttributes<HTMLTextAreaElement> & { error?: string | null | false }
> = (props) => (
  <>
    <TextAreaInputComponent {...props} />
    {props.error && <ErrorDisplay>{props.error}</ErrorDisplay>}
  </>
);
