import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { Switch } from '@oysterjs/ui/Form/switch';
import { TextAreaInput } from '@oysterjs/ui/Form/text';
import { VerticalLayout } from '@oysterjs/ui/VerticalLayout';
import config from '@oysterjs/core/config';
import { LSRetailRSeriesIntegrationSettings, MerchantIntegration } from '@oysterjs/types';
import { ChannelSetting, ChannelSettingsWrapper, Step } from './settings';

export const LightspeedRetailRSeriesSetup = (props: {
  integration?: MerchantIntegration;
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
}) => {
  if (!props.integration) {
    const installUrl = `${config().backendBaseUrl.integrate}/lsretail-rseries/authorize`;
    return (
      <>
        <p className="text-base my-4">
          To start offering insurance for your products in your Lightspeed store, you need to
          install the{' '}
          <a href={installUrl} target="_blank">
            Oyster app
          </a>{' '}
          and link your store with your Oyster account.
        </p>
        <VerticalLayout invertedIcon>
          <Step title="Login to your store and install the app">
            Ensure that you are logged into the Lightspeed store you'd like to install the Oyster
            app in. Then,{' '}
            <a href={installUrl} target="_blank">
              click here to install Oyster
            </a>{' '}
            in your store.
          </Step>
          <Step title="Grant access to Oyster">
            Oyster will ask for specific permissions to read product and order data. We will use
            this information to determine the insurance eligibility of products and customers and
            send post-purchase email offers to customers based on the eligiblity criteria you
            define.
          </Step>
          <Step title="Finish setting up your integration" last>
            Configure which products and customers are eligible to receive insurance offers via
            email.
          </Step>
        </VerticalLayout>
      </>
    );
  }

  return (
    <ChannelSettingsWrapper
      integration={props.integration}
      onUpdateIntegration={props.onUpdateIntegration}
    >
      {(settings: LSRetailRSeriesIntegrationSettings, loading, onUpdateSettings) => (
        <>
          <ChannelSetting
            title="Enable Email Quotes"
            description="If enabled, Oyster will send co-branded quotes to eligible customers via email based on the criteria you define."
            rightContent={
              <Switch
                enabled={settings.EmailMarketingEnabled}
                loading={loading}
                onChange={() =>
                  onUpdateSettings({
                    ...settings,
                    EmailMarketingEnabled: !settings.EmailMarketingEnabled
                  })
                }
              />
            }
          />
          {settings.EmailMarketingEnabled && (
            <>
              <ChannelSetting
                title="Require Customer Opt-In"
                description="If enabled, Oyster will only send quotes to customers who opt-in at the point-of-sale."
                indented
                rightContent={
                  <Switch
                    enabled={settings.EmailMarketingRequiresOptIn}
                    loading={loading}
                    onChange={() =>
                      onUpdateSettings({
                        ...settings,
                        EmailMarketingRequiresOptIn: !settings.EmailMarketingRequiresOptIn
                      })
                    }
                  />
                }
              />
              <ChannelSetting
                title="Product Categories"
                description="Optionally, specify which of your Lightspeed product categories Oyster should generate offers for. Enter one category per line."
                indented
              >
                <TextAreaInput
                  rows={4}
                  id="ls-retail-product-categories"
                  style={{ resize: 'none' }}
                  defaultValue={settings.EmailMarketingCategoriesEnabled?.join('\n')}
                  disabled={loading}
                />
                <ButtonContainer style={{ paddingTop: '8px' }}>
                  <Button
                    primary
                    loading={loading}
                    onClick={() => {
                      const elem = document.querySelector<HTMLTextAreaElement>(
                        '#ls-retail-product-categories'
                      );
                      if (elem) {
                        const categories = elem.value
                          .split('\n')
                          .map((s) => s.trim())
                          .filter((s) => s.length > 0);
                        onUpdateSettings({
                          ...settings,
                          EmailMarketingCategoriesEnabled: categories.length > 0 ? categories : null
                        });
                      }
                    }}
                  >
                    Save Categories
                  </Button>
                </ButtonContainer>
              </ChannelSetting>
            </>
          )}
        </>
      )}
    </ChannelSettingsWrapper>
  );
};
