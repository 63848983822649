import * as React from 'react';
import { IoArrowForward } from 'react-icons/io5';
import { getMerchantAccount } from '@oysterjs/core/api/merchant';
import { getMerchantPayment } from '@oysterjs/core/api/plaid';
import { Badge } from '@oysterjs/ui/Badge';
import { Banner } from '@oysterjs/ui/Banner';
import { ButtonLink } from '@oysterjs/ui/Button';
import { Loadable } from '@oysterjs/ui/Loadable';

import { ErrorCode, ErrorType } from '@oysterjs/core/errors';
import { Address, DeepPartial, Merchant, PaymentMethodType } from '@oysterjs/types';
import { PayoutSummary, UserPayment } from './payment';

export const AccountStatusBanner = (): JSX.Element => (
  <Loadable request={getMerchantAccount()} hideSpinner>
    {(res) => {
      const hasPayment = res.Merchant.BusinessProfile?.PaymentMethod;
      if (hasPayment) {
        return null;
      }

      const hasActionPayment = true;
      const titlePayment = 'Please add a payment method to your account';
      const descriptionPayment =
        'We need to collect your preferred payment method to receive your payouts.';

      return (
        <>
          {!hasPayment && (
            <Banner
              title={titlePayment}
              description={descriptionPayment}
              bannerAction={
                hasActionPayment ? (
                  <ButtonLink href="/payouts" primary icon={<IoArrowForward />}>
                    Add Payment Method
                  </ButtonLink>
                ) : undefined
              }
            ></Banner>
          )}
        </>
      );
    }}
  </Loadable>
);

const PayoutPage = (props: {
  paymentMethod: PaymentMethodType;
  address: Partial<Address>;
  loading: boolean;
  onUpdateLoading: (loading: boolean) => void;
  onUpdatePayoutsInfo: (
    paymentMethod: PaymentMethodType,
    formAddress?: Partial<Address>,
    mercuryRecipientID?: string
  ) => void;
  mercuryRecipientID?: string;
  error?: string;
}) => {
  return props.mercuryRecipientID && !props.loading ? (
    <PayoutSummary
      paymentMethod={props.paymentMethod}
      address={props.address}
      onUpdatePayoutsInfo={props.onUpdatePayoutsInfo}
    />
  ) : (
    <UserPayment
      loading={props.loading}
      paymentMethod={props.paymentMethod}
      address={props.address}
      onUpdatePayoutsInfo={props.onUpdatePayoutsInfo}
      onUpdateLoading={props.onUpdateLoading}
      error={props.error}
    />
  );
};

export const PayoutsPage = (props: { merchant: DeepPartial<Merchant> }) => {
  const [paymentMethod, setPaymentMethod] = React.useState(
    props.merchant.BusinessProfile?.PaymentMethod || PaymentMethodType.Unknown
  );
  const [mercuryRecipientID, setMercuryRecipientID] = React.useState(
    props.merchant.MercuryRecipientID
  );
  const [address, setAddress] = React.useState(props.merchant.BusinessProfile?.Address || {});
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(
    !mercuryRecipientID && paymentMethod !== PaymentMethodType.Unknown && !!address
  );

  const merchantPayment = () => {
    getMerchantPayment()
      .then((res) => {
        setMercuryRecipientID(res.MercuryRecipientID);
        setPaymentMethod(res.PaymentType);
        setAddress(res.Address);
      })
      .catch((err) => {
        if (err.type() == ErrorType.processingError && err.code() == ErrorCode.submissionFailed) {
          setError(
            "There seems to be something wrong with the address you've entered. Please double check that it is entered correctly. If this error persists, please contact us at partners@withoyster.com."
          );
        } else {
          setError(err.message);
        }
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    merchantPayment();
  }, []);

  const onUpdatePayoutsInfo = (
    paymentMethod: PaymentMethodType,
    formAddress?: Partial<Address>
  ) => {
    setError('');
    setPaymentMethod(paymentMethod);
    setAddress(formAddress || address);

    merchantPayment();
  };

  const onUpdateLoading = (loading: boolean) => {
    setLoading(loading);
  };

  return (
    <>
      <h2 className="m-0 text-base font-medium font-body">Payouts</h2>
      <p className="text-sm text-neutral-500">
        {mercuryRecipientID
          ? "You're all set! Payouts have been configured and you'll receive earned referral fees on a monthly basis."
          : loading
            ? 'Your payment method is being processed, please check back later to see if it succeeded.'
            : 'Enter your billing address and select your preferred payout method to receive referral fees.'}
      </p>
      <div className="mt-2 mb-4">
        {mercuryRecipientID ? (
          <Badge color="#baf7bb" label="Active" />
        ) : loading ? (
          <Badge color="#e6e6e6" label="Processing" />
        ) : (
          <Badge color="#ffa72494" label="Setup Required" />
        )}
      </div>
      <PayoutPage
        paymentMethod={paymentMethod}
        loading={loading}
        mercuryRecipientID={mercuryRecipientID}
        address={address}
        onUpdatePayoutsInfo={onUpdatePayoutsInfo}
        onUpdateLoading={onUpdateLoading}
        error={error}
      />
    </>
  );
};
