import React from 'react';
import { IoCheckmarkCircle, IoEllipseOutline } from 'react-icons/io5';
import { AttachmentFile, ValidationError } from '@oysterjs/types';
import styled from 'styled-components';
import { ErrorDisplay } from '../Form/text';
import { Spinner } from '../Spinner';
import { AttachmentLozenge } from './lozenge';

const AttachmentContainer = styled.div<{ enabled?: boolean; error?: boolean; dragging?: boolean }>`
  box-shadow: ${(props) =>
    props.error ? '0 0 0 2px #d1344b' : props.enabled ? '0 0 0 0px #C8C8C8' : '0 0 0 2px #0ea5e9'};
  border: ${(props) =>
    props.error || !props.enabled ? '1px solid transparent' : '1px dashed #C8C8C8'};
  border-radius: 8px;
  transition: 0.15s all ease-in-out;
  padding: 16px;
  box-sizing: border-box;
  background: ${(props) => (props.dragging ? '#e2e2e2' : 'white')};

  display: flex;
  flex-direction: row;
  margin: 0px;
  cursor: pointer;

  * {
    pointer-events: none;
  }
`;

const AttachmentCheckboxContainer = styled.div`
  width: 30px;
`;

const AttachmentContentContainer = styled.div`
  width: calc(100% - 30px);
`;

const AttachmentDescription = styled.div`
  color: #999999;
  font-size: 0.9em;
  margin-top: 5px;
`;

const AttachmentContent = styled.div`
  font-size: 0.9em;
  margin-top: 5px;
  padding: 10px 0px 5px 0px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;

  * {
    pointer-events: auto;
  }
`;

const AttachmentHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
`;

const ChildrenContainer = styled.div`
  margin-top: 10px;
`;

const getFilesFromEvent = (dataTransfer?: DataTransfer): File[] => {
  const files: File[] = [];

  if (dataTransfer && dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (dataTransfer.items[i].kind === 'file') {
        const file = dataTransfer.items[i].getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }
  } else if (dataTransfer && dataTransfer.files) {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < dataTransfer.files.length || 0; i++) {
      const file = dataTransfer.files[i];
      if (file) {
        files.push(file);
      }
    }
  }

  return files;
};

export interface AttachmentUploaderProps {
  title?: string;
  description?: string;
  multiple?: boolean;
  allowDeleteExisting?: boolean;
  attachments: AttachmentFile[];
  onAttachmentsAdded: (files: File[]) => Promise<void>;
  onAttachmentRemoved?: (index: number, attachment?: AttachmentFile, file?: File) => Promise<void>;
  validationError?: ValidationError;
}

export const AttachmentUploader: React.FunctionComponent<
  React.PropsWithChildren<AttachmentUploaderProps>
> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);

  const attachRef = React.useRef<HTMLInputElement>(null);

  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [existingAttachmentsList, setExistingAttachmentsList] = React.useState<AttachmentFile[]>(
    []
  );
  const [newAttachmentsList, setNewAttachmentsList] = React.useState<File[]>([]);

  React.useEffect(() => {
    setValidationError(props.validationError);
  }, [props.validationError]);

  React.useEffect(() => {
    // Set existing attachments
    setExistingAttachmentsList(props.attachments);

    // Remove new attachments that has the same name as existing attachments
    // This is for the case where we've uploaded the file and backend updates the list of attachments
    setNewAttachmentsList((prev) =>
      prev.filter(
        (newAttachment) =>
          props.attachments.findIndex(
            (uploadedAttachment) => uploadedAttachment.Name === newAttachment.name
          ) === -1
      )
    );
  }, [props]);

  const addFiles = (files: File[]) => {
    setValidationError(undefined);
    setNewAttachmentsList((prev) => [...prev, ...files]);
    setLoading(true);

    // Call the attachment added callback, remove new files if the call fails
    props
      .onAttachmentsAdded(files)
      .catch(() => {
        setNewAttachmentsList((prev) =>
          prev.filter((newAttachment) => files.indexOf(newAttachment) === -1)
        );
      })
      .finally(() => setLoading(false));
  };

  const onDragEnter = (e: { dataTransfer?: DataTransfer; preventDefault: () => void }) => {
    e.preventDefault();
    setDragging(true);
  };

  const onDragLeave = (e: { dataTransfer?: DataTransfer; preventDefault: () => void }) => {
    e.preventDefault();
    setDragging(false);
  };

  const onDragOver = (e: { dataTransfer?: DataTransfer; preventDefault: () => void }) => {
    e.preventDefault();
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'copy';
    }
  };

  const onDrop = (e: { dataTransfer?: DataTransfer; preventDefault: () => void }) => {
    e.preventDefault();
    setDragging(false);

    const files = getFilesFromEvent(e.dataTransfer);
    if (files.length > 0) {
      addFiles(files);
    }
  };

  return (
    <div>
      <input
        type="file"
        name="files"
        multiple={props.multiple || false}
        ref={attachRef}
        style={{ display: 'none' }}
        onChange={(e) => addFiles(Array.from(e.currentTarget?.files || []))}
      />
      <AttachmentContainer
        enabled={!loading && newAttachmentsList.length === 0 && props.attachments.length === 0}
        dragging={dragging}
        error={!!validationError}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onClick={() => attachRef?.current?.click()}
      >
        <AttachmentCheckboxContainer>
          {!loading && newAttachmentsList.length + props.attachments.length === 0 && (
            <IoEllipseOutline style={{ color: '#cccccc', fontSize: '1.3em' }} />
          )}
          {!loading && newAttachmentsList.length + props.attachments.length > 0 && (
            <IoCheckmarkCircle style={{ color: '#0EA5E9', fontSize: '1.3em' }} />
          )}
          {loading && <Spinner size={16} color="#666666" />}
        </AttachmentCheckboxContainer>
        <AttachmentContentContainer>
          {props.title && <AttachmentHeaderContainer>{props.title}</AttachmentHeaderContainer>}
          {props.description && <AttachmentDescription>{props.description}</AttachmentDescription>}
          {existingAttachmentsList.length + newAttachmentsList.length > 0 && (
            <AttachmentContent>
              {existingAttachmentsList.map((attachment, index) => (
                <AttachmentLozenge
                  key={attachment.ID}
                  name={attachment.Name}
                  size={attachment.Size}
                  onRemove={
                    props.onAttachmentRemoved && props.allowDeleteExisting
                      ? () => {
                          setLoading(true);
                          if (props.onAttachmentRemoved) {
                            props
                              .onAttachmentRemoved(index, attachment, undefined)
                              .then(() => setLoading(false));
                          }
                        }
                      : undefined
                  }
                />
              ))}
              {newAttachmentsList.map((file, index) => (
                <AttachmentLozenge
                  uploading
                  key={'uploading-' + file.name}
                  name={file.name}
                  size={file.size}
                  onRemove={
                    props.onAttachmentRemoved && !loading
                      ? () => {
                          setLoading(true);
                          if (props.onAttachmentRemoved) {
                            props
                              .onAttachmentRemoved(
                                existingAttachmentsList.length + index,
                                undefined,
                                file
                              )
                              .then(() => {
                                setNewAttachmentsList((prev) => {
                                  const newIndex = prev.indexOf(file);
                                  if (newIndex >= 0) {
                                    prev.splice(newIndex, 1);
                                  }
                                  return prev;
                                });
                                setLoading(false);
                              });
                          }
                        }
                      : undefined
                  }
                />
              ))}
            </AttachmentContent>
          )}
          {props.children && <ChildrenContainer>{props.children}</ChildrenContainer>}
          {validationError && (
            <ErrorDisplay key={validationError.Message}>{validationError.Message}</ErrorDisplay>
          )}
        </AttachmentContentContainer>
      </AttachmentContainer>
    </div>
  );
};
