import React from 'react';
import styled from 'styled-components';

import { ValidationError } from '@oysterjs/types';

import { AutocompleteTextInput, TextInput } from './text';
import { getLocationSuggestions, getMapURL, MAPBOX_PUBLIC_KEY } from '@oysterjs/core/mapbox';
import { FormColumn, FormRow } from './builder';
import { AddressAutofill } from '@mapbox/search-js-react';
import config from '@oysterjs/core/config';

const ErrorDisplay = styled.div`
  font-size: 0.8em;
  margin-top: 5px;
  color: #d1344b;
`;

interface AddressInputProps {
  id: string;

  onChange: (address: string, longitude: number, latitude: number) => void;
  disabled?: boolean;

  initialAddress?: [string, number, number];
  validationError?: ValidationError;
}

export const AddressInput: React.FunctionComponent<React.PropsWithChildren<AddressInputProps>> = (
  props
) => {
  const [state, setState] = React.useState<[string, number, number]>(
    props.initialAddress || ['', 0, 0]
  );
  const [suggestions, setSuggestions] = React.useState<
    {
      address: string;
      coordinates: [number, number];
    }[]
  >([]);
  const [validationError, setValidationError] = React.useState(props.validationError);

  React.useEffect(() => {
    setValidationError(props.validationError);
  }, [props.validationError]);

  const setData = (address: string, longitude: number, latitude: number) => {
    setState([address, longitude, latitude]);
    props.onChange(address, longitude, latitude);
  };

  return (
    <>
      <AutocompleteTextInput
        id={props.id}
        suggestions={suggestions.map((s) => s.address)}
        error={!!validationError}
        value={state[0]}
        onChange={(value) => {
          setData(value, 0, 0);
          setValidationError(undefined);
          getLocationSuggestions(value).then(setSuggestions);
        }}
        onSelectSuggestion={(suggestion, index) => {
          setData(suggestion, suggestions[index].coordinates[0], suggestions[index].coordinates[1]);
          setValidationError(undefined);
        }}
        disabled={props.disabled}
      />
      {validationError && <ErrorDisplay>{validationError?.Message}</ErrorDisplay>}
      {state[1] !== 0 && state[2] !== 0 && (
        <img
          style={{
            marginTop: '20px',
            width: '100%',
            maxWidth: '400px',
            borderRadius: '8px'
          }}
          src={getMapURL(state[1], state[2])}
        />
      )}
    </>
  );
};

interface Address {
  businessName?: string;
  streetAddress?: string;
  streetAddressLine2?: string;
  aptFloor?: string;
  aptUnit?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

interface AddressInputFormProps {
  showBusinessName?: boolean;
  showSecondLine?: boolean;
  singleSecondLine?: boolean;
  initialValue?: Address;
  validationError?: Partial<Record<keyof Address, string | false | null | undefined>>;
  onChange: (address: Address) => void;
  disabled?: boolean;
}

const ConditionalWrapper = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

export const AddressInputForm: React.FunctionComponent<
  React.PropsWithChildren<AddressInputFormProps>
> = (props) => {
  const [state, setState] = React.useState<Address>(
    props.initialValue || {
      businessName: '',
      streetAddress: '',
      streetAddressLine2: '',
      aptFloor: '',
      aptUnit: '',
      city: '',
      state: '',
      zipCode: ''
    }
  );

  const onChange = (fn: (prev: Address) => Address) =>
    setState((prev) => {
      const next = fn(prev);
      props.onChange(next);
      return next;
    });

  return (
    <>
      {props.showBusinessName && (
        <FormRow>
          <FormColumn title="Business name">
            <TextInput
              disabled={props.disabled}
              style={{ maxWidth: '100%' }}
              value={state.businessName}
              error={props.validationError?.businessName}
              onChange={(e) => {
                const value = e.currentTarget.value;
                onChange((prev) => ({ ...prev, businessName: value }));
              }}
              autoComplete="organization"
            />
          </FormColumn>
        </FormRow>
      )}
      <FormRow>
        <FormColumn title="Street address">
          <ConditionalWrapper
            condition={config().serviceName === 'partners'}
            wrap={(child) => (
              <AddressAutofill accessToken={MAPBOX_PUBLIC_KEY()}>{child}</AddressAutofill>
            )}
          >
            <TextInput
              disabled={props.disabled}
              style={{ maxWidth: '100%' }}
              value={state.streetAddress}
              error={props.validationError?.streetAddress}
              onChange={(e) => {
                const value = e.currentTarget.value;
                onChange((prev) => ({ ...prev, streetAddress: value }));
              }}
              autoComplete="address-line1"
            />
          </ConditionalWrapper>
        </FormColumn>
      </FormRow>
      {props.showSecondLine && props.singleSecondLine && (
        <FormRow>
          <FormColumn title="Apartment or suite no.">
            <TextInput
              disabled={props.disabled}
              style={{ maxWidth: '100%' }}
              value={state.streetAddressLine2}
              error={props.validationError?.streetAddressLine2}
              onChange={(e) => {
                const value = e.currentTarget.value;
                onChange((prev) => ({ ...prev, streetAddressLine2: value }));
              }}
              autoComplete="address-line2"
            />
          </FormColumn>
        </FormRow>
      )}
      {props.showSecondLine && !props.singleSecondLine && (
        <FormRow>
          <FormColumn title="Floor no.">
            <TextInput
              disabled={props.disabled}
              style={{ maxWidth: '100%' }}
              value={state.aptFloor}
              error={props.validationError?.aptFloor}
              onChange={(e) => {
                const value = e.currentTarget.value;
                onChange((prev) => ({ ...prev, aptFloor: value }));
              }}
            />
          </FormColumn>
          <FormColumn title="Unit no.">
            <TextInput
              disabled={props.disabled}
              style={{ maxWidth: '100%' }}
              value={state.aptUnit}
              error={props.validationError?.aptUnit}
              onChange={(e) => {
                const value = e.currentTarget.value;
                onChange((prev) => ({ ...prev, aptUnit: value }));
              }}
            />
          </FormColumn>
        </FormRow>
      )}
      <FormRow>
        <FormColumn title="City">
          <TextInput
            disabled={props.disabled}
            value={state.city}
            error={props.validationError?.city}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChange((prev) => ({ ...prev, city: value }));
            }}
            autoComplete="address-level2"
          />
        </FormColumn>
        <FormColumn title="State" style={{ maxWidth: '72px' }}>
          <TextInput
            disabled={props.disabled}
            value={state.state}
            error={props.validationError?.state}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChange((prev) => ({ ...prev, state: value }));
            }}
            autoComplete="address-level1"
          />
        </FormColumn>
        <FormColumn title="Zip code" style={{ maxWidth: '96px' }}>
          <TextInput
            disabled={props.disabled}
            value={state.zipCode}
            error={props.validationError?.zipCode}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChange((prev) => ({ ...prev, zipCode: value }));
            }}
            inputMode="numeric"
            autoComplete="postal-code"
          />
        </FormColumn>
      </FormRow>
    </>
  );
};
