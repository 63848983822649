import * as React from 'react';
import styled from 'styled-components';
import {
  IoShieldCheckmarkSharp,
  IoShieldOutline,
  IoChevronForwardSharp,
  IoCheckmarkSharp,
  IoAlertCircleSharp,
  IoPerson,
  IoBusiness,
  IoDownload
} from 'react-icons/io5';

import {
  BillingMode,
  Merchant,
  MerchantRentalConfiguration,
  RentalAsset,
  RentalBooking,
  RentalPolicyState,
  RentalWaiver,
  WaiverEntry,
  WaiverState
} from '@oysterjs/types';
import { PageTitle } from '@oysterjs/ui/Page/section';
import {
  getMerchantAccount,
  getMerchantRentalAssets,
  getMerchantRentalConfiguration,
  getRentalWaivers,
  requestQRCodePdf,
  updateMerchantRentalConfiguration
} from '@oysterjs/core/api/merchant';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { Button, ButtonContainer, LinkComponent } from '@oysterjs/ui/Button';
import { Spinner } from '@oysterjs/ui/Spinner';
import { Table } from '@oysterjs/ui/Table';
import { PageNavigation } from '@oysterjs/ui/Page/navigation';
import { SlideOut } from '@oysterjs/ui/Modal/slideout';
import { useHistory, useLocation } from 'react-router';
import { RentalWaiverForm } from '../rental/RentalWaiverForm';
import { RentalWaiverSentPage } from '../rental/RentalWaiverSentPage';
import { RentalBookingDetailsPage } from '../rental/RentalBookingDetailsPage';
import { RentalClaimForm } from '../rental/RentalClaimForm';
import { CreateRentalAssetPage, ViewRentalAssetPage } from '../rental/RentalAssetPage';
import { Setting, SettingsContainer, Statement } from '../rental/components';
import { CardGallery, SelectableOptionCard } from '@oysterjs/ui/Card';
import {
  MerchantBilledInsuranceSettings,
  MerchantUnifiedWaiverFlowSettings
} from '../rental/RentalSettings';
import { WrappedError } from '@oysterjs/core/errors';
import { Loadable, LoadableContainer } from '@oysterjs/ui/Loadable';
import { Select } from '@oysterjs/ui/Form/select';
import { CopyableLink } from '../setups/referralLink';
import { ErrorDisplay } from '@oysterjs/ui/Form/text';
import { Switch } from '@oysterjs/ui/Form/switch';
import { MerchantRentalSetup } from '../setups/rental';

const RentalTable = styled(Table)`
  thead tr th {
    text-align: left;

    &:first-child {
      padding-right: 0px;
    }
    &:last-child {
      padding-left: 0px;
    }
  }

  tbody tr {
    &:hover {
      cursor: pointer;
      background: #f2f2f2;
    }
  }

  tbody tr td {
    svg {
      display: block;
      color: #637d8c;
    }

    &:first-child {
      padding-right: 0px;

      svg {
        float: right;
      }
    }

    &:last-child {
      padding-left: 0px;

      svg {
        float: left;
      }
    }
  }
`;

const MerchantRentalList = (props: {
  rentalConfiguration: MerchantRentalConfiguration;
  waivers: WaiverEntry[];
}) => {
  const history = useHistory();

  const onCreateNewWaiver = () => {
    history.push('/rental/overview/waiver/edit');
  };

  const onViewBooking = (bookingId: string) => {
    history.push(`/rental/overview/booking/${bookingId}/view`);
  };

  const onCreateClaim = () => {
    history.push(`/rental/overview/claim`);
  };

  return (
    <>
      {props.rentalConfiguration.Details.State === RentalPolicyState.approved && (
        <ButtonContainer>
          <Button style={{ margin: '10px 0px' }} primary onClick={onCreateNewWaiver}>
            Create Waiver
          </Button>
        </ButtonContainer>
      )}
      {props.rentalConfiguration.Details.State === RentalPolicyState.approved_blanket && (
        <ButtonContainer>
          <Button style={{ marginBottom: '10px 0px' }} primary onClick={onCreateClaim}>
            Submit Claim
          </Button>
        </ButtonContainer>
      )}
      {
        <RentalTable>
          <thead>
            <tr>
              <th />
              <th>Rental Date</th>
              <th>Booking Reference</th>
              <th>Customer Name</th>
              <th>Email</th>
              <th>Assets</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {props.waivers.map((entry) => {
              if (!entry.Booking) {
                return;
              }

              return (
                <tr key={entry.Booking.ID} onClick={() => onViewBooking(entry.Booking?.ID || '')}>
                  <td>
                    {(() => {
                      switch (entry.Waiver?.State) {
                        case WaiverState.active:
                        case WaiverState.expired:
                          return <IoShieldCheckmarkSharp style={{ fontSize: '1.2em' }} />;
                        default:
                          return <IoShieldOutline style={{ fontSize: '1.2em', opacity: 0.5 }} />;
                      }
                    })()}
                  </td>
                  <td>{new Date(entry.Booking.StartTime).toLocaleDateString()}</td>
                  <td>{entry.Booking.Details.BookingReference}</td>
                  <td>
                    {entry.Booking.Details.Insured.FirstName}{' '}
                    {entry.Booking.Details.Insured.LastName}
                  </td>
                  <td>{entry.Booking.Details.Insured.Email}</td>
                  <td>
                    {entry.Booking.Details.Assets?.map((asset) => (
                      <div
                        key={asset.Details.SerialNumber}
                      >{`${asset.Name} [${asset.Details.SerialNumber}]`}</div>
                    ))}
                    {entry.Booking.Details.BookingLineItems?.map((asset) => (
                      <div key={asset.SerialNumber}>{`${asset.Name} [${asset.SerialNumber}]`}</div>
                    ))}
                  </td>
                  <td>
                    <IoChevronForwardSharp />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </RentalTable>
      }
      {props.waivers.length === 0 && (
        <p style={{ textAlign: 'center', margin: '80px' }}>
          No waivers have been created yet. Click on create waiver button above to start.
        </p>
      )}
    </>
  );
};

const MerchantRentalAssetManager = () => {
  const history = useHistory();

  const [assets, setAssets] = React.useState<RentalAsset[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const getAssetIdFromRentalUrl = (): string => {
    const pathMatch = window.location.pathname.match(/.*\/rental\/assets\/(.*)\/.*/);
    if (pathMatch && pathMatch.length > 1) {
      return pathMatch[1];
    } else {
      return '';
    }
  };

  // Create a list of unique asset names
  const assetGroups: [string, RentalAsset[]][] = Object.entries(
    assets.reduce((acc, curr) => ({ ...acc, [curr.Name]: [...(acc[curr.Name] || []), curr] }), {})
  );
  assetGroups.sort((a, b) => (a[0] < b[0] ? -1 : 1));

  const selectedAsset = assets.find((a) => a.ID === getAssetIdFromRentalUrl());

  const fetchAssets = async () => {
    setLoading(assets.length === 0);
    try {
      const assets = await getMerchantRentalAssets();

      // It's possible that the asset that the merchant is currently viewing has
      // been removed, even though there are other assets with the same name. In
      // that case, find an alternative asset with the same name to ensure that
      // there is continuity in the UX.
      if (selectedAsset && !assets.find((a) => a.ID === selectedAsset.ID)) {
        const replacementAsset = assets.find((a) => a.Name === selectedAsset.Name);
        if (replacementAsset) {
          history.replace(`/rental/assets/${replacementAsset.ID}/view`);
        } else {
          history.replace('/rental/assets');
        }
      }

      setAssets(assets);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAssets();
  }, []);

  const onCreateAsset = () => history.push(`/rental/assets/create`);
  const onViewAsset = (assetId: string) => history.push(`/rental/assets/${assetId}/view`);

  return (
    <>
      {loading && (
        <LoadableContainer>
          <Spinner color="#333333" />
        </LoadableContainer>
      )}
      {!loading && (
        <>
          <ButtonContainer>
            <Button style={{ margin: '10px 0px' }} primary onClick={onCreateAsset}>
              Create Asset
            </Button>
          </ButtonContainer>
          <RentalTable>
            <thead>
              <tr>
                <th>Asset Name</th>
                <th>Type</th>
                <th>Value</th>
                <th>Description</th>
                <th>Quantity</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {assetGroups.map(([name, assets]) => (
                <tr key={name} onClick={() => onViewAsset(assets[0].ID)}>
                  <td>{assets[0].Name}</td>
                  <td>{assets[0].Type}</td>
                  <td>
                    {new Intl.NumberFormat(undefined, {
                      style: 'currency',
                      currency: 'usd',
                      maximumFractionDigits: 0
                    }).format(assets[0].Value)}
                  </td>
                  <td>{assets[0].Description}</td>
                  <td>{assets.length}</td>
                  <td>
                    <IoChevronForwardSharp />
                  </td>
                </tr>
              ))}
            </tbody>
          </RentalTable>
        </>
      )}
      {!loading && assets.length === 0 && (
        <p style={{ textAlign: 'center', margin: '80px' }}>
          No rental assets has been added yet. Click on "Create Asset" above to start.
        </p>
      )}

      <SlideOut
        showing={
          selectedAsset &&
          assets.length > 0 &&
          /^\/rental\/assets\/.*\/view/.test(location.pathname)
        }
        onClose={() => history.replace('/rental/assets')}
        onBack={() => history.replace('/rental/assets')}
      >
        <ErrorBoundary forceMobile>
          <ViewRentalAssetPage
            asset={selectedAsset as RentalAsset}
            assets={assets}
            onChange={fetchAssets}
            onClose={() => history.replace('/rental/assets')}
          />
        </ErrorBoundary>
      </SlideOut>

      <SlideOut
        showing={/^\/rental\/assets\/create/.test(location.pathname)}
        onClose={() => history.replace('/rental/assets')}
        onBack={() => history.replace('/rental/assets')}
      >
        <ErrorBoundary forceMobile>
          <CreateRentalAssetPage
            onCreate={async (asset: RentalAsset) => {
              await fetchAssets();
              history.replace(`/rental/assets/${asset.ID}/view`);
            }}
          />
        </ErrorBoundary>
      </SlideOut>
    </>
  );
};

const MerchantRentalSettings = () => {
  const history = useHistory();

  const [merchant, setMerchant] = React.useState<Merchant>();
  const [configuration, setConfiguration] = React.useState<MerchantRentalConfiguration>();

  const [loading, setLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const onFetchData = async () => {
    setLoading(true);
    try {
      const [merchant, config] = await Promise.all([
        getMerchantAccount(),
        getMerchantRentalConfiguration()
      ]);
      setMerchant(merchant.Merchant);
      setConfiguration(config.Configuration);
    } finally {
      setLoading(false);
    }
  };

  const onUpdate = async (
    fn: (config: MerchantRentalConfiguration) => MerchantRentalConfiguration
  ) => {
    // Store the original config so we can revert to it in case of an error
    const origConfig = configuration ? { ...configuration } : configuration;
    const updatedConfig = configuration ? fn(configuration).Details : {};

    // Set the loading flag and tentatively update the policy with the
    // desired changes.
    setUpdateLoading(true);
    setUpdated(false);
    setError(undefined);
    setConfiguration((config) => (config ? fn(config) : config));

    try {
      // Update the policy and set the policy based on the backend response,
      // which may have rejected the update.
      const res = await updateMerchantRentalConfiguration(updatedConfig);
      setConfiguration(res.Configuration);
      setUpdated(true);
    } catch (e) {
      // If there was an error, revert to the original policy
      const err = WrappedError.asWrappedError(e);
      setConfiguration(origConfig);
      setError(err.message);
    } finally {
      // In any case, set the loading flag to false
      setUpdateLoading(false);
    }
  };

  React.useEffect(() => {
    onFetchData();
  }, []);

  return (
    <>
      {loading && (
        <LoadableContainer>
          <Spinner color="#333333" />
        </LoadableContainer>
      )}
      {!loading && configuration && (
        <>
          <SettingsContainer style={{ maxWidth: '684px' }}>
            <Setting
              title="Billing Mode"
              description="Choose the party Oyster should collect the rental waiver premium from."
            >
              <CardGallery style={{ padding: 0, margin: 0 }}>
                <SelectableOptionCard
                  title="Customer"
                  description="Directly bill the customer for the waiver premium"
                  selected={configuration?.Details.BillingMode === BillingMode.Customer}
                  disabled={updateLoading}
                  loading={
                    updateLoading && configuration?.Details.BillingMode !== BillingMode.Customer
                  }
                  onClick={() =>
                    onUpdate((prev) => ({
                      ...prev,
                      Details: { ...prev.Details, BillingMode: BillingMode.Customer }
                    }))
                  }
                  image={<IoPerson />}
                />
                <SelectableOptionCard
                  title="Merchant"
                  description={`Bill ${merchant?.BusinessProfile.Name} for the premium on behalf of the customer`}
                  selected={configuration?.Details.BillingMode === BillingMode.Merchant}
                  disabled={updateLoading}
                  loading={
                    updateLoading && configuration?.Details.BillingMode !== BillingMode.Merchant
                  }
                  onClick={() => history.push('/rental/settings/merchant-billing')}
                  image={<IoBusiness />}
                />
              </CardGallery>
            </Setting>
            <Setting
              title="Unified Waiver Flow"
              description="Oyster can faciliate the signature collection process for your shop's own rental agreement. Simply enable this feature, upload your documents, and share your QR code. Customers will have the option to add and pay for insurance as well."
            >
              <Switch
                loading={updateLoading}
                enabled={configuration?.Details.UnifiedWaiverFlowEnabled}
                onChange={() => {
                  if (!configuration?.Details.UnifiedWaiverFlowEnabled) {
                    history.push('/rental/settings/unified-waiver-flow');
                  } else {
                    onUpdate((prev) => ({
                      ...prev,
                      Details: {
                        ...prev.Details,
                        UnifiedWaiverFlowEnabled: false
                      }
                    }));
                  }
                }}
              />
              {configuration?.Details.UnifiedWaiverFlowEnabled && (
                <div style={{ fontSize: '0.8em', padding: '8px 0px' }}>
                  <LinkComponent href="/rental/settings/unified-waiver-flow">
                    Manage rental documents
                  </LinkComponent>
                </div>
              )}
            </Setting>
          </SettingsContainer>
          {(updated || error) && (
            <div style={{ padding: '10px 0px 0px 0px', fontSize: '0.8em' }}>
              {updated && (
                <div
                  style={{ display: 'flex', gap: '4px', alignItems: 'center', color: '#999999' }}
                >
                  <IoCheckmarkSharp />
                  <div>Saved!</div>
                </div>
              )}
              {error && (
                <div
                  style={{ display: 'flex', gap: '4px', alignItems: 'center', color: '#d1344b' }}
                >
                  <IoAlertCircleSharp />
                  <div>{error}</div>
                </div>
              )}
            </div>
          )}
        </>
      )}

      <SlideOut
        showing={/^\/rental\/settings\/merchant-billing/.test(location.pathname)}
        onClose={() => history.replace('/rental/settings')}
        onBack={() => history.replace('/rental/settings')}
      >
        <ErrorBoundary forceMobile>
          <MerchantBilledInsuranceSettings
            merchant={merchant}
            onEnable={() => {
              onUpdate((prev) => ({
                ...prev,
                Details: { ...prev.Details, BillingMode: BillingMode.Merchant }
              }));
              history.replace('/rental/settings');
            }}
          />
        </ErrorBoundary>
      </SlideOut>

      <SlideOut
        showing={/^\/rental\/settings\/unified-waiver-flow/.test(location.pathname)}
        onClose={() => history.replace('/rental/settings')}
        onBack={() => history.replace('/rental/settings')}
      >
        <ErrorBoundary forceMobile>
          <MerchantUnifiedWaiverFlowSettings
            merchant={merchant}
            onEnable={(enabled) => {
              onUpdate((prev) => ({
                ...prev,
                Details: { ...prev.Details, UnifiedWaiverFlowEnabled: enabled }
              }));
              history.replace('/rental/settings');
            }}
          />
        </ErrorBoundary>
      </SlideOut>
    </>
  );
};

const MerchantRentalResources = () => {
  const [configuration, setConfiguration] = React.useState<MerchantRentalConfiguration>();
  const [referralLink, setReferralLink] = React.useState<string>();
  const [qrCode, setQrCode] = React.useState<string>();

  const [loading, setLoading] = React.useState(false);
  const [downloadLoading, setDownloadLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const onFetchData = async () => {
    setLoading(true);
    try {
      const config = await getMerchantRentalConfiguration();
      setConfiguration(config.Configuration);
      setReferralLink(config.ReferralLink);
      setQrCode(config.QRCodeBase64);
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    setError(undefined);
    setDownloadLoading(true);
    requestQRCodePdf()
      .then((res) => {
        const binaryString = window.atob(res.DocumentZip);
        const binaryLen = binaryString.length;

        const arrayBuffer = new ArrayBuffer(binaryLen);
        const intArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryLen; i++) {
          intArray[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: 'application/zip' });
        window.open(URL.createObjectURL(blob), '_self');
      })
      .catch(() => {
        setError(
          'Seems like something went wrong, please contact partners@withoyster.com to get your marketing kit!'
        );
      })
      .finally(() => setDownloadLoading(false));
  };

  React.useEffect(() => {
    onFetchData();
  }, []);

  return (
    <>
      {loading && (
        <LoadableContainer>
          <Spinner color="#333333" />
        </LoadableContainer>
      )}
      {!loading && configuration && (
        <>
          <SettingsContainer>
            <Setting
              title="Customer self-serve flow"
              description="Allow customers to create, sign, and pay for waivers on their own with a custom, co-branded landing page."
            >
              <p style={{ margin: 0, maxWidth: '600px', fontSize: '0.9em' }}>
                Download and print this QR code. We recommend attaching it to every asset you rent
                out and writing the name and serial number for your customers to easily reference.
              </p>
              {qrCode && (
                <img
                  src={`data:image/png;base64, ${qrCode}`}
                  style={{
                    width: '300px',
                    height: '300px',
                    border: '2px solid #f2f2f2',
                    margin: '20px 0px'
                  }}
                />
              )}
              {referralLink && (
                <div style={{ maxWidth: '600px' }}>
                  <CopyableLink link={referralLink} />
                </div>
              )}
              <p style={{ maxWidth: '600px', fontSize: '0.9em' }}>
                Oyster also provides a co-branded marketing kit which includes a flyer summarizing
                the coverages of the waiver and the same QR code your customers can use to purchase
                a waiver. We recommend putting this up in your shop and sending it digitally for
                every reservation.
              </p>
              <ButtonContainer>
                <Button
                  primary
                  leftIcon={<IoDownload />}
                  loading={downloadLoading}
                  onClick={onDownload}
                >
                  Download your kit
                </Button>
              </ButtonContainer>
              {error && <ErrorDisplay>{error}</ErrorDisplay>}
            </Setting>
          </SettingsContainer>
        </>
      )}
    </>
  );
};

const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const getMonthsDate = (today: Date) =>
  [0, 1, 2, 3, 4, 5, 6].map((diff) => ({
    start: Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - diff, 1, 0, 0, 0, 0),
    end: Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1 - diff, 1, 0, 0, 0, 0)
  }));

const getMonthName = (date: Date): string => monthList[date.getUTCMonth()];

export const MerchantRentalStatementsPage = (props: { waivers: WaiverEntry[] }) => {
  const monthOptions = getMonthsDate(new Date()).map(({ start, end }) => ({
    title: `${getMonthName(new Date(start))} ${new Date(start).getUTCFullYear()}`,
    start,
    end
  }));

  const [selectedMonth, setSelectedMonth] = React.useState(monthOptions[0]);
  const waivers = props.waivers.filter(
    (w): w is { Waiver: RentalWaiver; Booking: RentalBooking } =>
      !!w.Waiver &&
      !!w.Booking &&
      [WaiverState.active, WaiverState.expired].includes(w.Waiver.State) &&
      new Date(w.Booking.StartTime).getTime() >= selectedMonth.start &&
      new Date(w.Booking.StartTime).getTime() < selectedMonth.end
  );

  return (
    <div>
      <SettingsContainer>
        <Setting
          title="Time Period"
          description="Filter your account statements down to the specified time period."
        >
          <Select
            style={{ maxWidth: '240px' }}
            initialSelected={selectedMonth.title}
            options={monthOptions.map((o) => ({ value: o.title }))}
            onChange={(value) => setSelectedMonth(monthOptions.find((m) => m.title === value)!)}
          />
        </Setting>
        <Setting
          title="Detailed Statement"
          description="Line-by-line description of waivers created and billed in this period."
        >
          {waivers.length === 0 && <p>No waivers in this billing period.</p>}
          {waivers.length > 0 && <MerchantRentalStatement waivers={waivers} />}
        </Setting>
      </SettingsContainer>
    </div>
  );
};

const MerchantRentalStatement = (props: {
  waivers: { Waiver: RentalWaiver; Booking: RentalBooking }[];
}) => {
  const calculateDuration = (booking: RentalBooking) => {
    const days = Math.ceil(
      (new Date(booking.EndTime).getTime() - new Date(booking.StartTime).getTime()) /
        (24 * 60 * 60 * 1000)
    );

    if (days === 1) {
      return `${days} day`;
    }

    return `${days} days`;
  };

  return (
    <Statement
      lineItems={props.waivers.map((waiver) => ({
        waiverId: waiver.Waiver.ID,
        date: new Date(waiver.Booking.StartTime).toLocaleDateString(),
        customer:
          waiver.Booking.Details.Insured.FirstName + ' ' + waiver.Booking.Details.Insured.LastName,
        assets:
          waiver.Waiver.Details.Assets?.map(
            (a) => a.Asset?.Name || a.BookingLineItem?.Name || ''
          ).filter((a) => !!a) || [],
        rentalDuration: calculateDuration(waiver.Booking),
        premium: waiver.Waiver.Details.Premium.Total,
        adjustments: [
          {
            description: 'Paid by customer',
            show: waiver.Waiver?.Details.BillingMode !== BillingMode.Merchant,
            amount: -waiver.Waiver.Details.Premium.Total
          },
          {
            description: 'Merchant Fee',
            show: waiver.Waiver.Details.Premium.MerchantFeeAmount > 0,
            amount: -waiver.Waiver.Details.Premium.MerchantFeeAmount
          }
        ].filter((a) => a.show)
      }))}
    />
  );
};

export const MerchantRentalPage = () => {
  const history = useHistory();
  const location = useLocation();

  const [merchant, setMerchant] = React.useState<Merchant>();
  const [configuration, setConfiguration] = React.useState<MerchantRentalConfiguration>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onFetchData = async () => {
    setLoading(true);
    try {
      const merchant = await getMerchantAccount();
      setMerchant(merchant.Merchant);
      const config = await getMerchantRentalConfiguration();
      setConfiguration(config.Configuration);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    onFetchData();
  }, []);

  const getWaiverIdFromRentalUrl = (): string => {
    const pathMatch = window.location.pathname.match(/.*\/rental\/overview\/waiver\/(.*)\/.*/);
    if (pathMatch && pathMatch.length > 1) {
      return pathMatch[1];
    } else {
      return '';
    }
  };

  const getBookingIdFromRentalUrl = (): string => {
    const pathMatch = window.location.pathname.match(/.*\/rental\/overview\/booking\/(.*)\/.*/);
    if (pathMatch && pathMatch.length > 1) {
      return pathMatch[1];
    } else {
      return '';
    }
  };

  return (
    <>
      <PageTitle title="Rental" description="Manage your rental waivers and claims.">
        <ErrorBoundary forceMobile>
          {loading && <Spinner color="#333333" />}
          {!loading &&
            merchant &&
            (!configuration || configuration?.Details.State !== RentalPolicyState.approved) && (
              <MerchantRentalSetup
                merchant={merchant}
                configuration={configuration}
                onContinueToRental={() => history.replace('/rental')}
              />
            )}

          {!loading &&
            merchant &&
            configuration &&
            configuration?.Details.State === RentalPolicyState.approved && (
              <PageNavigation
                redirect={{ '/': '/overview' }}
                routes={[
                  {
                    path: '/overview',
                    title: 'Bookings',
                    render: () => (
                      <Loadable request={getRentalWaivers()}>
                        {(waivers) => (
                          <MerchantRentalList
                            waivers={waivers}
                            rentalConfiguration={configuration}
                          />
                        )}
                      </Loadable>
                    )
                  },
                  {
                    path: '/assets',
                    title: 'Asset Manager',
                    render: () => <MerchantRentalAssetManager />
                  },
                  {
                    path: '/resources',
                    title: 'Resources',
                    render: () => <MerchantRentalResources />
                  },
                  {
                    path: '/statements',
                    title: 'Statements',
                    render: () => (
                      <Loadable request={getRentalWaivers()}>
                        {(waivers) => <MerchantRentalStatementsPage waivers={waivers} />}
                      </Loadable>
                    )
                  },
                  {
                    path: '/settings',
                    title: 'Settings',
                    render: () => <MerchantRentalSettings />
                  }
                ]}
              />
            )}

          <SlideOut
            showing={/^\/rental\/overview\/waiver\/?.*\/edit/.test(location.pathname)}
            onClose={() => history.replace('/rental/overview')}
            onBack={() => history.replace('/rental/overview')}
          >
            <ErrorBoundary forceMobile>
              <RentalWaiverForm
                waiverId={getWaiverIdFromRentalUrl()}
                onWaiverCreated={(booking, waiver, showConfirmationInDashboard) => {
                  onFetchData();
                  if (showConfirmationInDashboard) {
                    history.push(`/rental/overview/booking/${booking.ID}/view`);
                  } else {
                    history.push(`/rental/overview/waiver/${waiver.ID}/sent`);
                  }
                }}
              />
            </ErrorBoundary>
          </SlideOut>

          <SlideOut
            showing={/^\/rental\/overview\/booking\/.*\/view/.test(location.pathname)}
            onClose={() => history.replace('/rental/overview')}
            onBack={() => history.replace('/rental/overview')}
          >
            <ErrorBoundary forceMobile>
              <RentalBookingDetailsPage
                bookingId={getBookingIdFromRentalUrl()}
                onCreateWaiver={() => history.push('/rental/overview/waiver/create')}
                onViewWaiver={(waiver) => history.push(`/rental/overview/waiver/${waiver.ID}/edit`)}
                onFileClaim={(waiver) => history.push(`/rental/overview/waiver/${waiver.ID}/claim`)}
              />
            </ErrorBoundary>
          </SlideOut>

          <SlideOut
            showing={/^\/rental\/overview\/waiver\/.*\/sent/.test(location.pathname)}
            onClose={() => history.replace('/rental/overview')}
            onBack={() => history.replace('/rental/overview')}
          >
            <ErrorBoundary forceMobile>
              <RentalWaiverSentPage onClose={() => history.replace('/rental/overview')} />
            </ErrorBoundary>
          </SlideOut>

          <SlideOut
            showing={/^\/rental\/overview\/waiver\/.*\/claim/.test(location.pathname)}
            onClose={() => history.replace('/rental/overview')}
            onBack={() => history.replace('/rental/overview')}
          >
            <ErrorBoundary forceMobile>
              <RentalClaimForm
                waiverId={getWaiverIdFromRentalUrl()}
                claimId={''}
                onClaimSubmitted={(_, booking) => {
                  if (booking) {
                    history.replace(`/rental/overview/booking/${booking.ID}/view`);
                  } else {
                    history.replace('/rental/overview');
                  }
                }}
              />
            </ErrorBoundary>
          </SlideOut>
        </ErrorBoundary>
      </PageTitle>
    </>
  );
};
