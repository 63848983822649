import { getToken, resetToken } from '@oysterjs/core/auth';
import config from '@oysterjs/core/config';
import { ErrorCode, ErrorType, WrappedError } from '@oysterjs/core/errors';

export const getEncodedQueryString = (m: Record<string, string | boolean | undefined>): string => {
  const params = new URLSearchParams();
  Object.entries(m).forEach(([key, value]) => {
    if (value) {
      params.set(key, value.toString());
    }
  });

  return params.toString();
};

interface RequestOpts extends RequestInit {
  redirectUrl?: string;
  disableUnauthorizedRedirect?: boolean;
}

export const Get = <T>(path: RequestInfo, init?: RequestOpts) =>
  request<T>(path, undefined, { ...init, method: 'GET' });

export const Post = <T>(path: RequestInfo, json?: unknown, opts?: RequestOpts) =>
  request<T>(path, json ? JSON.stringify(json) : undefined, { ...opts, method: 'POST' });

export const Put = <T>(path: RequestInfo, json?: unknown, opts?: RequestOpts) =>
  request<T>(path, json ? JSON.stringify(json) : undefined, { ...opts, method: 'PUT' });

export const Delete = <T>(path: RequestInfo, json?: unknown, opts?: RequestOpts) =>
  request<T>(path, json ? JSON.stringify(json) : undefined, { ...opts, method: 'DELETE' });

const request = <T>(path: RequestInfo, json: string | undefined, opts: RequestOpts): Promise<T> =>
  fetch(config().backendBaseUrl.api + path, {
    ...opts,
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...(json ? { 'Content-Type': 'application/json' } : {}),
      ...(getToken() ? { Authorization: `Bearer ${getToken()}` } : {}),
      ...opts.headers
    },
    body: opts.body || json
  })
    .catch((err) => {
      throw new WrappedError('Error performing network request', {
        code: ErrorCode.unknown,
        type: ErrorType.networkError,
        details: `Error performing ${opts.method} ${path}`,
        cause: err
      });
    })
    .then((res) =>
      res.json().then((data) => {
        if (res.status < 300 && data) {
          return data;
        }

        if (!opts.disableUnauthorizedRedirect && res.status === 401) {
          resetToken(opts.redirectUrl);
          return data;
        }

        if (data.Error) {
          throw WrappedError.fromApiError(data.Error);
        }

        throw new WrappedError(`An unknown error with status ${res.status} occurred`, {
          type: ErrorType.unknownApiError,
          code: ErrorCode.unknown,
          details: data.toString()
        });
      })
    );
