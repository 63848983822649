import { init as initApm } from '@oysterjs/core/apm';
initApm();

import { init as initAppConfiguration } from '@oysterjs/core/api/appConfiguration';
initAppConfiguration();

import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Switch, Route as BrowserRoute, Redirect } from 'react-router-dom';

import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);

import config from '@oysterjs/core/config';

import { Dashboard } from './pages';
import MerchantPersonalization from './pages/personalization';

import {
  OnboardCheckfront,
  OnboardLightspeedRetailRSeries,
  OnboardShopify,
  OnboardWooCommerce
} from './onboard';
import { useGA } from '@oysterjs/core/ga';
import { SignIn, SignUp } from './pages/signin';
import { useGoogleTags } from '@oysterjs/core/analytics/googletags';
import { HubspotChatContextProvider } from '@oysterjs/core/hubspot';
import { Authenticated } from './authenticated';
import { CertificateApp } from './pages/certificate';
import { ApolloProvider } from '@apollo/client';
import { getMerchantGraphQLClient } from '@oysterjs/core/api/merchant';

import { Landing as CommercialLanding } from './pages/commercial/landing';
import * as CommercialApp from './pages/commercial/app';
import { ApplicationWrapper } from './pages/commercial/common';

const App = () => {
  if (config().environment === 'production') {
    useGA('G-MX1C87Q096');
    useGoogleTags();
  }

  return (
    <Router>
      <HubspotChatContextProvider>
        <ApolloProvider client={getMerchantGraphQLClient()}>
          <Switch>
            <Route path="/link/shopify">
              <OnboardShopify />
            </Route>
            <Route path="/link/lsretail-rseries">
              <OnboardLightspeedRetailRSeries />
            </Route>
            <Route path="/link/woocommerce">
              <OnboardWooCommerce />
            </Route>
            <Route path="/link/checkfront">
              <OnboardCheckfront />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
            <Route path="/signin">
              <SignIn />
            </Route>
            <Route path="/personalization">
              <MerchantPersonalization />
            </Route>
            <Route path="/certificate/:certificateNumber">
              {(props) => (
                <CertificateApp certificateNumber={props.match?.params.certificateNumber || ''} />
              )}
            </Route>
            <Route path="/commercial-insurance">
              <Redirect to="/commercial" />
            </Route>
            <Route exact path="/commercial">
              <CommercialLanding />
            </Route>
            <Route path="/commercial/m/:id">
              {(props) => <CommercialLanding merchantHandle={props.match?.params.id || ''} />}
            </Route>
            <Route exact path="/commercial/app/:id">
              {(props) => (
                <ApplicationWrapper id={props.match?.params.id || ''}>
                  <CommercialApp.Intro />
                </ApplicationWrapper>
              )}
            </Route>
            <Route exact path="/commercial/app/:id/business">
              {(props) => (
                <ApplicationWrapper id={props.match?.params.id || ''}>
                  <CommercialApp.Business />
                </ApplicationWrapper>
              )}
            </Route>
            <Route exact path="/commercial/app/:id/locations">
              {(props) => (
                <ApplicationWrapper id={props.match?.params.id || ''}>
                  <CommercialApp.Locations />
                </ApplicationWrapper>
              )}
            </Route>
            <Route exact path="/commercial/app/:id/losses">
              {(props) => (
                <ApplicationWrapper id={props.match?.params.id || ''}>
                  <CommercialApp.Losses />
                </ApplicationWrapper>
              )}
            </Route>
            <Route exact path="/commercial/app/:id/carriers">
              {(props) => (
                <ApplicationWrapper id={props.match?.params.id || ''} includeQuotableCarriers>
                  <CommercialApp.CarrierSelection />
                </ApplicationWrapper>
              )}
            </Route>
            <Route exact path="/commercial/app/:id/underwriting">
              {(props) => (
                <ApplicationWrapper id={props.match?.params.id || ''}>
                  <CommercialApp.Underwriting />
                </ApplicationWrapper>
              )}
            </Route>
            <Route exact path="/commercial/app/:id/quote">
              {(props) => (
                <ApplicationWrapper id={props.match?.params.id || ''} includeQuotes>
                  <CommercialApp.Quote />
                </ApplicationWrapper>
              )}
            </Route>
            <Route exact path="/commercial/app/:id/complete">
              {(props) => (
                <ApplicationWrapper id={props.match?.params.id || ''} includeQuotes>
                  <CommercialApp.Complete />
                </ApplicationWrapper>
              )}
            </Route>
            <Route path="/">
              <Authenticated>
                <Dashboard />
              </Authenticated>
            </Route>
          </Switch>
        </ApolloProvider>
      </HubspotChatContextProvider>
    </Router>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
