import * as React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import styled from 'styled-components';

const BannerContainer = styled.div`
  background: #fff3f0;
  padding: 20px 20px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;

  transition: 0.15s all ease-in-out;

  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const IconContainer = styled.div`
  font-size: 1.2em;
`;

const TextContainer = styled.div`
  flex-grow: 1;
`;

const ActionContainer = styled.div`
  min-width: 180px;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 600px) {
    justify-content: flex-start;
  }
`;

const TitleText = styled.div`
  font-weight: 500;
  font-size: 0.9em;
  padding-bottom: 5px;
`;

const DescriptionText = styled.div`
  font-size: 0.8em;
  color: #555555;
`;

interface BannerProps {
  title: string;
  description: string | JSX.Element;
  bannerAction?: JSX.Element;
}

export const Banner: React.FunctionComponent<
  React.AllHTMLAttributes<HTMLDivElement> & BannerProps
> = (props): JSX.Element => (
  <BannerContainer {...props}>
    <IconContainer aria-hidden>
      <IoInformationCircleOutline />
    </IconContainer>
    <ContentContainer>
      <TextContainer>
        <TitleText>{props.title}</TitleText>
        <DescriptionText>{props.description}</DescriptionText>
      </TextContainer>
      {props.bannerAction && <ActionContainer>{props.bannerAction}</ActionContainer>}
    </ContentContainer>
  </BannerContainer>
);
