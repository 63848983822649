import config from './config';

export const MAPBOX_PUBLIC_KEY = () => config().secrets.mapboxPublicKey || '';

export const getLocationSuggestions = (
  address: string
): Promise<{ address: string; coordinates: [number, number] }[]> =>
  // TODO: add proximity to user zip code
  fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(
      address
    )}.json?access_token=${MAPBOX_PUBLIC_KEY()}&autocomplete=true&country=us&types=locality,neighborhood,address,poi`
  ).then((res) =>
    res.json().then((data) => {
      if (res.status === 200 && data) {
        // parse data
        return data.features.map((f) => ({ address: f.place_name, coordinates: f.center }));
      }
      if (data) {
        throw new Error(JSON.stringify(data));
      }
      throw new Error('ugh?');
    })
  );

export const getMapURL = (
  longitude: number,
  latitude: number,
  opts?: { zoomLevel?: number; height?: number; width?: number }
): string =>
  `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+0EA5E9(${longitude},${latitude})/${longitude},${latitude},${
    opts?.zoomLevel || 12
  },0/${opts?.width || 400}x${opts?.height || 280}@2x?access_token=${MAPBOX_PUBLIC_KEY()}`;
