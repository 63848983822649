import styled from 'styled-components';

import { Checkbox } from './checkbox';
import { Radio } from './radio';

const OptionContainer = styled.div<{ enabled: boolean; disabled?: boolean }>`
  transition: 0.15s all ease-in-out;
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  display: flex;
  flex-direction: row;
`;

const OptionCheckboxContainer = styled.div`
  width: 27px;
  padding-left: 3px;
`;

const OptionImageContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  background: #eeeeee;

  img {
    width: 60px;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const OptionContentContainer = styled.div`
  flex: 1 1 0;
`;

const OptionDescription = styled.div`
  color: #999999;
  font-size: 0.8em;
  margin-top: 5px;
`;

const OptionContent = styled.div`
  color: #999999;
  font-size: 0.8em;
  margin-top: 5px;
  padding: 10px 0px 5px 0px;
`;

const OptionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
`;

const OptionHeader = ({
  title,
  description
}: {
  title: string;
  description?: string | JSX.Element;
}) => (
  <OptionHeaderContainer>
    <span>{title}</span>
    <span>{description}</span>
  </OptionHeaderContainer>
);

interface OptionGroupProps {
  multi?: boolean;
  loading?: boolean;
  name: string;
  options: Array<{
    title: string;
    description: string | JSX.Element;
    imageUrl?: string;
    titleRight?: string | JSX.Element;
    enabled?: boolean;
    disabled?: boolean;
    children?: JSX.Element;
    onSelect?: () => void;
  }>;
}

export const OptionGroup = (props: OptionGroupProps): JSX.Element => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px 10px' }}>
    {props.options.map((option) => (
      <OptionContainer
        key={option.title}
        enabled={option.enabled || false}
        disabled={option.disabled || false}
        onClick={() => option.onSelect && option.onSelect()}
      >
        <OptionCheckboxContainer>
          {props.multi ? (
            <Checkbox
              label={option.title}
              checked={option.enabled || false}
              disabled={option.disabled || false}
              onChange={() => option.onSelect && option.onSelect()}
            />
          ) : (
            <Radio
              label={option.title}
              checked={option.enabled || false}
              disabled={option.disabled || false}
              onChange={() => option.onSelect && option.onSelect()}
            />
          )}
        </OptionCheckboxContainer>
        {option.imageUrl && (
          <OptionImageContainer>
            <img src={option.imageUrl} />
          </OptionImageContainer>
        )}
        <OptionContentContainer>
          <OptionHeader title={option.title} description={option.titleRight} />
          <OptionDescription>{option.description}</OptionDescription>
          {option.enabled && option.children && <OptionContent>{option.children}</OptionContent>}
        </OptionContentContainer>
      </OptionContainer>
    ))}
  </div>
);
