import React from 'react';
import styled from 'styled-components';
import { Table } from '@oysterjs/ui/Table';

export const SettingsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Setting = (
  props: React.PropsWithChildren<{ title: string; description: string; rightAction?: JSX.Element }>
) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3 style={{ fontWeight: 500, fontSize: '1em', margin: '0' }}>{props.title}</h3>
        {props.rightAction && <div>{props.rightAction}</div>}
      </div>
      <p style={{ fontSize: '0.9em', color: '#666666', margin: '5px 0px 0px 0px' }}>
        {props.description}
      </p>
    </div>
    <div style={{ margin: '24px 0px' }}>{props.children}</div>
  </div>
);

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  & > div {
    flex: 1 0 0;
    flex-basis: 40%;
    box-sizing: border-box;

    &:not(:first-child) {
      max-width: calc(50% - 5px);
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    & > div {
      &:not(:first-child) {
        max-width: 100%;
      }
    }
  }
`;

const RentalSlideoutHeader = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 0px;
`;

interface RentalPageContainerProps {
  title: string;
  description: string;
  badge?: JSX.Element;
}

export const RentalPageContainer = (props: React.PropsWithChildren<RentalPageContainerProps>) => (
  <div style={{ padding: '0px 20px' }}>
    <RentalSlideoutHeader>
      <h1>{props.title}</h1>
      {props.badge}
    </RentalSlideoutHeader>
    <p style={{ margin: '-1em 0 1em 0', fontSize: '0.9em', color: '#666666' }}>
      {props.description}
    </p>
    {props.children}
    <p style={{ fontSize: '0.9em', color: '#333333' }}>
      <b style={{ fontWeight: 500 }}>Need help?</b>{' '}
      <a
        target="_blank"
        href={`https://meetings.hubspot.com/gunnar-reinig/commercial-insurance-consultation`}
      >
        Schedule a call
      </a>{' '}
      with us!
    </p>
  </div>
);

const StatementTable = styled(Table)`
  margin: 0;

  thead {
    text-align: left;
  }

  tbody tr td {
    border: 0;
    font-size: 0.9em;
  }

  tbody tr td:last-child,
  thead tr th:last-child {
    text-align: right;
  }

  tr.line-item td:last-child {
    font-weight: 500;
  }

  tr.line-item--no-adjustment td {
    border-bottom: 1px solid #f2f2f2;
  }

  tr.line-item--with-adjustment td {
    padding-bottom: 0px;
  }

  tr.line-item-adjustment td {
    font-size: 0.8em;
    padding-top: 4px;
    padding-bottom: 0px;
    color: #666666;
  }

  tr.line-item-adjustment--last-of-group td {
    padding-bottom: 16px;
    border-bottom: 1px solid #f2f2f2;
  }

  tr.summary-line td {
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 0px;
  }

  tr.summary-line--first-line td {
    border-top: 3px solid #eaeaea;
  }
`;

interface Adjustment {
  description: string;
  amount: number;
}

interface LineItem {
  waiverId: string;
  date: string;
  customer: string;
  assets: string[];
  rentalDuration: string;
  premium: number;
  adjustments: Adjustment[];
}

export const Statement = (props: { lineItems: LineItem[] }) => (
  <StatementTable>
    <thead>
      <tr>
        <th>Date</th>
        <th>Customer</th>
        <th>Assets Rented</th>
        <th>Rental Duration</th>
        <th>Amount</th>
      </tr>
    </thead>
    <tbody>
      {props.lineItems.map((item) => (
        <React.Fragment key={item.waiverId}>
          <tr
            className={`line-item${
              item.adjustments.length === 0
                ? ' line-item--no-adjustment'
                : ' line-item--with-adjustment'
            }`}
          >
            <td>{item.date}</td>
            <td>{item.customer}</td>
            <td>{item.assets.join(', ')}</td>
            <td>{item.rentalDuration}</td>
            <td style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'USD'
              }).format(item.premium)}
            </td>
          </tr>
          {item.adjustments.map((adjustment, i) => (
            <tr
              className={`line-item-adjustment${
                item.adjustments.length - 1 === i ? ' line-item-adjustment--last-of-group' : ''
              }`}
            >
              <td />
              <td colSpan={3}>{adjustment.description}</td>
              <td style={{ textAlign: 'right' }}>
                {new Intl.NumberFormat(undefined, {
                  style: 'currency',
                  currency: 'USD'
                }).format(adjustment.amount)}
              </td>
            </tr>
          ))}
        </React.Fragment>
      ))}
      <tr className="summary-line summary-line--first-line">
        <td colSpan={4}>Total Waivers</td>
        <td>{props.lineItems.length}</td>
      </tr>
      <tr className="summary-line">
        <td colSpan={4}>Total Waiver Premium</td>
        <td>
          {new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'USD'
          }).format(props.lineItems.reduce((acc, curr) => acc + curr.premium, 0))}
        </td>
      </tr>
      <tr className="summary-line">
        <td colSpan={4}>Total Premium Due</td>
        <td>
          {new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'USD'
          }).format(
            props.lineItems.reduce(
              (acc, curr) =>
                acc + curr.premium + curr.adjustments.reduce((acc2, curr) => acc2 + curr.amount, 0),
              0
            )
          )}
        </td>
      </tr>
    </tbody>
  </StatementTable>
);
