import React from 'react';
import clsx from 'clsx';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
interface PageNavigationRoute {
  path: string;
  title: string;
  count?: number;
  exact?: boolean;
  absolute?: boolean;
  render: () => JSX.Element;
}

interface PageNavigationProps {
  routes: PageNavigationRoute[];
  redirect?: Record<string, string>;
}

export const PageNavigation = (props: React.PropsWithChildren<PageNavigationProps>) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const concatPath = (base: string, relative: string) =>
    `${base.replace(/\/+$/, '')}/${relative.replace(/\/+$/, '').replace(/^\/+/, '')}`;

  return (
    <div className="-mt-6">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          value={
            props.routes.find((tab) => window.location.pathname.includes(concatPath(url, tab.path)))
              ?.path
          }
          onChange={(e) => history.push(concatPath(url, e.currentTarget.value))}
          className="block w-full rounded-md border-neutral-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
        >
          {props.routes.map((tab) => (
            <option key={tab.path} value={tab.path}>
              {tab.title}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-neutral-200">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {props.routes.map((tab) => (
              <NavLink
                key={tab.path}
                to={concatPath(url, tab.path)}
                aria-current={
                  window.location.pathname.includes(concatPath(url, tab.path)) ? 'page' : undefined
                }
                className={clsx(
                  window.location.pathname.includes(concatPath(url, tab.path))
                    ? 'border-primary-500 text-primary-600'
                    : 'border-transparent text-neutral-500 hover:border-neutral-200 hover:text-neutral-700',
                  'flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                )}
              >
                {tab.title}
                {tab.count ? (
                  <span
                    className={clsx(
                      window.location.pathname.includes(concatPath(url, tab.path))
                        ? 'bg-primary-200/65 text-primary-600'
                        : 'bg-neutral-200 text-neutral-900',
                      'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div className="mt-8">
        <Switch>
          {props.routes.map((route) => (
            <Route
              exact={route.exact}
              key={route.path}
              path={concatPath(path, route.path)}
              render={route.render}
            />
          ))}
          {Object.entries(props.redirect || {}).map(([from, to]) => (
            <Redirect
              exact
              key={from + to}
              from={concatPath(path, from)}
              to={concatPath(path, to)}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
};
