import { Address, MercuryPaymentTypes, PaymentMethodType } from '@oysterjs/types';
import { Get, Post } from './base';

export const setMerchantPayment = (data: {
  PublicToken: string;
  PaymentType: MercuryPaymentTypes;
  Address?: Partial<Address>;
}) => Post('/merchant/payment', data);

export const getMerchantPayment = () =>
  Get<{ Address: Address; PaymentType: PaymentMethodType; MercuryRecipientID: string }>(
    '/merchant/payment'
  );

export const connectToPlaid = () => Get<{ LinkToken: string }>('/merchant/plaid');
