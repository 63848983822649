import React from 'react';

import { RentalPageContainer } from './components';
import { Button, ButtonContainer, LinkComponent, UnstyledButton } from '@oysterjs/ui/Button';
import { IoArrowForward, IoTrashOutline } from 'react-icons/io5';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { Checkbox, CheckboxProps } from '@oysterjs/ui/Form/checkbox';
import { Loadable } from '@oysterjs/ui/Loadable';
import {
  deleteRentalDocument,
  getMerchantRentalPaymentSetup,
  getRentalDocuments,
  uploadRentalDocuments
} from '@oysterjs/core/api/merchant';
import { PaymentMethodInput } from '@oysterjs/core/payment';
import { Merchant } from '@oysterjs/types';
import { Table } from '@oysterjs/ui/Table';
import { AttachmentUploader } from '@oysterjs/ui/Attachment';
import { WrappedError } from '@oysterjs/core/errors';
import { ErrorDisplay } from '@oysterjs/ui/Form/text';
import { Spinner } from '@oysterjs/ui/Spinner';

const LabeledCheckbox = (props: CheckboxProps) => (
  <div style={{ display: 'flex', gap: '8px', cursor: 'pointer' }} onClick={props.onChange}>
    <Checkbox {...props} />
    <div style={{ color: '#999999', fontSize: '0.9em' }}>{props.label}</div>
  </div>
);

export const MerchantBilledInsuranceSettings = (props: {
  merchant?: Merchant;
  onEnable: () => void;
}) => {
  const [agreementAccepted, setAgreementAccepted] = React.useState(false);

  return (
    <RentalPageContainer
      title="Enable merchant billing"
      description="Configure and enable merchant billing for rental waiver premium"
    >
      <Loadable request={getMerchantRentalPaymentSetup()} exactlyOnce>
        {(setup) => (
          <>
            <p>
              Simplify the process to cover your customers and your rentals by paying for the
              waivers on behalf of the customer. You can then choose to consolidate the waiver
              charge into a single point-of-sale transaction at the time of rental.
            </p>
            <h2>How it works</h2>
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <li>Connect your bank account here.</li>
              <li>
                For subsequently-created waivers, Oyster won't collect premium from the customer.
                Instead, Oyster will count the premium for your store.
              </li>
              <li>
                At the start of the month, Oyster will send an invoice for the waiver premium owed
                from the prior month, accounting for any fees or remittances. Your account will be
                debited the quoted amount 7 days later.
              </li>
            </ul>
            {setup.SetupIntentClientSecret && (
              <PaymentMethodInput
                getPaymentIntent={() => Promise.resolve(setup.SetupIntentClientSecret || '')}
                returnUrl={window.location.origin + `/rental/settings/merchant-billing`}
                setupOnly
                hideLegal
                continueLabel="Connect Account"
                defaultName={props.merchant?.BusinessProfile.Name}
                defaultEmail={props.merchant?.BusinessProfile.Email}
              />
            )}
            {setup.PaymentMethod && (
              <FormContainer onSubmit={(e) => e.preventDefault()}>
                <FormRowHeader title="Connected Account" />
                <FormRow>
                  <FormColumn>
                    {setup.PaymentMethod.BankName} {setup.PaymentMethod.LastFour}
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <LabeledCheckbox
                      label="By checking this box, you authorize Oyster Technologies, Inc. to periodically debit the connected bank account for any amount owed for charges arising from your use of Oyster Technologies, Inc.'s services and/or purchase of products from Oyster Technologies, Inc., pursuant to Oyster Technologies, Inc.'s website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to Oyster Technologies, Inc. with 30 (thirty) days notice. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained."
                      checked={agreementAccepted}
                      onChange={() => setAgreementAccepted((prev) => !prev)}
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <Button
                    primary
                    icon={<IoArrowForward />}
                    disabled={!agreementAccepted}
                    onClick={props.onEnable}
                  >
                    Accept and enable
                  </Button>
                </FormRow>
              </FormContainer>
            )}
          </>
        )}
      </Loadable>
    </RentalPageContainer>
  );
};

export const MerchantUnifiedWaiverFlowSettings = (props: {
  merchant?: Merchant;
  onEnable: (enabled: boolean) => void;
}) => {
  // Fetch documents
  const [documents, setDocuments] = React.useState<{ Name: string; ID: string }[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const getDocuments = async () => {
    setLoading(true);
    setError(undefined);

    try {
      setDocuments(await getRentalDocuments());
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getDocuments();
  }, []);

  // Add documents
  const [attachments, setAttachments] = React.useState<File[]>([]);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const onAttachmentsAdded = async (files: File[]) => setAttachments((prev) => [...prev, ...files]);

  const uploadDocuments = async () => {
    setUploadLoading(true);
    setError(undefined);

    try {
      if (attachments.length > 0) {
        const updatedFiles = await uploadRentalDocuments(attachments);
        setAttachments([]);
        setDocuments(updatedFiles);
      }

      props.onEnable(true);
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      setError(err.message);
    } finally {
      setUploadLoading(false);
    }
  };

  // Delete document
  const deleteDocument = async (id: string) => {
    setDeleteLoading(true);
    setError(undefined);

    try {
      setDocuments(await deleteRentalDocument(id));
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      setError(err.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <RentalPageContainer
      title="Enable unified waiver flow"
      description="Configure and enable the collection of signatures for your own rental documents alongside Oyster's rental theft and damage waiver"
    >
      <h2>How it works</h2>
      <ul style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <li>Upload your rental documents and enable this feature.</li>
        <li>
          Provide your customers access to our flow via{' '}
          <LinkComponent target="_blank" href="/rental/resources">
            link or QR code
          </LinkComponent>
          .
        </li>
        <li>
          The customer fills out the information and reads, agrees to, and signs your documents.
        </li>
        <li>
          View the documents with an appended signature page as part of each booking's details.
        </li>
      </ul>
      <h2>Your rental documents</h2>
      <p>View and manage the documents that customers are asked to agree to here.</p>
      {loading && <Spinner color="#333333" />}
      {!loading && documents.length > 0 && (
        <Table style={{ textAlign: 'left' }}>
          <thead>
            <tr>
              <th style={{ width: '100%' }}>Document Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {documents.map((a) => (
              <tr key={a.ID}>
                <td>{a.Name}</td>
                <td>
                  <UnstyledButton onClick={() => deleteDocument(a.ID)} disabled={loading}>
                    <IoTrashOutline />
                  </UnstyledButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <AttachmentUploader
        title="Upload rental documents"
        description="Add additional documents to have your customers sign"
        attachments={[]}
        multiple
        onAttachmentsAdded={onAttachmentsAdded}
      />
      <ButtonContainer style={{ paddingTop: '16px' }}>
        <Button primary loading={uploadLoading} disabled={deleteLoading} onClick={uploadDocuments}>
          Upload and enable
        </Button>
        <Button disabled={uploadLoading || deleteLoading} onClick={() => props.onEnable(false)}>
          Disable
        </Button>
      </ButtonContainer>
      {error && <ErrorDisplay>{error}</ErrorDisplay>}
    </RentalPageContainer>
  );
};
