import * as Sentry from '@sentry/react';
import appConfiguration from './api/appConfiguration';
import { ReferralSubChannel } from '@oysterjs/types';
import { getUser } from './auth';
import config from './config';

class Apm {
  constructor(private session: Session) {}

  // eslint-disable-next-line
  captureError(error: any) {
    if (Sentry.isInitialized()) {
      Sentry.captureException(error);
    }
  }

  setUserContext(userObject: { id?: string; email?: string }) {
    if (Sentry.isInitialized()) {
      Sentry.setUser(userObject);
    }
    this.session.userId = userObject.id?.toString() || this.session.userId;
  }

  setProductInfo(product) {
    if (!product) return;

    const key = `oyster_product_for_user_${this.getSession().userId}`;
    window.sessionStorage.setItem(key, JSON.stringify(product));

    let itemList: string[] = JSON.parse(
      window.sessionStorage.getItem('oyster_session_product_keys') || '[]'
    );

    if (itemList) {
      itemList = itemList.filter((item) => item !== key);
      window.sessionStorage.setItem(
        'oyster_session_product_keys',
        JSON.stringify([...itemList, key])
      );
    } else {
      window.sessionStorage.setItem('oyster_session_product_keys', JSON.stringify([key]));
    }
  }

  getSession(): Session {
    return this.session;
  }

  getSessionId() {
    return Sentry.getReplay()?.getReplayId();
  }

  getProductInfo() {
    const key = `oyster_product_for_user_${this.getSession().userId}`;
    return JSON.parse(window.sessionStorage.getItem(key) || '""');
  }

  clearProductInfo() {
    const key = `oyster_product_for_user_${this.getSession().userId}`;
    window.sessionStorage.removeItem(key);
  }

  setReferralSubChannel(subChannel?: ReferralSubChannel) {
    if (!subChannel) {
      this.clearReferralSubChannel();
      return;
    }

    const key = `oyster_referral_subchannel_for_user_${this.getSession().userId}`;
    window.sessionStorage.setItem(key, subChannel);
  }

  getReferralSubChannel(): ReferralSubChannel {
    const key = `oyster_referral_subchannel_for_user_${this.getSession().userId}`;
    return (window.sessionStorage.getItem(key) || ReferralSubChannel.unknown) as ReferralSubChannel;
  }

  clearReferralSubChannel() {
    const key = `oyster_referral_subchannel_for_user_${this.getSession().userId}`;
    window.sessionStorage.removeItem(key);
  }

  // eslint-disable-next-line
  async sendEvent(id: string, metadata?: Record<string, any>) {
    const productType = this.getProductInfo();
    const data = {
      UserID: getUser()?.ID || this.session.userId,
      PageLoadID: this.session.pageLoadId
    };
    const featureFlags = await appConfiguration().getAll();

    // Get formatted feature flags
    let formattedFeatureFlags = {};
    featureFlags.forEach(
      (ff) =>
        (formattedFeatureFlags = {
          ...formattedFeatureFlags,
          [ff.ID]: ff.Value
        })
    );

    const updatedMetadata = {
      ...metadata,
      ProductType: productType,
      feature_flags: formattedFeatureFlags,
      referral_sub_channel: metadata?.referral_sub_channel || this.getReferralSubChannel() // Prioritize passed-in values
    };

    const url = `${config().backendBaseUrl.metrics}/event/${id}`;

    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-type': 'application/json',
        'X-Merchant-API-Key': config().merchant?.apiKey || metadata?.merchant_api_key || '',
        'X-Merchant-Integration-ID':
          config().merchant?.integrationId || metadata?.integration_id || ''
      },
      body: JSON.stringify({ ...data, Metadata: updatedMetadata })
    });
  }
}

let apm: Apm;

interface Session {
  userId: string;
  pageLoadId: string;
  merchantId?: string;
}

const initSession = (): Session => {
  const getRandomString = (len: number): string => {
    const characters = '0123456789abcdef';
    let str = '';
    for (let i = 0; i < len; i++) {
      str += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return str;
  };

  // Check if we have an authenticated user ID
  let userId = getUser()?.ID;

  // Check if we already stored an ID locally
  if (!userId) {
    userId = window.localStorage.getItem('oyster_user_id') || undefined;
  }

  // Otherwise, generate an ID and store it
  if (!userId) {
    userId = getRandomString(24);
    window.localStorage.setItem('oyster_user_id', userId);
  }

  return {
    userId,
    pageLoadId: getRandomString(24)
  };
};

export const init = (): void => {
  const session = initSession();

  Sentry.init({
    dsn: config().secrets.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error']
      }),
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [
          window.location.origin,
          config().backendBaseUrl.api,
          config().backendBaseUrl.integrate,
          config().backendBaseUrl.merchant,
          config().backendBaseUrl.metrics,
          config().backendBaseUrl.statics
        ],
        networkCaptureBodies: true,
        networkRequestHeaders: ['X-Merchant-Integration-ID', 'X-Merchant-API-Key'],
        maskAllText: false,
        blockAllMedia: false
      }),
      Sentry.sessionTimingIntegration()
    ],

    autoSessionTracking: true,
    enabled: true,
    enableTracing: true,

    environment: config().environment,
    release: config().serviceVersion,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      config().backendBaseUrl.api,
      config().backendBaseUrl.integrate,
      config().backendBaseUrl.merchant,
      config().backendBaseUrl.metrics,
      config().backendBaseUrl.statics
    ],

    // Capture Replay for 100% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  });

  apm = new Apm(session);
};

export const embeddedInit = (): void => {
  const session = initSession();
  apm = new Apm(session);
};

// eslint-disable-next-line
// @ts-ignore
export default (): Apm => apm;
