import React from 'react';
import { Analytics } from './analytics';

const tagId = 'AW-10974576559';

export enum GetOysterEventNames {
  GetAQuickQuote = 'Get A Quick Quote', // On the first page of getoyster
  ReviewCoverage = 'Review Coverage', // When users see their coverage after form inputs
  PaymentSubmitted = 'Payment Submitted', // When users submit their payment info
  PaymentMethodSubmitted = 'Payment Method Submitted', // When users submit their payment method to create a token
  OfferSubmitted = 'Offer Submitted', // When payment is accepted and policy is confirmed
  ApplicationSubmitted = 'Application Submitted', // When users activate their account

  WaiverPaymentSubmitted = 'Waiver Payment Submitted' // When users submit their payment info
}

const install = () => {
  const scriptId = 'ga-gtag';

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement('script');
  script.id = scriptId;
  script.type = 'text/javascript';
  script.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
  head.insertBefore(script, head.firstChild);

  const tagScript = document.createElement('script');
  tagScript.type = 'text/javascript';
  tagScript.innerText = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${tagId}');
  `;
  head.insertBefore(tagScript, script);
};

export const useGoogleTags = (): void => {
  React.useEffect(() => {
    install();
  }, []);
};

export class GoogleTagAnalytics implements Analytics {
  identify(): void {
    // Do nothing
  }
  screen(): void {
    // Do nothing
  }
  event(name: string, properties: { [property: string]: unknown }): void {
    // Track only when Google Tag is installed
    if (window['dataLayer'] !== undefined && window['dataLayer'].length > 0) {
      const tagEvent = convertEvent(name);
      if (tagEvent.type) {
        window['gtag']('event', tagEvent.type, { send_to: tagEvent.sendTo, ...properties });
      }
    }
  }
  reset(): void {
    // Do nothing
  }
}

interface GTagEvent {
  type: string;
  sendTo: string;
}

const convertEvent = (name: string): GTagEvent => {
  switch (name) {
    case GetOysterEventNames.GetAQuickQuote:
      return {
        type: 'conversion',
        sendTo: `${tagId}/0FLjCNLW8toDEK__ivEo`
      };
    case GetOysterEventNames.ReviewCoverage:
      return {
        type: 'conversion',
        sendTo: `${tagId}/iiNpCNvW8toDEK__ivEo`
      };
    case GetOysterEventNames.OfferSubmitted:
      return {
        type: 'conversion',
        sendTo: `${tagId}/MbxmCIHex-YDEK__ivEo`
      };
    case GetOysterEventNames.ApplicationSubmitted:
      return {
        type: 'conversion',
        sendTo: `${tagId}/zQfFCKSfyNkDEK__ivEo`
      };
    default:
      return {
        type: '',
        sendTo: ''
      };
  }
};
