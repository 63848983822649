import { InsuranceType } from './graphql';

export const getDisplayInsuranceType = (type: InsuranceType | undefined): string => {
  switch (type) {
    case InsuranceType.AccidentAndHealth:
      return 'Accident and Health';
    case InsuranceType.BusinessOwners:
      return 'Business Owners';
    case InsuranceType.CommercialAuto:
      return 'Auto';
    case InsuranceType.Cyber:
      return 'Cyber';
    case InsuranceType.DirectorsAndOfficers:
      return 'Directors and Officers';
    case InsuranceType.EmploymentPracticesLiability:
      return 'Employment Practices Liability';
    case InsuranceType.ErrorsAndOmission:
      return 'Errors and Omission Liability';
    case InsuranceType.GeneralLiability:
      return 'General Liability';
    case InsuranceType.JewelersBlock:
      return 'Jewelers Block';
    case InsuranceType.Other:
      return 'Other';
    case InsuranceType.Package:
      return 'Package';
    case InsuranceType.ProductLiability:
      return 'Product Liability';
    case InsuranceType.ProfessionalLiability:
      return 'Professional Liability';
    case InsuranceType.Property:
      return 'Property';
    case InsuranceType.Rental:
      return 'Rental';
    case InsuranceType.ShippingAndTransportation:
      return 'Shipping and Transportation';
    case InsuranceType.UmbrellaOrExcess:
      return 'Umbrella or Excess';
    case InsuranceType.WorkersCompensation:
      return 'Workers Compensation';
    default:
      return 'Unknown';
  }
};
