export const Spinner = ({
  color,
  size,
  centerHorizontal
}: {
  color?: string;
  size?: number;
  centerHorizontal?: boolean;
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ background: 'transparent', width: centerHorizontal ? '100%' : undefined }}
    width={`${size || 24}px`}
    height={`${size || 24}px`}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    aria-label="Loading"
  >
    <path d="M10 50A40 40 0 0 0 90 50A40 50 0 0 1 10 50" fill={color || '#ffffff'} stroke="none">
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="0.641025641025641s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 51.5;360 50 51.5"
      />
    </path>
  </svg>
);
