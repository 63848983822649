import * as React from 'react';
import { SketchPicker } from 'react-color';

export const ColorPicker = (props: {
  color: string;
  onUpdateSettings: (color: string) => void;
}) => {
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const [primaryColor, setPrimaryColor] = React.useState(props.color);

  return (
    <>
      <div className="relative rounded-md cursor-pointer items-center flex px-2 hover:bg-neutral-200">
        <div
          className="rounded-sm h-4 w-4 p-2"
          style={{ backgroundColor: primaryColor }}
          onClick={() => setShowColorPicker(true)}
        />
        <div className="pl-2" onClick={() => setShowColorPicker(true)}>
          <div className="w-full font-mono">{primaryColor}</div>
        </div>
        {showColorPicker && (
          <div className="z-10 absolute right-0 top-7">
            <div
              className="fixed top-0 right-0 left-0 bottom-0"
              onClick={() => {
                props.onUpdateSettings(primaryColor);
                setShowColorPicker(false);
              }}
            />
            <SketchPicker
              color={primaryColor}
              onChangeComplete={(color: { hex: string }) => setPrimaryColor(color.hex)}
            />
          </div>
        )}
      </div>
    </>
  );
};
