import { Merchant, MerchantRentalConfiguration, RentalPolicyState } from '@oysterjs/types';
import { Button } from '@oysterjs/ui/v2/button';
import config from '@oysterjs/core/config';
import { openPageInNewTab } from '@oysterjs/core/window';
import { getUser } from '@oysterjs/core/auth';

export const MerchantRentalSetup = (props: {
  merchant?: Merchant;
  configuration?: MerchantRentalConfiguration;
  onContinueToRental: () => void;
}) => {
  const getDescription = (): JSX.Element => {
    if (!props.configuration) {
      return (
        <>
          <p className="text-base">
            Offering theft and damage waivers for your rental customers is a simple process. Here's
            how it works:
          </p>
          <ul className="list-disc pl-4 mt-4">
            <li>Oyster will issue your business an insurance policy at no cost.</li>
            <li>
              Before completing a rental transaction, you can offer customers the option to purchase
              a waiver.
            </li>
            <li>If a covered loss occurs, you can file a claim and get reimbursed.</li>
            <li>You earn additional revenue for each waiver your customers purchase.</li>
          </ul>
        </>
      );
    }

    switch (props.configuration?.Details.State) {
      case RentalPolicyState.registered:
        return (
          <p className="text-base">
            Your policy application has been received. We will follow up with you after it has been
            processed.
          </p>
        );
      case RentalPolicyState.submitted:
        return (
          <p className="text-base">
            Our team is reviewing your information and will be in touch soon. If you have any
            questions, please feel free to contact us.
          </p>
        );
      case RentalPolicyState.approved:
        return (
          <p className="text-base">
            Your application has been approved! Click on the button below to use the rental app.
          </p>
        );
      case RentalPolicyState.approved_blanket:
        return (
          <p className="text-base">
            Your application has been approved! Click on the button below to use the rental app and
            submit claims.
          </p>
        );
      case RentalPolicyState.denied:
        return (
          <p className="text-base">
            Your policy application has been denied. Please contact us to troubleshoot.
          </p>
        );
      default:
        throw new Error('Unknown rental configuration state.');
    }
  };

  const merchantUser = getUser();

  const onApply = () => {
    const params = new URLSearchParams();
    params.set('id', props.merchant?.ID || '');
    params.set('email', props.merchant?.BusinessProfile.Email || '');
    params.set('phone', props.merchant?.BusinessProfile.Phone || '');
    params.set('domain', props.merchant?.BusinessProfile.Domain || '');

    params.set('fn', merchantUser?.FirstName || '');
    params.set('ln', merchantUser?.LastName || '');

    const merchantAddress = props.merchant?.BusinessProfile.Address;
    params.set('a1', merchantAddress?.AddressLine1 || '');
    if (merchantAddress?.AddressLine2) {
      params.set('a2', props.merchant?.BusinessProfile.Address.AddressLine2 || '');
    }
    params.set('c', merchantAddress?.City || '');
    params.set('s', merchantAddress?.Zone || '');
    params.set('z', merchantAddress?.PostalCode || '');

    let filloutFormId = 'nothing';
    switch (config().environment) {
      case 'local':
        filloutFormId = 'x7BhnjHjbJus';
        break;
      case 'staging':
        filloutFormId = '3Wbim9bv9Zus';
        break;
      case 'dev':
        filloutFormId = 'bPBWYEm25kus';
        break;
      case 'production':
        // On production, the fillout form is embedded in our website
        openPageInNewTab(`http://www.withoyster.com/rental-insurance-app?${params.toString()}`);
        return;
      default:
        throw new Error('Invalid environment.');
    }

    openPageInNewTab(`https://form.fillout.com/t/${filloutFormId}?${params.toString()}`);
  };

  return (
    <>
      {getDescription()}
      {!props.configuration && (
        <Button type="button" color="sky" onClick={onApply} className="mt-4">
          Apply for Rental Insurance
        </Button>
      )}
      {props.configuration &&
        (props.configuration.Details.State === RentalPolicyState.approved ||
          props.configuration.Details.State === RentalPolicyState.approved_blanket) && (
          <Button type="button" color="sky" onClick={props.onContinueToRental} className="mt-4">
            Continue to Rental Dashboard
          </Button>
        )}
    </>
  );
};
