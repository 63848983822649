import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0px 12px 0px;
  gap: 12px;
`;

export const Action = styled.button`
  border: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  padding: 8px 16px;
  border-radius: 30px;
  user-select: none;

  font-size: 0.7em;
  font-weight: 500;

  justify-content: center;

  color: #333333;
  background: #eaeaea;
  font-family: 'Rubik', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  svg {
    font-size: 1.2em;
  }

  &:hover {
    background: #e6e6e6;
  }

  &:active {
    background: #cccccc;
  }
`;
