import * as React from 'react';
import styled from 'styled-components';

const VerticalLayoutContainer = styled.div<{ activeIconColor?: string; invertedIcon?: boolean }>`
  display: flex;
  flex-direction: column;

  // if inverted, border --> activeIconColor || white; background -> border and text color;
  .icon-container {
    border: ${(props) => (props.invertedIcon ? props.activeIconColor || '2px solid #0EA5E9' : '0')};
    background: ${(props) => (props.invertedIcon ? 'white' : props.activeIconColor || '#0EA5E9')};
  }
`;

const VerticalLayoutItemContainer = styled.div<{ last?: boolean; inactive?: boolean }>`
  pointer-events: ${(props) => (props.inactive ? 'none' : 'auto')};
  padding-bottom: 30px;
  display: flex;
  gap: 16px;
  border-left: 1px solid ${(props) => (props.last ? 'transparent' : '#eaeaea')};
  margin-left: 16px;
  transition: min-height 0.5s ease-in-out;
`;

const VerticalLayoutIconContainer = styled.div<{ inactive?: boolean }>`
  height: 34px;
  width: 34px;
  margin-left: -19px;
  color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid white;
  align-items: center;

  transition: background 0.15s ease-in-out;
  background: ${(props) => (props.inactive ? '#cccccc !important' : 'inherit')};

  svg {
    vertical-align: middle;
  }
`;

const VerticalLayoutDetailsContainer = styled.div<{ inactive?: boolean }>`
  box-sizing: border-box;
  flex: 1 0 0;
  transition: opacity 0.15s ease-in-out;
  opacity: ${(props) => (props.inactive ? 0.3 : 1)};
`;

interface VerticalLayoutProps {
  activeIconColor?: string;
  invertedIcon?: boolean;
}

export const VerticalLayout: React.FunctionComponent<
  React.PropsWithChildren<VerticalLayoutProps>
> = (props) => (
  <VerticalLayoutContainer
    activeIconColor={props.activeIconColor}
    invertedIcon={props.invertedIcon}
  >
    {props.children}
  </VerticalLayoutContainer>
);

interface VerticalLayoutItemProps {
  icon: JSX.Element;
  last?: boolean;
  inactive?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  children?: React.ReactNode | React.ReactNode[];
}

export const VerticalLayoutItem = React.forwardRef(
  (props: React.PropsWithChildren<VerticalLayoutItemProps>, ref: React.Ref<HTMLDivElement>) => (
    <VerticalLayoutItemContainer
      onClick={props.onClick}
      inactive={props.inactive}
      last={props.last}
      style={props.style}
      ref={ref}
    >
      <VerticalLayoutIconContainer className="icon-container" inactive={props.inactive} aria-hidden>
        {props.icon}
      </VerticalLayoutIconContainer>
      <VerticalLayoutDetailsContainer inactive={props.inactive}>
        {props.children}
      </VerticalLayoutDetailsContainer>
    </VerticalLayoutItemContainer>
  )
);
