///////////////////////////////////////////////////////
// SDK-specific types                                //
///////////////////////////////////////////////////////

import { CSSProperties } from 'react';

export type EcommercePlatform = 'shopify' | 'magento' | 'custom';

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export interface InitOptions {
  apiKey: string;
  integrationID?: string;
  platform?: EcommercePlatform;
  apiBaseUrl?: string;
  metricsBaseUrl?: string;
  staticsBaseUrl?: string;
  environment?: string;
}

export interface OysterWidgetState {
  // map of policy ID to policy for each policy added to cart
  Policies?: Record<string, Policy>;
}

export enum WidgetRenderStyle {
  default = '',
  switch = 'switch',
  single_line = 'single_line',
  cart_widget = 'cart_widget',
  registration_widget = 'registration_widget',
  card_selection_widget = 'card_selection_widget'
}

export interface WidgetStyle {
  renderStyle?: WidgetRenderStyle;
  center?: boolean;
  title?: CSSProperties;
  addCoverageButton?: CSSProperties;
  fonts?: FontProperties[];
}

export interface FontProperties {
  family: string;
  src: string;
  stretch?: string;
  style?: string;
  weight?: string;
  display?: string;
  unicodeRange?: string;
}

export enum WidgetFlowStyle {
  product = 'product',
  hybrid = 'hybrid',
  full = 'full',
  single_click = 'single_click',
  registration = 'registration'
}

export interface WidgetFlow {
  style: WidgetFlowStyle;
  enableDefaultInsurance?: boolean;
}

export interface InjectOptions {
  products: Product[];
  selectedPolicyId?: string;
  hasInsuranceProductInCart?: boolean;
  insured?: Insured;
  style?: WidgetStyle;
  flow?: WidgetFlow;
  subChannel?: ReferralSubChannel;
}

export interface InjectBadgesOptions {
  test?: string;
}

export interface InjectSubtotalBadgeOptions {
  products?: Product[];
  addBadgeToItems?: boolean;
  invertBadgeColor?: boolean;
}

export interface ShopifyCheckout {
  id: number;
  attributes: Record<string, string>;
  email: string;

  // eslint-disable-next-line
  billing_address: any;

  // eslint-disable-next-line
  line_items: any[];

  metafields: Record<string, Record<string, string>>;
  productType?: ProductType;
}

export interface ConfirmPurchaseOptions {
  policyIds: string[];
  productSkus: string[];
  insured?: Insured;
  shopifyCheckout?: ShopifyCheckout;
  injectPostPurchase?: boolean;
}

export type InjectCallback = (selectedPolicy?: Policy) => void;
export type ConfirmPurchaseCallback = (policy: Policy | null, err?: Error) => void;

///////////////////////////////////////////////////////
// Types corresponding to product models             //
///////////////////////////////////////////////////////

export enum ProductType {
  bike = 'bike',
  offroad = 'offroad_vehicle',
  motorcycle = 'motorcycle',
  jewelry = 'jewelry',
  phone = 'phone',
  electronics = 'electronics'
}

export enum AdditionalMerchantProductType {
  collectibles = 'collectibles',
  furniture = 'furniture',
  fashion = 'fashion',
  food_and_beverage = 'food_and_beverage',
  beauty_and_supplements = 'beauty_and_supplements',
  outdoor_sports = 'outdoor_sports',
  other = 'other'
}

export type MerchantProductType = ProductType | AdditionalMerchantProductType;

export interface Product<T = MotorProduct | BikeProduct | JewelryProduct | ElectronicsProduct> {
  ID?: string;
  Type: ProductType;
  SKU: string;
  Name: string;
  Description: string;
  ImageURL: string;
  Quantity: number;
  Price: Price;
  Pending?: boolean;
  HasNotReceived?: boolean;
  Details: T;
}

export enum BikeType {
  roadTrack = 'road_track',
  mountain = 'mountain',
  hybridCruiser = 'hybrid_cruiser',
  recumbent = 'recumbent',
  tandem = 'tandem',
  bmx = 'bmx',
  tricycle = 'tricycle',
  electric = 'electric'
}

export enum BikeFrameType {
  none = '',
  aluminum = 'aluminum',
  carbonFiber = 'carbon_fiber',
  steel = 'steel',
  titatium = 'titanium',
  wood = 'wood',
  other = 'other'
}

export enum BikePowerSource {
  none = '',
  throttleAssist20MPH = 'throttle_assist_20_mph',
  pedalAssist20MPH = 'pedal_assist_20_mph',
  pedalAssist28MPH = 'pedal_assist_28_mph',
  other = 'other'
}

export enum MotorProductType {
  // Motorcycles
  preferred_tour = 'preferred_tour',
  tour = 'tour',
  sport_tour = 'sport_tour',
  non_touring_bmw = 'non_touring_bmw',
  big_twin = 'big_twin',
  low_surcharge = 'low_surcharge',
  high_surcharge = 'high_surcharge',
  professional_racing_surcharge = 'professional_racing_surcharge',
  naked_sport = 'naked_sport',
  sportster = 'sportster',
  cruiser = 'cruiser',
  high_performance_cruiser = 'high_performance_cruiser',
  moped = 'moped',
  scooter = 'scooter',
  street_bike = 'street_bike',
  off_road_trail_bike = 'off_road_trail_bike',
  tour_trike = 'tour_trike',

  // Offroad vehicles
  sport_performance_atv = 'sport_performance_atv',
  recreation_utility_atv = 'recreation_utility_atv',
  super_utility_atv = 'super_utility_atv',
  golf_cart = 'golf_cart'
}

export enum ActivationSource {
  TrackingNumber = 'TrackingNumber',
  SetStartDate = 'SetStartDate',
  ManuallyActivate = 'ManuallyActivate'
}

export interface ActivationTrigger {
  Source: ActivationSource;
  TrackingNumber?: string;
  StartDate?: string;
}

export interface MotorProduct {
  ModelYear: string;
  PurchaseYear: string;

  Type: MotorProductType;
  Make: string;
  Model: string;
  VIN: string;
  CCSize: string;

  EstimatedAnnualMileage: string;
  AccessoryValue?: Price;

  HasElectronicAlarm?: boolean;
  HasTrackingSystem?: boolean;
  HasLienHolder?: boolean;
  IsPerformanceEnhanced?: boolean;
  IsStructurallyModified?: boolean;
}

export const MotorProductValidationKeys: (keyof MotorProduct)[] = [
  'ModelYear',
  'PurchaseYear',

  'Type',
  'Make',
  'Model',
  'VIN',
  'CCSize',

  // 'EstimatedAnnualMileage', - this one is checked on a later page
  'AccessoryValue'
];

export interface BikeProduct {
  Type: BikeType;
  FrameType: BikeFrameType;
  PowerSource?: BikePowerSource;
  CanUnlock?: boolean;
  IsPendingSerialNumber?: boolean;
  StartLater?: boolean;

  ModelYear: string;
  PurchaseYear: string;
  Make: string;
  Model: string;
  FrameSerialNumber: string;
  IsSecondHandPurchase: boolean;

  TotalInsuredValue?: Price;
}

export const BikeProductValidationKeys: (keyof BikeProduct)[] = [
  'Type',
  'FrameType',
  'PowerSource',
  'CanUnlock',
  'ModelYear',
  'PurchaseYear',
  'Make',
  'Model',
  'FrameSerialNumber',
  'TotalInsuredValue'
];

export enum ElectronicsType {
  laptop = 'laptop',
  iPhone = 'iphone',
  smartPhone = 'smartphone',
  iPad = 'ipad',
  smallElectronics = 'small_electronics',
  gamingSystem = 'gaming_system',
  eReaderKindle = 'ereader_kindle',
  tablet = 'tablet'
}

export interface ElectronicsProduct {
  Type: ElectronicsType;
  Manufacturer: string;
  Model: string;
  SerialNumber: string;
  PurchaseReceiptFileIDs?: string[];

  _PurchaseReceiptFiles?: File[];
}

export const ElectronicsProductValidationKeys: (keyof ElectronicsProduct)[] = [
  'Type',
  'Manufacturer',
  'Model',
  'SerialNumber'
];

export interface JewelryProduct {
  Type: string;
  Metal: string;
  Brand: string;
  StoneType: string;
  StoneWeight: string;
  StoneCarat: string;
  StoneColor: string;
  StoneClarity: string;
  InSafe: boolean;
  IsNewPurchase: boolean;
  AppraisalFileIDs?: string[];

  _AppraisalFiles?: File[];
}

export const JewelryProductValidationKeys: (keyof JewelryProduct)[] = [
  'Type',
  'Metal',
  'Brand',
  'StoneType',
  'StoneWeight',
  'StoneCarat',
  'StoneColor',
  'StoneClarity'
];

///////////////////////////////////////////////////////
// Types corresponding to policy models              //
///////////////////////////////////////////////////////

export enum Cadence {
  year = 'year',
  month = 'month'
}

export enum PolicyState {
  unknown = '',
  offerSubmitted = 'offer_submitted',
  applicationSubmitted = 'application_submitted',
  quoteActive = 'quote_active',
  quoteSigned = 'quote_signed',
  policyBinding = 'policy_binding',
  policyInforce = 'policy_inforce',
  policyCanceled = 'policy_canceled',
  policyExpired = 'policy_expired'
}

export enum PolicyType {
  bike = 'bike',
  markelBike = 'markel_bike',
  markelOffroad = 'markel_offroad',
  markelMotorcycle = 'markel_motorcycle',
  minicoJewelry = 'minico_jewelry',
  chubbJewelry = 'chubb_jewelry',
  worthAveElectronics = 'worth_ave_electronics'
}

export interface PolicyTableValues {
  CreatedAt: Date;
  InsuredItems: Product[];
}

export interface Policy {
  ID: string;
  Name: string;
  UserID: string;
  MerchantID: string;

  Type: PolicyType;
  State: PolicyState;

  InsuredItems: Product[];
  Underwriting: Underwriting;
  Coverage: Coverage;
  ReferralChannelInfo?: PolicyReferralChannelInfo;

  Price?: Price;
  PaymentCadence: Cadence;
  DisableAutoRenew: boolean;

  CreatedAt: Date;
  StartedAt: Date;
  ExpiresAt: Date;
}

export interface Price {
  Amount: number;
  Currency: string;
}

export interface Coverage {
  Deductible: DiscreteOption;
  Options: Option[];
  ActivationTrigger?: ActivationTrigger;
}

export interface PolicyReferralChannelInfo {
  ReferralChannel: ReferralChannelType;
  ReferralSubChannel: ReferralSubChannel;
  Settings: MerchantReferralSettings | MarketingCampaignSettings | DirectReferralSettings;
}

export enum ReferralSubChannel {
  product = 'ProductPage',
  addToCartButton = 'AddToCartButton',
  cart = 'CartPage',
  confirm = 'OrderConfirmationPage',
  defaultInsurance = 'DefaultInsurance',
  unknown = ''
}

export interface MerchantReferralSettings {
  MerchantID: string;
  IntegrationID: string;
}

export interface MarketingCampaignSettings {
  Source: string;
  Campaign: string;
  Term: string;
  Medium: string;
  PageHistory: string[];
}

export interface DirectReferralSettings {
  Parameters: string;
  PageHistory: string[];
}

export enum ReferralChannelType {
  ReferralChannelMerchant = 'Merchant',
  ReferralChannelMarketingCampaign = 'Marketing Campaign',
  ReferralChannelDirect = 'Direct'
}

export enum BikeUsageType {
  casual = 'casual',
  fitness = 'fitness',
  commute = 'commute',
  competition = 'competition'
}

export enum MarkelPersonalCharacteristicFlag {
  homeowner = 'homeowner',
  motorcycle_endorsed = 'motorcycle_endorsed',
  taken_safety_course = 'taken_safety_course',
  taken_mature_driver_course = 'taken_mature_driver_course'
}

export interface MarkelMotorCriteria {
  ZipCode: string;

  YearsOfExperience: string;
  MinorViolations: string;
  MajorViolations: string;
  AtFaultAccidents: string;

  DriversLicenseNumber: string;
  DriversLicenseState: string;

  PersonalCharacteristicFlags: MarkelPersonalCharacteristicFlag[];
}

export interface BikeCriteria {
  ZipCode: string;
  UsageTypes: BikeUsageType[];

  StorageAddressLine1: string;
  StorageAddressLine2: string;
  StorageCity: string;
  StorageState: string;
  StorageZipCode: string;
  ResidentialIsStorage: boolean;
}

export interface MarkelBikeCriteria {
  ZipCode: string;
  UsageTypes: BikeUsageType[];

  StorageAddressLine1: string;
  StorageAddressLine2: string;
  StorageCity: string;
  StorageState: string;
  StorageZipCode: string;
  ResidentialIsStorage: boolean;
  YearsOfExperience: string;
}

export enum JewelryUnderwritingFlag {
  default = 'default',
  exhibition = 'exhibition',
  bankruptcy = 'bankruptcy',
  judgment = 'judgment',
  conviction = 'conviction'
}

export enum JewelryStorageLocationFlag {
  central_station_fire = 'central_station_fire',
  burglar_alarm = 'burglar_alarm',
  central_station_burglar_alarm = 'central_station_burglar_alarm',
  deadbolts = 'deadbolts',
  below_ground = 'below_ground',
  off_the_ground = 'off_the_ground',
  near_fire_hydrant = 'near_fire_hydrant',
  flood_zone = 'flood_zone',
  has_doorman = 'has_doorman',
  storage_climate_controlled = 'storage_climate_controlled'
}

export interface JewelryPriorLoss {
  lossIdentifier: string;
  dateOfLoss: Date;
  lossAmount: number;
  description: string;
}

export interface JewelryCriteria {
  ZipCode: string;
  PriorLosses: JewelryPriorLoss[];
  UnderwritingFlags: JewelryUnderwritingFlag[];
  UnderwritingJustification: string;

  StorageStreetAddress: string;
  StorageAptFloor: string;
  StorageAptUnit: string;
  StorageCity: string;
  StorageState: string;
  StorageZipCode: string;
  StorageIsResidential: boolean;

  ConstructionType: string;
  ConstructionYear: string;
  StorageLocationFlags: JewelryStorageLocationFlag[];

  HasAdditionalInsured: boolean;
  AdditionalInsuredFirstName: string;
  AdditionalInsuredLastName: string;

  IsCurrentlyInsured: boolean;
  CurrentInsurerName: string;
}

export interface ChubbAddress {
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  stateOrProvinceName: string;
}

export interface ChubbLoss {
  lossIdentifier: string;
  causeOfLossCode: string;
  sourceOfLossCode: string;
  causeOfLossDescription: string;
  dateOfLoss: string;
  lossPaidDate: string;
  lossPaidAmount: number;
  lossType: string;
  lossCatastrophe: boolean;
  lossStatus: string;
}
export interface ChubbJewelryCriteria {
  ZipCode: string;
  PriorLosses: ChubbLoss[];

  StorageAddress: ChubbAddress;
  StorageIsResidential: boolean;

  ConstructionType: string;
  ResidenceType: string;

  AcceptConsumerDisclosure: string;

  HasAdditionalInsured: boolean;
  AdditionalInsuredFirstName: string;
  AdditionalInsuredLastName: string;
  AdditionalInsuredOccupation: string;
  AdditionalInsuredOccupationDetails: string;
  AdditionalInsuredDateOfBirth: { Day: string; Month: string; Year: string };

  IsCoveredItemsElevated: boolean;
  IsStoredInClimateControlledStorage: boolean;

  OccupationDetails: string;
  OtherOccupationDetails: string;

  CentralStationFireAlarm: boolean;
  TwentyFourHourSecurity: boolean;
  SignalContinuity: boolean;
  FullTimeCaretaker: boolean;
  GatedCommunity: boolean;
  GatedCommunityPatrol: boolean;
  FiftyPercentJewelryKeptInVault: boolean;
  SafeAtHome: boolean;
  HasInHouseEmployees: boolean;
  InHouseEmployees: string;
  InsuredTravel: string;
  AverageJewelryValueOnTravel: number;
  JewelrySafeguardOnTravel: string;
  JewelrySafeguardAtHomeInsuredOnTravel: string;
}

export interface Underwriting {
  Criteria:
    | MarkelMotorCriteria
    | BikeCriteria
    | MarkelBikeCriteria
    | JewelryCriteria
    | ChubbJewelryCriteria;
  Insured: Insured;
  Underwriter?: string;
}

export type OptionDetails =
  | BooleanOption
  | DiscreteOption
  | MultiDiscreteOption
  | RangeOption
  | TextOption;

export enum OptionType {
  boolean = 'boolean',
  discrete = 'discrete',
  multi_discrete = 'multi_discrete',
  range = 'range',
  text = 'text'
}

export interface Option {
  ID: string;
  Name: string;
  Description: string;
  Required: boolean;
  Enabled: boolean;
  Type: OptionType;
  Option: OptionDetails;
}

export interface BooleanOption {
  ValueLabel: string;
}

export interface DiscreteOption {
  Value: string;
  AllowedValues: string[];
  AllowedValueDescriptions: Record<string, string>;
}

export interface MultiDiscreteOption {
  Values: string[];
  AllowedValues: string[];
  AllowedValueDescriptions?: Record<string, string>;
}

export interface RangeOption {
  Value: number;
  MinValue: number;
  MaxValue: number;
  Step: number;
}

export interface TextOption {
  Value: string;
  Validators: string[];
}

export enum Gender {
  male = 'male',
  female = 'female',
  non_binary = 'non_binary',
  other = 'other'
}

export interface Insured {
  FirstName: string;
  LastName: string;
  Email: string;
  Phone?: string;
  Occupation?: string;
  Gender?: Gender;
  MaritalStatus?:
    | 'married'
    | 'single'
    | 'divorced'
    | 'separated'
    | 'widowed'
    | 'civil_union'
    | 'domestic_partnership';
  DateOfBirth?: { Day: string; Month: string; Year: string };

  AddressLine1?: string;
  AddressLine2?: string;
  AddressCity?: string;
  AddressState?: string;
  AddressZipCode?: string;
}

///////////////////////////////////////////////////////
// Types corresponding to files and metadata         //
///////////////////////////////////////////////////////

export interface AttachmentFile {
  ID: string;
  Name: string;
  Type: string;
  Size: number;

  Attachment?: AttachmentDetails;
}

export interface AttachmentDetails {
  FileID: string;
  RefID: string;
  Role: DefaultFileRoles;
  EntityType: string;

  CreatedAt: Date;
  UpdatedAt: Date;
}

export enum DefaultFileRoles {
  jewelryAppraisal = 'JewelryAppraisal',
  purchaseReceipt = 'PurchaseReceipt',

  waiverClaimAttachment = 'WaiverClaimAttachment',
  waiverLDWDocument = 'LossDamageWaiver',
  signedRentalAgreement = 'SignedRentalAgreement',
  merchantRentalPDTA = 'ProgramDescriptionTermsAccepance',

  commercialDocument = 'CommercialDocument',
  unknown = 'Unknown'
}

///////////////////////////////////////////////////////
// Types corresponding to claim models               //
///////////////////////////////////////////////////////

export enum ClaimState {
  unknown = '',
  awaitingProcessing = 'awaiting_processing',
  collectingInformation = 'collecting_information',
  settled = 'settled',
  denied = 'denied'
}

export enum ClaimType {
  // General claim types
  unknown = '',
  theft = 'theft',
  damage = 'damage', // for electronics: includes cracked screen and spills
  injury = 'injury', // bike only
  liability = 'liability', // bike only
  other = 'other',

  // Electronics-specific claim types
  electricalBreakdown = 'electrical_breakdown', // only if caused by lightning
  mechanicalBreakdown = 'mechanical_breakdown', // only if warranty is added
  naturalDisaster = 'natural_disaster', // fire, flood, lightning, natural disaster, wind
  waterDamage = 'water_damage' // includes spill
}

export interface Claim {
  ID: string;
  State: ClaimState;
  UserID: string;
  Policy: Policy;
  Product: Product;

  Types: ClaimType[];
  Sections: ClaimSection[];

  IncidentDate: Date;
  Attachments: Record<string, ClaimAttachment[]>;
  Data: Record<string, string>;

  CreatedAt: Date;
  UpdatedAt: Date;
}

export enum ClaimSectionItemType {
  checkbox = 'checkbox',
  select = 'select',
  text = 'text',
  date = 'date',
  location = 'location',
  longText = 'long_text',
  upload = 'upload',
  confirm = 'confirm'
}

export type ClaimSectionItemDetails =
  | CheckboxItem
  | SelectItem
  | TextItem
  | DateItem
  | LocationItem
  | LongTextItem
  | UploadItem
  | ConfirmItem;

export interface ClaimSection {
  ID: string;
  Field: string;
  Title: string;
  Description: string;
  Items: ClaimSectionItem[];
}

export interface ClaimSectionItem {
  SubField: string;
  SubTitle?: string;
  Description?: string;
  Type: ClaimSectionItemType;
  Item: ClaimSectionItemDetails;
}

// eslint-disable-next-line
export interface CheckboxItem {}

export interface SectionItemDescription {
  Title: string;
  Description: string;
  Value: string;
  Icon: string;
}

export interface SelectItem {
  AllowedValues: SectionItemDescription[];
}

export interface TextItem {
  Required?: boolean;
}

// eslint-disable-next-line
export interface DateItem {}

export interface LocationItem {
  AddressField: string;
  LongitudeField: string;
  LatitudeField: string;
}

export interface LongTextItem {
  Field: string;
}

export interface UploadItem {
  Name: string;
  Description: string;
  AllowedTypes: string[];
  MaxSize: number;
  NumRequired: number;
}

export interface Confirmation {
  Title: string;
  Description: string;
}

export interface ConfirmItem {
  Confirmation: Confirmation[];
}
export interface ClaimAttachment {
  ID: string;
  Name: string;
  Type: string;
  Size: number;
  URL: string;
}

///////////////////////////////////////////////////////
// Types corresponding to user models                //
///////////////////////////////////////////////////////

export interface User {
  ID: string;
  FirstName: string;
  LastName: string;
  Email: string;
  PublicHandle: string;
  CreatedAt: Date;
}

export interface UserVerification {
  ID: string;
  Secret: string;
  Status: string;
  Error: string;
  CreatedAt: Date;
}

export enum ReferralStatus {
  created = 'created',
  fulfilled = 'fulfilled'
}

export interface UserReferral {
  ID: string;
  Referrer: {
    UserID: string;
    FirstName: string;
    LastName: string;
    Email: string;
    BusinessName: string;
  };
  Referee: {
    UserID: string;
    FirstName: string;
    LastName: string;
    Email: string;
    BusinessName: string;
  };
  Status: ReferralStatus;
  Reward: number;
  CreatedAt: Date;
  UpdatedAt: Date;
}

export interface AccountSummary {
  PaymentMethods?: PaymentMethod[];
  PastStatements: Statement[];
  User?: User;
  Verification?: UserVerification;
}

export enum VerificationStatus {
  RequiresInput = 'requires_input',
  Processing = 'processing',
  Verified = 'verified',
  Canceled = 'canceled'
}

export interface PaymentMethod {
  ID: string;
  Brand: string;
  ExpMonth: number;
  ExpYear: number;
  LastFour: string;
  Default: boolean;
}

export interface Statement {
  ID: string;

  StatementDate: Date;
  GeneratedAt: Date;

  PolicyStatements: PolicyStatement[];
  Currency: string;
  TaxRate: number;

  SubTotal: number;
  TaxTotal: number;
  Total: number;
}

export interface PolicyStatement {
  Name: string;
  LineItems: LineItem[];
  Adjustments: LineItem[];
  Total: number;
}

export interface LineItem {
  Title: string;
  Description: string;
  Price: Price;
}

///////////////////////////////////////////////////////
// Types corresponding to general models             //
///////////////////////////////////////////////////////

export interface ValidationError {
  Field: string;
  SubField?: string;
  Message: string;
}

///////////////////////////////////////////////////////
// Types corresponding to merchant models            //
///////////////////////////////////////////////////////

export interface Address {
  AddressLine1: string;
  AddressLine2?: string;

  // Level 1 administrative division depending on country. For example, this is
  // the state in the U.S., the province in Canada, etc.
  Zone: string;

  // Level 2 administrative division depending on country. For example, this is
  // the county in the U.S., prefectural city in China, division in India, etc.
  SubZone?: string;

  // Level 3 administrative division depending on country. For example, this is
  // the city in the U.S., a muncipality in Austria, a ward in Singapore, etc.
  City: string;

  // Country-specific mailing identifier, e.g. ZIP Code in the U.S., Post Code
  // in the U.K., etc.
  PostalCode: string;
}

export enum PaymentMethodType {
  Electronic = 'Electronic',
  Check = 'Check',
  Unknown = ''
}

export enum SalesChannelType {
  Online = 'Online',
  Dealer = 'Dealer',
  InStore = 'InStore',
  Rental = 'Rental'
}

export enum OnlineSalesChannelType {
  Shopify = 'Shopify',
  WooCommerce = 'WooCommerce',
  BigCommerce = 'BigCommerce',
  Lightspeed = 'Lightspeed eCommerce',
  Checkfront = 'Checkfront',
  Adobe = 'Adobe Commerce (Magento)',
  Custom = 'Custom',
  Other = 'Other'
}

export enum InStoreSalesChannelType {
  ShopifyPOS = 'Shopify PoS',
  LightspeedRSeries = 'Lightspeed R-Series',
  LightspeedXSeries = 'Lightspeed X-Series',
  Ascend = 'Ascend',
  Edge = 'EDGE',
  Square = 'Square',
  Other = 'Other'
}

export enum BusinessOperationType {
  RetailECommerce = 'Retail or E-Commerce',
  Wholesale = 'Wholesale',
  Manufacturing = 'Manufacturing',
  ServiceOrRepair = 'Service or Repair',
  Rental = 'Rental',
  Other = 'Other'
}

export enum BusinessInsuranceType {
  BusinessOwners = 'Business Owners',
  CommercialAuto = 'Commercial Auto',
  Cyber = 'Cyber',
  DirectorsAndOfficers = 'Directors and Officers',
  GeneralLiability = 'General Liability',
  JewelersBlock = 'Jewelers Block',
  Other = 'Other',
  Property = 'Property',
  Rental = 'Rental',
  ShippingAndTransportation = 'Shipping and Transportation',
  UmbrellaExcess = 'Umbrella or Excess',
  WorkersCompensation = 'Workers Compensation'
}

export enum RentalProductType {
  BikesAndEbikes = 'Bikes and eBikes',
  Kayaks = 'Kayaks',
  Other = 'Other',
  Paddleboards = 'Paddleboards'
}

export interface Personalization {
  // Toggle the various types of insurance in the onboarding flow
  BusinessInsuranceEnabled: boolean;
  ProductInsuranceEnabled: boolean;
  RentalInsuranceEnabled: boolean;

  // Business insurance settings
  BusinessOperationTypes?: BusinessOperationType[];
  BusinessOperationTypesOtherDesc: string;

  BusinessRevenueBreakdownRetail: string;
  BusinessRevenueBreakdownWholesale: string;
  BusinessRevenueBreakdownManufacturing: string;
  BusinessRevenueBreakdownServiceOrRepair: string;
  BusinessRevenueBreakdownRental: string;
  BusinessRevenueBreakdownOther: string;
  BusinessRevenueBreakdownRentalPercentGuided: string;

  BusinessTotalPayroll: string;

  BusinessManufactureOrWholesaleOwnBrand?: boolean;

  BusinessInsuranceTypes?: BusinessInsuranceType[];
  BusinessInsuranceTypesOtherDesc: string;

  BusinessNumberOfPriorLosses?: string;

  BusinessInsuranceFilloutFormSubmitted: boolean;

  // Personal insurance settings
  SalesChannels?: SalesChannelType[];

  OnlineSalesChannelType?: OnlineSalesChannelType;
  OnlineSalesChannelName?: string;
  ShopifyAdminURL?: string;

  InStoreSalesChannelType?: InStoreSalesChannelType;
  InStoreSalesChannelName?: string;

  RentalChannelName?: string;

  ProductAnnualRevenue: string;

  // Rental insurance settings
  RentalProductTypes?: RentalProductType[];
  RentalProductTypeOtherDesc: string;

  RentalMotorizedAssetsExceedEBikeClassification?: boolean;

  RentalMaximumAssetValue: string;
  RentalAverageAssetValue: string;

  RentalAnnualRevenue: string;
  RentalAnnualVolume: string;

  RentalPlatformName: string;
  RentalPointOfSaleName: string;
}

export interface BusinessProfile {
  Name: string;
  DBA: string;
  Email: string;
  Phone: string;
  Domain: string;
  Address: Address;
  PaymentMethod: PaymentMethodType;

  ProductVerticals?: ProductType[];
  Personalization: Personalization;
}

export interface Merchant {
  ID: string;
  MercuryRecipientID: string;
  BusinessProfile: BusinessProfile;
  CreatedAt: Date;
  PublicHandle: string;
}

export interface MerchantUser {
  ID: string;
  MerchantID: string;

  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;

  ActivationToken?: string;
  CreatedAt: Date;
}

export interface MerchantIntegration {
  ID: string;
  Type: MerchantIntegrationType;
  Settings: MerchantIntegrationSettings;
  Status: MerchantIntegrationStatus;
}

export enum MerchantIntegrationStatus {
  active = 'Active',
  pending = 'Pending',
  disabled = 'Disabled',
  inactive = 'Inactive'
}

export enum MerchantIntegrationType {
  qr_code = 'QRCode',
  referral_link = 'ReferralLink',
  embeddable_marketing_page = 'EmbeddableMarketingPage',
  shopify = 'Shopify',
  custom = 'Custom',
  checkfront = 'Checkfront',
  lsretail_rseries = 'LSRetailRSeries',
  woocommerce = 'WooCommerce',
  not_available = ''
}

export type MerchantIntegrationSettings =
  | ShopifyIntegrationSettings
  | LSRetailRSeriesIntegrationSettings
  | QRCodeIntegrationSettings
  | ReferralLinkIntegrationSettings
  | EmbeddableMarketingPageIntegrationSettings
  | WooCommerceIntegrationSettings
  | CustomIntegrationSettings;

export interface ShopifyIntegrationSettings {
  ShopName: string;
  InsuranceProductID?: string;
  CartPageWidgetEnabled: boolean;
  OrderConfirmationPageWidgetEnabled: boolean;
  ProductPageAddToCartTriggerEnabled: boolean;
  ProductPageWidgetEnabled: boolean;
  UseOysterProductAsIndicator?: boolean;
  OrderConfirmationPageWidgetFirstLoad: Date;
}

export interface LSRetailRSeriesIntegrationSettings {
  AccountID: string;
  AccountName: string;
  EmailMarketingEnabled: boolean;
  EmailMarketingRequiresOptIn: boolean;
  EmailMarketingCategoriesEnabled: string[] | null;
}

export interface QRCodeIntegrationSettings {
  ReferralLink: string;
  BrandColor?: string;
  FirstDownload?: string;
}

export interface ReferralLinkIntegrationSettings {
  ReferralLink: string;
  BrandColor?: string;
  FirstPageLoad?: string;
}

export interface EmbeddableMarketingPageIntegrationSettings {
  ReferralLink: string;
  FirstPageLoad?: string;
}

export interface WooCommerceIntegrationSettings {
  BaseURL: string;
  OysterVirtualProductID?: number;
  CartPageWidgetEnabled: boolean;
  OrderConfirmationPageWidgetEnabled: boolean;
  ProductPageAddToCartTriggerEnabled: boolean;
  ProductPageWidgetEnabled: boolean;
}

export interface CustomIntegrationSettings {
  Data: string;
}

export interface MerchantRentalConfiguration {
  ID: string;
  MerchantID: string;
  Details: MerchantRentalConfigurationDetails;
}

export interface PublicMerchantRentalConfiguration {
  BillingMode: BillingMode;
  UnifiedWaiverFlowEnabled: boolean;
  AutoRenewWaiver: boolean;
}

export enum BillingMode {
  Customer = 'Customer',
  Merchant = 'Merchant'
}

export interface MerchantRentalConfigurationDetails {
  BillingMode: BillingMode;
  Pricing: RentalPricingConfiguration;
  State: RentalPolicyState;
  PolicyDocument: AttachmentFile;
  AutoRenewWaiver: boolean;
  UnifiedWaiverFlowEnabled: boolean;
}

export enum RentalPolicyState {
  unknown = 'Unknown',
  registered = 'Registered',
  submitted = 'Submitted',
  approved = 'Approved',
  approved_blanket = 'ApprovedBlanketPolicy',
  denied = 'Denied'
}

export interface RentalPricingConfiguration {
  Currency: string;
  AssetValueUnitDenominator: number;
  DailyRatingPerValueUnit: number;
  WeeklyRatingPerValueUnit: number;
  MonthlyRatingPerValueUnit: number;
  MinimumPremium: number;

  MerchantFeePercent: number;
  MerchantFeeAbs: number;
  MerchantDeductible: number;
  PlatformFeePercent: number;
  PlatformFeeAbs: number;
}

export enum MercuryPaymentTypes {
  electronic = 'electronic',
  check = 'check',
  unknown = ''
}

export interface ReferralLinkIntegration {
  MerchantName: string;
  IntegrationID: string;
  IntegrationType: MerchantIntegrationType;
  Settings: ReferralLinkIntegrationSettings;
  Disabled?: boolean;
}

export enum FeatureFlagExperiments {
  oneliner_widget = 'force_render_oneliner_widget',
  single_click_flow = 'single_click_flow',
  show_logo_on_widget = 'show_logo_on_widget'
}

export interface FeatureFlag {
  ID: string;
  Value: boolean;
}

export enum MerchantAppType {
  rental = 'rental',
  unknown = ''
}

export interface WaiverEntry {
  Waiver?: RentalWaiver;
  Booking?: RentalBooking;
}

export enum WaiverState {
  pending = 'Pending',
  canceled = 'Canceled',
  active = 'Active',
  expired = 'Expired',
  upcoming_renewal = 'UpcomingRenewal',
  renewal_canceled = 'RenewalCanceled',
  unknown = ''
}

export interface RentalWaiver {
  ID: string;
  MerchantID: string;
  RentalBookingID: string;
  State: WaiverState;
  Details: WaiverDetails;
}

export interface WaiverDetails {
  BillingMode: BillingMode;
  WaiverReferenceNumber: string;
  Assets: WaiverAsset[];
  Premium: WaiverPremium;
  PremiumRounding: Price;
  SignatureReference?: AttachmentFile;
}

export interface WaiverAsset {
  // Either Asset or BookingLineItem will be present
  Asset?: RentalAsset;
  Premium: WaiverPremium;
  Accessories: AssetAccessory[];
  BookingLineItem?: BookingLineItem;
}

export interface WaiverPremium {
  Currency: string;
  Base: number;
  Total: number;

  ProcessingFeeAmount: number;
  MerchantFeeAmount: number;
  OysterFeeAmount: number;
}

export interface RentalAsset {
  ID: string;
  MerchantID: string;

  Type: AssetType;
  Name: string;
  Description: string;
  Value: number;
  Details: AssetDetails;
}

export enum AssetType {
  bicycle = 'Bicycle',
  kayak = 'Kayak',
  paddleboard = 'Paddleboard',
  scooter = 'Scooter',
  motorcycle = 'Motorcycle',
  atv = 'ATV',
  other = 'Other'
}

export interface AssetDetails {
  SerialNumber: string;
  AvailableAccessories: AssetAccessory[];
}

export interface AssetAccessory {
  Name: string;
  Value: number;
}

export interface RentalBooking {
  ID: string;
  MerchantID: string;

  State: RentalBookingState;
  Details: RentalBookingDetails;
  StartTime: Date;
  EndTime: Date;
}

export enum RentalBookingState {
  Unknown = '',
  Confirmed = 'Confirmed'
}

export interface BookingLineItem {
  Name: string;
  SKU: string;
  ExternalID: string;
  SerialNumber: string;
  StartDate: Date;
  EndDate: Date;
  Total: number;
  SubTotal: number;
  TaxTotal: number;
  Quantity: number;
}

export interface RentalBookingDetails {
  BookingReference: string;
  Insured: Insured;
  Assets?: RentalAsset[];
  Accessories: AssetAccessory[];
  AutoRenew: boolean;
  BookingPlatformDomain?: string;
  BookingLineItems?: BookingLineItem[];
}

export interface RentalClaim {
  ID: string;
  MerchantID: string;
  RentalWaiverID: string;

  State: RentalClaimState;
  Details: RentalClaimDetails;
}

export enum RentalClaimState {
  unknown = '',
  logged = 'Logged',
  submitted = 'Submitted',
  approved = 'Approved',
  denied = 'Denied',
  requires_information = 'RequiresInformation'
}

export enum RentalDamageType {
  unknown = '',
  fire = 'Fire',
  theft = 'Theft',
  water = 'Water',
  accident = 'Accident',
  other = 'Other'
}

export interface RentalClaimDetails {
  ClaimNumber: string;
  Assets: WaiverAsset[];

  IncidentDate: Date;
  IncidentCause: string;
  IncidentAddress: RentalClaimAddress;

  DamageType: RentalDamageType;

  PoliceReportFiled?: boolean;
  NameOfEmergencyDepartment?: string;
  ReportOrIncidentNumber?: string;
  SourceOfWaterDamage?: string;
  EquipmentExposedToRain?: boolean;
  HasOtherInsuranceCoverage?: boolean;
}

export interface RentalClaimAddress {
  AddressLine1?: string;
  AddressLine2?: string;
  AddressCity?: string;
  AddressState?: string;
  AddressZipCode?: string;
}

export enum TransactionType {
  credit = 'Credit',
  debit = 'Debit'
}

export enum TransactionState {
  created = 'Created',
  pending = 'Pending',
  success = 'Success',
  failure = 'Failure',
  canceled = 'Canceled',
  unknown = ''
}

export enum TransactionProcessor {
  stripe = 'Stripe',
  mercury_ach = 'MercuryACH',
  mercury_check = 'MercuryCheck'
}

export interface Transaction {
  ID: string;
  PaymentAccountID: string;

  Type: TransactionType;
  State: TransactionState;
  Processor: TransactionProcessor;
  ProcessorID: string;

  Amount: number;
  Currency: string;

  CreatedAt: Date;
  UpdatedAt: Date;
  ClearedAt: Date;
}

export enum BusinessLocationType {
  unknown = '',
  home = 'Home',
  buildingLeased = 'BuildingLeased',
  buildingOwned = 'BuildingOwned'
}

export interface BusinessInformation {
  // Identity
  BusinessName: string;
  DBA: string;
  Email: string;
  Website: string;
  FirstName?: string;
  LastName?: string;
  PhoneNumber?: string;

  // Operation
  ProductVerticals: ProductType[];
  Operations?: BusinessOperationType[];
  InsuranceTypes?: BusinessInsuranceType[];
  NumberOfEmployees?: number;
  BusinessAgeInMonths?: number;
  OptInOysterBundle?: boolean;
  ManufactureOrWholesaleOwnBrand?: boolean;

  // Location
  MailingAddress?: Address;
  Address?: Address;
  SquareFootage?: number;
  LocationType?: BusinessLocationType;
  HasSprinklers?: boolean;
  BuildingValue?: number;
  BPPValue?: number;

  // Limits
  GLLimit?: number;
  BPPLimit?: number;
  BuildingLimit?: number;

  // Stats
  AnnualRevenue?: number;
  AnnualPayroll?: number;
  RetailRevenue?: number;
  WholesaleRevenue?: number;
  ManufacturingRevenue?: number;
  ServiceOrRepairRevenue?: number;
  RentalRevenue?: number;
  OtherRevenue?: number;
  RentalPercentGuided?: number;

  BPPDeductible?: number;
  BuildingDeductible?: number;

  PolicyStartDate?: Date;
}

export enum WorflowStatus {
  Started = 'started',
  Completed = 'completed',
  Canceled = 'canceled',
  Failed = 'failed'
}

export enum InsightSourceType {
  Shopify = 'shopify',
  PolicyDocument = 'policy_doc'
}

export interface WorkflowError {
  Code: string;
  Desc: string;
}

export interface Insights {
  ID: string;
  WorkflowID: string;
  Source: InsightSourceType;

  BusinessInformation: BusinessInformation;
  WorkflowStatus: WorflowStatus;
  WorkflowError: WorkflowError;
}

export interface CommercialPolicyQuote {
  ID: string;

  Type: BusinessInsuranceType;
  Limit: number;
  Quote: Price;
  OysterPartnerQuote: Price;
  StartDate: Date;
  EndDate: Date;

  CreatedAt: Date;
}

export interface BusinessInsuranceApplication {
  ID: string;
  MerchantID: string;

  BusinessInformation: BusinessInformation;
  Integrations?: MerchantIntegration[];
  FileIDs?: string[];

  // TODO: Rename this to Extractions
  Insights: Insights[];

  AggregatedInformation: BusinessInformation;

  Quotes?: CommercialPolicyQuote[];

  // TODO: Rename this to Insights
  AIInsights: string[];
  CreatedAt: Date;
  UpdatedAt: Date;
}
