import * as React from 'react';

import { Button } from '@oysterjs/ui/v2/button';
import {
  MerchantIntegration,
  MerchantIntegrationStatus,
  MerchantRentalConfiguration,
  RentalPolicyState
} from '@oysterjs/types';
import { ErrorDetail, ErrorDetails } from '@oysterjs/ui/ErrorBoundary';
import config from '@oysterjs/core/config';
import { getMerchantRentalConfiguration, requestCheckfront } from '@oysterjs/core/api/merchant';
import { Spinner } from '@oysterjs/ui/Spinner';
import { useHistory } from 'react-router';

export const CheckfrontSetup = (props: {
  integration?: MerchantIntegration;
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
  publicHandle: string;
}) => {
  const history = useHistory();
  const [integration, setIntegration] = React.useState(props.integration);
  const [loading, setLoading] = React.useState(false);
  const [configuration, setConfiguration] = React.useState<MerchantRentalConfiguration>();

  const onFetchConfigurationData = async () => {
    setLoading(true);
    try {
      const config = await getMerchantRentalConfiguration();
      setConfiguration(config.Configuration);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    onFetchConfigurationData();
  }, []);

  const onSetup = async () => {
    if (integration) {
      return;
    }

    setLoading(true);
    try {
      const checkfrontIntegration = await requestCheckfront();
      setIntegration(checkfrontIntegration);
    } finally {
      setLoading(false);
    }
  };

  const onClickRentalApp = () => {
    history.push('/integrations/channels/rental');
  };

  const notificationURL = `${config().serviceBaseUrl.webhooks}/checkfront/${props.publicHandle}`;

  return (
    <>
      {loading && <Spinner color="#333333" />}
      {!loading &&
        (!configuration || configuration?.Details.State !== RentalPolicyState.approved) && (
          <div>
            <p className="text-base my-4">
              Before you can integrate with Checkfront, please finish setting up your rental
              configuration. You can do so by going back and setting up <b>Rental Insurance</b>. You
              can also click the button below to link you there.
            </p>
            <Button loading={loading} type="submit" color="sky" onClick={onClickRentalApp}>
              Setup Rental Insurance
            </Button>
          </div>
        )}
      {!loading &&
        !integration &&
        configuration &&
        configuration?.Details.State === RentalPolicyState.approved && (
          <div>
            <p className="text-base my-4">
              In just a few minutes, add an integration that offers Oyster rental insurance to your
              customers on you Checkfront account.
            </p>
            <Button loading={loading} type="submit" color="sky" onClick={onSetup}>
              Setup Checkfront Integration
            </Button>
          </div>
        )}
      {!loading &&
        integration &&
        integration.Status != MerchantIntegrationStatus.active &&
        configuration &&
        configuration?.Details.State === RentalPolicyState.approved && (
          <>
            <p className="text-base my-4">
              To start offering insurance for bookings in your Checkfront store, you need to set up
              a webhook in your Checkfront Account with Oyster.
            </p>
            <p className="text-base my-4">
              Do so by going to <b>Manage &gt; Developer &gt; Webhooks</b>. Click{' '}
              <b>+ Add Webhook</b> and enter the following values:
            </p>
            <ErrorDetails errorData={notificationURL} style={{ maxWidth: '100%' }}>
              <ErrorDetail title="Notification URL" description={notificationURL} />
            </ErrorDetails>
            <img className="w-full" src="/images/checkfront-instruction-1.png" />
          </>
        )}
      {!loading &&
        integration &&
        integration.Status == MerchantIntegrationStatus.active &&
        configuration &&
        configuration?.Details.State === RentalPolicyState.approved && (
          <p className="text-base my-4">
            Your Checkfront account is all set up! Please contact{' '}
            <a
              target="_blank"
              href="mailto:partners@withoyster.com"
              className="text-sky-600 hover:text-sky-900 hover:underline"
            >
              partners@withoyster.com
            </a>{' '}
            if you would like to remove this integration.
          </p>
        )}
    </>
  );
};
