import * as React from 'react';

import { Merchant, MerchantIntegration, MerchantRentalConfiguration } from '@oysterjs/types';
import { updateMerchantIntegration } from '@oysterjs/core/api/merchant';
import { PageTitle } from '@oysterjs/ui/Page/section';
import { ChannelsListPage } from './channels';
import { PageNavigation } from '@oysterjs/ui/Page/navigation';
import { ReferralsPage } from './referrals';
import { PayoutsPage } from './payouts';

export const IntegrationsPage = (props: {
  apiKey: string;
  merchant: Merchant;
  merchantIntegrations: MerchantIntegration[];
  rentalConfiguration?: MerchantRentalConfiguration;
}) => {
  const [merchantIntegrations, setMerchantIntegrations] = React.useState(
    props.merchantIntegrations
  );

  const onUpdateIntegration = async (integration: MerchantIntegration) => {
    const res = await updateMerchantIntegration(integration);
    setMerchantIntegrations(
      merchantIntegrations.map((i) => (i.ID === integration.ID ? res.Integration : i))
    );
  };

  React.useEffect(() => {
    setMerchantIntegrations(props.merchantIntegrations);
  }, [props.merchantIntegrations]);

  return (
    <>
      <PageTitle
        title="Integrations"
        description="Make the most of Oyster by offering insurance in each of your sales and marketing channels."
      >
        <PageNavigation
          redirect={{ '/': '/channels' }}
          routes={[
            {
              path: '/channels',
              title: 'Channels',
              render: () => (
                <ChannelsListPage
                  apiKey={props.apiKey}
                  merchant={props.merchant}
                  merchantIntegrations={merchantIntegrations}
                  rentalConfiguration={props.rentalConfiguration}
                  onUpdateIntegration={onUpdateIntegration}
                />
              )
            },
            {
              path: '/reports',
              title: 'Reports',
              render: () => <ReferralsPage />
            },
            {
              path: '/payouts',
              title: 'Payment Settings',
              render: () => <PayoutsPage merchant={props.merchant} />
            }
          ]}
        />
      </PageTitle>
    </>
  );
};
