import styled from 'styled-components';
import { IoArrowForward } from 'react-icons/io5';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';

export const RequirementItem = (props: { name: string; description: string }): JSX.Element => (
  <RequirementItemContainer>
    <RequirementCheckboxContainer>
      <Checkbox label={props.name} checked disabled />
    </RequirementCheckboxContainer>
    <RequirementContentContainer>
      <RequirementTitle>{props.name}</RequirementTitle>
      <RequirementDescription>{props.description}</RequirementDescription>
    </RequirementContentContainer>
  </RequirementItemContainer>
);

export const RequirementPage = ({
  items,
  onNext
}: {
  items: Array<{ name: string; description: string }>;
  onNext: () => void;
}): JSX.Element => (
  <>
    {items.map((item) => (
      <RequirementItem key={item.name} name={item.name} description={item.description} />
    ))}
    <ButtonContainer center>
      <Button onClick={() => onNext()} icon={<IoArrowForward />} primary>
        Agree
      </Button>
    </ButtonContainer>
  </>
);

export const RequirementItemContainer = styled.div`
  padding: 16px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

export const RequirementCheckboxContainer = styled.div`
  width: 30px;
`;

export const RequirementContentContainer = styled.div`
  width: calc(100% - 30px);
`;

export const RequirementDescription = styled.div<{ marginTop?: string }>`
  color: #999999;
  font-size: 0.9em;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '5px')};
`;

export const RequirementTitle = styled.div`
  font-weight: 500;
`;

export const RequirementError = styled.div`
  font-size: 0.8em;
  margin-top: 10px;
  color: #d1344b;
`;
