import styled from 'styled-components';
import { useId } from 'react-id-generator';

const CheckboxContainer = styled.div<{ disabled?: boolean; checked?: boolean }>`
  .checkbox-symbol {
    pointer-events: none;
    user-select: none;
  }

  .checkbox-container {
    box-sizing: border-box;
    background: #ffffff;
  }

  .checkbox-container * {
    box-sizing: border-box;
  }

  .checkbox-input {
    position: absolute;
    visibility: hidden;
  }

  .checkbox {
    user-select: none;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    overflow: hidden;
    transition: all 0.15s ease;
    display: flex;
  }

  .checkbox span {
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    position: relative;
    margin-top: 2px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    transform: scale(1);
    border: 1px solid #cccfdb;
    transition: all 0.15s ease;
    background: ${(props) => (props.disabled ? '#ffffff' : props.checked ? '#0EA5E9' : 'white')};
    opacity: ${(props) => (props.disabled ? '0.3' : '1.0')};
  }

  .checkbox span svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.15s ease;
    transform: translate3d(0, 0, 0);
  }

  .checkbox-input:checked + .checkbox span {
    background: #0ea5e9;
    border-color: #0ea5e9;
    animation: zoom-in-out 0.15s ease;
  }

  .checkbox-input:checked + .checkbox span svg {
    stroke-dashoffset: 0;
  }

  @keyframes zoom-in-out {
    50% {
      transform: scale(0.9);
    }
  }
`;

export interface CheckboxProps {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

export const Checkbox = (props: CheckboxProps): JSX.Element => {
  const [id] = useId();
  return (
    <CheckboxContainer disabled={props.disabled} checked={props.checked}>
      <div className="checkbox-container">
        <input
          className="checkbox-input"
          id={id}
          type="checkbox"
          checked={props.checked}
          disabled={props.disabled}
          onInput={props.onChange}
          readOnly
        />
        <label className="checkbox" htmlFor={id}>
          {props.label && <span style={{ display: 'none' }}>{props.label}</span>}
          <span aria-hidden>
            <svg className="checkbox-symbol" width="10px" height="8px" viewBox="0 0 12 10">
              <polyline
                points="1.5 6 4.5 9 10.5 1"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </span>
        </label>
      </div>
    </CheckboxContainer>
  );
};
