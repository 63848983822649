import React from 'react';

export const useWindowScrollPosition = (): {
  scrollPosX: number;
  scrollPosY: number;
} => {
  const [scrollPosX, setScrollPosX] = React.useState(window.scrollX);
  const [scrollPosY, setScrollPosY] = React.useState(window.scrollY);

  React.useEffect(() => {
    const onscroll = () => {
      setScrollPosX(window.scrollX);
      setScrollPosY(window.scrollY);
    };

    window.addEventListener('scroll', onscroll);
    return () => window.removeEventListener('scroll', onscroll);
  }, [setScrollPosX, setScrollPosY]);

  return { scrollPosX, scrollPosY };
};

export const useWindowDimensions = (): {
  innerWidth: number;
  innerHeight: number;
} => {
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const onresize = () => {
      setInnerWidth(window.innerWidth);
      setInnerHeight(window.innerHeight);
    };

    window.addEventListener('resize', onresize);
    return () => window.removeEventListener('resize', onresize);
  }, [setInnerWidth, setInnerHeight]);

  return { innerWidth, innerHeight };
};

export const openPageInNewTab = (url: string): Window | null => {
  return window.open(url, '_blank');
};

export const openAsyncPageInNewTab = (
  urlPromise: Promise<string | null | undefined | void>
): Window | null => {
  // First open an empty tab synchronously
  const w = openPageInNewTab('');
  if (w != null) {
    // Resolve location when promise is finished
    urlPromise.then((url) => {
      // If the URL is resolved correctly, set the location, otherwise close the window
      if (url) {
        w.location = url;
      } else {
        w.close();
      }
    });
  }

  return w;
};
