import React from 'react';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router';

export const useGA = (trackingId: string): void => {
  useHistory();

  React.useEffect(() => {
    ReactGA.initialize(trackingId);
  }, []);

  React.useEffect(() => {
    const obj = { hitType: 'pageview', page: window.location.pathname + window.location.search };
    ReactGA.send(obj);
  }, [window.location.href]);
};
