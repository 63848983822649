import React from 'react';
import styled from 'styled-components';
import { useId } from 'react-id-generator';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const FormRowContainer = styled.div<{
  breakMobile?: boolean;
  alignItems?: string;
  singleItem?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'normal')};
  gap: 16px;
  width: 100%;
  max-width: ${(props) => (props.singleItem ? 'calc(50% - 8px)' : '100%')};

  @media (max-width: 600px) {
    max-width: 100%;
    flex-direction: ${(props) => (props.breakMobile ? 'column' : 'row')};
  }
`;

const FormRowHeaderContainer = styled.div<{ paddingTop?: string }>`
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '24px')};
`;

const FormRowHeaderDescription = styled.div`
  color: #999999;
  font-size: 0.9em;
  padding-top: 4px;
`;

const FormRowHeaderTitle = styled.div`
  font-size: 1.1em;
  font-weight: 500;
`;

const FormRowError = styled.div`
  font-size: 0.8em;
  color: #d1344b;
`;

interface FormRowProps {
  error?: string | false;
  breakMobile?: boolean;
  alignItems?: string;
  singleItem?: boolean;
}

export const FormRow: React.FunctionComponent<
  React.PropsWithChildren<React.AllHTMLAttributes<HTMLDivElement> & FormRowProps>
> = (props) => (
  <>
    <FormRowContainer {...props}>{props.children}</FormRowContainer>
    {props.error && <FormRowError>{props.error}</FormRowError>}
  </>
);

interface FormRowHeaderProps {
  title?: string;
  description?: string;
  paddingTop?: string;
}

export const FormRowHeader: React.FunctionComponent<React.PropsWithChildren<FormRowHeaderProps>> = (
  props
) => (
  <FormRowHeaderContainer paddingTop={props.paddingTop}>
    {props.title && <FormRowHeaderTitle>{props.title}</FormRowHeaderTitle>}
    {props.description && <FormRowHeaderDescription>{props.description}</FormRowHeaderDescription>}
  </FormRowHeaderContainer>
);

const FormColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 0;
`;

const FormColumnTitle = styled.label`
  font-weight: 500;
`;

const FormColumnDescription = styled.div`
  padding-bottom: 8px;
  font-size: 0.8em;
  color: #666666;
`;

const FormColumnContents = styled.div``;

// Temporary enum to distinguish the desired style
// on the merchant form vs. the  consumer forms. we'll
// unify these styles once we have a design language.
export enum FormColumnStyle {
  merchant = 'merchant'
}

interface FormColumnProps {
  title?: string;
  description?: string;
  label?: string;
  optional?: boolean;
  formColumnStyle?: FormColumnStyle;
}

export const FormColumn: React.FunctionComponent<
  React.PropsWithChildren<React.AllHTMLAttributes<HTMLDivElement> & FormColumnProps>
> = (props) => {
  const [id] = useId();

  return (
    <FormColumnContainer {...props}>
      <FormColumnTitle
        htmlFor={id}
        style={{
          display: props.title ? 'block' : 'none',
          fontSize: props.formColumnStyle === FormColumnStyle.merchant ? '1em' : '0.9em',
          color: props.formColumnStyle === FormColumnStyle.merchant ? '#000000' : '#666666'
        }}
        aria-hidden={!props.title && !props.label}
      >
        {props.title || props.label}
        {props.optional && <span style={{ fontWeight: 400, color: '#aaaaaa' }}> (opt.)</span>}
      </FormColumnTitle>
      {props.description && <FormColumnDescription>{props.description}</FormColumnDescription>}
      <FormColumnContents>
        {React.Children.map(props.children, (child) =>
          React.isValidElement(child) ? React.cloneElement(child as JSX.Element, { id }) : child
        )}
      </FormColumnContents>
    </FormColumnContainer>
  );
};
