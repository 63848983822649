import * as React from 'react';
import { IoArrowBack, IoArrowForward, IoLockClosed } from 'react-icons/io5';
import {
  useStripe,
  useElements,
  Elements,
  PaymentElement,
  CardElement
} from '@stripe/react-stripe-js';

import { Button, ButtonContainer, UnstyledButton } from '@oysterjs/ui/Button';
import { ErrorDisplay } from '@oysterjs/ui/Form/text';
import { PageSection } from '@oysterjs/ui/Page';
import { Spinner } from '@oysterjs/ui/Spinner';

import { Loadable } from '@oysterjs/ui/Loadable';
import loadStripe from '@oysterjs/core/stripe';
import { PolicyType, PaymentMethod as IPaymentMethod } from '@oysterjs/types';
import { Table } from '@oysterjs/ui/Table';
import { useHistory } from 'react-router';
import { useAnalytics } from '@oysterjs/core/analytics/analytics';
import { GetOysterEventNames } from '@oysterjs/core/analytics/googletags';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer
} from '@oysterjs/ui/common';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { getDocumentUrl } from '@oysterjs/core/statics';
import { FaCcAmex, FaCcDiscover, FaCcMastercard, FaCcVisa, FaCreditCard } from 'react-icons/fa';
import { Stripe } from '@stripe/stripe-js';

export const PaymentMethod: React.FunctionComponent<
  React.PropsWithChildren<{ paymentMethod: IPaymentMethod }>
> = (props) => (
  <div style={{ display: 'flex', gap: '16px' }}>
    <div style={{ fontSize: '2em', color: '#33BFFF' }}>
      {(() => {
        switch (props.paymentMethod.Brand) {
          case 'visa':
            return <FaCcVisa aria-hidden />;
          case 'mastercard':
            return <FaCcMastercard aria-hidden />;
          case 'discover':
            return <FaCcDiscover aria-hidden />;
          case 'amex':
            return <FaCcAmex aria-hidden />;
          default:
            return <FaCreditCard aria-hidden />;
        }
      })()}
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <div style={{ fontWeight: 'bold' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div>
            {props.paymentMethod.Brand.charAt(0).toUpperCase() +
              props.paymentMethod.Brand.substring(1)}{' '}
            {props.paymentMethod.LastFour}
          </div>
        </div>
      </div>
      <div style={{ color: '#999999', fontSize: '0.9em' }}>
        Expires {props.paymentMethod.ExpMonth}/{props.paymentMethod.ExpYear}
      </div>
    </div>
  </div>
);

const PaymentDisclosure = (props: { policyType?: string; noPadding?: boolean }) => {
  const disclosure = (() => {
    switch (props.policyType) {
      case PolicyType.bike:
      case PolicyType.markelBike:
        return {
          name: 'Cyclist Compliance Disclosure',
          url: 'https://www.withoyster.com/cyclist-compliance-disclosures'
        };
      case PolicyType.minicoJewelry:
        return {
          name: 'Jewelry Compliance Disclosure',
          url: 'https://www.withoyster.com/jewelry-compliance-disclosures'
        };
      case PolicyType.worthAveElectronics:
        return {
          name: 'Electronics Compliance Disclosure',
          url: 'https://www.withoyster.com/electronics-compliance-disclosures'
        };
      default:
        return {
          name: 'Electronic Transaction Consent',
          url: 'https://www.withoyster.com/consent-to-electronic-transactions'
        };
    }
  })();

  return (
    <div style={{ padding: props.noPadding ? '0px 0px 0px 0px' : '0px 0px 16px 0px' }}>
      <p style={{ color: '#999999', fontSize: '0.8em' }}>
        By submitting this form, you allow Oyster to charge your card for future payments and agree
        to Oyster's{' '}
        <a target="_blank" href="https://www.withoyster.com/terms-conditions">
          Terms & Conditions
        </a>{' '}
        ,{' '}
        <a target="_blank" href="https://www.withoyster.com/privacy-policy">
          Privacy Policy
        </a>
        , and{' '}
        <a target="_blank" href={disclosure.url}>
          {disclosure.name}
        </a>
        .
      </p>
    </div>
  );
};

export const SubmissionDisclosure = (props: { policyType?: string; noPadding?: boolean }) => {
  const disclosure = (() => {
    switch (props.policyType) {
      case PolicyType.bike:
      case PolicyType.markelBike:
        return {
          name: 'Cyclist Compliance Disclosure',
          url: 'https://www.withoyster.com/cyclist-compliance-disclosures'
        };
      case PolicyType.minicoJewelry:
        return {
          name: 'Jewelry Compliance Disclosure',
          url: 'https://www.withoyster.com/jewelry-compliance-disclosures'
        };
      case PolicyType.worthAveElectronics:
        return {
          name: 'Electronics Compliance Disclosure',
          url: 'https://www.withoyster.com/electronics-compliance-disclosures'
        };
      default:
        return {
          name: 'Electronic Transaction Consent',
          url: 'https://www.withoyster.com/consent-to-electronic-transactions'
        };
    }
  })();

  return (
    <div style={{ padding: props.noPadding ? '0px 0px 0px 0px' : '0px 0px 16px 0px' }}>
      <p style={{ color: '#999999', fontSize: '0.8em' }}>
        By continuing with this application, you agree to Oyster's{' '}
        <a target="_blank" href="https://www.withoyster.com/terms-conditions">
          Terms & Conditions
        </a>{' '}
        ,{' '}
        <a target="_blank" href="https://www.withoyster.com/privacy-policy">
          Privacy Policy
        </a>
        , and{' '}
        <a target="_blank" href={disclosure.url}>
          {disclosure.name}
        </a>
        .
      </p>
    </div>
  );
};

const PaymentCollection = (props: {
  returnUrl: string;
  continueLabel?: string;
  setupOnly: boolean;
  onBack?: ((e) => void) | null;
  policyType?: string;
  underwriter?: string;
  hideLegal?: boolean;
  defaultName?: string;
  defaultEmail?: string;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [hasConsent, setHasConsent] = React.useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const analytics = useAnalytics();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (props.policyType && props.policyType == PolicyType.chubbJewelry && !hasConsent) {
      setError('Please agree to the Electronic Transaction Consent.');
      return;
    }

    setLoading(true);
    analytics.event(GetOysterEventNames.PaymentSubmitted);

    const options = {
      elements,
      confirmParams: {
        return_url: props.returnUrl
      }
    };
    const { error } = props.setupOnly
      ? await stripe.confirmSetup(options)
      : await stripe.confirmPayment(options);

    if (error && error.type !== 'validation_error') {
      setError(error.message || '');
    }

    setLoading(false);
  };

  return (
    <>
      <form style={{ padding: '16px 0px 0px 0px' }}>
        <PaymentElement
          options={{
            terms: { card: 'never' },
            defaultValues: {
              billingDetails: { name: props.defaultName, email: props.defaultEmail }
            }
          }}
        />
      </form>
      {!props.hideLegal && (
        <PaymentDisclosure
          policyType={props.policyType}
          noPadding={
            !!props.policyType &&
            ((props.policyType == PolicyType.chubbJewelry && !!props.underwriter) ||
              props.policyType == PolicyType.markelBike)
          }
        />
      )}
      {props.policyType && props.policyType == PolicyType.markelBike && (
        <div style={{ padding: '0px 0px 0px 0px' }}>
          <p style={{ color: '#999999', fontSize: '0.8em' }}>
            Insurance Underwritten By: Markel Group Inc.
          </p>
        </div>
      )}

      {props.policyType && props.policyType == PolicyType.chubbJewelry && (
        <>
          {props.underwriter && (
            <div style={{ padding: '0px 0px 0px 0px' }}>
              <p style={{ color: '#999999', fontSize: '0.8em' }}>
                Insurance Underwritten By:{' '}
                {props.underwriter == 'FEDERAL' ? 'Federal Insurance Co' : props.underwriter}
              </p>
            </div>
          )}
          <RequirementItemContainer
            style={{ padding: '0px', paddingBottom: '16px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setHasConsent(!hasConsent);
            }}
          >
            <RequirementCheckboxContainer>
              <Checkbox
                label={'ElectronincCommunicationsConsent'}
                checked={hasConsent}
                onChange={() => {
                  setHasConsent(!hasConsent);
                }}
              />
            </RequirementCheckboxContainer>
            <RequirementContentContainer>
              <RequirementDescription marginTop="0px">
                I acknowledge that I’ve read and accepted the{' '}
                <a href={getDocumentUrl('chubb_electronic_consent')} target="_blank">
                  Electronic Communications Consent
                </a>
              </RequirementDescription>
            </RequirementContentContainer>
          </RequirementItemContainer>
        </>
      )}

      {(!stripe || !elements) && (
        <div style={{ padding: '16px 0px' }}>
          <Spinner color="#333333" />
        </div>
      )}
      {error && <ErrorDisplay style={{ paddingBottom: '16px' }}>{error}</ErrorDisplay>}

      <PageSection noBorder noPadding centered>
        <ButtonContainer center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            leftIcon={<IoLockClosed />}
            icon={<IoArrowForward />}
            primary
            disabled={!stripe || !elements}
            loading={loading}
            onClick={handleSubmit}
          >
            {props.continueLabel || 'Confirm'}
          </Button>
        </ButtonContainer>
      </PageSection>
    </>
  );
};

export const ShowDefaultPayment = (props: {
  confirmPaymentIntent: () => Promise<string>;
  returnUrl: string;
  defaultPayment: IPaymentMethod;
  continueLabel?: string;
  policyType?: string;
  underwriter?: string;
  onBack?: ((e) => void) | null;
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [hasConsent, setHasConsent] = React.useState(false);

  const history = useHistory();

  const handleSubmit = async () => {
    setError(undefined);
    setLoading(true);
    if (props.policyType && props.policyType == PolicyType.chubbJewelry && !hasConsent) {
      setError('Please agree to the Electronic Communications Consent.');
    }
    props
      .confirmPaymentIntent()
      .then(() => history.push(props.returnUrl.replace(window.location.origin, '')))
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Table>
        <thead>
          <tr>
            <td>Default Payment Method</td>
            <td />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <PaymentMethod paymentMethod={props.defaultPayment} />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              Oyster will use your default payment method to purchase this policy. If you would like
              to use a different payment method, you can change your default{' '}
              <a href={window.location.origin + '/account/billing'} target="_blank">
                here
              </a>
              .
            </td>
            <td />
          </tr>
        </tfoot>
      </Table>
      <PaymentDisclosure
        policyType={props.policyType}
        noPadding={
          !!props.policyType && props.policyType == PolicyType.chubbJewelry && !!props.underwriter
        }
      />
      {props.policyType && props.policyType == PolicyType.chubbJewelry && (
        <>
          {props.underwriter && (
            <div style={{ padding: '0px 0px 0px 0px' }}>
              <p style={{ color: '#999999', fontSize: '0.8em' }}>
                Insurance Underwritten By:{' '}
                {props.underwriter == 'FEDERAL' ? 'Federal Insurance Co' : props.underwriter}
              </p>
            </div>
          )}
          <RequirementItemContainer
            style={{ padding: '0px', paddingBottom: '16px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setHasConsent(!hasConsent);
            }}
          >
            <RequirementCheckboxContainer>
              <Checkbox
                label={'ElectronincCommunicationsConsent'}
                checked={hasConsent}
                onChange={() => {
                  setHasConsent(!hasConsent);
                }}
              />
            </RequirementCheckboxContainer>
            <RequirementContentContainer>
              <RequirementDescription marginTop="0px">
                I acknowledge that I’ve read and accepted the{' '}
                <a target="_blank" href={getDocumentUrl('chubb_electronic_consent')}>
                  Electronic Communications Consent
                </a>
              </RequirementDescription>
            </RequirementContentContainer>
          </RequirementItemContainer>
        </>
      )}
      <PageSection noBorder noPadding centered>
        <ButtonContainer center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            leftIcon={<IoLockClosed />}
            icon={<IoArrowForward />}
            loading={loading}
            primary
            onClick={handleSubmit}
          >
            {props.continueLabel || 'Confirm'}
          </Button>
        </ButtonContainer>
      </PageSection>
      {error && <ErrorDisplay style={{ paddingBottom: '16px' }}>{error}</ErrorDisplay>}
    </>
  );
};

export const PaymentMethodInput: React.FunctionComponent<{
  getPaymentIntent: () => Promise<string>;
  returnUrl: string;
  continueLabel?: string;
  policyType?: string;
  setupOnly: boolean;
  onBack?: ((e) => void) | null;
  underwriter?: string;
  hideLegal?: boolean;
  defaultName?: string;
  defaultEmail?: string;
}> = (props) => (
  <Loadable request={props.getPaymentIntent()}>
    {(clientSecret) => (
      <Elements
        stripe={loadStripe()}
        options={{
          clientSecret,
          fonts: [
            {
              family: 'Inter',
              style: 'normal',
              weight: '400',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2')",
              unicodeRange:
                'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '400',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2')",
              unicodeRange:
                'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '500',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2')",
              unicodeRange:
                'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '500',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2')",
              unicodeRange:
                'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '600',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZFhiI2B.woff2) format('woff2')",
              unicodeRange:
                'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '600',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hiA.woff2) format('woff2')",
              unicodeRange:
                'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '700',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2')",
              unicodeRange:
                'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '700',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2')",
              unicodeRange:
                'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
            }
          ],
          appearance: {
            theme: 'stripe',
            variables: {
              fontFamily: "'Inter', 'Helvetica Neue', 'Helvetica', Arial, sans-serif",
              borderRadius: '20px',
              fontSizeBase: '1em',
              fontWeightNormal: '400',
              colorPrimary: '#0EA5E9',
              colorBackground: '#ffffff',
              colorText: '#000000',
              colorDanger: '#d1344b',
              spacingGridRow: '16px',
              spacingGridColumn: '24px'
            },
            rules: {
              '.Label, .Input, .Error': {
                letterSpacing: '-0.2px'
              },
              '.Label': {
                color: '#666666',
                fontSize: '0.9em',
                fontWeight: '500'
              },
              '.Input': {
                padding: '8px 16px',
                border: '2px solid #e8e8e8',
                boxShadow: '0'
              },
              '.Input:focus': {
                boxShadow: '0',
                borderColor: 'var(--colorPrimary)'
              },
              '.Input--invalid': {
                boxShadow: '0',
                color: '#000000'
              },
              '.Error': {
                fontSize: '0.8em',
                marginTop: '5px'
              }
            }
          }
        }}
      >
        <PaymentCollection
          returnUrl={props.returnUrl}
          continueLabel={props.continueLabel}
          onBack={props.onBack}
          policyType={props.policyType}
          setupOnly={props.setupOnly}
          underwriter={props.underwriter}
          hideLegal={props.hideLegal}
          defaultName={props.defaultName}
          defaultEmail={props.defaultEmail}
        />
      </Elements>
    )}
  </Loadable>
);

const CardElementTokenCollection = (props: {
  onTokenCreated: (token: string) => void;
  onError: (error: string) => void;
  onBack: ((e) => unknown) | null;
  onSkip: () => void;
  canSkip: boolean;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const stripe = useStripe();
  const elements = useElements();
  const analytics = useAnalytics();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    analytics.event(GetOysterEventNames.PaymentMethodSubmitted);

    const cardElements = elements.getElement('card')!;
    const result = await stripe.createToken(cardElements);
    if (result.error) {
      if (result.error.type !== 'validation_error') {
        props.onError(result.error.message || '');
      } else {
        setError(result.error.message);
      }
    } else {
      props.onTokenCreated(result.token.id);
    }

    setLoading(false);
  };

  return (
    <>
      <form style={{ padding: '16px 0px 0px 0px' }}>
        <div style={{ padding: '16px', border: '2px solid #eaeaea', borderRadius: '8px' }}>
          <CardElement />
        </div>
        <div style={{ padding: '0px 0px 0px 0px' }}>
          <p style={{ color: '#999999', fontSize: '0.8em' }}>
            Please note that this policy is underwritten by Coterie. By submitting this form, you
            allow Coterie to make direct charges to this payment method.
          </p>
        </div>
      </form>
      {(!stripe || !elements) && (
        <div style={{ padding: '16px 0px' }}>
          <Spinner color="#333333" />
        </div>
      )}
      {error && <ErrorDisplay style={{ paddingBottom: '16px' }}>{error}</ErrorDisplay>}

      <PageSection noBorder noPadding centered>
        <ButtonContainer center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            leftIcon={<IoLockClosed />}
            icon={<IoArrowForward />}
            primary
            disabled={!stripe || !elements}
            loading={loading}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </ButtonContainer>
        {props.canSkip && (
          <ButtonContainer center style={{ marginTop: '16px', fontSize: '0.85em' }}>
            <UnstyledButton onClick={props.onSkip}>
              <u>Get connected with an Oyster representative</u>
            </UnstyledButton>
          </ButtonContainer>
        )}
      </PageSection>
    </>
  );
};

export const CardElementTokenCollectionInput: React.FunctionComponent<{
  onTokenCreated: (token: string) => void;
  onError: (error: string) => void;
  onBack: ((e) => unknown) | null;
  onSkip: () => void;
  stripeProvider: () => Promise<Stripe | null>;
  canSkip: boolean;
}> = (props) => (
  <Elements
    stripe={props.stripeProvider()}
    options={{
      fonts: [
        {
          family: 'Inter',
          style: 'normal',
          weight: '400',
          display: 'swap',
          src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2')",
          unicodeRange:
            'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
        },
        {
          family: 'Inter',
          style: 'normal',
          weight: '400',
          display: 'swap',
          src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2')",
          unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
        },
        {
          family: 'Inter',
          style: 'normal',
          weight: '500',
          display: 'swap',
          src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2')",
          unicodeRange:
            'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
        },
        {
          family: 'Inter',
          style: 'normal',
          weight: '500',
          display: 'swap',
          src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2')",
          unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
        },
        {
          family: 'Inter',
          style: 'normal',
          weight: '600',
          display: 'swap',
          src: "url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZFhiI2B.woff2) format('woff2')",
          unicodeRange:
            'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
        },
        {
          family: 'Inter',
          style: 'normal',
          weight: '600',
          display: 'swap',
          src: "url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hiA.woff2) format('woff2')",
          unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
        },
        {
          family: 'Inter',
          style: 'normal',
          weight: '700',
          display: 'swap',
          src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2')",
          unicodeRange:
            'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
        },
        {
          family: 'Inter',
          style: 'normal',
          weight: '700',
          display: 'swap',
          src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2')",
          unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
        }
      ],
      appearance: {
        theme: 'stripe',
        variables: {
          fontFamily: "'Inter', 'Helvetica Neue', 'Helvetica', Arial, sans-serif",
          borderRadius: '20px',
          fontSizeBase: '1em',
          fontWeightNormal: '400',
          colorPrimary: '#0EA5E9',
          colorBackground: '#ffffff',
          colorText: '#000000',
          colorDanger: '#d1344b',
          spacingGridRow: '16px',
          spacingGridColumn: '24px'
        },
        rules: {
          '.Label, .Input, .Error': {
            letterSpacing: '-0.2px'
          },
          '.Label': {
            color: '#666666',
            fontSize: '0.9em',
            fontWeight: '500'
          },
          '.Input': {
            padding: '8px 16px',
            border: '2px solid #e8e8e8',
            boxShadow: '0'
          },
          '.Input:focus': {
            boxShadow: '0',
            borderColor: 'var(--colorPrimary)'
          },
          '.Input--invalid': {
            boxShadow: '0',
            color: '#000000'
          },
          '.Error': {
            fontSize: '0.8em',
            marginTop: '5px'
          }
        }
      }
    }}
  >
    <CardElementTokenCollection {...props} />
  </Elements>
);
