import React from 'react';

import {
  addRentalAssetSerialNumber,
  createRentalWaiver,
  getMerchantRentalAssets,
  getMerchantRentalConfiguration,
  getRentalWaiver,
  getWaiverPricing,
  updateRentalWaiver
} from '@oysterjs/core/api/merchant';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { FormContainer, FormRowHeader, FormRow, FormColumn } from '@oysterjs/ui/Form/builder';
import { SearchableSelect, Select } from '@oysterjs/ui/Form/select';
import { ErrorDisplay, TextInput, TextInputComponent } from '@oysterjs/ui/Form/text';
import { Spinner } from '@oysterjs/ui/Spinner';
import { DatePicker } from '@oysterjs/ui/Form/datepicker';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';
import {
  RentalAsset,
  RentalWaiver,
  RentalBooking,
  Price,
  ValidationError,
  WaiverAsset,
  AssetAccessory,
  WaiverState,
  WaiverEntry,
  BillingMode,
  MerchantRentalConfiguration,
  RentalBookingState
} from '@oysterjs/types';
import styled from 'styled-components';
import { RentalPageContainer } from './components';
import { openAsyncPageInNewTab } from '@oysterjs/core/window';
import config from '@oysterjs/core/config';

const CoveragePriceAmount = styled.span`
  font-weight: 100;
  font-size: 1.2em;
  vertical-align: baseline;
`;

const getCurrentTime = (): Date => {
  const date = new Date();
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

const minimumRentalDuration = (config?: MerchantRentalConfiguration) =>
  config?.Details.AutoRenewWaiver === true ? 7 : 1;

export const RentalWaiverForm = (props: {
  waiverId?: string;
  onWaiverCreated: (
    booking: RentalBooking,
    waiver: RentalWaiver,
    showConfirmationInDashboard: boolean
  ) => void;
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [priceLoading, setPriceLoading] = React.useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = React.useState<number>(0);
  const [inputChanged, setInputChanged] = React.useState(false);
  const [showDatePicker, setShowDatePicker] = React.useState(false);

  const [rentalConfiguration, setRentalConfiguration] =
    React.useState<MerchantRentalConfiguration>();
  const [availableAssets, setAvailableAssets] = React.useState<RentalAsset[]>([]);
  const [waiver, setWaiver] = React.useState<RentalWaiver>();
  const [booking, setBooking] = React.useState<RentalBooking>();
  const [startTime, setStartTime] = React.useState<Date>(getCurrentTime());
  const [durationInDays, setDurationInDays] = React.useState<number>(1);
  const [waiverPrice, setWaiverPrice] = React.useState<Price>({ Currency: 'usd', Amount: 0 });
  const [pricingUpdateIndex, setPricingUpdateIndex] = React.useState<number>(0);

  const [error, setError] = React.useState<string>();
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [serialNumberErrors, setSerialNumberErrors] = React.useState<(boolean | undefined)[]>([]);

  const fetchData = async () => {
    setLoading(true);

    // Fetch rental configuration
    const res = await getMerchantRentalConfiguration();
    const minDurationInDays = res.Configuration ? minimumRentalDuration(res.Configuration) : 1;
    const autoRenewWaiver = !!res.Configuration?.Details.AutoRenewWaiver;
    setRentalConfiguration(res.Configuration);

    // Set the initial minimum duration (for renewable waivers, it should be 7 days)
    setDurationInDays(minDurationInDays);

    // Fetch assets
    const assets = await getMerchantRentalAssets();
    setAvailableAssets(assets);

    let entry: WaiverEntry | undefined;
    if (props.waiverId) {
      entry = await getRentalWaiver(props.waiverId);
      setBooking(entry.Booking);
      setWaiver(entry.Waiver);
      const e = new Date(entry.Booking?.EndTime || '');
      const s = new Date(entry.Booking?.StartTime || '');
      setStartTime(s);
      setDurationInDays(Math.max((e.valueOf() - s.valueOf()) / 86400000, minDurationInDays));
    }

    // Fetch booking
    if (!entry || !entry.Booking) {
      const endTime = new Date(startTime);
      endTime.setDate(endTime.getDate() + minDurationInDays);
      setBooking({
        ID: '',
        MerchantID: '',
        State: RentalBookingState.Confirmed,
        StartTime: startTime,
        EndTime: endTime,
        Details: {
          Assets: [assets[0]],
          Accessories: [],
          BookingReference: '',
          Insured: {
            FirstName: '',
            LastName: '',
            Email: ''
          },
          AutoRenew: autoRenewWaiver
        }
      });
    }

    // Fetch waiver
    if (!entry || !entry.Waiver) {
      setWaiver({
        ID: '',
        MerchantID: '',
        RentalBookingID: '',
        State: WaiverState.pending,
        Details: {
          BillingMode: BillingMode.Customer,
          Assets: [rentalAssetToWaiverAsset(assets[0])],
          WaiverReferenceNumber: '',
          Premium: {
            Base: 0,
            Total: 0,
            Currency: 'usd',
            MerchantFeeAmount: 0,
            OysterFeeAmount: 0,
            ProcessingFeeAmount: 0
          },
          PremiumRounding: {
            Amount: 0,
            Currency: 'usd'
          }
        }
      });
    }

    setLoading(false);
    setPricingUpdateIndex((i) => ++i);
  };

  const rentalAssetToWaiverAsset = (rentalAsset: RentalAsset): WaiverAsset => ({
    Asset: rentalAsset,
    Accessories: [],
    Premium: {
      Currency: 'usd',
      Base: 0,
      MerchantFeeAmount: 0,
      OysterFeeAmount: 0,
      ProcessingFeeAmount: 0,
      Total: 0
    }
  });

  React.useEffect(() => {
    fetchData();
  }, []);

  //================================================================
  // BOOKING, WAIVERS, AND ASSETS LOGIC
  //================================================================

  // Create a list of unique asset names
  const assetNames = Object.keys(
    availableAssets.map((a) => a.Name).reduce((acc, curr) => ({ ...acc, [curr]: true }), {})
  );

  // For each unique asset name, create a list of all the serial numbers and associated asset IDs
  const assetSerialNumbers = Object.fromEntries(
    assetNames.map((name) => [
      name,
      availableAssets
        .filter((a) => a.Name === name)
        .map((a) => ({ serialNumber: a.Details.SerialNumber, assetId: a.ID }))
        .filter((s) => !!s)
    ])
  );

  const onChangeBooking = (block: (booking: RentalBooking) => RentalBooking) => {
    if (booking) {
      setInputChanged(true);
      setBooking({ ...block(booking) });
    } else {
      throw new Error('Booking data change attempted without being initialized');
    }
  };

  const onChangeWaiver = (block: (waiver: RentalWaiver) => RentalWaiver) => {
    if (waiver) {
      setInputChanged(true);
      setWaiver({ ...block(waiver) });
    } else {
      throw new Error('Waiver data change attempted without being initialized');
    }
  };

  const onAddAsset = () => {
    if (booking) {
      onChangeBooking((currentBooking) => ({
        ...currentBooking,
        Details: {
          ...currentBooking.Details,
          Assets: [...(currentBooking.Details.Assets || []), availableAssets[0]]
        }
      }));
    }

    if (waiver) {
      onChangeWaiver((currentWaiver) => ({
        ...currentWaiver,
        Details: {
          ...currentWaiver.Details,
          Assets: [
            ...currentWaiver.Details.Assets,
            {
              Asset: availableAssets[0],
              Accessories: [],
              Premium: {
                Currency: 'usd',
                Base: 0,
                MerchantFeeAmount: 0,
                OysterFeeAmount: 0,
                ProcessingFeeAmount: 0,
                Total: 0
              }
            }
          ]
        }
      }));
    }
  };

  const onRemoveAsset = () => {
    clearSerialNumberError(waiver ? waiver.Details.Assets.length - 1 : 0);

    if (booking) {
      onChangeBooking((currentBooking) => {
        const updatedAssets = currentBooking.Details.Assets || [];
        updatedAssets.splice(updatedAssets.length - 1, 1);
        return {
          ...currentBooking,
          Details: {
            ...currentBooking.Details,
            Assets: updatedAssets
          }
        };
      });

      setPricingUpdateIndex((i) => ++i);
    }

    if (waiver) {
      onChangeWaiver((currentWaiver) => {
        const updatedAssets = currentWaiver.Details.Assets;
        updatedAssets.splice(updatedAssets.length - 1, 1);
        return {
          ...currentWaiver,
          Details: {
            ...currentWaiver.Details,
            Assets: updatedAssets
          }
        };
      });

      setPricingUpdateIndex((i) => ++i);
    }
  };

  //================================================================
  // SERIAL NUMBERS LOGIC
  //================================================================

  const setSerialNumberError = (index: number) =>
    setSerialNumberErrors((errors) => {
      const newErrors = [...errors];
      newErrors[index] = true;
      return newErrors;
    });

  const clearSerialNumberError = (index: number) =>
    setSerialNumberErrors((errors) => {
      const newErrors = [...errors];
      newErrors[index] = undefined;
      return newErrors;
    });

  const selectSerialNumber = (index: number, serialNumber: string, assets: RentalAsset[]) => {
    if (!serialNumber) {
      setSerialNumberError(index);
      return;
    }

    const selectedAsset = assets.find((ast) => ast.Details.SerialNumber === serialNumber);

    if (!selectedAsset) {
      setSerialNumberError(index);
      return;
    }

    onChangeWaiver((currentWaiver) => {
      currentWaiver.Details.Assets[index].Asset = selectedAsset;
      return currentWaiver;
    });

    onChangeBooking((currentBooking) => {
      if (currentBooking.Details.Assets) {
        currentBooking.Details.Assets[index] = selectedAsset;
      }
      return currentBooking;
    });

    setPricingUpdateIndex((i) => ++i);
    clearSerialNumberError(index);
  };

  const handleSelectSerialNumber = (index: number) => (serialNumber: string) =>
    selectSerialNumber(index, serialNumber, availableAssets);

  const handleAddSerialNumber = (index: number) => async (serialNumber: string) => {
    if (loading) {
      return;
    }

    setLoading(true);

    const assetId = waiver?.Details.Assets[index]?.Asset?.ID;
    if (!assetId) {
      throw new Error('Asset not found');
    }

    try {
      await addRentalAssetSerialNumber(assetId, serialNumber);
      const assets = await getMerchantRentalAssets();

      selectSerialNumber(index, serialNumber, assets);
      setAvailableAssets(assets);

      setError(undefined);
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  //================================================================
  // ACCESSORIES LOGIC
  //================================================================

  const onAddAccessory = (assetIndex: number, accessory: AssetAccessory) => {
    if (waiver) {
      const updatedAsset = {
        ...waiver.Details.Assets[assetIndex]
      };
      updatedAsset.Accessories = [...updatedAsset.Accessories, accessory];
      const updatedAssets = [
        ...waiver.Details.Assets.slice(0, assetIndex),
        updatedAsset,
        ...waiver.Details.Assets.slice(assetIndex + 1, waiver.Details.Assets.length)
      ];

      onChangeWaiver((currentWaiver) => {
        return {
          ...currentWaiver,
          Details: {
            ...currentWaiver.Details,
            Assets: updatedAssets
          }
        };
      });
    }

    if (booking) {
      onChangeBooking((currentBooking) => {
        return {
          ...currentBooking,
          Details: {
            ...currentBooking.Details,
            Accessories: [...currentBooking.Details.Accessories, accessory]
          }
        };
      });
    }

    setPricingUpdateIndex((i) => ++i);
  };

  const onRemoveAccessory = (assetIndex: number, accessory: AssetAccessory) => {
    if (waiver) {
      const updatedAsset = {
        ...waiver.Details.Assets[assetIndex]
      };
      const indexToRemove = updatedAsset.Accessories.findIndex(
        (acc) => acc.Name === accessory.Name && acc.Value === accessory.Value
      );
      if (indexToRemove !== -1) {
        updatedAsset.Accessories.splice(indexToRemove, 1);
        const updatedAssets = [
          ...waiver.Details.Assets.slice(0, assetIndex),
          updatedAsset,
          ...waiver.Details.Assets.slice(assetIndex + 1, waiver.Details.Assets.length)
        ];

        onChangeWaiver((currentWaiver) => {
          return {
            ...currentWaiver,
            Details: {
              ...currentWaiver.Details,
              Assets: updatedAssets
            }
          };
        });
      }
    }

    if (booking) {
      const indexToRemove = booking.Details.Accessories.findIndex(
        (acc) => acc.Name === accessory.Name && acc.Value === accessory.Value
      );

      if (indexToRemove !== -1) {
        onChangeBooking((currentBooking) => {
          const updatedAccessories = currentBooking.Details.Accessories;
          updatedAccessories.splice(indexToRemove, 1);
          return {
            ...currentBooking,
            Details: {
              ...currentBooking.Details,
              Accessories: updatedAccessories
            }
          };
        });
      }
    }

    setPricingUpdateIndex((i) => ++i);
  };

  //================================================================
  //  DATE AND TIME LOGIC
  //================================================================

  const getStartTimeOptions = () => {
    const options: { value: string; displayValue: string }[] = [];
    for (let i = 0; i < 24; i++) {
      options.push({ value: i.toString(), displayValue: `${i.toString().padStart(2, '0')}:00` });
    }

    return options;
  };

  const updateBookingTimes = () => {
    if (booking) {
      onChangeBooking((currentBooking) => {
        currentBooking.StartTime = startTime;
        currentBooking.EndTime = new Date(startTime);
        currentBooking.EndTime.setDate(currentBooking.EndTime.getDate() + durationInDays);
        return currentBooking;
      });

      setPricingUpdateIndex((i) => ++i);
    }
  };

  React.useEffect(() => {
    updateBookingTimes();
  }, [startTime, durationInDays]);

  //================================================================
  // PRICING LOGIC
  //================================================================
  // TODO: Maybe we can re-use this somewhere
  function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

    React.useEffect(() => {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
      return () => clearTimeout(timer);
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedPricingUpdateIndex = useDebounce<number>(pricingUpdateIndex, 500);
  React.useEffect(() => {
    if (booking && waiver) {
      setPriceLoading(true);
      getWaiverPricing(booking, waiver)
        .then((updatedWaiver) => {
          onChangeWaiver(() => updatedWaiver);
          setWaiverPrice({
            Currency: updatedWaiver.Details.Premium.Currency,
            Amount: updatedWaiver.Details.Premium.Total
          });
        })
        .finally(() => setPriceLoading(false));
    }
  }, [!!booking, !!waiver, debouncedPricingUpdateIndex]);

  //================================================================
  // CREATE LOGIC
  //================================================================

  const onCreateOrUpdateWaiver = (sendEmail: boolean) => {
    if (booking && waiver) {
      let apiFunc = createRentalWaiver;
      if (booking.ID && waiver.ID) {
        apiFunc = updateRentalWaiver;
      }

      // Check that selected assets have a serial number and that the
      // serial number matches an available asset.
      let hasSerialNumberError = false;
      for (let i = 0; i < waiver.Details.Assets.length; i++) {
        const serialNumber = waiver.Details.Assets[i].Asset?.Details.SerialNumber;
        const asset = availableAssets.find((ast) => ast.Details.SerialNumber === serialNumber);

        if (!serialNumber || !asset) {
          setSerialNumberError(i);
          hasSerialNumberError = true;
        }
      }

      if (hasSerialNumberError) {
        return;
      }

      setSubmitLoading(sendEmail ? 1 : 2);
      setError(undefined);
      setValidationError(undefined);
      setInputChanged(false);
      const updateHandler = apiFunc(booking, waiver, sendEmail)
        .then((res) => {
          props.onWaiverCreated(res.UpdatedBooking, res.UpdatedWaiver, !sendEmail);
          if (!sendEmail) {
            return (
              config().serviceBaseUrl.getoyster +
              `/rental/waiver/${res.UpdatedWaiver.ID}/confirm?from=partner`
            );
          }
        })
        .catch((e) => {
          const err = WrappedError.asWrappedError(e);
          if (err.type() === ErrorType.validationError) {
            setError('');
            setValidationError(err.getValidationError());
          } else {
            setValidationError(undefined);
            setError(err.message);
          }
        })
        .finally(() => setSubmitLoading(0));

      openAsyncPageInNewTab(updateHandler);
    }
  };

  return (
    <RentalPageContainer
      title={props.waiverId ? 'Edit Waiver' : 'Create a Waiver'}
      description="Start a waiver for a customer and send it to them to sign and pay for"
    >
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          updateBookingTimes();
        }}
      >
        {loading && !booking && !waiver && <Spinner color="#333333" />}
        {booking && waiver && (
          <>
            <FormRowHeader title="Customer Information" />
            <FormRow breakMobile>
              <FormColumn title="First Name">
                <TextInput
                  error={
                    !inputChanged &&
                    validationError?.Field === 'FirstName' &&
                    validationError?.Message
                  }
                  value={booking?.Details.Insured.FirstName}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onChangeBooking((b) => {
                      b.Details.Insured.FirstName = value;
                      return b;
                    });
                  }}
                  disabled={!!submitLoading}
                />
              </FormColumn>
              <FormColumn title="Last Name">
                <TextInput
                  error={
                    !inputChanged &&
                    validationError?.Field === 'LastName' &&
                    validationError?.Message
                  }
                  value={booking?.Details.Insured.LastName}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onChangeBooking((b) => {
                      b.Details.Insured.LastName = value;
                      return b;
                    });
                  }}
                  disabled={!!submitLoading}
                />
              </FormColumn>
            </FormRow>
            <FormRow breakMobile>
              <FormColumn title="Email Address">
                <TextInput
                  error={
                    !inputChanged && validationError?.Field === 'Email' && validationError?.Message
                  }
                  value={booking?.Details.Insured.Email}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onChangeBooking((b) => {
                      b.Details.Insured.Email = value;
                      return b;
                    });
                  }}
                  disabled={!!submitLoading}
                />
              </FormColumn>
              <FormColumn title="Phone Number">
                <TextInput
                  error={
                    !inputChanged && validationError?.Field === 'Phone' && validationError?.Message
                  }
                  value={booking?.Details.Insured.Phone}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onChangeBooking((b) => {
                      b.Details.Insured.Phone = value;
                      return b;
                    });
                  }}
                  disabled={!!submitLoading}
                />
              </FormColumn>
            </FormRow>

            <FormRowHeader title="Booking Information" />
            <FormRow breakMobile>
              <FormColumn title="Reference Number">
                <TextInput
                  error={
                    !inputChanged &&
                    validationError?.Field === 'BookingReference' &&
                    validationError?.Message
                  }
                  value={booking?.Details.BookingReference}
                  disabled={!!submitLoading}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onChangeBooking((b) => {
                      b.Details.BookingReference = value;
                      return b;
                    });
                  }}
                />
              </FormColumn>
            </FormRow>
            <FormRow breakMobile>
              <FormColumn title="Start Date">
                <TextInput
                  error={
                    !inputChanged &&
                    validationError?.Field === 'StartDate' &&
                    validationError?.Message
                  }
                  value={startTime.toDateString()}
                  disabled={!!submitLoading}
                  onFocus={() => setShowDatePicker(true)}
                />
              </FormColumn>
              <FormColumn title="Start Time">
                <Select
                  value={startTime.getHours().toString()}
                  options={getStartTimeOptions()}
                  onChange={(newStartHour) => {
                    const updatedTime = new Date(startTime);
                    updatedTime.setHours(newStartHour as number, 0);
                    setStartTime(updatedTime);
                  }}
                  disabled={!!submitLoading}
                />
              </FormColumn>
              <FormColumn title="Duration (days)">
                <TextInputComponent
                  type="number"
                  min={minimumRentalDuration(rentalConfiguration)}
                  value={durationInDays}
                  inputMode="numeric"
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    setDurationInDays(Number(value));
                  }}
                  error={
                    !inputChanged &&
                    validationError?.Field === 'Duration' &&
                    validationError?.Message
                  }
                  disabled={!!submitLoading}
                />
              </FormColumn>
            </FormRow>
            {showDatePicker && (
              <FormRow>
                <DatePicker
                  loading={loading}
                  onDayClick={(date) => {
                    setShowDatePicker(false);
                    date.setHours(
                      startTime.getHours(),
                      startTime.getMinutes(),
                      startTime.getSeconds()
                    );
                    setStartTime(date);
                  }}
                  selected={startTime}
                  disabled={(day) => {
                    const today = new Date(new Date().setHours(0, 0, 0, 0));
                    return day.getTime() < today.setDate(today.getDate());
                  }}
                />
              </FormRow>
            )}

            <FormRowHeader title="Assets" />
            {waiver &&
              waiver.Details.Assets.filter((asset) => !!asset.Asset).map((asset, index) => {
                const getAccessoriesQuantity = (
                  list: AssetAccessory[],
                  accessory: AssetAccessory
                ): number =>
                  list.filter((acc) => acc.Name === accessory.Name && acc.Value === accessory.Value)
                    .length;

                return (
                  <div key={index}>
                    <FormRow>
                      <FormColumn title="Asset">
                        <Select
                          options={assetNames.map((name) => ({ value: name }))}
                          defaultValue={asset.Asset!.Name}
                          onChange={(value) => {
                            const selectedAsset = availableAssets.find((ast) => ast.Name === value);
                            if (!selectedAsset) {
                              throw new Error('Selected asset not found');
                            }

                            onChangeWaiver((currentWaiver) => {
                              currentWaiver.Details.Assets[index].Asset = selectedAsset;
                              currentWaiver.Details.Assets[index].Accessories = [];

                              return currentWaiver;
                            });

                            onChangeBooking((currentBooking) => {
                              if (currentBooking.Details.Assets) {
                                currentBooking.Details.Assets[index] = selectedAsset;
                              }
                              currentBooking.Details.Accessories = [];

                              return currentBooking;
                            });

                            clearSerialNumberError(index);
                            setPricingUpdateIndex((i) => ++i);
                          }}
                        />
                      </FormColumn>
                      <FormColumn title="Serial Number">
                        <SearchableSelect
                          options={assetSerialNumbers[asset.Asset!.Name]
                            .map((a) => a.serialNumber)
                            .filter((s) => !!s)}
                          initialValue={asset.Asset!.Details.SerialNumber}
                          error={
                            serialNumberErrors[index]
                              ? 'Please choose a serial number or enter a new one and click "add"'
                              : undefined
                          }
                          onChange={handleSelectSerialNumber(index)}
                          onSelectOption={handleSelectSerialNumber(index)}
                          onAddOption={handleAddSerialNumber(index)}
                        />
                      </FormColumn>
                    </FormRow>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px'
                      }}
                    >
                      {asset.Asset!.Details.AvailableAccessories?.map((acc) => (
                        <div
                          key={acc.Name}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexGrow: 1
                          }}
                        >
                          <div
                            style={{
                              fontSize: '1em',
                              color: '#333333',
                              fontWeight: 500,
                              flexGrow: 1
                            }}
                          >
                            {acc.Name}
                          </div>
                          <Button
                            style={{ margin: '15px', minWidth: '0px' }}
                            onClick={() => onRemoveAccessory(index, acc)}
                          >
                            -
                          </Button>
                          <h4>{getAccessoriesQuantity(asset.Accessories, acc)}</h4>
                          <Button
                            style={{ margin: '15px', minWidth: '0px' }}
                            onClick={() => onAddAccessory(index, acc)}
                          >
                            +
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button style={{ marginRight: '10px', flexGrow: 1 }} onClick={onAddAsset}>
                Add another
              </Button>
              <Button
                disabled={waiver.Details.Assets.length <= 1}
                style={{ marginLeft: '10px', flexGrow: 1 }}
                onClick={onRemoveAsset}
              >
                Remove asset
              </Button>
            </div>

            {/* Waiver pricing component */}
            <FormRowHeader title="Waiver Price" />
            <div>
              {priceLoading && <Spinner size={20} color="#333333" />}
              {!priceLoading && (
                <CoveragePriceAmount>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: waiverPrice.Currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(waiverPrice.Amount)}
                </CoveragePriceAmount>
              )}
              <p style={{ fontSize: '0.75em', margin: '5px 0px 0px 0px', color: '#999999' }}>
                Due at signing
              </p>
            </div>

            {/* Actions */}
            <ButtonContainer style={{ padding: '24px 0px 0px 0px' }}>
              <Button
                primary
                disabled={!!submitLoading || serialNumberErrors.some((v) => !!v)}
                loading={submitLoading === 1}
                style={{ marginRight: '10px', flexGrow: 1 }}
                onClick={() => onCreateOrUpdateWaiver(true)}
              >
                Send to customer
              </Button>
              <Button
                primary
                disabled={!!submitLoading || serialNumberErrors.some((v) => !!v)}
                loading={submitLoading === 2}
                style={{ marginLeft: '10px', flexGrow: 1 }}
                onClick={() => onCreateOrUpdateWaiver(false)}
              >
                Sign waiver
              </Button>
            </ButtonContainer>
            {error && <ErrorDisplay>error</ErrorDisplay>}
          </>
        )}
      </FormContainer>
    </RentalPageContainer>
  );
};
