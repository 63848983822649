import { getToken, resetToken } from '@oysterjs/core/auth';
import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import {
  DeepPartial,
  Merchant,
  MerchantUser,
  MerchantIntegration,
  ReferralLinkIntegration,
  PolicyTableValues,
  MerchantIntegrationType,
  MerchantRentalConfiguration,
  RentalWaiver,
  WaiverEntry,
  RentalAsset,
  RentalBooking,
  RentalClaim,
  AttachmentFile,
  ValidationError,
  Transaction,
  UserReferral,
  MerchantRentalConfigurationDetails,
  Insured,
  BusinessInformation,
  DefaultFileRoles,
  BusinessInsuranceApplication,
  PublicMerchantRentalConfiguration
} from '@oysterjs/types';
import { Delete, Get, getEncodedQueryString, Post, Put } from './base';
import config from '../config';

export const getMerchantGraphQLClient = () => {
  const removeTypenameLink = removeTypenameFromVariables();
  const link = from([
    removeTypenameLink,
    createHttpLink({
      uri: `${config().backendBaseUrl.api}/merchant/graphql/query`,
      credentials: 'include',
      headers: {
        ...(getToken() ? { Authorization: `Bearer ${getToken()}` } : {})
      }
    })
  ]);
  return new ApolloClient({
    cache: new InMemoryCache(),
    link
  });
};

export const createMerchant = (data: {
  Merchant: DeepPartial<Merchant>;
  MerchantUser: Partial<MerchantUser>;
  AccessCode: string;
}) => Post('/merchant', data);

export const merchantUserSignInInit = (email: string, redirect?: string) =>
  Post(
    '/merchant/user/signin/init',
    { Email: email, Redirect: redirect },
    { disableUnauthorizedRedirect: true }
  );

export const merchantUserSignInComplete = (email: string, code: string) =>
  Post<{ Token: string; MerchantUser: MerchantUser }>(
    '/merchant/user/signin/complete',
    { Email: email, LoginCode: code },
    { disableUnauthorizedRedirect: true }
  );

export const merchantUserSignOut = () =>
  Post('/merchant/user/signout', { disableUnauthorizedRedirect: true });

export const getMerchantAccount = (redirectUrl?: string) =>
  Get<{
    Merchant: Merchant;
    MerchantUser: MerchantUser;
    PersonalizationError?: ValidationError;
  }>('/merchant', { redirectUrl });

export const getMerchantIntegrations = (redirectUrl?: string) =>
  Get<{
    ApiKey: string;
    Integrations: MerchantIntegration[];
  }>('/merchant/integration', { redirectUrl });

export const updateMerchantIntegration = (integration: MerchantIntegration) =>
  Put<{ Integration: MerchantIntegration }>(`/merchant/integration/${integration.ID}`, {
    Integration: integration
  });

export const updateMerchantAccount = (data: { Merchant: DeepPartial<Merchant> }) =>
  Put<{ Merchant: Merchant }>('/merchant', { Merchant: data.Merchant });

export const personalizeMerchant = (merchant: Merchant, discardUpdateOnError?: boolean) =>
  Post<{ Merchant: Merchant; NextValidationError?: ValidationError }>(
    `/merchant/personalize?${getEncodedQueryString({
      DiscardUpdateOnError: discardUpdateOnError
    })}`,
    {
      BusinessProfile: merchant.BusinessProfile
    }
  );

export const linkMerchantWithShopify = (
  shop: string,
  integrationData: string,
  redirectUrl: string
) => {
  if (!getToken()) {
    resetToken(redirectUrl);
    return Promise.resolve();
  } else {
    return Post(
      '/merchant/link-integration',
      {
        Type: 'Shopify',
        ExternalID: shop,
        IntegrationData: integrationData
      },
      { redirectUrl }
    );
  }
};

export const linkBIApplicationWithShopify = (
  shop: string,
  integrationData: string,
  redirectUrl: string,
  biApplicationID: string
) => {
  return Post(
    '/merchant/link-bi-integration',
    {
      Type: 'Shopify',
      ExternalID: shop,
      IntegrationData: integrationData,
      BIApplicationID: biApplicationID
    },
    { redirectUrl }
  );
};

export const linkMerchantWithLightspeedRetailRSeries = (
  _: string,
  integrationData: string,
  redirectUrl: string
) => {
  if (!getToken()) {
    resetToken(redirectUrl);
    return Promise.resolve();
  } else {
    return Post(
      '/merchant/link-integration',
      {
        Type: 'LSRetailRSeries',
        IntegrationData: integrationData
      },
      { redirectUrl }
    );
  }
};

export const linkMerchantWithCheckfront = (
  _: string,
  integrationData: string,
  redirectUrl: string
) => {
  if (!getToken()) {
    resetToken(redirectUrl);
    return Promise.resolve();
  } else {
    return Post(
      '/merchant/link-integration',
      {
        Type: 'Checkfront',
        IntegrationData: integrationData
      },
      { redirectUrl }
    );
  }
};

export const requestEmbeddablePage = () =>
  Get<{
    Integration: MerchantIntegration;
  }>('/merchant/integration/embeddable-page').then((res) => res.Integration);

export const requestCheckfront = () =>
  Get<{
    Integration: MerchantIntegration;
  }>('/merchant/integration/checkfront').then((res) => res.Integration);

export const requestQRCode = () =>
  Get<{
    Integration: MerchantIntegration;
    QRCode: string;
  }>('/merchant/integration/QRCode');

export const requestQRCodePdf = () =>
  Get<{ DocumentUrl: string; DocumentZip: string }>('/merchant/integration/QRCode/pdf');

export const getMerchantReferrals = (start: Date, end: Date) =>
  Get<{ Referrals: PolicyTableValues[] }>(
    `/merchant/referrals?startTime=${start.toISOString()}&endTime=${end.toISOString()}`
  );

export const getMerchantTransactions = (start: Date, end: Date) =>
  Get<{ Transactions: Transaction[] }>(
    `/merchant/transactions?startTime=${start.toISOString()}&endTime=${end.toISOString()}`
  );

export const getMerchantLink = (
  integrationType?: MerchantIntegrationType,
  externalID?: string,
  integrationID?: string
) =>
  Get<ReferralLinkIntegration>(
    `/merchant/referrallink?${getEncodedQueryString({
      externalID,
      integrationID,
      integrationType
    })}`
  );

export const getMerchantUsers = () => Get<{ Users: MerchantUser[] }>('/merchant/users');
export const createMerchantUser = (user: Partial<MerchantUser>) =>
  Post('/merchant/users', { MerchantUser: user });

// RENTAL APIs

export const getMerchantRentalConfiguration = (): Promise<{
  Configuration?: MerchantRentalConfiguration;
  ReferralLink?: string;
  QRCodeBase64?: string;
}> => Get('/merchant/rental');

export const getPublicMerchantRentalConfiguration = (
  integrationId: string
): Promise<{
  Configuration: PublicMerchantRentalConfiguration;
}> => Get('/merchant/rental/public', { headers: { 'X-Merchant-Integration-Id': integrationId } });

export const updateMerchantRentalConfiguration = (
  settings: DeepPartial<MerchantRentalConfigurationDetails>
): Promise<{
  Configuration: MerchantRentalConfiguration;
}> => Put('/merchant/rental', { Settings: settings });

export const getMerchantRentalPaymentSetup = (): Promise<{
  PaymentMethod?: { BankName: string; LastFour: string };
  SetupIntentClientSecret?: string;
}> => Get('/merchant/rental/payment');

export const getRentalDocuments = () =>
  Get<{ Metadata: AttachmentFile[] }>(`/merchant/rental/waiverdocuments`).then(
    (d) => d.Metadata || []
  );

export const uploadRentalDocuments = (files: File[]) => {
  const form = new FormData();
  files.forEach((file) => form.append('files[]', file));
  return Post<{ Metadata: AttachmentFile[] }>(`/merchant/rental/waiverdocuments`, undefined, {
    body: form
  }).then((d) => d.Metadata || []);
};

export const deleteRentalDocument = (id: string) =>
  Delete<{ Metadata: AttachmentFile[] }>(`/merchant/rental/waiverdocuments/${id}`).then(
    (d) => d.Metadata || []
  );

export const getRentalWaivers = (): Promise<WaiverEntry[]> =>
  Get<{
    Entries: WaiverEntry[];
  }>('/merchant/rental/waivers').then((d) => d.Entries);

export const getRentalWaiver = (waiverId: string): Promise<WaiverEntry> =>
  Get<{
    Entry: WaiverEntry;
  }>(`/merchant/rental/waiver/${waiverId}`).then((d) => d.Entry);

export const getRentalBooking = (bookingId: string): Promise<WaiverEntry> =>
  Get<{
    Entry: WaiverEntry;
  }>(`/merchant/rental/booking/${bookingId}`).then((d) => d.Entry);

export const createRentalBooking = (
  booking: RentalBooking,
  integrationId?: string
): Promise<{ UpdatedBooking: RentalBooking; UpdatedWaiver?: RentalWaiver }> =>
  Post<{
    Booking: RentalBooking;
    Waiver: RentalWaiver;
  }>(
    '/merchant/rental/booking',
    { Booking: booking },
    { headers: { 'X-Merchant-Integration-Id': integrationId || '' } }
  ).then((d) => ({
    UpdatedBooking: d.Booking,
    UpdatedWaiver: d.Waiver
  }));

export const updateRentalBooking = (
  booking: RentalBooking,
  integrationId?: string
): Promise<{ UpdatedBooking: RentalBooking; UpdatedWaiver?: RentalWaiver }> =>
  Put<{
    Booking: RentalBooking;
    Waiver: RentalWaiver;
  }>(
    `/merchant/rental/booking/${booking.ID}`,
    { Booking: booking },
    { headers: { 'X-Merchant-Integration-Id': integrationId || '' } }
  ).then((d) => ({
    UpdatedBooking: d.Booking,
    UpdatedWaiver: d.Waiver
  }));

export const getWaiverPricing = (
  booking: RentalBooking,
  waiver: RentalWaiver,
  integrationId?: string
): Promise<RentalWaiver> =>
  Post<{
    Waiver: RentalWaiver;
  }>(
    '/merchant/rental/waiver/price',
    { Booking: booking, Waiver: waiver },
    { headers: { 'X-Merchant-Integration-Id': integrationId || '' } }
  ).then((d) => d.Waiver);

export const createRentalWaiver = (
  booking: RentalBooking,
  waiver: RentalWaiver,
  sendEmail: boolean,
  integrationId?: string
): Promise<{ UpdatedBooking: RentalBooking; UpdatedWaiver: RentalWaiver }> =>
  Post<{
    Booking: RentalBooking;
    Waiver: RentalWaiver;
  }>(
    '/merchant/rental/waiver',
    { Booking: booking, Waiver: waiver, SendEmail: sendEmail },
    { headers: { 'X-Merchant-Integration-Id': integrationId || '' } }
  ).then((d) => ({
    UpdatedBooking: d.Booking,
    UpdatedWaiver: d.Waiver
  }));

export const updateRentalWaiver = (
  booking: RentalBooking,
  waiver: RentalWaiver,
  sendEmail: boolean,
  integrationId?: string
): Promise<{ UpdatedBooking: RentalBooking; UpdatedWaiver: RentalWaiver }> =>
  Put<{
    Booking: RentalBooking;
    Waiver: RentalWaiver;
  }>(
    '/merchant/rental/waiver',
    { Booking: booking, Waiver: waiver, SendEmail: sendEmail },
    { headers: { 'X-Merchant-Integration-Id': integrationId || '' } }
  ).then((d) => ({
    UpdatedBooking: d.Booking,
    UpdatedWaiver: d.Waiver
  }));

export const createMerchantRentalAsset = (asset: DeepPartial<RentalAsset>) =>
  Post<{ Asset: RentalAsset }>(`/merchant/rental/assets`, { Asset: asset }).then((r) => r.Asset);

export const deleteMerchantRentalAsset = (assetId: string) =>
  Delete(`/merchant/rental/assets/${assetId}`);

export const addRentalAssetSerialNumber = (
  assetId: string,
  serialNumber: string,
  integrationId?: string
) =>
  Put<{ Asset: RentalAsset }>(
    `/merchant/rental/assets/${assetId}/serialnumber`,
    {
      SerialNumber: serialNumber
    },
    { headers: { 'X-Merchant-Integration-Id': integrationId || '' } }
  ).then((r) => r.Asset);

export const getRentalWaiverPaymentSession = (waiverId: string): Promise<string> =>
  Post<{ StripeClientSecret: string }>(`/merchant/rental/waiver/${waiverId}/payment`).then((d) => {
    return d.StripeClientSecret;
  });

export const getRentalWaiverAgreementInformation = (
  waiverId: string
): Promise<{
  SignedDate: Date;
  CustomerName: string;
  MerchantName: string;
  RentalBookingDate: Date;
  RentalBookingDurationInDays: number;
}> => Post(`/merchant/rental/waiver/${waiverId}/agreement`);

export const completeRentalBooking = (
  bookingId: string,
  signatureData: string,
  insured?: DeepPartial<Insured>
): Promise<RentalBooking | undefined> =>
  Post<{
    Booking: RentalBooking;
  }>(`/merchant/rental/booking/${bookingId}/complete`, {
    Insured: insured,
    SignatureData: signatureData
  }).then((d) => d.Booking);

export const completeRentalWaiver = (
  waiverId: string,
  signatureData: string,
  insured?: DeepPartial<Insured>
): Promise<RentalWaiver | undefined> =>
  Post<{
    Waiver: RentalWaiver;
  }>(`/merchant/rental/waiver/${waiverId}/complete`, {
    Insured: insured,
    SignatureData: signatureData
  }).then((d) => d.Waiver);

export const cancelBookingRenewal = (bookingId: string): Promise<RentalBooking | undefined> =>
  Post<{
    Booking: RentalBooking;
  }>(`/merchant/rental/booking/${bookingId}/cancel-renew`).then((d) => d.Booking);

export const getMerchantRentalAssets = (integrationId?: string): Promise<RentalAsset[]> =>
  Get<{
    Assets?: RentalAsset[];
  }>('/merchant/rental/assets', {
    headers: { 'X-Merchant-Integration-Id': integrationId || '' }
  }).then((d) => d.Assets || []);

export const getRentalBookingFiles = (id: string) =>
  Get<{
    Files: AttachmentFile[] | null;
  }>(`/merchant/rental/booking/${id}/files`).then((res) => res.Files);

export const getRentalClaimByWaiverId = (
  waiverId: string
): Promise<{ Entry: WaiverEntry; Claim?: RentalClaim; Attachments: AttachmentFile[] }> =>
  Get<{
    Entry: WaiverEntry;
    Claim?: RentalClaim;
    Attachments: AttachmentFile[];
  }>(`/merchant/rental/waiver/${waiverId}/claim`);

export const getRentalClaim = (
  id: string
): Promise<{ Entry: WaiverEntry; Claim: RentalClaim; Attachments: AttachmentFile[] }> =>
  Get<{
    Entry: WaiverEntry;
    Claim: RentalClaim;
    Attachments: AttachmentFile[];
  }>(`/merchant/rental/claim/${id}`);

export const createRentalClaim = (claim: RentalClaim): Promise<RentalClaim> =>
  Post<{ UpdatedClaim: RentalClaim }>(`/merchant/rental/claim`, {
    Claim: claim
  }).then((res) => res.UpdatedClaim);

export const updateRentalClaim = (id: string, claim: RentalClaim): Promise<RentalClaim> =>
  Put<{ UpdatedClaim: RentalClaim }>(`/merchant/rental/claim/${id}`, {
    Claim: claim
  }).then((res) => res.UpdatedClaim);

export const uploadClaimAttachments = (claimId: string, fileRole: string, files: File[]) => {
  const form = new FormData();
  files.forEach((file) => form.append('files[]', file));
  form.append('FileRole', fileRole);
  return Post<{ Metadata: AttachmentFile[] }>(
    `/merchant/rental/claim/${claimId}/attachment`,
    undefined,
    {
      body: form
    }
  ).then((d) => d.Metadata || []);
};

export const getMerchantReferralList = () =>
  Get<{ Referrals: UserReferral[] }>('/merchant/referral/list').then((d) => d.Referrals || []);

export const getMerchantBIApplication = (applicationID: string) =>
  Get<{ Application: BusinessInsuranceApplication }>(`/merchant/bi/${applicationID}`).then(
    (d) => d.Application
  );

export const updateMerchantBIApplication = (
  applicationID: string,
  businessInformation: BusinessInformation
) =>
  Put<{ Application: BusinessInsuranceApplication; NextValidationError?: ValidationError }>(
    `/merchant/bi/${applicationID}`,
    {
      BusinessInformation: businessInformation
    }
  );

export const createMerchantBIApplication = (businessInformation: BusinessInformation) =>
  Post<{ Application: BusinessInsuranceApplication; NextValidationError?: ValidationError }>(
    `/merchant/bi`,
    {
      BusinessInformation: businessInformation
    }
  );

export const uploadCommercialDocuments = (
  applicationID: string,
  fileRole: DefaultFileRoles,
  files: File[],
  existingFileIDs?: string[]
) => {
  const form = new FormData();
  form.append('FileRole', fileRole);
  if (existingFileIDs) {
    form.append('ExistingFileIDs', JSON.stringify(existingFileIDs));
  }
  files.forEach((file) => form.append('files[]', file));
  return Post<{ Metadata: AttachmentFile[] }>(
    `/merchant/commercial/${applicationID}/documents`,
    undefined,
    {
      body: form
    }
  ).then((res) => res.Metadata);
};

export const triggerCommercialDataExtraction = (applicationID: string) =>
  Post<{ Application: BusinessInsuranceApplication }>(
    `/merchant/commercial/${applicationID}/verify`,
    null
  );

export const retrieveCommercialDataExtraction = (applicationID: string, force: boolean) =>
  Get<{ Completed: boolean; Application: BusinessInsuranceApplication }>(
    `/merchant/commercial/${applicationID}/verify/retrieve?force=${force}`
  );

export const submitBusinessInsuraceApplication = (applicationID: string) =>
  Post<{ Redirect: string; Application: BusinessInsuranceApplication }>(
    `/merchant/bi/${applicationID}/complete`,
    null
  );
