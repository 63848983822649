import { PolicyType } from '@oysterjs/types';
import config from './config';

const getStaticFileUrl = (path: string): string =>
  `${config().backendBaseUrl.statics}/${path.replace(/^\/+/, '')}`;

export const getSamplePolicyUrl = (type: PolicyType): string => {
  switch (type) {
    case PolicyType.minicoJewelry:
    case PolicyType.chubbJewelry:
      return getStaticFileUrl('/policy/sample/m_jewelry');
    case PolicyType.markelOffroad:
      return getStaticFileUrl('/policy/sample/m_offroad');
    case PolicyType.markelMotorcycle:
      return getStaticFileUrl('/policy/sample/m_motorcycle');
    case PolicyType.markelBike:
      return getStaticFileUrl('/policy/sample/m_bike');
    case PolicyType.worthAveElectronics:
      return getStaticFileUrl('/policy/sample/w_electronics');
    default:
      return getStaticFileUrl(`/policy/sample/${type}`);
  }
};

export const getDocumentUrl = (id: string): string => {
  return getStaticFileUrl(`/doc/${id}`);
};

export const getPolicyDocumentUrl = (id: string): string => {
  return getStaticFileUrl(`/policy/doc/${id}`);
};

export const getRentalDocumentUrl = (id: string): string => {
  return getStaticFileUrl(`/rental/doc/${id}`);
};
