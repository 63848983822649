import { DayPicker, DayPickerProps } from 'react-day-picker';
import styled from 'styled-components';
import 'react-day-picker/src/style.css';

const DatePickerContainer = styled.div<{ loading?: boolean }>`
  .rdp {
    opacity: ${(props) => (props.loading ? 0.5 : 1.0)};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
    transition: all 0.15s ease-in-out;

    margin: 0;

    .rdp-caption_label {
      padding: 0;
      border: 0;
    }

    .rdp-day_selected,
    .rdp-day_selected:hover,
    .rdp-day_selected:focus,
    .rdp-day_selected:active {
      background-color: #0ea5e9;
      border: 2px solid transparent;
    }

    .rdp-day,
    .rdp-day:hover,
    .rdp-day:focus,
    .rdp-day:active {
      border: 2px solid transparent;
    }

    .rdp-button:hover:not(.rdp-day_selected) {
      background-color: #e6f9f6;
    }
  }
`;

export declare type DatePickerProps = {
  loading?: boolean;
} & DayPickerProps;

export const DatePicker = (props: DatePickerProps): JSX.Element => {
  return (
    <DatePickerContainer loading={props.loading}>
      <DayPicker {...props} />
    </DatePickerContainer>
  );
};
