import React from 'react';
import { PolicyType } from '@oysterjs/types';
import styled from 'styled-components';
import { FooterMinimal, FooterStandard } from './footer';

const PaneContainer = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${(props) => props.gap}px;

  @media (max-width: 700px) {
    display: block;
  }
`;

const Pane = styled.div<{ gap: number; paneWidth: number }>`
  width: calc(${(props) => props.paneWidth}% - ${(props) => props.gap / 2}px);

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const TwoPaneContainer: React.FunctionComponent<
  React.PropsWithChildren<{
    gap?: number;
    leftPaneWidth?: number;
    rightPane: JSX.Element;
  }>
> = (props) => (
  <PaneContainer gap={props.gap === undefined ? 40 : props.gap}>
    <Pane gap={props.gap === undefined ? 40 : props.gap} paneWidth={props.leftPaneWidth || 70}>
      {props.children}
    </Pane>
    <Pane
      gap={props.gap === undefined ? 40 : props.gap}
      paneWidth={100 - (props.leftPaneWidth || 70)}
    >
      {props.rightPane}
    </Pane>
  </PaneContainer>
);

export enum FooterStyle {
  none,
  minimal,
  standard
}

const PageContainerDiv = styled.div<{
  width?: number | string;
  marginTop?: number;
  marginRight?: number;
  marginLeft?: number;
  marginBottom?: number;
}>`
  max-width: ${(props) =>
    props.width ? (isNaN(Number(props.width)) ? props.width : `${props.width}px`) : '1000px'};
  width: 100%;
  margin-top: ${(props) => (props.marginTop !== undefined ? `${props.marginTop}px` : 'auto')};
  margin-right: ${(props) => (props.marginRight !== undefined ? `${props.marginRight}px` : 'auto')};
  margin-bottom: ${(props) =>
    props.marginBottom !== undefined ? `${props.marginBottom}px` : 'auto'};
  margin-left: ${(props) => (props.marginLeft !== undefined ? `${props.marginLeft}px` : 'auto')};
`;

export const PageContainer: React.FunctionComponent<
  React.PropsWithChildren<{
    width?: number | string;
    footerStyle?: FooterStyle;
    marginTop?: number;
    marginRight?: number;
    marginLeft?: number;
    marginBottom?: number;
    policyType?: PolicyType;
    footerTextColor?: string;
  }>
> = (props) => {
  return (
    <PageContainerDiv
      width={props.width}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
    >
      {props.children}
      {(props.footerStyle === undefined || props.footerStyle === FooterStyle.standard) && (
        <FooterStandard policyType={props.policyType} color={props.footerTextColor} />
      )}
      {props.footerStyle === FooterStyle.minimal && <FooterMinimal color={props.footerTextColor} />}
    </PageContainerDiv>
  );
};

export const PageSection = styled.div<{
  noBorder?: boolean;
  noPadding?: boolean;
  centered?: boolean;
  customPadding?: string;
}>`
  padding: ${(props) =>
    props.customPadding ? props.customPadding : props.noPadding ? '0px 40px' : '20px 40px'};
  border-bottom: ${(props) => (props.noBorder ? '0' : '1px solid #f8f8f8')};
  ${(props) =>
    !props.centered
      ? ``
      : `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}

  h2 {
    color: #666666;
  }
`;
