import * as React from 'react';
import styled from 'styled-components';

const DigitsInputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Digit = styled.input`
  border: 0;
  outline: none;
  box-shadow: none;
  appearance: none;

  border-radius: 15px;
  border: 2px solid #e8e8e8;
  box-sizing: border-box;
  font-size: 1.8em;
  font-weight: 500;

  width: 60px;
  height: 72px;
  text-align: center;

  transition: 0.15s border ease-in-out;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:focus {
    border: 2px solid #0ea5e9;
  }

  @media (max-width: 500px) {
    width: 48px;
    height: 58px;
    font-size: 1.4em;
  }

  @media (max-width: 400px) {
    width: 42px;
    height: 50px;
    font-size: 1.2em;
  }
`;

export const DigitsInput = (props: {
  digits: number;
  initialDigits?: string;
  disabled?: boolean;
  onComplete: (value: string) => void;
  onInputChange: (value: string) => void;
}): JSX.Element => {
  const refs = [...new Array(props.digits)].map(() => React.useRef<HTMLInputElement>(null));
  const [digits, setDigits] = React.useState(
    (() => {
      const digits = new Array(props.digits).fill('');
      (props.initialDigits || '').split('').forEach((v, i) => {
        if (i < digits.length) {
          digits[i] = v;
        }
      });
      return digits;
    })()
  );

  React.useEffect(() => {
    refs[0].current?.focus();
  }, []);

  return (
    <DigitsInputContainer>
      {[...new Array(props.digits)].map((_, i) => (
        <React.Fragment key={i}>
          <label style={{ display: 'none' }} htmlFor={`otp-digits-${i}`}>
            Digit ${i + 1}
          </label>
          <Digit
            id={`otp-digits-${i}`}
            ref={refs[i]}
            value={digits[i]}
            disabled={props.disabled}
            inputMode="numeric"
            autoComplete={i === 0 ? 'one-time-password' : undefined}
            onKeyUp={(e) => {
              if (e.key === 'Backspace') {
                refs[i - 1]?.current?.focus();
              }
            }}
            onFocus={(e) => {
              e.currentTarget.select();
            }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setDigits((prev) => {
                const next = [...prev];
                next[i] = '';
                value.split('').forEach((v, j) => {
                  if (i + j < next.length) {
                    next[i + j] = v;
                  }
                });
                props.onInputChange(next.join(''));
                if (i + value.length >= next.length) {
                  props.onComplete(next.join(''));
                }
                if (next[i]) {
                  refs[i]?.current?.blur();
                  refs[i + value.length]?.current?.focus();
                }
                return next;
              });
            }}
          ></Digit>
        </React.Fragment>
      ))}
    </DigitsInputContainer>
  );
};
