import styled from 'styled-components';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0px;

  tr td,
  tr th {
    padding: 16px 16px;
    vertical-align: top;
    vertical-align: middle;
    border-bottom: 1px solid #f2f2f2;

    :first-child {
      padding-left: 0px;
    }

    :last-child {
      padding-right: 0px;
      vertical-align: middle;
    }
  }

  thead tr td,
  thead tr th {
    padding: 8px 16px;
    font-weight: 600;
    font-size: 0.85em;
    color: #666666;
    border-bottom: 3px solid #eaeaea;
  }

  tbody tr {
    td.labeled {
      div:last-child {
        color: #999999;
        font-size: 0.85em;
        margin-top: 5px;
      }
    }
  }

  @media (max-width: 600px) {
    thead tr td {
      padding: 8px 8px;
    }

    tr td {
      padding: 16px 8px;
    }
  }
`;
